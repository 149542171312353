import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FranchiserComponent } from './franchiser.component';
import {HomeMenuPageguard} from '../home/home-menu.pageguard';
import {FranchiserPageguard} from './franchiser.pageguard';

const routes: Routes = [{
    path: 'franchiser/:parent-franchiser-id/:franchiser-id', component: FranchiserComponent, canActivate: [HomeMenuPageguard, FranchiserPageguard]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FranchiserRoutingModule { }
