import { QuestionBase } from './question-base';
import {SmartraceSettings} from './smartrace-settings';
import CycleInfo = SmartraceSettings.CycleInfo;

export class RaceCycleQuestion extends QuestionBase<CycleInfo> {
    controlType = 'race-cycle';
    save: Function;

    constructor(options: {} = {}) {
        super(options);
        this.save = options['save'];
    }
}
