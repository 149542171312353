import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DynamicFormComponent} from './dynamic-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DynamicFormQuestionComponent} from './dynamic-form-question/dynamic-form-question.component';
import {CustomMatCheckboxComponent} from './custom-mat-checkbox.component';
import {MaterialImportsModule} from '../../material-imports.module';
import {SharedModule} from '../../shared.module';
import {CustomMatSliderComponent} from './custom-mat-slider.component';
import {CustomTimeframeComponent} from './custom-time-frame/custom-timeframe.component';
import {DateInputComponent} from './datei-input/date-input.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialImportsModule,
    SharedModule
  ],
  declarations: [DynamicFormComponent, DynamicFormQuestionComponent, CustomMatCheckboxComponent, CustomMatSliderComponent,
    CustomTimeframeComponent, DateInputComponent],
    exports: [DynamicFormComponent, CustomMatSliderComponent, DateInputComponent]
})
export class DynamicFormModule {
}
