<h1 mat-dialog-title>{{'ticketDetail.livesports.secureCodeDialog.title' | translate}}</h1>
<div mat-dialog-content>
  <p>{{'ticketDetail.livesports.secureCodeDialog.message' | translate}}</p>
  <mat-form-field>
    <input type="password" matInput [(ngModel)]="data.secureCode"
           [placeholder]="'ticketDetail.livesports.secureCodeDialog.placeholder' | translate">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{'ticketDetail.livesports.secureCodeDialog.cancel' | translate}}</button>
  <button [disabled]="!data?.secureCode" mat-button [mat-dialog-close]="data.secureCode">
    {{'ticketDetail.livesports.secureCodeDialog.ok' | translate}}
  </button>
</div>
