import {TreeNodeType} from '../domain/tree-node-type';
import {StationType} from '../domain/station-type';

export class StationUtils {
  public static getTextIcon(type: TreeNodeType, stationType: StationType) {
    if (type === TreeNodeType.FRANCHISER) {
      return 'F'; // + (isTest ? 'T' : '') + StationUtils.getLockedSuffix(isSoftLocked, isHardLocked) + '.png';
    } else {
      switch (stationType) {
        case StationType.TERMINAL:
          return 'T'; // + StationUtils.getLockedSuffix(isSoftLocked, isHardLocked) + '.png';
        case StationType.CASHIER:
          return 'C'; // .png';
        case StationType.SHOP:
          return 'S'; // + StationUtils.getLockedSuffix(isSoftLocked, isHardLocked) + '.png';
        case StationType.WEBBRIDGE:
          return 'B'; // + StationUtils.getLockedSuffix(isSoftLocked, isHardLocked) + '.png';
        case StationType.WEBSHOP:
          return 'W'; // + StationUtils.getLockedSuffix(isSoftLocked, isHardLocked) + '.png';
      }
    }
  }

  public static getTextIconForGroup(userId: number, groupId: number, isPublic: boolean) {
    if (userId === groupId) {
      return isPublic ? 'V' : 'L';
    }
    return '';
  }

  public static getIcon(type: TreeNodeType, stationType: StationType, isTest = false, isSoftLocked = false, isHardLocked = false) {
    if (type === TreeNodeType.FRANCHISER) {
      return 'F' + (isTest ? 'T' : '') + StationUtils.getLockedSuffix(isSoftLocked, isHardLocked) + '.png';
    } else {
      switch (stationType) {
        case StationType.TERMINAL:
          return 'T' + StationUtils.getLockedSuffix(isSoftLocked, isHardLocked) + '.png';
        case StationType.CASHIER:
          return 'C.png';
        case StationType.SHOP:
          return 'S' + StationUtils.getLockedSuffix(isSoftLocked, isHardLocked) + '.png';
        case StationType.WEBBRIDGE:
          return 'B' + StationUtils.getLockedSuffix(isSoftLocked, isHardLocked) + '.png';
        case StationType.WEBSHOP:
          return 'W' + StationUtils.getLockedSuffix(isSoftLocked, isHardLocked) + '.png';
      }
    }
  }

  public static getLockedSuffix(isSoftLocked: boolean, isHardLocked: boolean) {
    return isHardLocked ? '_hl' : (isSoftLocked ? '_sl' : '');
  }
}
