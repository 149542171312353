<table>
  <tr>
    <td>{{'ticketDetail.ticketNumber' | translate}}</td>
    <td>{{ticket?.number}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.code' | translate}}</td>
    <td>{{ticket?.code ? ticket?.code : '---'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.eventID' | translate}}</td>
    <td>{{ticketData?.raceNumber + ' (' +ticket?.raceID+')'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.stationID' | translate}}</td>
    <td>{{ticket?.stationID}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.stakeTotalGross' | translate}}</td>
    <td>{{(ticket?.stake + ticket?.tax) | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td class="indented">{{'ticketDetail.tax' | translate}}</td>
    <td>{{ticket?.tax | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td class="indented">{{'ticketDetail.stake' | translate}}</td>
    <td>{{ticket?.stake | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.profitTotalGross' | translate}}</td>
    <td class="bold">{{(ticket?.profit + ticket?.winTax + ticket?.jackpotProfit) | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td class="indented">{{'ticketDetail.profitNet' | translate}}</td>
    <td>{{ticket?.profit | number: '0.2-2'}}</td>
  <tr>
  <tr>
    <td class="indented">{{'ticketDetail.jackpotProfit' | translate}}</td>
    <td>{{ticket?.jackpotProfit | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td class="indented">{{'ticketDetail.winTax' | translate}}</td>
    <td>{{ticket?.winTax | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.wonProfit' | translate}}</td>
    <td class="bold">{{(ticket?.profit + ticket?.jackpotProfit) | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.inDate' | translate}}</td>
    <td>{{ticket?.inDate | date: 'medium'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.calcDate' | translate}}</td>
    <td>{{ticket?.calcDate | date: 'medium'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.outDate' | translate}}</td>
    <td>{{ticket?.outDate | date: 'medium'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.jackpotTicket' | translate}}</td>
    <td><input type="checkbox" disabled [checked]="ticket?.jackpotTicket"></td>
  </tr>
  <tr>
    <td>{{'ticketDetail.smartrace.raceCycle' | translate}}</td>
    <td>{{ticketData?.raceCycleDescription}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.status' | translate}}</td>
    <td>
      <vit-ticket-status [ticket]="ticket"></vit-ticket-status>
    </td>
  </tr>
  <tr>
    <td>{{'ticketDetail.smartrace.result' | translate}}</td>
    <td>{{ticketData?.first + ' - ' + ticketData?.second + ' - ' + ticketData?.third}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.smartrace.doubleWin' | translate}}</td>
    <td><input type="checkbox" disabled [checked]="ticket?.doubleWin"></td>
  </tr>
</table>
<table class="bets">
  <tr>
    <td>{{'ticketDetail.smartrace.type' | translate}}</td>
    <td>{{'ticketDetail.smartrace.text' | translate}}</td>
    <td>{{'ticketDetail.smartrace.odds' | translate}}</td>
    <td>{{'ticketDetail.smartrace.stake' | translate}}</td>
    <td>{{'ticketDetail.smartrace.status' | translate}}</td>
  </tr>
  <tr *ngFor="let bet of ticketData?.bets">
    <td>{{bet.betTypeText}}</td>
    <td>{{bet.text}}</td>
    <td>{{bet.odd | number: '0.2-2'}}</td>
    <td>{{bet.stake | number: '0.2-2'}}</td>
    <td>
      <vit-bet-status [betCalculated]="bet.calculated"
                      [betWon]="bet.won"
                      [betStorno]="bet.storno"></vit-bet-status>
    </td>
  </tr>
</table>
