<vit-search-form (submitEmiter)="fetch($event)"
                 [showWebshops]="true"
                 [hideMinProfit]="true"
                 [hideMinStake]="true"
                 [hideStation]="true"
                 [hideIgnoreTest]="true"
                 [hideStationRestrictions]="true"
                 [hideFranchiser]="true"
                 [hideIgnoreSub]="true"
                 [hideIntoEuro]="true"
                 [hideDetails]="true"
                 [hideMinTickets]="true">
</vit-search-form>
<p class="label-row" *ngIf="rowDataHoldOverview">{{'webShopHoldOverview.userAccounts' | translate}}</p>
<ag-grid-angular
  *ngIf="rowDataHoldOverview"
  (gridReady)="onGridReadyHoldOverview($event)"
  (columnVisible)="storeColumnVisibilityFn('webshopHoldOverview', $event)"
  class="ag-theme-balham"
  style="width: 100%; flex: 1;"
  [gridOptions]="gridOptionsHoldOverview"
  [rowData]="rowDataHoldOverview | async"
  [columnDefs]="columnDefsHoldOverview"
  [detailCellRendererParams]="detailParamsHoldOverview">
</ag-grid-angular>

<p class="label-row" *ngIf="rowDataCashflow">{{'webShopHoldOverview.cashflow' | translate}}</p>
<ag-grid-angular
  *ngIf="rowDataCashflow"
  (gridReady)="onGridReadyCashflow($event)"
  (columnVisible)="storeColumnVisibilityFn('webshopCashflow', $event)"
  class="ag-theme-balham"
  style="width: 100%; flex: 1;"
  [gridOptions]="gridOptionsCashflow"
  [rowData]="rowDataCashflow | async"
  [columnDefs]="columnDefsCashflow">
</ag-grid-angular>

