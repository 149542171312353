import {Component, Inject} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  templateUrl: './securecode-dialog.component.html'
})
export class SecurecodeDialogComponent {

  constructor(public dialogRef: MatDialogRef<SecurecodeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { secureCode: '' }) {
  }

  onNoClick(): void {
    this.data.secureCode = '';
    this.dialogRef.close();
  }

}
