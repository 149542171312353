export interface Franchiser {
  franchiserID: number;
  masterFranchiserID: number;
  name: string;
  test: boolean;
  masterPIN: number;
  softLocked: boolean;
  hardLocked: boolean;

  address: string;
  financialAccountID: string;
  internalAccountID: string;
  vatid: string;
  email: string;
  alertEmail: string;
  info: string;
  invoicePaymentType: number;
  paymentType: number;
  invoiceDeliveryType: number;
  crossPaymentID: string;
  userAccountManagerID: string;
  allowAnonymousCPC: boolean;

  openingHours: string;
  financialAccountID2: string;
  iban: string;
  info2: string;
  contactPerson: string;
  contactMail: string;
  contactPhone: string;
  legalPerson: string;
  legalPhone: string;
  salesPerson: string;

}

export module Franchiser {
  export enum TreeStats {
    NUM_FRANCHISERS = 'numFranchisers',
    NUM_STATIONS = 'numStations',
    NUM_TERMINALS = 'numTerminals',
    NUM_SHOPS = 'numShops',
    NUM_WEBSHOPS = 'numWebshops',
    NUM_WEBBRIDGES = 'numWebBridges',
    NUM_CASHIERS = 'numCashiers',
    NUM_LOCKED = 'numLocked'
  }

}
