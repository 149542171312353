import {Component, OnDestroy, OnInit} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {StationUtils} from '../../utils/station-utils';
import {TreeNodeType} from '../../domain/tree-node-type';
import {getContextMenuItems, isNumber} from '../../../utils';
import {BalanceService} from './balance.service';
import {enrichByStationId, loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {GridReadyEvent} from 'ag-grid-community';
import {map} from 'rxjs/operators';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss']
})
export class BalanceComponent implements OnInit, OnDestroy {

  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;
  showBalanceWarning = false;
  columnDefs;
  gridOptions;
  rowData;
  titleRow;
  detailParams;

  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public balanceService: BalanceService,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public datePipe: DatePipe,
              public httpService: HttpService,
              public decimalPipe: DecimalPipe) {
    this.columnDefs = this.generateColumnDefs();
    this.detailParams = this.generateDetailParams();
    this.gridOptions = this.generateGridOptions();
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('balance', event);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  fetch(query: QueryFilter) {
    this.showBalanceWarning = false;
    if (query) {
      this.query = query;
      this.rowData = this.balanceService.fetchBalances(query).pipe(
        map(data => data.filter(d => {
          const isTitleRow = d.stationID === 0;
          if (isTitleRow) {
            this.titleRow = [d];
            if (d?.stornoOld > 0) {
              this.showBalanceWarning = true;
            }
          }
          return !isTitleRow;
        }))
      );
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('balance.type'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'type',
        cellClass: 'type',
        width: 23,
        cellRenderer: function (params) {
          const icon = StationUtils.getIcon(TreeNodeType.STATION, params.data.type, false, params.data.softLocked, params.data.hardLocked);
          const img = '<img src=\'/assets/img/icons/' + icon + '\'>';
          return params.data.stationID === 0 ? '' : img;
        }
      },
      {
        headerName: this.translationService.translate('balance.stationID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationID',
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40,
        headerTooltip: this.translationService.translate('balance.stationID')
      },
      {
        headerName: this.translationService.translate('balance.stationName'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationName',
        width: BalanceComponent.COLUMN_WIDTH_LARGE,
      },
      {
        headerName: this.translationService.translate('holdOverview.franchiser'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'franchiser',
        hide: true,
        width: BalanceComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('holdOverview.franchiser')
      },
      {
        headerName: this.translationService.translate('balance.cashInGross'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashInGross',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.cashInGross')
      },
      {
        headerName: this.translationService.translate('balance.cashIn'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashIn',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.cashIn')
      },
      {
        headerName: this.translationService.translate('balance.bonus'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'bonus',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.bonusTooltip')
      },
      {
        headerName: this.translationService.translate('balance.cashOut'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashOut',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.cashOut')
      },
      {
        headerName: this.translationService.translate('balance.stationBalance'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationBalance',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right text-highlight',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.stationBalance')
      },
      {
        headerName: this.translationService.translate('balance.balance'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'balance',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right text-highlight-2 text-bold',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.balanceTooltip')
      },
      {
        headerName: this.translationService.translate('balance.balancePercent'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'balancePercent',
        hide: true,
        valueFormatter: this.percentFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.balancePercent')
      },
      {
        headerName: this.translationService.translate('balance.webshopPayIn'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'webshopPayIn',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.webshopPayInTooltip')
      },
      {
        headerName: this.translationService.translate('balance.webshopPayOut'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'webshopPayOut',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.webshopPayOutTooltip')
      },
      {
        headerName: this.translationService.translate('balance.webshopBalance'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'webshopBalance',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right text-highlight',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.webshopBalanceTooltip')
      },
      {
        headerName: this.translationService.translate('balance.onlinePayIn'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'onlinePayIn',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.onlinePayInTooltip')
      },
      {
        headerName: this.translationService.translate('balance.tax'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'tax',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.tax')
      },
      {
        headerName: this.translationService.translate('balance.winTax'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'winTax',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.winTax')
      },
      {
        headerName: this.translationService.translate('balance.stornoNew'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stornoNew',
        hide: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.stornoNew')
      },
      {
        headerName: this.translationService.translate('balance.taxStornoNew'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'taxStornoNew',
        hide: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.taxStornoNew')
      },
      {
        headerName: this.translationService.translate('balance.stornoOld'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stornoOld',
        hide: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.stornoOld')
      },
      {
        headerName: this.translationService.translate('balance.taxStornoOld'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'taxStornoOld',
        hide: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.taxStornoOld')
      },
      {
        headerName: this.translationService.translate('balance.identifier'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'identifier',
        hide: true,
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.identifier')
      },
      {
        headerName: this.translationService.translate('balance.deposit'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'deposit',
        hide: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.deposit')
      },
      {
        headerName: this.translationService.translate('balance.withdrawal'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'withdrawal',
        hide: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.withdrawal')
      },
      {
        headerName: this.translationService.translate('balance.jackpotContribution'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'jackpotContribution',
        hide: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.jackpotContributionTooltip')
      },
      {
        headerName: this.translationService.translate('balance.internalAccountID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'internalAccountID',
        hide: true,
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.internalAccountID')
      },
      {
        headerName: this.translationService.translate('balance.thirdPartyWSBalance'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'thirdPartyWSBalance',
        hide: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.thirdPartyWSBalance')
      },
      {
        headerName: this.translationService.translate('balance.thirdPartyBalance'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'thirdPartyBalance',
        hide: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.thirdPartyBalance')
      },
      {
        headerName: this.translationService.translate('balance.thirdPartyBalancePercent'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'thirdPartyBalancePercent',
        hide: true,
        valueFormatter: this.percentFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balance.thirdPartyBalancePercent')
      }
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: true,
      detailRowHeight: 160,
      isRowMaster: (data) => data.stationID !== 0,
      rowClassRules: {
        'bold': (params) => params.data.stationID === 0,
        'text-align-right': (params) => isNumber(params.data.value)
      },
      onCellClicked: (params) => {
        params.node.setExpanded(!params.node.expanded);
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }


  generateDetailParams() {
    return {
      detailGridOptions: {
        toolPanelSuppressSideButtons: false,
        enableSorting: true,
        toolPanelSuppressValues: true,
        toolPanelSuppressRowGroups: true,
        toolPanelSuppressPivotMode: true,
        enableFilter: true,
        enableColResize: true,
        columnDefs: [
          {
            headerName: this.translationService.translate('balance.game'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'stationName',
            width: BalanceComponent.COLUMN_WIDTH
          },
          {
            headerName: this.translationService.translate('balance.cashIn'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'cashIn',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: BalanceComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balance.cashInTooltip')
          },
          {
            headerName: this.translationService.translate('balance.tax'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'tax',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: BalanceComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balance.taxTooltip')
          },
          {
            headerName: this.translationService.translate('balance.cashOut'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'cashOut',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: BalanceComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balance.cashOutTooltip')
          },
          {
            headerName: this.translationService.translate('balance.winTax'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'winTax',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: BalanceComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balance.winTax')
          },
          {
            headerName: this.translationService.translate('balance.balance'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'balance',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: BalanceComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balance.balance')
          }
        ],
        onCellClicked: (params) => {
          params.node.setExpanded(!params.node.expanded);
        }
      },
      getDetailRowData: (params) => {
        this.query.stationID = params.data.stationID;
        this.balanceService.fetchBalancesPerGame(this.query).subscribe(data => {
          params.successCallback(enrichByStationId(data, params.data.stationID));
        });
      }
    };
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }

}
