import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {SessionService} from './session.service';
import {SystemConfig} from '../config/system-config';
import {Observable, throwError} from 'rxjs';
import {HoldOverviewDataRow} from '../domain/hold-overview-data-row';
import {QueryFilter} from '../domain/query-filter';
import {TicketsDataRow} from '../domain/tickets-data-row';
import {map, tap} from 'rxjs/operators';
import {TicketState} from '../domain/ticket-state';
import {GameNumber} from '../domain/game-number';

@Injectable({
  providedIn: 'root'
})
export class HoldOverviewService {

  private lastHoldOverviewQuery: QueryFilter;
  private lastHoldOverviewPerGameQuery: QueryFilter;
  private lastTicketListQuery: QueryFilter;
  private lastTicketQuery: QueryFilter;
  private lastHoldOverviewFranchiserQuery: QueryFilter;
  private lastHoldOverviewFranchiserPerGameQuery: QueryFilter;

  constructor(public httpService: HttpService,
              public sessionService: SessionService) {
  }

  fetchHoldOverviewFranchiser(query: QueryFilter): Observable<HoldOverviewDataRow[]> {
    return this.httpService.post(SystemConfig.Endpoints.HOLD_OVERVIEW_FRANCHISER, query).pipe(tap(() => this.lastHoldOverviewFranchiserQuery = query));
  }

  fetchHoldOverviewFranchiserPerGame(query: QueryFilter): Observable<HoldOverviewDataRow[]> {
    return this.httpService.post(SystemConfig.Endpoints.HOLD_OVERVIEW_FRANCHISER_PER_GAME, query).pipe(tap(() => this.lastHoldOverviewFranchiserPerGameQuery = query));
  }

  fetchHoldOverview(query: QueryFilter): Observable<HoldOverviewDataRow[]> {
    return this.httpService.post(SystemConfig.Endpoints.HOLD_OVERVIEW, query).pipe(tap(() => this.lastHoldOverviewQuery = query));
  }

  fetchHoldOverviewPerGame(query: QueryFilter): Observable<HoldOverviewDataRow[]> {
    return this.httpService.post(SystemConfig.Endpoints.HOLD_OVERVIEW_GAME, query).pipe(tap(() => this.lastHoldOverviewPerGameQuery = query));
  }

  fetchTicketList(query: QueryFilter, gameNumber: GameNumber, lastTicketNumber?: number): Observable<{ tickets: TicketsDataRow[] }> {
    let endpoint;
    try {
      endpoint = this.getTicketListEndpoint(gameNumber, lastTicketNumber);
    } catch (e) {
      return throwError(e);
    }
    return this.httpService.post(endpoint, query).pipe(map((res: { tickets: TicketsDataRow[] }) => {
      if (res && res.tickets && res.tickets.length > 0) {
        res.tickets.forEach(this.setState);
      }
      this.lastTicketListQuery = query;
      return res;
    }));
  }

  fetchTicketListForMatchEditor(query: any, allTickets: boolean): Observable<{ tickets: TicketsDataRow[] }> {
    return this.httpService.post(allTickets ? SystemConfig.Endpoints.ME_ALL_TICKETS : SystemConfig.Endpoints.MATCH_EDITOR_LB2_TICKET_LIST, query)
      .pipe(map((tickets: TicketsDataRow[]) => {
        if (tickets?.length > 0) {
          tickets.forEach(this.setState);
        }
        return {tickets};
      }));
  }

  fetchEnhancedTicketList(query: any): Observable<{ tickets: TicketsDataRow[] }> {
    return this.httpService.post(SystemConfig.Endpoints.LB2_TICKET_LIST, query)
      .pipe(map((tickets: TicketsDataRow[]) => {
        if (tickets?.length > 0) {
          tickets.forEach(this.setState);
        }
        return {tickets};
      }));
  }

  fetchTicket(query: QueryFilter, gameNumber: GameNumber): Observable<any> {
    let endpoint;
    try {
      endpoint = this.getTicketEndpoint(gameNumber); // if gameNumber == KENOBET_OVERFLOW use KENOBET instead
    } catch (e) {
      return throwError(e);
    }
    return this.httpService.post(endpoint, query).pipe(tap(() => this.lastTicketQuery = query));
  }

  setState(tdr: TicketsDataRow) {
    if (!tdr.storno && tdr.cashOut) {
      tdr.state = TicketState.CASHOUT;
    } else if (!tdr.calculated && !tdr.storno) {
      if (tdr.preCalcStatus === 1) {
        tdr.state = TicketState.PENDING_1;
      } else if (tdr.preCalcStatus === 2) {
        tdr.state = TicketState.PENDING_2;
      } else {
        tdr.state = TicketState.PENDING;
      }
    } else if (tdr.calculated && !tdr.storno && !tdr.won) {
      tdr.state = TicketState.LOST;
    } else if (tdr.calculated && !tdr.storno && tdr.won && tdr.disbursed) {
      tdr.state = TicketState.DISBURSED;
    } else if (tdr.calculated && !tdr.storno && tdr.won) {
      tdr.state = TicketState.WON;
    } else if (tdr.storno) {
      tdr.state = TicketState.STORNO;
    }
  }

  private getTicketListEndpoint(gameNumber: GameNumber, lastTicketNumber?: number) {
    let endpoint;
    switch (gameNumber) {
      case GameNumber.POKERBET:
        endpoint = SystemConfig.Endpoints.HOLD_OVERVIEW_POKERBET_TICKET_LIST;
        break;
      case GameNumber.SMARTRACE:
        endpoint = SystemConfig.Endpoints.HOLD_OVERVIEW_SMARTRACE_TICKET_LIST;
        break;
      case GameNumber.KENOBET:
      case GameNumber.KENOBET_OVERFLOW:
        endpoint = SystemConfig.Endpoints.HOLD_OVERVIEW_KENOBET_TICKET_LIST;
        break;
      case GameNumber.LIVESPORTS:
      case GameNumber.LIVESPORTS_2:
        endpoint = SystemConfig.Endpoints.HOLD_OVERVIEW_LIVESPORTS_TICKET_LIST;
        break;
      default:
        console.log('unknown', gameNumber);
        throw new Error('unknown gameNumber: ' + gameNumber);
    }
    return endpoint + (lastTicketNumber ? ('/' + lastTicketNumber) : '');
  }

  private getTicketEndpoint(gameNumber: GameNumber) {
    let endpoint;
    switch (gameNumber) {
      case GameNumber.CASH_TICKET:
        endpoint = SystemConfig.Endpoints.HOLD_OVERVIEW_CASH_TICKET;
        break;
      case GameNumber.POKERBET:
        endpoint = SystemConfig.Endpoints.HOLD_OVERVIEW_POKERBET_TICKET;
        break;
      case GameNumber.SMARTRACE:
        endpoint = SystemConfig.Endpoints.HOLD_OVERVIEW_SMARTRACE_TICKET;
        break;
      case GameNumber.KENOBET:
      case GameNumber.KENOBET_OVERFLOW:
        endpoint = SystemConfig.Endpoints.HOLD_OVERVIEW_KENOBET_TICKET;
        break;
      case GameNumber.LIVESPORTS:
      case GameNumber.LIVESPORTS_2:
        endpoint = SystemConfig.Endpoints.HOLD_OVERVIEW_LIVESPORTS_TICKET;
        break;
      default:
        console.log('unknown', gameNumber);
        throw new Error('unknown gameNumber: ' + gameNumber);
    }
    return endpoint;
  }

}
