import {NgModule} from '@angular/core';
import {TranslationPipe} from './app/pipes/translation.pipe';
import { AutofocusDirective } from './app/directives/autofocus.directive';
import {SanitizePipe} from './app/pipes/sanitize.pipe';

@NgModule({
  declarations: [
    TranslationPipe,
    SanitizePipe,
    AutofocusDirective
  ],
  exports: [
    TranslationPipe,
    SanitizePipe,
    AutofocusDirective
  ]
})
export class SharedModule {
}
