import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GameNumber} from '../../domain/game-number';
import {SessionService} from '../../services/session.service';
import {TranslationService} from '../../services/translation.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {SecurecodeDialogComponent} from '../../ticket-detail-livesports/securecode-dialog.component';
import {TicketDetailLivesportsService} from '../../ticket-detail-livesports/ticket-detail-livesports.service';
import {Permission} from '../../domain/permission';

@Component({
  selector: 'vit-cancel-all-bets',
  templateUrl: './cancel-all-bets.component.html',
  styleUrls: ['./cancel-all-bets.component.scss']
})
export class CancelAllBetsComponent implements OnInit {

  @Input()
  ticket: any;

  @Input()
  gameNumer: GameNumber;

  @Output()
  update: EventEmitter<void> = new EventEmitter();

  constructor(public sessionService: SessionService,
              public translationService: TranslationService,
              public ticketDetailLivesportsService: TicketDetailLivesportsService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  click() {
    const dialogRef = this.dialog.open(SecurecodeDialogComponent, {
      data: {secureCode: this.sessionService.secureCode},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(secureCode => {
      if (secureCode && secureCode.length > 0) {
        this.ticketDetailLivesportsService.update(null, this.ticket.number, true, secureCode).subscribe(() => {
          this.sessionService.secureCode = secureCode;
          this.update.emit();
        });
      }
    });
  }

  isAllowed() {
    return this.sessionService.hasPermission(Permission.editLB2TicketBets) && !this.ticket.disbursed && !this.ticket.storno;
  }
}
