<vit-search-form
  (submitEmiter)="fetchCashErrors($event)"
  [hideGames]="true"
  [hideMinProfit]="true"
  [hideMinStake]="true"
  [hideMinTickets]="true"
  [hideIntoEuro]="true"
  [showNow]="true"
  [hideStationRestrictions]="true"
  [hideDetails]="true">
</vit-search-form>
<ag-grid-angular
  *ngIf="rowData"
  (columnVisible)="storeColumnVisibilityFn('stationOverview', $event)"
  class="ag-theme-balham"
  style="width: 100%; flex: 1 1 1px;"
  [gridOptions]="gridOptions"
  [rowData]="rowData | async"
  [columnDefs]="columnDefs"
  (gridReady)="onGridReady($event)">
</ag-grid-angular>

