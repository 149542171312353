import {Component, OnInit} from '@angular/core';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {getContextMenuItems, isNumber} from 'utils';
import {loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {GridApi, GridReadyEvent} from 'ag-grid-community';
import {CustomerInfoService} from './customer-hold.service';
import {tap} from 'rxjs';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-customer-hold',
  templateUrl: './customer-hold.component.html',
  styleUrls: ['./customer-hold.component.scss']
})
export class CustomerHoldComponent implements OnInit {

  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;

  columnDefs;
  gridOptions;
  rowData;
  detailParams;
  rowCount;
  monthMax = 0;
  minMonthValue = 0;
  api: GridApi;

  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public customerInfoService: CustomerInfoService,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public datePipe: DatePipe,
              public decimalPipe: DecimalPipe,
              public httpService: HttpService,
              public dialog: MatDialog) {
    this.columnDefs = this.generateColumnDefs();
    this.gridOptions = this.generateGridOptions();
    this.rowData = this.customerInfoService.fetch().pipe(tap(data => {
      this.monthMax = Math.max(...data.map(x => x.oneMonth));
    }));
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('customerHold', event);
    this.api = event.api;
  }

  ngOnInit() {
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('customerHold.customerID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'customerID',
        width: CustomerHoldComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('customerHold.customerID')
      },
      {
        headerName: this.translationService.translate('customerHold.oneDay'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'oneDay',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CustomerHoldComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('customerHold.oneDayTT')
      },
      {
        headerName: this.translationService.translate('customerHold.oneWeek'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'oneWeek',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CustomerHoldComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('customerHold.oneWeek')
      },
      {
        headerName: this.translationService.translate('customerHold.oneMonth'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'oneMonth',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CustomerHoldComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('customerHold.oneMonth')
      },
      {
        headerName: this.translationService.translate('customerHold.oneYear'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'oneYear',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CustomerHoldComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('customerHold.oneYear')
      },
      {
        headerName: this.translationService.translate('customerHold.currentWeek'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'currentWeek',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CustomerHoldComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('customerHold.currentWeek')
      },
      {
        headerName: this.translationService.translate('customerHold.currentMonth'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'currentMonth',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CustomerHoldComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('customerHold.currentMonth')
      },
      {
        headerName: this.translationService.translate('customerHold.currentYear'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'currentYear',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CustomerHoldComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('customerHold.currentYear')
      },
      {
        headerName: this.translationService.translate('customerHold.total'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'total',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CustomerHoldComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('customerHold.total')
      },
      {
        headerName: this.translationService.translate('customerHold.countOneYear'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'countOneYear',
        valueFormatter: this.integerFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CustomerHoldComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('customerHold.countOneYearTT')
      },
      {
        headerName: this.translationService.translate('customerHold.countTotal'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'countTotal',
        valueFormatter: this.integerFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CustomerHoldComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('customerHold.countTotalTT')
      },
      {
        headerName: this.translationService.translate('customerHold.lastChange'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'currentLastChange',
        valueFormatter: this.dateFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CustomerHoldComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('customerHold.lastChange')
      }
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, { }, params),
      masterDetail: true,
      rowClassRules: {
        'text-align-right': (params) => isNumber(params.data.value),
        'vit-red': (params) => this.calculateDaysOffline(params.data.lastLogin) >= 3
      },
      isExternalFilterPresent: () => this.minMonthValue > 0,
      doesExternalFilterPass: (node) => Math.abs(node.data.oneMonth) >= this.minMonthValue,
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }

  private calculateDaysOffline(lastLogout) {
    return lastLogout ? Math.floor((new Date().getTime() - new Date(lastLogout).getTime()) / (1000 * 60 * 60 * 24)) : null;
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  integerFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.0-0');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }

  onSliderChange(sliderValue: number) {
    this.minMonthValue = sliderValue;
    this.api.onFilterChanged();
  }
}
