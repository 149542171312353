import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {SessionService} from './session.service';
import {SystemConfig} from '../config/system-config';
import {Observable} from 'rxjs';
import {HoldOverviewDataRow} from '../domain/hold-overview-data-row';
import {QueryFilter} from '../domain/query-filter';
import {TicketsDataRow} from '../domain/tickets-data-row';
import {tap} from 'rxjs/operators';
import {TicketState} from '../domain/ticket-state';
import {GameNumber} from '../domain/game-number';

@Injectable({
  providedIn: 'root'
})
export class CashbookService {

  private lastCashbookStationsQuery: QueryFilter;
  public lastCashbookStationsRowCount: number;
  private lastCashbooksQuery: QueryFilter;
  private lastCashbooksPerGameQuery: QueryFilter;

  constructor(public httpService: HttpService,
              public sessionService: SessionService) {
  }

  fetchCashbookStations(query: QueryFilter): Observable<any[]> {
    this.lastCashbookStationsRowCount = null;
    return this.httpService.post(SystemConfig.Endpoints.CASHBOOK_STATIONS, query).pipe(tap((rows: any[]) => {
      this.lastCashbookStationsQuery = query;
      this.lastCashbookStationsRowCount = rows.length;
    }));
  }

  fetchCashbooks(query: QueryFilter): Observable<HoldOverviewDataRow[]> {
    return this.httpService.post(SystemConfig.Endpoints.CASHBOOKS, query).pipe(tap(() => this.lastCashbooksQuery = query));
  }

  fetchCashbooksPerGame(query: QueryFilter): Observable<HoldOverviewDataRow[]> {
    return this.httpService.post(SystemConfig.Endpoints.CASHBOOKS_PER_GAME, query).pipe(tap(() => this.lastCashbooksPerGameQuery = query));
  }
}
