import {Component, Input, OnInit} from '@angular/core';
import {Bb1LsBetType} from './bb1-ls-bet-type';
import {ApplicationSubtype} from '../ticket-detail/application-subtype';
import {TranslationService} from '../services/translation.service';
import {Bb1LsUo} from './bb1-ls-uo';
import {Bb1LsEo} from './bb1-ls-eo';
import {Bb1LsColors} from './bb1-ls-colors';

@Component({
  selector: 'vit-ticket-detail-keno',
  templateUrl: './ticket-detail-keno.component.html',
  styleUrls: ['./ticket-detail-keno.component.scss']
})
export class TicketDetailKenoComponent implements OnInit {

  @Input()
  ticketData: any;
  ticket: any;

  applicationSubtype = ApplicationSubtype;

  bb1LsUo = Bb1LsUo;
  bb1LsEo = Bb1LsEo;
  bb1LsColors = Bb1LsColors;

  constructor(public translationService: TranslationService) {
  }

  ngOnInit() {
    this.ticket = this.ticketData.ticket;
  }

  mapMostColors(mostColors: string) {
    return mostColors.split(',').map(mc => this.bb1LsColors[mc.trim()]).join(',');
  }

  translateTipValues(betType: Bb1LsBetType, tipValues: string) {
    switch (betType) {
      case Bb1LsBetType.DrawnBalls_EO:
      case Bb1LsBetType.FirstBall_EO:
      case Bb1LsBetType.Preballs_EO:
        return tipValues.split(',')
          .map(tip => this.translationService.translate('ticketDetail.keno.lsEo.' + tip))
          .join(',');
      case Bb1LsBetType.FirstBall_UO:
      case Bb1LsBetType.Preballs_UO:
        return tipValues.split(',')
          .map(tip => this.translationService.translate('ticketDetail.keno.lsUo.' + tip))
          .join(',');
      case Bb1LsBetType.FirstBall_Color:
      case Bb1LsBetType.LastBall_Color:
      case Bb1LsBetType.MostColor:
        return tipValues.split(',')
          .map(tip => this.translationService.translate('ticketDetail.keno.lsColor.' + tip))
          .join(',');
      case Bb1LsBetType.Preball_Sel:
      case Bb1LsBetType.Systembet:
      default:
        return tipValues;
        // *ngIf="ticket?.applicationSubType === applicationSubtype.BB1_LUCKYSIX"
    }
  }
}
