import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login.component';
import {LoginPageGuard} from './login.pageguard';

const routes: Routes = [{
  path: 'login',
  component: LoginComponent,
  canActivate: [LoginPageGuard]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule {
}
