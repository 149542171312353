import {Component, OnInit, Query} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {DayMonthHoldOverviewService} from './day-month-hold-overview.service';
import {StationUtils} from '../../utils/station-utils';
import {TreeNodeType} from '../../domain/tree-node-type';
import {NewTabComponent} from '../../new-tab/new-tab.component';
import {environment} from '../../../environments/environment';
import {SystemConfig} from '../../config/system-config';
import {TicketListComponent} from '../../ticket-list/ticket-list.component';
import {getContextMenuItems, isNumber, loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import * as moment from 'moment';
import {enrichByDateFromTo, enrichByStationId} from '../../../utils';
import {clone} from '../../utils/basic-utils';
import {GridReadyEvent} from 'ag-grid-community';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-hold-overview',
  templateUrl: './hold-overview.component.html',
  styleUrls: ['./hold-overview.component.scss']
})
export class HoldOverviewComponent implements OnInit {

  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;
  showPerDay = true;
  columnDefs;
  gridOptions;
  rowData;
  detailParams;

  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public route: ActivatedRoute,
              public dayMonthHoldOverviewService: DayMonthHoldOverviewService,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public datePipe: DatePipe,
              public decimalPipe: DecimalPipe,
              public httpService: HttpService,
              public dialog: MatDialog) {
    this.columnDefs = this.generateColumnDefs();
    this.detailParams = this.generateDetailParams();
    this.gridOptions = this.generateGridOptions();
    this.route.paramMap.subscribe((params: ParamMap) => {
      // TODO: navigating to month/day after each other does not work as component is not reloaded
      this.showPerDay = params.get('type') === 'day';
    });
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('holdOverview', event);
  }

  ngOnInit() {
  }

  fetch(query: QueryFilter) {
    if (query) {
      this.query = query;
      this.rowData = this.dayMonthHoldOverviewService.fetch(query, this.showPerDay);
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('holdOverview.type'),
        field: 'type',
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'type',
        width: 23,
        cellRenderer: function (params) {
          const icon = StationUtils.getIcon(TreeNodeType.STATION, params.data.type, false, params.data.softLocked, params.data.hardLocked);
          const img = '<img src=\'/assets/img/icons/' + icon + '\'>'
            + (params.data.online ? '<div class=\'online\'></div>' : '');
          return params.data.stationID === 0 ? '' : img;
        }
      },
      {
        headerName: this.translationService.translate('holdOverview.stationID'),
        field: 'stationID',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40,
        headerTooltip: this.translationService.translate('holdOverview.stationID')
      },
      {
        headerName: this.translationService.translate('holdOverview.stationName'),
        field: 'stationName',
        sortable: true,
        filter: true,
        resizable: true,
        width: HoldOverviewComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('holdOverview.stationName')
      },
      {
        headerName: this.translationService.translate('holdOverview.countryCode'),
        field: 'countryCode',
        sortable: true,
        filter: true,
        resizable: true,
        width: HoldOverviewComponent.COLUMN_WIDTH,
        hide: true,
        headerTooltip: this.translationService.translate('holdOverview.countryCode')
      },
      {
        headerName: this.translationService.translate('holdOverview.online'),
        field: 'online',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: (params) => params.data.stationID > 0 ? (params.value === true ?
          this.translationService.translate('holdOverview.isOnline') : this.translationService.translate('holdOverview.isOffline'))
          : '',
        width: HoldOverviewComponent.COLUMN_WIDTH,
        hide: true,
        headerTooltip: this.translationService.translate('holdOverview.online')
      },
      {
        headerName: this.translationService.translate('holdOverview.currencyCode'),
        field: 'currencyCode',
        sortable: true,
        filter: true,
        resizable: true,
        width: HoldOverviewComponent.COLUMN_WIDTH,
        hide: true,
        headerTooltip: this.translationService.translate('holdOverview.currencyCode')
      },
      {
        headerName: this.translationService.translate('holdOverview.quantity'),
        field: 'quantity',
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: HoldOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.quantity')
      },
      {
        headerName: this.translationService.translate('holdOverview.stakeNet'),
        field: 'stakeNet',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: HoldOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.stakeNet')
      },
      {
        headerName: this.translationService.translate('holdOverview.averageStakeNet'),
        field: 'averageStakeNet',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        hide: true,
        width: HoldOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.averageStakeNet')
      },
      {
        headerName: this.translationService.translate('holdOverview.stakeTax'),
        field: 'stakeTax',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        hide: true,
        width: HoldOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.stakeTax')
      },
      {
        headerName: this.translationService.translate('holdOverview.profitTotalGross'),
        field: 'profitTotalGross',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: HoldOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.profitTotalGross')
      },
      {
        headerName: this.translationService.translate('holdOverview.winTax'),
        field: 'winTax',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: HoldOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.winTax')
      },
      {
        headerName: this.translationService.translate('holdOverview.openPayoffGross'),
        field: 'openPayoffGross',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: HoldOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.openPayoffGross')
      },
      {
        headerName: this.translationService.translate('holdOverview.jackpotProfitNet'),
        field: 'jackpotProfitNet',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: HoldOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.jackpotProfitNet')
      },
      {
        headerName: this.translationService.translate('holdOverview.hold'),
        field: 'hold',
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: HoldOverviewComponent.COLUMN_WIDTH,
        valueFormatter: this.decimalFormatter.bind(this),
        headerTooltip: this.translationService.translate('holdOverview.hold')
      },
      {
        headerName: this.translationService.translate('holdOverview.holdPercent'),
        field: 'holdPercent',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.percentFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: HoldOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.holdPercent')
      },
      {
        headerName: this.translationService.translate('holdOverview.identifier'),
        field: 'identifier',
        sortable: true,
        filter: true,
        resizable: true,
        hide: true,
        cellClass: 'ui-grid-cell-contents-auto',
        headerTooltip: this.translationService.translate('holdOverview.identifier')
      }
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: true,
      rowClassRules: {
        'bold': (params) => params.data.stationID === 0,
        'text-align-right': (params) => isNumber(params.data.value)
      },
      onCellClicked: (params) => {
        params.node.setExpanded(!params.node.expanded);
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      },
    };
  }

  generateDetailParams() {
    return {
      detailGridOptions: {
        masterDetail: true,
        detailRowHeight: 160,
        detailCellRendererParams: {
          detailGridOptions: {
            columnDefs: [
              {
                headerName: this.translationService.translate('holdOverview.newTab'),
                field: 'newTab',
                sortable: true,
                filter: true,
                resizable: true,
                width: 23,
                cellClass: 'new-tab',
                cellRenderer: NewTabComponent,
              },
              {
                headerName: this.translationService.translate('holdOverview.gameName'),
                field: 'gameName',
                sortable: true,
                filter: true,
                resizable: true,
                width: 190,
                cellRenderer: 'agGroupCellRenderer',
                headerTooltip: this.translationService.translate('holdOverview.gameName')
              },
              {
                headerName: this.translationService.translate('holdOverview.quantity'),
                field: 'quantity',
                sortable: true,
                filter: true,
                resizable: true,
                cellClass: 'text-align-right',
                cellClassRules: {
                  'smaller-font': this.isLargeNumber.bind(this)
                },
                width: HoldOverviewComponent.COLUMN_WIDTH,
                headerTooltip: this.translationService.translate('holdOverview.quantity')
              },
              {
                headerName: this.translationService.translate('holdOverview.stakeNet'),
                field: 'stakeNet',
                sortable: true,
                filter: true,
                resizable: true,
                valueFormatter: this.decimalFormatter.bind(this),
                cellClass: 'text-align-right',
                cellClassRules: {
                  'smaller-font': this.isLargeNumber.bind(this)
                },
                width: HoldOverviewComponent.COLUMN_WIDTH,
                headerTooltip: this.translationService.translate('holdOverview.stakeNet')
              },
              {
                headerName: this.translationService.translate('holdOverview.averageStakeNet'),
                field: 'averageStakeNet',
                sortable: true,
                filter: true,
                resizable: true,
                valueFormatter: this.decimalFormatter.bind(this),
                cellClass: 'text-align-right',
                cellClassRules: {
                  'smaller-font': this.isLargeNumber.bind(this)
                },
                hide: true,
                width: HoldOverviewComponent.COLUMN_WIDTH,
                headerTooltip: this.translationService.translate('holdOverview.averageStakeNet')
              },
              {
                headerName: this.translationService.translate('holdOverview.stakeTax'),
                field: 'stakeTax',
                sortable: true,
                filter: true,
                resizable: true,
                valueFormatter: this.decimalFormatter.bind(this),
                cellClass: 'text-align-right',
                cellClassRules: {
                  'smaller-font': this.isLargeNumber.bind(this)
                },
                hide: true,
                width: HoldOverviewComponent.COLUMN_WIDTH,
                headerTooltip: this.translationService.translate('holdOverview.stakeTax')
              },
              {
                headerName: this.translationService.translate('holdOverview.profitTotalGross'),
                field: 'profitTotalGross',
                sortable: true,
                filter: true,
                resizable: true,
                valueFormatter: this.decimalFormatter.bind(this),
                cellClass: 'text-align-right',
                cellClassRules: {
                  'smaller-font': this.isLargeNumber.bind(this)
                },
                width: HoldOverviewComponent.COLUMN_WIDTH,
                headerTooltip: this.translationService.translate('holdOverview.profitTotalGross')
              },
              {
                headerName: this.translationService.translate('holdOverview.winTax'),
                field: 'winTax',
                sortable: true,
                filter: true,
                resizable: true,
                valueFormatter: this.decimalFormatter.bind(this),
                cellClass: 'text-align-right',
                cellClassRules: {
                  'smaller-font': this.isLargeNumber.bind(this)
                },
                width: HoldOverviewComponent.COLUMN_WIDTH,
                headerTooltip: this.translationService.translate('holdOverview.winTax')
              },
              {
                headerName: this.translationService.translate('holdOverview.openPayoffGross'),
                field: 'openPayoffGross',
                sortable: true,
                filter: true,
                resizable: true,
                valueFormatter: this.decimalFormatter.bind(this),
                cellClass: 'text-align-right',
                cellClassRules: {
                  'smaller-font': this.isLargeNumber.bind(this)
                },
                width: HoldOverviewComponent.COLUMN_WIDTH,
                headerTooltip: this.translationService.translate('holdOverview.openPayoffGross')
              },
              {
                headerName: this.translationService.translate('holdOverview.jackpotProfitNet'),
                field: 'jackpotProfitNet',
                sortable: true,
                filter: true,
                resizable: true,
                valueFormatter: this.decimalFormatter.bind(this),
                cellClass: 'text-align-right',
                cellClassRules: {
                  'smaller-font': this.isLargeNumber.bind(this)
                },
                width: HoldOverviewComponent.COLUMN_WIDTH,
                headerTooltip: this.translationService.translate('holdOverview.jackpotProfitNet')
              },
              {
                headerName: this.translationService.translate('holdOverview.hold'),
                field: 'hold',
                sortable: true,
                filter: true,
                resizable: true,
                cellClass: 'text-align-right',
                cellClassRules: {
                  'smaller-font': this.isLargeNumber.bind(this)
                },
                width: HoldOverviewComponent.COLUMN_WIDTH,
                valueFormatter: this.decimalFormatter.bind(this),
                headerTooltip: this.translationService.translate('holdOverview.hold')
              },
              {
                headerName: this.translationService.translate('holdOverview.holdPercent'),
                field: 'holdPercent',
                sortable: true,
                filter: true,
                resizable: true,
                valueFormatter: this.percentFormatter.bind(this),
                cellClass: 'text-align-right',
                cellClassRules: {
                  'smaller-font': this.isLargeNumber.bind(this)
                },
                width: HoldOverviewComponent.COLUMN_WIDTH,
                headerTooltip: this.translationService.translate('holdOverview.holdPercent')
              },
              {
                headerName: this.translationService.translate('holdOverview.identifier'),
                field: 'identifier',
                sortable: true,
                filter: true,
                resizable: true,
                hide: true,
                cellClass: 'ui-grid-cell-contents-auto',
                headerTooltip: this.translationService.translate('holdOverview.identifier')
              }
            ],
            onCellClicked: (params) => {
              const query: QueryFilter = clone(this.query);
              query.stationID = params.data.stationID;
              query.dateFrom = params.data.dateFrom;
              query.dateTo = params.data.dateTo;
              if (params.colDef.cellRenderer === NewTabComponent) {
                // TODO: use service-worker instead to pass info
                const url = environment.host + SystemConfig.Routes.TICKET_LIST + '/' + params.data.gameNumber
                  + '/' + btoa(JSON.stringify(this.query));
                window.open(encodeURI(url), '_blank');
              } else {
                this.dialog.open(TicketListComponent, {
                  height: '90%',
                  width: '80%',
                  data: {query: query, gameNumber: params.data.gameNumber},
                  disableClose: true
                });
              }
            }
          },
          getDetailRowData: (params) => {
            const query = clone(this.query);
            query.stationID = params.data.stationID;
            const date = params.data.date;

            if (this.showPerDay) {
              const from = moment(date.substring(0, 10), 'DD-MM-YYYY');
              query.dateFrom = from.format('YYYY-MM-DD') + 'T' + from.format('HH:mm:ss');
              const to = from.add(1, 'day');
              query.dateTo = to.format('YYYY-MM-DD') + 'T' + to.format('HH:mm:ss');
            } else {
              const from = moment(date.substring(0, 6), 'YYYYMM');
              query.dateFrom = from.format('YYYY-MM-DD') + 'T' + from.format('HH:mm:ss');
              const to = from.add(1, 'month');
              query.dateTo = to.format('YYYY-MM-DD') + 'T' + to.format('HH:mm:ss');
            }

            //     this.rowData = this.holdOverviewService.fetchTicketList(this.data.query, this.data.gameNumber as GameNumber);
            this.dayMonthHoldOverviewService.fetchPerGame(query, this.showPerDay).subscribe(data => {
              params.successCallback(enrichByDateFromTo(enrichByStationId(data, params.data.stationID), query.dateFrom, query.dateTo));
            });
          }
        },
        columnDefs: [
          {
            headerName: this.translationService.translate('holdOverview.' + (this.showPerDay ? 'day' : 'month')),
            field: 'date',
            sortable: true,
            filter: true,
            resizable: true,
            width: 214,
            cellRenderer: 'agGroupCellRenderer'
          },
          {
            headerName: this.translationService.translate('holdOverview.quantity'),
            field: 'quantity',
            sortable: true,
            filter: true,
            resizable: true,
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: HoldOverviewComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('holdOverview.quantity')
          },
          {
            headerName: this.translationService.translate('holdOverview.stakeNet'),
            field: 'stakeNet',
            sortable: true,
            filter: true,
            resizable: true,
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: HoldOverviewComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('holdOverview.stakeNet')
          },
          {
            headerName: this.translationService.translate('holdOverview.averageStakeNet'),
            field: 'averageStakeNet',
            sortable: true,
            filter: true,
            resizable: true,
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            hide: true,
            width: HoldOverviewComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('holdOverview.averageStakeNet')
          },
          {
            headerName: this.translationService.translate('holdOverview.stakeTax'),
            field: 'stakeTax',
            sortable: true,
            filter: true,
            resizable: true,
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            hide: true,
            width: HoldOverviewComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('holdOverview.stakeTax')
          },
          {
            headerName: this.translationService.translate('holdOverview.profitTotalGross'),
            field: 'profitTotalGross',
            sortable: true,
            filter: true,
            resizable: true,
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: HoldOverviewComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('holdOverview.profitTotalGross')
          },
          {
            headerName: this.translationService.translate('holdOverview.winTax'),
            field: 'winTax',
            sortable: true,
            filter: true,
            resizable: true,
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: HoldOverviewComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('holdOverview.winTax')
          },
          {
            headerName: this.translationService.translate('holdOverview.openPayoffGross'),
            field: 'openPayoffGross',
            sortable: true,
            filter: true,
            resizable: true,
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: HoldOverviewComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('holdOverview.openPayoffGross')
          },
          {
            headerName: this.translationService.translate('holdOverview.jackpotProfitNet'),
            field: 'jackpotProfitNet',
            sortable: true,
            filter: true,
            resizable: true,
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: HoldOverviewComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('holdOverview.jackpotProfitNet')
          },
          {
            headerName: this.translationService.translate('holdOverview.hold'),
            field: 'hold',
            sortable: true,
            filter: true,
            resizable: true,
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: HoldOverviewComponent.COLUMN_WIDTH,
            valueFormatter: this.decimalFormatter.bind(this)
          },
          {
            headerName: this.translationService.translate('holdOverview.holdPercent'),
            field: 'holdPercent',
            sortable: true,
            filter: true,
            resizable: true,
            valueFormatter: this.percentFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: HoldOverviewComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('holdOverview.holdPercent')
          },
          {
            headerName: this.translationService.translate('holdOverview.identifier'),
            field: 'identifier',
            sortable: true,
            filter: true,
            resizable: true,
            hide: true,
            cellClass: 'ui-grid-cell-contents-auto',
            headerTooltip: this.translationService.translate('holdOverview.identifier')
          }
        ],
        onCellClicked: (params) => {
          params.node.setExpanded(!params.node.expanded);
        }
      },
      getDetailRowData: (params) => {
        this.query.stationID = params.data.stationID;
        this.dayMonthHoldOverviewService.fetchPerDate(this.query, this.showPerDay).subscribe(data => {
          params.successCallback(enrichByStationId(data, params.data.stationID));
        });
      }
    };
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }
}
