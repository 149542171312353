import {Component, OnInit} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {getContextMenuItems, isNumber} from 'utils';
import {loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {UaTimelineService} from './ua-timeline.service';
import {GridReadyEvent} from 'ag-grid-community';
import {SystemConfig} from '../../config/system-config';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-ua-timeline',
  templateUrl: './ua-timeline.component.html',
  styleUrls: ['./ua-timeline.component.scss']
})
export class UaTimelineComponent implements OnInit {

  static COLUMN_SMALL = 55;
  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 300;
  static COLUMN_WIDTH_CHECKBOX = 50;
  columnDefs;
  gridOptions;
  rowData;
  detailParams;
  rowCount;

  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public uaTimelineService: UaTimelineService,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public datePipe: DatePipe,
              public decimalPipe: DecimalPipe,
              public httpService: HttpService,
              public dialog: MatDialog) {
    this.columnDefs = this.generateColumnDefs();
    this.gridOptions = this.generateGridOptions();
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('uaTimeline', event);
  }

  ngOnInit() {
  }

  fetch(query: QueryFilter) {
    if (query) {
      this.query = query;
      this.rowData = this.uaTimelineService.fetch(query);
      this.updateCustomerInsights(this.query.userAccountID);
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  updateCustomerInsights(userAccountId: number) {
    let storedIds: { key, value }[] = JSON.parse(localStorage.getItem(SystemConfig.LocalStorageConstants.RECENT_USER_ACCOUNT_IDS) || '[]');
    storedIds = storedIds.filter(el => el.key !== userAccountId);
    storedIds.unshift({
      key: userAccountId,
      value: '<b>UA-ID: ' + userAccountId + '</b> <small class="right-info">' + this.datePipe.transform(new Date(), 'short') + '</small>'
    });
    storedIds.length = Math.min(10, storedIds.length);
    localStorage.setItem(SystemConfig.LocalStorageConstants.RECENT_USER_ACCOUNT_IDS, JSON.stringify(storedIds));
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('uaTimeline.uaid'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'uaid',
        width: UaTimelineComponent.COLUMN_SMALL,
        headerTooltip: this.translationService.translate('uaTimeline.uaid')
      },
      {
        headerName: this.translationService.translate('uaTimeline.date'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'date',
        valueFormatter: this.dateFormatter.bind(this),
        width: 150,
        headerTooltip: this.translationService.translate('uaTimeline.date')
      },
      {
        headerName: this.translationService.translate('uaTimeline.action'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'action',
        width: UaTimelineComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('uaTimeline.action')
      },
      {
        headerName: this.translationService.translate('uaTimeline.delta'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'delta',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        width: 120,
        headerTooltip: this.translationService.translate('uaTimeline.delta')
      },
      {
        headerName: this.translationService.translate('uaTimeline.sum'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'sum',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        width: UaTimelineComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('uaTimeline.sum')
      }
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: true,
      rowClassRules: {
        'text-align-right': (params) => isNumber(params.data.value)
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }

}
