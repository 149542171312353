import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {SystemConfig} from '../config/system-config';
import {Observable} from 'rxjs';
import {QueryFilter} from '../domain/query-filter';

@Injectable({
  providedIn: 'root'
})
export class ResultsService {

  constructor(public httpService: HttpService) {
  }

  fetchRF2Cycles(): Observable<any[]> {
    return this.httpService.get(SystemConfig.Endpoints.RF2_RACE_CYCLE);
  }

  fetchBB1Cycles(): Observable<any[]> {
    return this.httpService.get(SystemConfig.Endpoints.BB1_RACE_CYCLE);
  }

  fetchPB1Stations(query: QueryFilter): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.PB1_STATIONS, query);
  }

  fetchRF2Results(query: QueryFilter): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.RESULTS_RF2, query);
  }

  fetchBB1Results(query: QueryFilter): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.RESULTS_BB1, query);
  }

  fetchPB1Results(query: QueryFilter): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.RESULTS_PB1, query);
  }
}
