<vit-search-form
  (submitEmiter)="actualize($event)"
  [hideGames]="true"
  [hideMinProfit]="true"
  [hideMinStake]="true"
  [hideMinTickets]="true"
  [hideStationRestrictions]="true"
  [onlyLastMonths]="true"
  [hideIntoEuro]="true"
  [submitLabel]="'balanceActualization.submit' | translate"
  [hideTimeInput]="true">
</vit-search-form>
