import { Injectable } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { SessionService } from '../../services/session.service';
import { Observable } from 'rxjs';
import { SystemConfig } from '../../config/system-config';

@Injectable({
  providedIn: 'root'
})
export class GroupManagementService {

  constructor(public httpService: HttpService,
    public sessionService: SessionService) {
  }

  fetchGroups(): Observable<any> {
    return this.httpService.get(SystemConfig.Endpoints.FRANCHISER_STATION_GROUPS);
  }

  fetchGroup(groupId: number): Observable<any[]> {
    return this.httpService.get(SystemConfig.Endpoints.FRANCHISER_STATION_GROUP + groupId);
  }

  deleteGroup(group): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.FRANCHISER_STATION_GROUP_DELETE, group);
  }

  updateGroup(group): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.FRANCHISER_STATION_GROUP_UPDATE, group);
  }

  insertGroup(group): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.FRANCHISER_STATION_GROUP_INSERT, group);
  }

}
