export interface MEObject {
  match: MeMatch;
  tournament: MeTournament;
  category: MeCategory;
  sport: MeSport;
  teamHome: MeTeam;
  teamAway: MeTeam;
  infos: MeInfo[];
  cards: MeCard[];
  scores: MeScore[];
  betTypes: MeBetType[]; // DO NOT USE
  betTypeGroups: MeBetTypeGroup[]; // transient
  nTickets: number;
  ntOpen: number;
  ntWon: number;
  ntLost: number;
}

export interface MeBetTypeGroup { // transient
  betTypeKey: number;
  betTypeLabel: string;
  expanded: boolean;
  hide: boolean;
  betTypes: MeBetType[];
  sumNBets: number; // transient
  sumNTickets: number; // transient
  sumMaxProfit: number; // transient
  anyClearbet: boolean;
  anyPending: boolean;
  anyCancelled: boolean;
}

export interface MeScore {
  away: number;
  brScoreID: number;
  cancelled: boolean;
  home: number;
  lB_MatchID: number;
  lB_ScoreID: number;
  player: null | any;
  scoringTeam: number;
  time: number;
  timeStamp: string;
  type: LBScoreType;
}

export interface MeCard {
  brCardID: number;
  cancelled: boolean;
  lB_CardID: number;
  lB_MatchID: number;
  player: null | any;
  team: number;
  time: number;
  timeStamp: string;
  type: LbCardType;
}

export enum LBScoreType {
  NONE = 0, LIVE = 1, SCORE = 2, HALFTIME = 3, FULLTIME = 4, OVERTIME = 5, PENALTY = 6, WALKOVER = 7,
  SET1 = 8, SET2 = 9, SET3 = 10, SET4 = 11, SET5 = 12, Q1 = 13, Q2 = 14, Q3 = 15, Q4 = 16, P1 = 17, P2 = 18, P3 = 19,
  AFTER_PENALTY = 20
}

export enum LbCardType {
  YELLOW = 1, YELLOW_RED = 2, RED = 3
}

export interface MeBetType {
  lB_BetTypeID: number;
  lB_MatchID: number;
  brOddsID: number;
  lB_BetTypeInfoID: number;
  betTypeKey: number;
  betTypeLabel?: string; // transient
  brSpecialOddsValue: string;
  active: boolean;
  minCombination: number;
  clearbet: boolean;
  cleared: boolean;
  clearDate: null;
  clearState: number;
  calculated: boolean;
  calcDate: null;
  cancelled: boolean;
  touched?: boolean; // transient
  autocancelled: boolean; // transient
  cancelDate: null;
  mostBalanced: boolean;
  userID: null;
  lastChange: Date;
  odds: MeOdd[];
  nTickets: number;
  nBets: number;
}

export enum SimpleMatchStatus {
  NOT_STARTED = 0,
  PLAYING = 1,
  PAUSED = 2,
  ENDED = 4,
  INTERRUPTED = 5,
  SPECIAL_INCIDENT = 6,
  CANCELLED = 7,
  UNKNOWN = 8,
  ERROR = 9
}

export enum MatchStatus {
  ERROR = 0,
  NOT_STARTED = 1,
  PERIOD_1 = 2,
  PERIOD_2 = 3,
  PERIOD_3 = 4,
  PAUSED = 5,
  OVERTIME = 6,
  PENALTY = 7,
  INTERRUPTED = 8,
  ENDED = 9,
  SET1 = 10,
  SET2 = 11,
  SET3 = 12,
  SET4 = 13,
  SET5 = 14,
  WALKOVER = 15,
  RETIRED = 16,
  DELAYED = 17,
  PERIOD_4 = 18,
  PERIOD_5 = 19,
  AWAITING_OT = 20,
  ABANDONED = 21,
  CANCELED = 22,
  POSTPONED = 23,
  PAUSE_1 = 24,
  PAUSE_2 = 25,
  PAUSE_3 = 26,
  PAUSE_4 = 27,
  I1T = 28,
  I1B = 29,
  I2T = 30,
  I2B = 31,
  I3T = 32,
  I3B = 33,
  I4T = 34,
  I4B = 35,
  I5T = 36,
  I5B = 37,
  I6T = 38,
  I6B = 39,
  I7T = 40,
  I7B = 41,
  I8T = 42,
  I8B = 43,
  I9T = 44,
  I9B = 45,
  IET = 46,
  IEB = 47,
  PAUSE_OT = 48,
  AWAITING_PENALTY = 49
}

export enum OddKey {
  NONE = -1,
  OTHER = 0,
  ROM_1 = 1,
  ROM_X = 2,
  ROM_2 = 3,
  UO_O = 4,
  UO_U = 5,
  ROM_H1_1 = 6,
  ROM_H1_X = 7,
  ROM_H1_2 = 8,
  UO_H1_O = 9,
  UO_H1_U = 10,
  NG_1 = 11,
  NG_2 = 12,
  NG_X = 13,
  RESULT_1 = 20,
  RESULT_X = 21,
  RESULT_2 = 22,
  KICKOFF_1 = 23,
  KICKOFF_2 = 24,
  DNB_1 = 25,
  DNB_2 = 26,
  DC_1X = 27,
  DC_12 = 28,
  DC_X2 = 29,
  ROM_OT_1 = 30,
  ROM_OT_X = 31,
  ROM_OT_2 = 32,
  UO_OT_O = 33,
  UO_OT_U = 34,
  NG_OT_1 = 35,
  NG_OT_X = 36,
  NG_OT_2 = 37,
  PENALTY_1 = 38,
  PENALTY_2 = 39,
  HC_1 = 40,
  HC_X = 41,
  HC_2 = 42,
  TENNIS_MW_1 = 43,
  TENNIS_MW_2 = 44,
  TENNIS_SW_1 = 45,
  TENNIS_SW_2 = 46,
  TENNIS_GW_1 = 47,
  TENNIS_GW_X = 48,
  TENNIS_GW_2 = 49,
  TENNIS_RESULTS3_20 = 50,
  TENNIS_RESULTS3_21 = 51,
  TENNIS_RESULTS3_02 = 52,
  TENNIS_RESULTS3_12 = 53,
  TENNIS_RESULTS5_30 = 54,
  TENNIS_RESULTS5_31 = 55,
  TENNIS_RESULTS5_32 = 56,
  TENNIS_RESULTS5_03 = 57,
  TENNIS_RESULTS5_13 = 58,
  TENNIS_RESULTS5_23 = 59,
  TENNIS_SETS3_2 = 60,
  TENNIS_SETS3_3 = 61,
  TENNIS_SETS5_3 = 62,
  TENNIS_SETS5_4 = 63,
  TENNIS_SETS5_5 = 64,
  RESULT_H1_1 = 65,
  RESULT_H1_X = 66,
  RESULT_H1_2 = 67,
  HT_FT_11 = 68,
  HT_FT_X1 = 69,
  HT_FT_21 = 70,
  HT_FT_1X = 71,
  HT_FT_XX = 72,
  HT_FT_2X = 73,
  HT_FT_12 = 74,
  HT_FT_X2 = 75,
  HT_FT_22 = 76,
  CS_10 = 77,
  CS_20 = 78,
  CS_21 = 79,
  CS_30 = 80,
  CS_31 = 81,
  CS_32 = 82,
  CS_40 = 83,
  CS_41 = 84,
  CS_42 = 85,
  CS_43 = 86,
  CS_50 = 87,
  CS_51 = 88,
  CS_52 = 89,
  CS_53 = 276,
  CS_54 = 277,
  CS_60 = 109,
  CS_61 = 157,
  CS_62 = 280,
  CS_70 = 158,
  CS_71 = 281,
  CS_80 = 282,
  CS_00 = 90,
  CS_11 = 91,
  CS_22 = 92,
  CS_33 = 93,
  CS_44 = 94,
  CS_01 = 95,
  CS_02 = 96,
  CS_12 = 97,
  CS_03 = 98,
  CS_13 = 99,
  CS_23 = 100,
  CS_04 = 101,
  CS_14 = 102,
  CS_24 = 103,
  CS_34 = 104,
  CS_05 = 105,
  CS_15 = 106,
  CS_25 = 107,
  CS_35 = 278,
  CS_45 = 279,
  CS_06 = 108,
  CS_16 = 155,
  CS_26 = 283,
  CS_07 = 156,
  CS_17 = 284,
  CS_08 = 285,
  CS_55 = 296,
  CS_OTHER = 110,
  GNG_GOAL = 120,
  GNG_NOGOAL = 121,
  GHT_ZERO = 122,
  GHT_ONE = 123,
  GHT_TWO = 124,
  GHT_THREE_PLUS = 125,
  GAT_ZERO = 126,
  GAT_ONE = 127,
  GAT_TWO = 128,
  GAT_THREE_PLUS = 129,
  RESULT_OT_1 = 130,
  RESULT_OT_X = 131,
  RESULT_OT_2 = 132,
  WAY2_1 = 133,
  WAY2_2 = 134,
  PERIOD_WINNER_1 = 135,
  PERIOD_WINNER_X = 136,
  PERIOD_WINNER_2 = 137,
  RACE_X_POINTS_1 = 138,
  RACE_X_POINTS_2 = 139,
  SCORE_X_POINT_1 = 140,
  SCORE_X_POINT_2 = 141,
  TOTAL_GOALS_0_1 = 142,
  TOTAL_GOALS_2_3 = 143,
  TOTAL_GOALS_4_5 = 144,
  TOTAL_GOALS_6_PLUS = 145,
  TENNIS_UO_O = 146,
  TENNIS_UO_U = 147,
  NG_H1_1 = 148,
  NG_H1_2 = 149,
  NG_H1_X = 150,
  TENNIS_GAME_X_WINNER_1 = 151,
  TENNIS_GAME_X_WINNER_2 = 152,
  TENNIS_SET_UO_O = 153,
  TENNIS_SET_UO_U = 154,

  GOAL_ODD = 159,
  GOAL_EVEN = 160,

  GNG_H1_GOAL = 161,
  GNG_H1_NOGOAL = 162,

  FG15_1_15 = 163,
  FG15_16_30 = 164,
  FG15_31_45 = 165,
  FG15_46_60 = 166,
  FG15_61_75 = 167,
  FG15_76_90 = 168,
  FG15_NONE = 169,

  LG_1 = 170,
  LG_X = 171,
  LG_2 = 172,

  HSH_1 = 173,
  HSH_X = 174,
  HSH_2 = 175,

  RESULT_H2_1 = 176,
  RESULT_H2_X = 177,
  RESULT_H2_2 = 178,

  UO_H2_O = 179,
  UO_H2_U = 180,

  PSO_YES = 181,
  PSO_NO = 182,

  CORNER_W3_1 = 183,
  CORNER_W3_X = 184,
  CORNER_W3_2 = 185,

  CORNER_TOTAL_0_8 = 186,
  CORNER_TOTAL_9_11 = 187,
  CORNER_TOTAL_12_PLUS = 188,

  W3_UO_1O = 189,
  W3_UO_XO = 190,
  W3_UO_2O = 191,
  W3_UO_1U = 192,
  W3_UO_XU = 193,
  W3_UO_2U = 194,

  TENNIS_SET_CS_60 = 195,
  TENNIS_SET_CS_61 = 196,
  TENNIS_SET_CS_62 = 197,
  TENNIS_SET_CS_63 = 198,
  TENNIS_SET_CS_64 = 199,
  TENNIS_SET_CS_75 = 200,
  TENNIS_SET_CS_76 = 201,
  TENNIS_SET_CS_06 = 202,
  TENNIS_SET_CS_16 = 203,
  TENNIS_SET_CS_26 = 204,
  TENNIS_SET_CS_36 = 205,
  TENNIS_SET_CS_46 = 206,
  TENNIS_SET_CS_57 = 207,
  TENNIS_SET_CS_67 = 208,

  ADV_NXT_RND_1 = 209,
  ADV_NXT_RND_2 = 210,

  CS_H1_10 = 211,
  CS_H1_20 = 212,
  CS_H1_21 = 213,
  CS_H1_30 = 214,
  CS_H1_31 = 215,
  CS_H1_32 = 216,
  CS_H1_40 = 217,
  CS_H1_00 = 218,
  CS_H1_11 = 219,
  CS_H1_22 = 220,
  CS_H1_33 = 221,
  CS_H1_01 = 222,
  CS_H1_02 = 223,
  CS_H1_12 = 224,
  CS_H1_03 = 225,
  CS_H1_13 = 226,
  CS_H1_23 = 227,
  CS_H1_04 = 228,
  CS_H1_OTHER = 229,

  HC_H1_1 = 230,
  HC_H1_X = 231,
  HC_H1_2 = 232,

  HC_H2_1 = 233,
  HC_H2_X = 234,
  HC_H2_2 = 235,

  DC_H1_1X = 236,
  DC_H1_12 = 237,
  DC_H1_X2 = 238,

  DC_H2_1X = 239,
  DC_H2_12 = 240,
  DC_H2_X2 = 241,

  TOTAL_GOALS_EXACT_0 = 242,
  TOTAL_GOALS_EXACT_1 = 243,
  TOTAL_GOALS_EXACT_2 = 244,
  TOTAL_GOALS_EXACT_3 = 245,
  TOTAL_GOALS_EXACT_4 = 246,
  TOTAL_GOALS_EXACT_5 = 247,
  TOTAL_GOALS_EXACT_6_PLUS = 248,

  TOTAL_GOALS_EXACT_H1_0 = 249,
  TOTAL_GOALS_EXACT_H1_1 = 250,
  TOTAL_GOALS_EXACT_H1_2_PLUS = 251,

  TOTAL_GOALS_EXACT_H2_0 = 252,
  TOTAL_GOALS_EXACT_H2_1 = 253,
  TOTAL_GOALS_EXACT_H2_2_PLUS = 254,

  UO_HOMETEAM_O = 255,
  UO_HOMETEAM_U = 256,

  UO_AWAYTEAM_O = 257,
  UO_AWAYTEAM_U = 258,

  UO_CORNER_O = 259,
  UO_CORNER_U = 260,

  GNG_H2_GOAL = 261,
  GNG_H2_NOGOAL = 262,

  WAY_3_GNG_1_GOAL = 263,
  WAY_3_GNG_X_GOAL = 264,
  WAY_3_GNG_2_GOAL = 265,
  WAY_3_GNG_1_NOGOAL = 266,
  WAY_3_GNG_X_NOGOAL = 267,
  WAY_3_GNG_2_NOGOAL = 268,
  UO_GNG_O_GOAL = 269,
  UO_GNG_U_GOAL = 270,
  UO_GNG_O_NOGOAL = 271,
  UO_GNG_U_NOGOAL = 272,
  WAY_3_INT10_1 = 273,
  WAY_3_INT10_X = 274,
  WAY_3_INT10_2 = 275,
  UO_HOMETEAM_H1_O = 286,
  UO_HOMETEAM_H1_U = 287,
  UO_AWAYTEAM_H1_O = 288,
  UO_AWAYTEAM_H1_U = 289,
  W3_UO_H1_1O = 290,
  W3_UO_H1_XO = 291,
  W3_UO_H1_2O = 292,
  W3_UO_H1_1U = 293,
  W3_UO_H1_XU = 294,
  W3_UO_H1_2U = 295,
  W3_GNG_H1_1_GOAL = 501,
  W3_GNG_H1_X_GOAL = 502,
  W3_GNG_H1_2_GOAL = 503,
  W3_GNG_H1_1_NOGOAL = 504,
  W3_GNG_H1_X_NOGOAL = 505,
  W3_GNG_H1_2_NOGOAL = 506,
  DC_UO_1X_O = 507,
  DC_UO_12_O = 508,
  DC_UO_X2_O = 509,
  DC_UO_1X_U = 510,
  DC_UO_12_U = 511,
  DC_UO_X2_U = 512,
  DC_GNG_1X_GOAL = 513,
  DC_GNG_12_GOAL = 514,
  DC_GNG_X2_GOAL = 515,
  DC_GNG_1X_NOGOAL = 516,
  DC_GNG_12_NOGOAL = 517,
  DC_GNG_X2_NOGOAL = 518,
  UO_PERIOD_O = 519,
  UO_PERIOD_U = 520,
  XTHG_PERIOD_1 = 521,
  XTHG_PERIOD_X = 522,
  XTHG_PERIOD_2 = 523,
  ROM_PERIOD_1 = 524,
  ROM_PERIOD_X = 525,
  ROM_PERIOD_2 = 526,
  HSP_1 = 527,
  HSP_2 = 528,
  HSP_3 = 529,
  HSP_X = 530,

  OUTRIGHT_W_1 = 301,
  OUTRIGHT_W_2 = 302,
  OUTRIGHT_W_3 = 303,
  OUTRIGHT_W_4 = 304,
  OUTRIGHT_W_5 = 305,
  OUTRIGHT_W_6 = 306,
  OUTRIGHT_W_7 = 307,
  OUTRIGHT_W_8 = 308,
  OUTRIGHT_W_9 = 309,
  OUTRIGHT_W_10 = 310,
  OUTRIGHT_W_11 = 311,
  OUTRIGHT_W_12 = 312,
  OUTRIGHT_W_13 = 313,
  OUTRIGHT_W_14 = 314,
  OUTRIGHT_W_15 = 315,
  OUTRIGHT_W_16 = 316,
  OUTRIGHT_W_17 = 317,
  OUTRIGHT_W_18 = 318,
  OUTRIGHT_W_19 = 319,
  OUTRIGHT_W_20 = 320,
  OUTRIGHT_W_21 = 321,
  OUTRIGHT_W_22 = 322,
  OUTRIGHT_W_23 = 323,
  OUTRIGHT_W_24 = 324,
  OUTRIGHT_W_25 = 325,
  OUTRIGHT_W_26 = 326,
  OUTRIGHT_W_27 = 327,
  OUTRIGHT_W_28 = 328,
  OUTRIGHT_W_29 = 329,
  OUTRIGHT_W_30 = 330,
  OUTRIGHT_W_31 = 331,
  OUTRIGHT_W_32 = 332,
  OUTRIGHT_W_33 = 333,
  OUTRIGHT_W_34 = 334,
  OUTRIGHT_W_35 = 335,
  OUTRIGHT_W_36 = 336,
  OUTRIGHT_W_37 = 337,
  OUTRIGHT_W_38 = 338,
  OUTRIGHT_W_39 = 339,
  OUTRIGHT_W_40 = 340,
  OUTRIGHT_W_41 = 341,
  OUTRIGHT_W_42 = 342,
  OUTRIGHT_W_43 = 343,
  OUTRIGHT_W_44 = 344,
  OUTRIGHT_W_45 = 345,
  OUTRIGHT_W_46 = 346,
  OUTRIGHT_W_47 = 347,
  OUTRIGHT_W_48 = 348,
  OUTRIGHT_W_49 = 349,
  OUTRIGHT_W_50 = 350,
  OUTRIGHT_W_51 = 351,
  OUTRIGHT_W_52 = 352,
  OUTRIGHT_W_53 = 353,
  OUTRIGHT_W_54 = 354,
  OUTRIGHT_W_55 = 355,
  OUTRIGHT_W_56 = 356,
  OUTRIGHT_W_57 = 357,
  OUTRIGHT_W_58 = 358,
  OUTRIGHT_W_59 = 359,
  OUTRIGHT_W_60 = 360,
  OUTRIGHT_W_61 = 361,
  OUTRIGHT_W_62 = 362,
  OUTRIGHT_W_63 = 363,
  OUTRIGHT_W_64 = 364,
  OUTRIGHT_W_65 = 365,
  OUTRIGHT_W_66 = 366,
  OUTRIGHT_W_67 = 367,
  OUTRIGHT_W_68 = 368,
  OUTRIGHT_W_69 = 369,
  OUTRIGHT_W_70 = 370,
  OUTRIGHT_W_71 = 371,
  OUTRIGHT_W_72 = 372,
  OUTRIGHT_W_73 = 373,
  OUTRIGHT_W_74 = 374,
  OUTRIGHT_W_75 = 375,
  OUTRIGHT_W_76 = 376,
  OUTRIGHT_W_77 = 377,
  OUTRIGHT_W_78 = 378,
  OUTRIGHT_W_79 = 379,
  OUTRIGHT_W_80 = 380,
  OUTRIGHT_W_81 = 381,
  OUTRIGHT_W_82 = 382,
  OUTRIGHT_W_83 = 383,
  OUTRIGHT_W_84 = 384,
  OUTRIGHT_W_85 = 385,
  OUTRIGHT_W_86 = 386,
  OUTRIGHT_W_87 = 387,
  OUTRIGHT_W_88 = 388,
  OUTRIGHT_W_89 = 389,
  OUTRIGHT_W_90 = 390,
  OUTRIGHT_W_91 = 391,
  OUTRIGHT_W_92 = 392,
  OUTRIGHT_W_93 = 393,
  OUTRIGHT_W_94 = 394,
  OUTRIGHT_W_95 = 395,
  OUTRIGHT_W_96 = 396,
  OUTRIGHT_W_97 = 397,
  OUTRIGHT_W_98 = 398,
  OUTRIGHT_W_99 = 399,
  OUTRIGHT_W_100 = 400,
  OUTRIGHT_W_101 = 401,
  OUTRIGHT_W_102 = 402,
  OUTRIGHT_W_103 = 403,
  OUTRIGHT_W_104 = 404,
  OUTRIGHT_W_105 = 405,
  OUTRIGHT_W_106 = 406,
  OUTRIGHT_W_107 = 407,
  OUTRIGHT_W_108 = 408,
  OUTRIGHT_W_109 = 409,
  OUTRIGHT_W_110 = 410,
  OUTRIGHT_W_111 = 411,
  OUTRIGHT_W_112 = 412,
  OUTRIGHT_W_113 = 413,
  OUTRIGHT_W_114 = 414,
  OUTRIGHT_W_115 = 415,
  OUTRIGHT_W_116 = 416,
  OUTRIGHT_W_117 = 417,
  OUTRIGHT_W_118 = 418,
  OUTRIGHT_W_119 = 419,
  OUTRIGHT_W_120 = 420,
  OUTRIGHT_W_121 = 421,
  OUTRIGHT_W_122 = 422,
  OUTRIGHT_W_123 = 423,
  OUTRIGHT_W_124 = 424,
  OUTRIGHT_W_125 = 425,
  OUTRIGHT_W_126 = 426,
  OUTRIGHT_W_127 = 427,
  OUTRIGHT_W_128 = 428,
  OUTRIGHT_W_129 = 429,
  OUTRIGHT_W_130 = 430,
  OUTRIGHT_W_131 = 431,
  OUTRIGHT_W_132 = 432,
  OUTRIGHT_W_133 = 433,
  OUTRIGHT_W_134 = 434,
  OUTRIGHT_W_135 = 435,
  OUTRIGHT_W_136 = 436,
  OUTRIGHT_W_137 = 437,
  OUTRIGHT_W_138 = 438,
  OUTRIGHT_W_139 = 439,
  OUTRIGHT_W_140 = 440,
  OUTRIGHT_W_141 = 441,
  OUTRIGHT_W_142 = 442,
  OUTRIGHT_W_143 = 443,
  OUTRIGHT_W_144 = 444,
  OUTRIGHT_W_145 = 445,
  OUTRIGHT_W_146 = 446,
  OUTRIGHT_W_147 = 447,
  OUTRIGHT_W_148 = 448,
  OUTRIGHT_W_149 = 449,
  OUTRIGHT_W_150 = 450,
  OUTRIGHT_W_151 = 451,
  OUTRIGHT_W_152 = 452,
  OUTRIGHT_W_153 = 453,
  OUTRIGHT_W_154 = 454,
  OUTRIGHT_W_155 = 455,
  OUTRIGHT_W_156 = 456,
  OUTRIGHT_W_157 = 457,
  OUTRIGHT_W_158 = 458,
  OUTRIGHT_W_159 = 459,
  OUTRIGHT_W_160 = 460,
  OUTRIGHT_W_161 = 461,
  OUTRIGHT_W_162 = 462,
  OUTRIGHT_W_163 = 463,
  OUTRIGHT_W_164 = 464,
  OUTRIGHT_W_165 = 465,
  OUTRIGHT_W_166 = 466,
  OUTRIGHT_W_167 = 467,
  OUTRIGHT_W_168 = 468,
  OUTRIGHT_W_169 = 469,
  OUTRIGHT_W_170 = 470,
  OUTRIGHT_W_171 = 471,
  OUTRIGHT_W_172 = 472,
  OUTRIGHT_W_173 = 473,
  OUTRIGHT_W_174 = 474,
  OUTRIGHT_W_175 = 475,
  OUTRIGHT_W_176 = 476,
  OUTRIGHT_W_177 = 477,
  OUTRIGHT_W_178 = 478,
  OUTRIGHT_W_179 = 479,
  OUTRIGHT_W_180 = 480,
  OUTRIGHT_W_181 = 481,
  OUTRIGHT_W_182 = 482,
  OUTRIGHT_W_183 = 483,
  OUTRIGHT_W_184 = 484,
  OUTRIGHT_W_185 = 485,
  OUTRIGHT_W_186 = 486,
  OUTRIGHT_W_187 = 487,
  OUTRIGHT_W_188 = 488,
  OUTRIGHT_W_189 = 489,
  OUTRIGHT_W_190 = 490,
  OUTRIGHT_W_191 = 491,
  OUTRIGHT_W_192 = 492,
  OUTRIGHT_W_193 = 493,
  OUTRIGHT_W_194 = 494,
  OUTRIGHT_W_195 = 495,
  OUTRIGHT_W_196 = 496,
  OUTRIGHT_W_197 = 497,
  OUTRIGHT_W_198 = 498,
  OUTRIGHT_W_199 = 499,
  DRAW_SUM_ODD = 700,
  DRAW_SUM_EVEN = 701,

  DRAW_SUM_OVER = 702,
  DRAW_SUM_UNDER = 703,

  DRAW_SUM_RANGE_1 = 704,
  DRAW_SUM_RANGE_2 = 705,
  DRAW_SUM_RANGE_3 = 706,
  DRAW_SUM_RANGE_4 = 707,
  DRAW_SUM_RANGE_5 = 708,
  DRAW_SUM_RANGE_6 = 709,
  DRAW_SUM_RANGE_7 = 710,
  DRAW_SUM_RANGE_8 = 711,
  DRAW_SUM_RANGE_9 = 712,
  DRAW_SUM_RANGE_10 = 713,
  DRAW_SUM_RANGE_11 = 714,
  DRAW_SUM_RANGE_12 = 715,
  DRAW_SUM_RANGE_13 = 716,
  DRAW_SUM_RANGE_14 = 717,

  DRAW_HIT_1 = 718,
  DRAW_HIT_2 = 719,
  DRAW_HIT_3 = 720,
  DRAW_HIT_4 = 721,
  DRAW_HIT_5 = 722,
  DRAW_HIT_6 = 723,
  DRAW_HIT_7 = 724,
  DRAW_HIT_8 = 725
}

export interface MeOdd {
  lB_OddID: number;
  lB_BetTypeID: number;
  lB_TeamID: null;
  oddKey: OddKey;
  oddLabel?: string; // transient
  brOddFieldType: null;
  active: boolean;
  value: number;
  won: boolean;
  touched?: boolean; // transient
  autocalculated?: boolean; // transient
  probability: number;
  team: any;
  maxProfit: number;
  stake: number;
}

export interface MeCategory {
  lB_CategoryID: number;
  masterServerCategoryID: number;
  name: string;
  nameDE: string;
  nameEN: string;
  nameRU: string;
  nameES: string;
  nameTR: string;
  nameAR: null;
  namePT: string;
  sortIndex: number;
  icon: null;
  userID: null;
  lastChange: null;
  numTmts: number;
  numTmtsActive: number;
  hasTmtsLoaded: boolean;
}

export interface MeInfo {
  lB_MatchInfoID: number;
  lB_MatchID: number;
  infoType: string;
  infoValue: string;
}

export interface MeMatch {
  lB_MatchID: number;
  brMatchID: number;
  lB_SportID: number;
  lB_TournamentID: number;
  homeTeamID: number;
  awayTeamID: number;
  competitors: string;
  score: string;
  scoreDetail: string;
  offered: boolean;
  offeredLive: boolean;
  active: boolean;
  brMsgNr: number;
  utcDate: string;
  date: Date;
  utcEndDate: null;
  endDate: null;
  betStatus: boolean;
  matchStatus: MatchStatus;
  simpleMatchStatus: SimpleMatchStatus;
  matchTime: null;
  message: null;
  matchType: number;
  matchIdentifier: string;
  matchNumber: number;
  liveOdds: boolean;
  dirty: boolean;
  vip: boolean;
  outright: boolean;
  redCardsHome: number;
  redCardsAway: number;
  yellowCardsHome: number;
  yellowCardsAway: number;
  yellowRedCardsHome: number;
  yellowRedCardsAway: number;
  cornersHome: number;
  cornersAway: number;
  firstLiveOddsChange: null;
  userID: number;
  lastChange: Date;
  brSportID: number;
}

export interface MeSport {
  lB_SportID: number;
  brSportID: number;
  brName: string;
}

export interface MeTeam {
  lB_TeamID: number;
  brTeamID: null;
  brTournamentID: null;
  brUniqueID: number;
  brOutrightID: null;
  brOutcomeID: null;
  brName: string;
  name: null;
  brNameDE: string;
  nameDE: null;
  brNameEN: string;
  nameEN: null;
  brNameRU: null;
  nameRU: null;
  brNameES: null;
  nameES: null;
  gender: string;
  ageGroup: string;
  countryCode: string;
  userID: null;
  lastChange: null;
}

export interface MeTournament {
  lB_TournamentID: number;
  lB_SportID: number;
  lB_CategoryID: number;
  brTournamentID: number;
  brName: string;
  name: null;
  brCategory: string;
  brCurrentSeasonID: null;
  riskPercentage: number;
  rating: string;
  minCombination: number;
  nationalLevel: number;
  autoOffer: boolean;
  autoOfferLive: boolean;
  autoOfferOutright: boolean;
  sortIndex: number;
  sortOrder: number;
  autoVIP: boolean;
  hasLeagueTable: boolean;
  userID: null;
  lastChange: Date;
  lB_SeasonID: null;
  currentSeasonStartDate: null;
  brSportID: number;
  row: number;
  isActive: boolean;
  isActiveNow: boolean;
  starts: null;
  ends: null;
  nEvents: number;
  pred: null;
  namePred: null;
  tags: null;
  hints: null;
  numMatches: null;
  region: null;
}
