import { Component, OnInit } from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import { TranslationService } from '../../services/translation.service';
import { SessionService } from '../../services/session.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {getContextMenuItems, isNumber} from 'utils';
import { loadColumnVisibility, storeColumnVisibility } from '../../../utils';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { CustomerBalanceWeekService } from './customer-balance-week.service';
import { tap } from 'rxjs';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-customer-balance-week',
  templateUrl: './customer-balance-week.component.html',
  styleUrls: ['./customer-balance-week.component.scss']
})
export class CustomerBalanceWeekComponent {

  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_MEDIUM = 140;
  static COLUMN_WIDTH_LARGE = 160;

  columnDefs;
  gridOptions;
  rowData;
  rowCount;
  query: QueryFilter;

  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public customerBalanceWeekService: CustomerBalanceWeekService,
    public translationService: TranslationService,
    public sessionService: SessionService,
    public datePipe: DatePipe,
    public decimalPipe: DecimalPipe,
    public httpService: HttpService,
    public dialog: MatDialog) {
    this.columnDefs = this.generateColumnDefs();
    this.gridOptions = this.generateGridOptions();
  };

  fetch(query: QueryFilter) {
    if (query) {
      this.query = query;
      this.rowData = this.customerBalanceWeekService.fetch(query);
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('customerBalanceWeek', event);
  }

  ngOnInit() {
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('customerBalanceWeek.customerID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'customerID',
        width: CustomerBalanceWeekComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('customerBalanceWeek.customerID')
      },
      {
        headerName: this.translationService.translate('customerBalanceWeek.dateFrom'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'dateFrom',
        valueFormatter: this.dateFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CustomerBalanceWeekComponent.COLUMN_WIDTH_MEDIUM,
        headerTooltip: this.translationService.translate('customerBalanceWeek.dateFromTT')
      },
      {
        headerName: this.translationService.translate('customerBalanceWeek.stake'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stake',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CustomerBalanceWeekComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('customerBalanceWeek.stake')
      },
      {
        headerName: this.translationService.translate('customerBalanceWeek.bonusStake'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'bonusStake',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CustomerBalanceWeekComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('customerBalanceWeek.bonusStake')
      },
      {
        headerName: this.translationService.translate('customerBalanceWeek.profit'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'profit',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CustomerBalanceWeekComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('customerBalanceWeek.profit')
      },
      {
        headerName: this.translationService.translate('customerBalanceWeek.hold'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'hold',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CustomerBalanceWeekComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('customerBalanceWeek.hold')
      },
      {
        headerName: this.translationService.translate('customerBalanceWeek.ticketCount'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'ticketCount',
        valueFormatter: this.integerFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CustomerBalanceWeekComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('customerBalanceWeek.ticketCount')
      }
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: true,
      rowClassRules: {
        'text-align-right': (params) => isNumber(params.data.value),
        'vit-red': (params) => this.calculateDaysOffline(params.data.lastLogin) >= 3
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }

  private calculateDaysOffline(lastLogout) {
    return lastLogout ? Math.floor((new Date().getTime() - new Date(lastLogout).getTime()) / (1000 * 60 * 60 * 24)) : null;
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  integerFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.0-0');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }

}
