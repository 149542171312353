
import {throwError as observableThrowError} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { SessionService } from './session.service';
import { SystemConfig } from '../config/system-config';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/index';
import { Game } from '../domain/game';
import { PokerbetSettingsData } from '../domain/pokerbet-settings-data';
import { KenobetSettingsData } from '../domain/kenobet-settings-data';
import { mapMap } from '../../utils';
import { SmartraceSettingsData } from '../domain/smartrace-settings-data';
import { SmartraceSettings } from '../domain/smartrace-settings';
import { KenobetSettings } from '../domain/kenobet-settings';
import { PokerbetSettings } from '../domain/pokerbet-settings';
import { LivesportsSettingsData } from '../domain/livesports-settings-data';
import { LivesportsSettings } from '../domain/livesports-settings';
import { Jackpot } from '../domain/jackpot';
import { JackpotService } from './jackpot.service';
import CombiBonus = LivesportsSettings.CombiBonus;
import CycleInfo = SmartraceSettings.CycleInfo;

@Injectable({
    providedIn: 'root',
})
export class GameService {
    constructor(public httpService: HttpService,
                public sessionService: SessionService) {
    }

    saveRaceCycle(raceCycle: CycleInfo) {
        return this.httpService.post(SystemConfig.Endpoints.GAME_SMARTRACE_SETTINGS_CYCLE_UPDATE, raceCycle);
    }

    get(game: Game, stationID: number): Observable<any> {
        switch (game) {
            case Game.KENO_BET:
                return this.httpService.get(SystemConfig.Endpoints.GAME_KENOBET_SETTINGS + stationID)
                    .pipe(map((json) => this.mapKenoBet(json)));
            case Game.POKER_BET:
                return this.httpService.get(SystemConfig.Endpoints.GAME_POKERBET_SETTINGS + stationID)
                    .pipe(map((json) => this.mapPokerBet(json)));
            case Game.SMART_RACE:
                return this.httpService.get(SystemConfig.Endpoints.GAME_SMARTRACE_SETTINGS + stationID)
                    .pipe(map((json) => this.mapSmartRace(json)));
            case Game.LIVESPORTS:
                return this.httpService.get(SystemConfig.Endpoints.GAME_LIVESPORTS_SETTINGS + stationID)
                    .pipe(map((json) => this.mapLiveSports(json)));
            default:
                return observableThrowError('unknown game: ' + game);
        }
    }

    copyCombiBonus(json: any): any {
        return this.httpService.post(SystemConfig.Endpoints.GAME_LIVESPORTS_COMBIBONUS_COPY, json);
    }

    saveCombiBonus(combiBoni: CombiBonus[]): any {
        return this.httpService.post(SystemConfig.Endpoints.GAME_LIVESPORTS_COMBIBONUS_UPDATE, combiBoni);
    }

    deleteCombiBonus(stationID: number): any {
        return this.httpService.post(SystemConfig.Endpoints.GAME_LIVESPORTS_COMBIBONUS_DELETE + stationID);
    }

    saveKenobetSettings(settings: KenobetSettings): Observable<any> {
        return this.httpService.post(SystemConfig.Endpoints.GAME_KENOBET_SETTINGS_UPDATE, settings);
    }

    savePokerbetSettings(settings: PokerbetSettings): Observable<any> {
        return this.httpService.post(SystemConfig.Endpoints.GAME_POKERBET_SETTINGS_UPDATE, settings);
    }

    saveSmartraceSettings(settings: SmartraceSettings): Observable<any> {
        return this.httpService.post(SystemConfig.Endpoints.GAME_SMARTRACE_SETTINGS_UPDATE, settings);
    }

    saveLivesportsSettings(settings: LivesportsSettings): Observable<any> {
        return this.httpService.post(SystemConfig.Endpoints.GAME_LIVESPORTS_SETTINGS_UPDATE, settings);
    }

    private mapJackpots(jackpots: Jackpot[]) {
        const noJackpot = new Jackpot();
        noJackpot.jackpotID = JackpotService.NO_JACKPOT_ID;
        noJackpot.name = '---';
        jackpots.unshift(noJackpot);
        return jackpots;
    }

    private mapKenoBet(json: any): KenobetSettingsData {
        const settings = new KenobetSettingsData();
        settings.settings = json.bB1Setting;
        settings.jackpots = this.mapJackpots(json.lstJackpots);
        settings.gameCycles = mapMap(json.lstBB1GameCycles);
        settings.winningPlanDescriptions = json.lstWinningPlanDescriptions;
        return settings;
    }

    private mapPokerBet(json: any): PokerbetSettingsData {
        const settings = new PokerbetSettingsData();
        settings.settings = json.pB1Setting;
        settings.jackpots = this.mapJackpots(json.lstJackpots);
        return settings;
    }

    private mapSmartRace(json: any): SmartraceSettingsData {
        const settings = new SmartraceSettingsData();
        settings.settings = json.rF2Setting;
        settings.jackpots = this.mapJackpots(json.lstJackpots);
        settings.gameCycles = json.lstStationRaceCycleInfo as SmartraceSettings.CycleInfo[];
        return settings;
    }

    private mapLiveSports(json: any): LivesportsSettingsData {
        const settings = new LivesportsSettingsData();
        settings.settings = json.lB2Setting;
        settings.lstLB2Design = mapMap(json.lstLB2Design);
        settings.lstLB2CombiBonus = json.lstLB2CombiBonus;
        settings.lstLegalBetTypeRestriction = mapMap(json.lstLegalBetTypeRestriction);
        settings.operatingHours = this.generateOperatingHours();
        return settings;
    }

    private generateOperatingHours() {
        const operatingHours = new Map<string, string>();

        // sets all operating times for 24 hours from 00:00:00 in 15 minute steps to 00:00:00
        // e.g. 00:00:00, 00:15:00, 00:30:00, 00:45:00, 01:00:00, ...

        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
              const formattedHour = hour.toString().padStart(2, '0');
              const formattedMinute = minute.toString().padStart(2, '0');
              const timeKey = `${formattedHour}:${formattedMinute}:00`;
              const timeValue = `${formattedHour}:${formattedMinute}`;
      
              operatingHours.set(timeKey, timeValue);
            }
          }

        return operatingHours;
    }
}
