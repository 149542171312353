import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { SessionService } from './session.service';
import {SystemConfig} from '../config/system-config';
import { Observable } from 'rxjs';
import { QueryFilter } from '../domain/query-filter';
import {CashError} from '../domain/cash-error';

@Injectable({
  providedIn: 'root'
})
export class CashErrorService {

  constructor(public httpService: HttpService,
    public sessionService: SessionService) { }

  fetchCashErrors(query: QueryFilter): Observable<CashError[]> {
    return this.httpService.post(SystemConfig.Endpoints.CASH_ERRORS, query);
  }

}
