import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatTree, MatTreeNestedDataSource } from '@angular/material/tree';
import {CustomerTreeNode} from '../domain/customer-tree-node';
import {of as observableOf} from 'rxjs';
import {SessionService} from '../services/session.service';
import {NestedTreeControl} from '@angular/cdk/tree';
import {CustomerTreeService} from '../services/customer-tree.service';
import {clone} from '../utils/basic-utils';
import {MobileService} from '../services/mobile.service';
import {TreeNodeType} from '../domain/tree-node-type';
import {environment} from '../../environments/environment';
import {NavigationStart, Router} from '@angular/router'; // added mpo
import {SystemConfig} from '../config/system-config';
import {Permission} from '../domain/permission'; // added mpo

@Component({
  selector: 'vit-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit {

  routes = SystemConfig.Routes; // added mpo

  buildTs = environment.buildTime;

  @ViewChild('tree', { static: true }) tree: MatTree<CustomerTreeNode>;

  nestedTreeControl: NestedTreeControl<CustomerTreeNode>;
  nestedDataSource: MatTreeNestedDataSource<CustomerTreeNode>;

  @Output()
  hide: EventEmitter<void> = new EventEmitter();

  expandedNodes = [];
  mappedClonedTree;
  Permission = Permission;

  get parentNode() {
    return this.nestedDataSource.data ? this.nestedDataSource.data[0] : null;
  }

  toggleSize() {
    this.sessionService.largeTree = !this.sessionService.largeTree;
  }

  constructor(public sessionService: SessionService,
              public mobileService: MobileService,
              public dialog: MatDialog,
              public customerTreeService: CustomerTreeService,
              public changeDetector: ChangeDetectorRef,
              public router: Router) {  // added mpo
    this.customerTreeService.tree.subscribe((tree: CustomerTreeNode) => {
      if (tree && this.nestedDataSource) {
        const clonedTree = clone(tree);
        this.mappedClonedTree = CustomerTreeNode.mapCloned(clonedTree);
        this.nestedDataSource.data = [CustomerTreeNode.mapCloned(this.removeChildChildren(clonedTree))];
        this.expandNodes();
      }
    });
    this.customerTreeService.showTreeNode.subscribe((p: {id: number, type: TreeNodeType}) => {
      const node = this.getTreeDataNode({id: p.id, type: p.type} as CustomerTreeNode, this.mappedClonedTree);
      this.expandedNodes = this.getUntilRoot(node);
      this.expandNodes();
    });
  }

  refreshTree() {
    const _data = this.nestedDataSource.data;
    this.nestedDataSource.data = null;
    this.nestedDataSource.data = _data;
  }

  ngOnInit() {
    this.nestedTreeControl = new NestedTreeControl<CustomerTreeNode>(this._getChildren);
    this.nestedDataSource = new MatTreeNestedDataSource();
  }

  removeChildChildren(tree: CustomerTreeNode): CustomerTreeNode {
    if (tree && tree.children) {
      tree.children.forEach(child => child.children = []);
    }
    return tree;
  }

  nodeClicked(node: CustomerTreeNode) {
    if (node.children.length === 0) {
      node.children = this.removeChildChildren(clone(this.customerTreeService.getTreeNode(node))).children.map(CustomerTreeNode.mapCloned);
      this.refreshTree();
    }

    // idk why isExpanded returns inverse value
    if (this.nestedTreeControl.isExpanded(node)) {
      this.nestedTreeControl.expand(node);
      this.expandedNodes.push(node);
    } else {
      this.nestedTreeControl.collapse(node);
      this.expandedNodes.splice(this.expandedNodes.indexOf(node), 1);
    }
  }

  expandNodes() {
    this.collapse();
    this.changeDetector.detectChanges();

    this.expandedNodes.forEach((node, idx) => {
      console.log(idx, node);
      const newNodeRef = this.getTreeDataNode(node);
      if (newNodeRef) {
        if (!newNodeRef.children || newNodeRef.children.length === 0) {
          newNodeRef.children = this.removeChildChildren(clone(this.customerTreeService.getTreeNode(node))).children.map(CustomerTreeNode.mapCloned);
          this.refreshTree();
        }
        this.nestedTreeControl.expand(newNodeRef);
      }
      this.changeDetector.detectChanges();
    });
  }

  collapse() {
    this.nestedTreeControl.collapseDescendants(this.parentNode);
    this.nestedTreeControl.expand(this.parentNode);
  }

  refresh() {
    this.customerTreeService.fetchTree();
  }

  openMenu(event: any, node: CustomerTreeNode): void {
    this.sessionService.treeNodeSelected.next(node);
    event.preventDefault();
  }

  private _getChildren = (node: CustomerTreeNode) => {
    return observableOf(node.children);
  };

  hasNestedChild = (_: number, nodeData: CustomerTreeNode) => {
    return nodeData.children || nodeData.children.length > 0;
  };

  getTreeDataNode(nodeToFind: CustomerTreeNode, node?: CustomerTreeNode) {
    node = node ? node : this.nestedDataSource.data[0];
    if (node.id === nodeToFind.id && node.type === nodeToFind.type) {
      return node;
    } else if (node.children != null) {
      let i;
      let result = null;
      for (i = 0; result == null && i < node.children.length; i++) {
        result = this.getTreeDataNode(nodeToFind, node.children[i]);
      }
      return result;
    }
    return null;
  }

  private getUntilRoot(node: CustomerTreeNode, result: CustomerTreeNode[] = []) {
    if (node && node.parentId !== -1) {
      return this.getUntilRoot(this.getTreeDataNode({
        id: node.parentId,
        type: node.parentType
      } as CustomerTreeNode, this.mappedClonedTree), [node, ...result]);
    }
    return result;
  }
}
