<div class="title-bar" *ngIf="!selectedEvent" [class.in-dialog]="data?.query">
  <span>{{data?.competitors}}</span>
  <button mat-icon-button class="close" *ngIf="dialogRef" (click)="dialogRef.close()">
    <mat-icon color="warn">close</mat-icon>
  </button>
</div>

<div class="slider-container" *ngIf="onlyOpen">
  <vit-custom-mat-slider
    [label]="'matchEditor.detail.settlementDelay' | translate"
    min="0"
    max="180"
    stepSize="5"
    tickInterval="true"
    numberFormat="0.0-0"
    [_sliderValue]="settlementDelayMinutes"
    (valueChange)="settlementDelayMinutes = $event"
    [thumbLabel]="true"
  ></vit-custom-mat-slider>
  <button mat-raised-button (click)="rowData = []; ngOnInit()">
    <mat-icon>refresh</mat-icon>
  </button>
  <button mat-icon-button (click)="showInfoWarning()" class="info-warning-icon">
    <mat-icon>info</mat-icon>
  </button>
</div>

<div class="info-container" *ngIf="onlyOpen">
  <span  class="info" *ngIf="rowData.length === 0 && matchStartMinutesAgo > settlementDelayMinutes">
    {{ 'matchEditor.detail.noOpenTickets' | translate }}
  </span>
  <span  class="info" *ngIf="rowData.length === 0 && matchStartMinutesAgo <= settlementDelayMinutes">
    {{ 'matchEditor.detail.openTicketsInfo' | translate }}
  </span>
  <span *ngIf="rowData.length > 0" class="info hint">
    {{ 'matchEditor.detail.winnableTicketsHint' | translate: ['' + (rowData.length - 1)] }}
  </span>
</div>

<div class="filter-container" *ngIf="allTickets || enhancedTicketList">
  <button mat-raised-button (click)="toggleAll()"
          [color]="allFilterEnabled ? 'accent' : ''"> {{ 'matchEditor.detail.ticketListFilter.all' | translate }}
  </button>
  <button mat-raised-button (click)="toggleWinnable()"
          [color]="winableFilterEnabled ? 'accent' : ''"> {{ 'matchEditor.detail.ticketListFilter.winnable' | translate }}
  </button>
  <button mat-raised-button (click)="toggleEdge()"
          [color]="edgeFilterEnabled ? 'accent' : ''"> {{ 'matchEditor.detail.ticketListFilter.edge' | translate }}
  </button>
  <button mat-raised-button (click)="toggleWon()"
          [color]="wonFilterEnabled ? 'accent' : ''"> {{ 'matchEditor.detail.ticketListFilter.won' | translate }}
  </button>
  <button mat-raised-button (click)="toggleLost()"
          [color]="lostFilterEnabled ? 'accent' : ''"> {{ 'matchEditor.detail.ticketListFilter.lost' | translate }}
  </button>
  <button mat-raised-button (click)="togglePaidOut()"
          [color]="paidOutFilterEnabled ? 'accent' : ''"> {{ 'matchEditor.detail.ticketListFilter.paidOut' | translate }}
  </button>
  <button mat-raised-button (click)="toggleNotPaidOut()"
          [color]="notPaidOutFilterEnabled ? 'accent' : ''"> {{ 'matchEditor.detail.ticketListFilter.notPaidOut' | translate }}
  </button>
  <button mat-raised-button (click)="toggleCashout()"
          [color]="cashoutFilterEnabled ? 'accent' : ''"> {{ 'matchEditor.detail.ticketListFilter.cashout' | translate }}
  </button>
  <button mat-raised-button (click)="toggleStorno()"
          [color]="stornoFilterEnabled ? 'accent' : ''"> {{ 'matchEditor.detail.ticketListFilter.storno' | translate }}
  </button>
  <button mat-raised-button (click)="toggleLocked()"
          [color]="lockedFilterEnabled ? 'accent' : ''"> {{ 'matchEditor.detail.ticketListFilter.locked' | translate }}
  </button>
  <button mat-raised-button (click)="toggleNotDecided()"
          [color]="notDecidedFilterEnabled ? 'accent' : ''"> {{ 'matchEditor.detail.ticketListFilter.notDecided' | translate }}
  </button>
  <button mat-raised-button (click)="toggleSingleBet()"
          [color]="singleBetFilterEnabled ? 'accent' : ''"> {{ 'matchEditor.detail.ticketListFilter.singleBet' | translate }}
  </button>
  <button mat-raised-button (click)="toggleUAID()" *ngIf="data?.uaid"
          [color]="uaidFilterEnabled ? 'accent' : ''"> {{ 'matchEditor.detail.ticketListFilter.uaid' | translate }}
  </button>
  <button mat-raised-button (click)="toggleCID()" *ngIf="data?.cid"
          [color]="cidFilterEnabled ? 'accent' : ''"> {{ 'matchEditor.detail.ticketListFilter.cid' | translate }}
  </button>
  <button mat-raised-button (click)="toggleAdditionalFilters()"
          [color]="additionalFiltersEnabled ? 'accent' : ''">
    <mat-icon>add_circle_outline</mat-icon>
  </button>
  <button mat-raised-button (click)="toggleBT()" *ngIf="data?.btKey && (additionalFiltersEnabled || btFilterEnabled)"
          [color]="btFilterEnabled ? 'accent' : ''"> {{ getBtLabel() }}
  </button>
  <button mat-raised-button (click)="toggleBTExact()" *ngIf="data?.btSov && (additionalFiltersEnabled || btExactFilterEnabled)"
          [color]="btExactFilterEnabled ? 'accent' : ''"> {{ 'matchEditor.detail.ticketListFilter.btExact' | translate: [data?.btSov] }}
  </button>

  <br *ngIf="data?.query">
  <mat-form-field appearance="outline" [class.highlighted]="!!minStake" class="short-input">
    <mat-label>{{ 'matchEditor.detail.ticketListFilter.minStake' | translate }}</mat-label>
    <input matInput [(ngModel)]="minStake"
           (ngModelChange)="allFilterEnabled = false; api?.onFilterChanged()">
  </mat-form-field>
  <mat-form-field appearance="outline" [class.highlighted]="!!minProfit" class="short-input">
    <mat-label>{{ 'matchEditor.detail.ticketListFilter.minProfit' | translate }}</mat-label>
    <input matInput [(ngModel)]="minProfit" (ngModelChange)="allFilterEnabled = false; api?.onFilterChanged()">
  </mat-form-field>
  <button mat-raised-button (click)="toggleDateFilter()" *ngIf="additionalFiltersEnabled || dateFilterEnabled"
          [color]="dateFilterEnabled ? 'accent' : ''"> {{ 'matchEditor.detail.ticketListFilter.date' | translate }}
  </button>
  <mat-form-field appearance="outline" [class.highlighted]="!!minDate" *ngIf="dateFilterEnabled">
    <mat-label>{{ 'matchEditor.detail.ticketListFilter.minDate' | translate }}</mat-label>
    <vit-date-input [(ngModel)]="minDate" (ngModelChange)="allFilterEnabled = false; api?.onFilterChanged()"></vit-date-input>
    <mat-icon (click)="minDate = null" style="cursor:pointer;" matSuffix class="clear-icon">clear</mat-icon>
  </mat-form-field>
  <mat-form-field appearance="outline" [class.highlighted]="!!maxDate" *ngIf="dateFilterEnabled">
    <mat-label>{{ 'matchEditor.detail.ticketListFilter.maxDate' | translate }}</mat-label>
    <vit-date-input [(ngModel)]="maxDate" (ngModelChange)="allFilterEnabled = false; api?.onFilterChanged()"></vit-date-input>
    <mat-icon (click)="maxDate = null" style="cursor:pointer;" matSuffix class="clear-icon">clear</mat-icon>
  </mat-form-field>
</div>

<ag-grid-angular class="ag-theme-balham" [sideBar]="sidebar" [rowClassRules]="rowClassRules" [detailRowHeight]="550"
                 [style.width]="'100%'" [rowData]="rowData" [pinnedTopRowData]="titleRow" [columnDefs]="columnDefs"
                 (filterChanged)="onFilterChanged()"
                 [isExternalFilterPresent]="isExternalFilterPresent" [doesExternalFilterPass]="doesExternalFilterPass"
                 (gridReady)="onGridReady($event)" [excelStyles]="excelStyles" [masterDetail]="true"
                 [statusBar]="statusBar" (columnVisible)="storeColumnVisibilityFn('ticketList-' + isForMatchEditor, $event)"
                 [detailCellRenderer]="'ticketDetailCellRenderer'" [components]="frameworkComponents">
</ag-grid-angular>
<table class="legend">
  <tr>
    <td class="state-won">
      <div class="box"></div>
    </td>
    <td>{{ 'tickets.states.won' | translate }}</td>
    <td class="state-lost">
      <div class="box"></div>
    </td>
    <td>{{ 'tickets.states.lost' | translate }}</td>
    <td class="state-storno">
      <div class="box"></div>
    </td>
    <td>{{ 'tickets.states.storno' | translate }}</td>
  </tr>
  <tr>
    <td class="state-disbursed">
      <div class="box"></div>
    </td>
    <td>{{ 'tickets.states.disbursed' | translate }}</td>
    <td class="state-pending">
      <div class="box"></div>
    </td>
    <td>{{ 'tickets.states.pending' | translate }}</td>
    <td class="state-cashout">
      <div class="box"></div>
    </td>
    <td>{{ 'tickets.states.cashout' | translate }}</td>
  </tr>
</table>

<button mat-raised-button class="more" *ngIf="status?.nRest > 0" (click)="ngOnInit()">
  {{ 'tickets.more' | translate: [status.nRest] }}
</button>


