import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GameNumber} from '../../domain/game-number';
import {TicketDetailService} from '../ticket-detail.service';
import {SessionService} from '../../services/session.service';
import {Permission} from '../../domain/permission';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {TranslationService} from '../../services/translation.service';
import {
  SecurecodeDialogComponent
} from '../../ticket-detail-livesports/securecode-dialog.component';

@Component({
  selector: 'vit-payout-widget',
  templateUrl: './payout-widget.component.html',
  styleUrls: ['./payout-widget.component.scss']
})
export class PayoutWidgetComponent implements OnInit {

  @Input()
  ticket: any;

  @Input()
  gameNumer: GameNumber;

  @Output()
  update: EventEmitter<void> = new EventEmitter();

  constructor(private ticketDetailService: TicketDetailService,
              public sessionService: SessionService,
              public translationService: TranslationService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  click() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        'message': this.translationService.translate('ticketDetail.payoutWidget.confirmMessage' + (!this.ticket.disbursed ? '' : 'Undo'))
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        if (this.ticket.disbursed) {
          const dialogRef = this.dialog.open(SecurecodeDialogComponent, {
            data: {secureCode: this.sessionService.secureCode},
            disableClose: true
          });
          dialogRef.afterClosed().subscribe(secureCode => {
            if (secureCode && secureCode.length > 0) {
              this.executeTicketUpdate(secureCode);
            }
          });
        } else {
          this.executeTicketUpdate(this.sessionService.secureCode);
        }
      }
    });
  }

  private executeTicketUpdate(secureCode) {
    this.ticket.disbursed = !this.ticket.disbursed;
    this.ticketDetailService.update(this.ticket, this.gameNumer, secureCode).subscribe(() => {
      this.sessionService.secureCode = secureCode;
      this.update.emit();
    });
    return secureCode;
  }

  isAllowed() {
    return (this.ticket.won && !this.ticket.storno && !this.ticket.disbursed
      && this.sessionService.hasPermission(Permission.payoutTicket))
      || (this.ticket.won && !this.ticket.storno && this.ticket.disbursed
        && this.sessionService.hasPermission(
          this.gameNumer === GameNumber.LIVESPORTS ? Permission.editAdvancedAdminSettings : Permission.payoutTicket));
  }
}
