import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsComponent } from './buttons.component';
import {MaterialImportsModule} from '../../material-imports.module';
import {SharedModule} from '../../shared.module';
import {BrowserModule} from '@angular/platform-browser';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BrowserModule,
    MaterialImportsModule
  ],
  declarations: [ButtonsComponent],
  exports: [ButtonsComponent]
})
export class ButtonsModule { }
