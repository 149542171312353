import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {SessionService} from '../services/session.service';
import {Permission} from '../domain/permission';

@Injectable({
  providedIn: 'root'
})
export class FranchiserPageguard  {

  constructor(private sessionService: SessionService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.sessionService.hasPermission(Permission.seeFranchiserData)) {
      console.log('missing role');
      return false;
    } else {
      return true;
    }
  }
}
