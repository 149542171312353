import { QuestionBase } from './question-base';

export class TextboxQuestion extends QuestionBase<string> {
    controlType = 'textbox';
    type: string;
    autocomplete: any[];

    constructor(options: {} = {}) {
        super(options);
      this.type = options['type'] || 'text';
      this.autocomplete = options['autocomplete'] || [];

    }
}
