import {Injectable, NgZone} from '@angular/core';

@Injectable()
export class MobileService {

  screenWidth: number;
  isMobile = false;

  constructor(public ngZone: NgZone) {
  }

  getRowCount(width) {
    if (width < 880) {
      return 1;
    } else if (width < 1400) {
      return 2;
    } else {
      return 3;
    }
  }

  setScreenWidth(screenWith: number) {
    if (screenWith < 600) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.screenWidth = screenWith;
    this.ngZone.run(() => {
    });
  }
}
