import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ColsComponent} from '../../cols/cols.component';
import {QuestionContainer} from '../../domain/question-container';
import {DropdownQuestion} from '../../domain/question-dropdown';
import {SessionService} from '../../services/session.service';
import {TranslationService} from '../../services/translation.service';
import {TextboxQuestion} from '../../domain/question-textbox';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {FormUtils} from '../../utils/form-utils';
import {DynamicFormComponent} from '../../dynamic-form/dynamic-form.component';
import {UntypedFormGroup} from '@angular/forms';
import {CycleService} from '../../services/cycle.service';
import {CheckboxQuestion} from '../../domain/question-checkbox';
import {ERROR_SNACK_CONFIG, SUCCESS_SNACK_CONFIG} from '../../../utils';

@Component({
  selector: 'vit-cycle-pb1',
  templateUrl: './cycle-pb1.component.html',
  styleUrls: ['./cycle-pb1.component.scss']
})
export class CyclePb1Component implements OnInit {

  loading: boolean;
  @ViewChild(ColsComponent, { static: false })
  colsComponent: ColsComponent;
  @ViewChildren(DynamicFormComponent)
  forms: QueryList<DynamicFormComponent>;

  questions: QuestionContainer[] = [];
  cycles: any[] = [];
  selectedCycle;

  constructor(public cycleService: CycleService,
              public snackBar: MatSnackBar,
              public sessionService: SessionService,
              public translationService: TranslationService) {
  }

  ngOnInit() {
    this.loading = true;
    this.cycleService.fetchPB1Cycles().subscribe(cycles => this.initForm(cycles));
  }

  reset() {
    this.initForm(this.cycles);
  }

  getFormValue(): any {
    const form: any = FormUtils.getFormValues(this.forms, 'cycle.pb1.header');
    return Object.assign(JSON.parse(JSON.stringify(this.selectedCycle)), form);
  }

  save() {
    const cycle = this.getFormValue();
    this.cycleService.updatePB1Cycle(cycle).subscribe(() => {
this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
      }, () => {
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  initForm(cycles: any[]) {
    this.loading = false;
    this.cycles = cycles;

    this.selectedCycle = this.cycles.length > 0 ? this.cycles[0] : null;
    this.setFormValues();
  }

  private setFormValues() {
    this.questions = [];
    this.questions.push(new QuestionContainer('cycle.pb1.header', [
      new DropdownQuestion({
        key: 'gameCycleID',
        label: 'cycle.pb1.gameCycleID',
        value: this.selectedCycle.gameCycleID.toString(),
        options: Array.from(this.cycles.map((c) => ({
          key: c.gameCycleID.toString(),
          value: c.description
        }))),
        onChange: this.cycleChanged.bind(this)
      }),
      new TextboxQuestion({
        type: 'number',
        key: 'betAcceptancePeriod',
        label: 'cycle.pb1.betAcceptancePeriod',
        value: this.selectedCycle.betAcceptancePeriod,
        required: true
      }),
      new TextboxQuestion({
        type: 'number',
        key: 'showResultPeriod',
        label: 'cycle.pb1.showResultPeriod',
        value: this.selectedCycle.showResultPeriod,
        required: true
      }),
      new TextboxQuestion({
        type: 'number',
        key: 'showdownPeriod',
        label: 'cycle.pb1.showdownPeriod',
        value: this.selectedCycle.showdownPeriod,
        required: true
      }),
      new CheckboxQuestion({
        key: 'active',
        label: 'cycle.pb1.active',
        value: this.selectedCycle.active
      }),
      new CheckboxQuestion({
        key: 'autostart',
        label: 'cycle.pb1.autostart',
        value: this.selectedCycle.autostart
      }),
      new TextboxQuestion({
        key: 'description',
        label: 'cycle.pb1.description',
        value: this.selectedCycle.description,
        required: true
      }),
    ]));
  }

  private cycleChanged(cycleId: string) {
    this.selectedCycle = this.cycles.find(c => c.gameCycleID === +cycleId);
    const form: UntypedFormGroup = FormUtils.getForm(this.forms, 'cycle.pb1.header');
    const formCycle = JSON.parse(JSON.stringify(this.selectedCycle));
    formCycle.gameCycleID = formCycle.gameCycleID.toString();
    form.reset(formCycle);
  }

}
