import {QuestionBase} from './question-base';
import {Permission} from './permission';
import {SessionService} from '../services/session.service';

export class QuestionContainer {
  questions: QuestionBase<any>[];
  editPermission: Permission;
  viewPermission: Permission;
  label: string;
  buttons: any = [];
  submitLabel: string;
  submitFunction: Function;
  submitPermission: Permission;
  x: string;
  y: string;
  id: string;
  width: string;
  last: boolean;

  constructor(label: string, questions: QuestionBase<any>[], editPermission?: Permission, viewPermission?: Permission) {
    this.label = label || '';
    this.questions = questions || [];
    this.editPermission = editPermission;
    this.viewPermission = viewPermission;
    this.id = new Date().getTime().toString() + Math.floor(1000000 * Math.random());
  }

  public addSubmit(label: string, submitFunction: Function, submitPermission?: Permission) {
    this.buttons.push({
      submitLabel: label,
      submitFunction: submitFunction,
      submitPermission: submitPermission
    });
  }

  isNotAuthorizeToView(sessionService: SessionService): boolean {
    return (this.viewPermission && !sessionService.hasPermission(this.viewPermission))
      || this.questions.every(q => !sessionService.hasPermission(q.viewPermission));
  }
}
