import {Component, OnInit} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {NewTabComponent} from '../../new-tab/new-tab.component';
import {environment} from '../../../environments/environment';
import {SystemConfig} from '../../config/system-config';
import {TicketListComponent} from '../../ticket-list/ticket-list.component';
import {isNumber} from 'utils';
import {enrichByStationId, loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {clone} from '../../utils/basic-utils';
import {WebshopHoldOverviewService} from './webshop-hold-overview.service';
import {GridReadyEvent} from 'ag-grid-community';
import {StationUtils} from '../../utils/station-utils';
import {TreeNodeType} from '../../domain/tree-node-type';

@Component({
  selector: 'vit-webshop-hold-overview',
  templateUrl: './webshop-hold-overview.component.html',
  styleUrls: ['./webshop-hold-overview.component.scss']
})
export class WebshopHoldOverviewComponent implements OnInit {

  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;

  rowDataHoldOverview;
  columnDefsHoldOverview;
  gridOptionsHoldOverview;
  detailParamsHoldOverview;

  rowDataCashflow;
  columnDefsCashflow;
  gridOptionsCashflow;

  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public webshopHoldOverviewService: WebshopHoldOverviewService,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public datePipe: DatePipe,
              public decimalPipe: DecimalPipe,
              public dialog: MatDialog) {
    this.columnDefsHoldOverview = this.generateColumnDefsHoldOverview();
    this.detailParamsHoldOverview = this.generateDetailParamsHoldOverview();
    this.gridOptionsHoldOverview = this.generateGridOptionsHoldOverview();

    this.columnDefsCashflow = this.generateColumnDefsCashflow();
    this.gridOptionsCashflow = this.generateGridOptionsCashflow();
  }

  onGridReady(event: GridReadyEvent) {
    // ignore generic handler since we got two different grids .. keep implemented to avoid exception
  }

  onGridReadyHoldOverview(event: GridReadyEvent) {
    loadColumnVisibility('webshopHoldOverview', event);
  }

  onGridReadyCashflow(event: GridReadyEvent) {
    loadColumnVisibility('webshopCashflow', event);
  }

  ngOnInit() {
  }

  fetch(query: QueryFilter) {
    if (query) {
      this.query = query;
      this.rowDataHoldOverview = this.webshopHoldOverviewService.fetchHoldOverview(query);
      this.rowDataCashflow = this.webshopHoldOverviewService.fetchCashflow(query);
    } else {
      this.query = null;
      this.rowDataHoldOverview = null;
      this.rowDataCashflow = null;
    }
  }

  generateColumnDefsHoldOverview() {
    return [
      {
        headerName: this.translationService.translate('webShopHoldOverview.username'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'username',
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.numTickets'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'numTickets',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH * 0.8
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.cashIn'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'payIn',
        cellClass: 'text-align-right',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.cashOut'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'payOut',
        cellClass: 'text-align-right',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.addedToCredit'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'totalCreditIncrement',
        cellClass: 'text-align-right',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.stakeNet'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stakeNet',
        cellClass: 'text-align-right',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.stakeTax'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stakeTax',
        cellClass: 'text-align-right',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.profitGross'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'profitGross',
        cellClass: 'text-align-right',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.openPayoutsGross'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'openPayoutsGross',
        cellClass: 'text-align-right',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.hold'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'hold',
        cellClass: 'text-align-right',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.holdPercent'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'holdPercent',
        valueFormatter: this.percentFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.accountID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'accountID',
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH * 0.6
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.lastActivity'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'lastActivity',
        valueFormatter: this.dateFormatter.bind(this),
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH_LARGE
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.credit'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'credit',
        cellClass: 'text-align-right',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH * 0.85
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.bonusCredit'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'bonusCredit',
        cellClass: 'text-align-right',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH
      }
    ];
  }

  generateGridOptionsHoldOverview() {
    return {
      masterDetail: true,
      detailRowHeight: 160,
      enableColResize: true,
      isRowMaster: (data) => data.stationID !== 0,
      rowClassRules: {
        'bold': (params) => params.data.accountID === 0,
        'text-align-right': (params) => isNumber(params.data.value)
      },
      onCellClicked: (params) => {
        params.node.setExpanded(!params.node.expanded);
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }

  generateDetailParamsHoldOverview() {
    return {
      detailGridOptions: {
        toolPanelSuppressSideButtons: false,
        masterDetail: true,
        enableSorting: true,
        toolPanelSuppressValues: true,
        toolPanelSuppressRowGroups: true,
        toolPanelSuppressPivotMode: true,
        enableFilter: true,
        enableColResize: true,
        columnDefs: [
          {
            headerName: this.translationService.translate('webShopHoldOverview.newTab'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'newTab',
            width: 23,
            cellClass: 'new-tab',
            cellRenderer: NewTabComponent
          },
          {
            headerName: this.translationService.translate('webShopHoldOverview.gameName'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'gameName',
            width: WebshopHoldOverviewComponent.COLUMN_WIDTH
          },
          {
            headerName: this.translationService.translate('webShopHoldOverview.numTickets'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'numTickets',
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: 67
          },
          {
            headerName: this.translationService.translate('webShopHoldOverview.stakeNet'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'stakeNet',
            cellClass: 'text-align-right',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: WebshopHoldOverviewComponent.COLUMN_WIDTH
          },
          {
            headerName: this.translationService.translate('webShopHoldOverview.stakeTax'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'stakeTax',
            cellClass: 'text-align-right',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: WebshopHoldOverviewComponent.COLUMN_WIDTH
          },
          {
            headerName: this.translationService.translate('webShopHoldOverview.profitGross'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'profitGross',
            cellClass: 'text-align-right',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: WebshopHoldOverviewComponent.COLUMN_WIDTH
          },
          {
            headerName: this.translationService.translate('webShopHoldOverview.openPayoutsGross'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'openPayoutsGross',
            cellClass: 'text-align-right',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: WebshopHoldOverviewComponent.COLUMN_WIDTH
          },
          {
            headerName: this.translationService.translate('webShopHoldOverview.hold'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'hold',
            cellClass: 'text-align-right',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: WebshopHoldOverviewComponent.COLUMN_WIDTH
          },
          {
            headerName: this.translationService.translate('webShopHoldOverview.holdPercent'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'holdPercent',
            valueFormatter: this.percentFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: WebshopHoldOverviewComponent.COLUMN_WIDTH
          },
          {
            headerName: this.translationService.translate('webShopHoldOverview.lastActivity'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'lastActivity',
            valueFormatter: this.dateFormatter.bind(this),
            width: WebshopHoldOverviewComponent.COLUMN_WIDTH_LARGE
          },
        ],
        onCellClicked: (params) => {
          const query: QueryFilter = clone(this.query);
          query.stationID = params.data.webShopID;
          if (params.colDef.cellRenderer === NewTabComponent) {
            // TODO: use service-worker instead to pass info
            const url = environment.host + SystemConfig.Routes.TICKET_LIST + '/' + params.data.gameNumber
              + '/' + btoa(JSON.stringify(this.query));
            window.open(encodeURI(url), '_blank');
          } else {
            this.dialog.open(TicketListComponent, {
              height: '90%',
              width: '80%',
              data: {query: query, gameNumber: params.data.gameNumber},
              disableClose: true
            });
          }
        }
      },
      getDetailRowData: (params) => {
        this.query.userAccountID = params.data.accountID;
        this.webshopHoldOverviewService.fetchPerGame(this.query).subscribe(data => {
          params.successCallback(enrichByStationId(data, params.data.stationID));
        });
      }
    };
  }


  generateColumnDefsCashflow() {
    return [
      {
        headerName: this.translationService.translate('webShopHoldOverview.stationType'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'type',
        cellClass: 'type',
        width: 23,
        cellRenderer: function (params) {
          const icon = StationUtils.getIcon(TreeNodeType.STATION, params.data.type, false, params.data.softLocked, params.data.hardLocked);
          const img = '<img src=\'/assets/img/icons/' + icon + '\'>';
          return params.data.stationID === 0 ? '' : img;
        }
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.stationID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationID',
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40,
        headerTooltip: this.translationService.translate('webShopHoldOverview.stationID')
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.stationName'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationName',
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH_LARGE,
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.crossPayment'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'isCrossPayment',
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH * 0.80,
        cellRenderer: this.checkboxGenerator.bind(this),
        headerTooltip: this.translationService.translate('stationOverview.online')
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.qtyIn'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'qtyIn',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH * 0.6
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.qtyOut'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'qtyOut',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH * 0.6
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.cashIn'),
        headerTooltip: this.translationService.translate('webShopHoldOverview.cashInTotal'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashIn',
        cellClass: 'text-align-right',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.cashOut'),
        headerTooltip: this.translationService.translate('webShopHoldOverview.cashOut'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashOut',
        cellClass: 'text-align-right',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.balance'),
        headerTooltip: this.translationService.translate('webShopHoldOverview.balance'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'balance',
        cellClass: 'text-align-right',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.cashInPct'),
        headerTooltip: this.translationService.translate('webShopHoldOverview.cashInPct'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashInPct',
        cellClass: 'text-align-right',
        valueFormatter: this.percentFormatter.bind(this),
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('webShopHoldOverview.cashOutPct'),
        headerTooltip: this.translationService.translate('webShopHoldOverview.cashOutPct'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashOutPct',
        cellClass: 'text-align-right',
        valueFormatter: this.percentFormatter.bind(this),
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: WebshopHoldOverviewComponent.COLUMN_WIDTH
      }
    ];
  }

  generateGridOptionsCashflow() {
    return {
      enableColResize: true,
      isRowMaster: (data) => data.stationID !== 0,
      rowClassRules: {
        'bold': (params) => params.data.stationID === 0,
        'text-align-right': (params) => isNumber(params.data.value)
      },
      onCellClicked: (params) => {
        params.node.setExpanded(!params.node.expanded);
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns-cashflow',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }

  checkboxGenerator(params) {
    const eDiv = document.createElement('div');
    eDiv.innerHTML = '<input type="checkbox" disabled="true" ' + (params.value ? 'checked="true"' : '') + '">';
    return eDiv;
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }
}
