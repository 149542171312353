<div style="display: inline-block;">
  <button mat-icon-button type="button" class="date-input-button soft-primary-text no-print"
          (click)="previousDay($event)"
          tabindex="-1" [disabled]="disabled">
    <mat-icon>navigate_before</mat-icon>
  </button>

  <div class="date-input-container">
    <input matInput type="text" [formControl]="dateControl" [matDatepicker]="datepicker" #dateInput
           autocomplete="off" spellcheck="false" size="10"
           (keydown.ArrowUp)="$event.preventDefault()"
           (keyup.ArrowUp)="nextDay()"
           (keyup.ArrowDown)="previousDay($event)">
  </div>

  <button mat-icon-button type="button" class="date-input-button soft-primary-text no-print" (click)="nextDay()"
          tabindex="-1"
          [disabled]="disabled">
    <mat-icon>navigate_next</mat-icon>
  </button>

  <mat-datepicker-toggle [for]="datepicker"
                         class="date-input-button soft-primary-text no-print"></mat-datepicker-toggle>
  <mat-datepicker #datepicker></mat-datepicker>
</div>

<div *ngIf="showTimeControl" style="display: inline-block;">
  <button mat-icon-button type="button" class="date-input-button soft-primary-text no-print"
          (click)="previousHour($event)"
          tabindex="-1" [disabled]="disabled">
    <mat-icon>navigate_before</mat-icon>
  </button>

  <div class="date-input-container">
    <input matInput type="text" [formControl]="timeControl" #timeInput
           autocomplete="off" spellcheck="false" size="8"
           (keydown.ArrowUp)="$event.preventDefault()"
           (keyup.ArrowUp)="nextHour()"
           (keyup.ArrowDown)="previousHour()">
  </div>

  <button mat-icon-button type="button" class="date-input-button soft-primary-text no-print"
          (click)="nextHour($event);" tabindex="-1" [disabled]="disabled">
    <mat-icon>navigate_next</mat-icon>
  </button>

  <button *ngIf="showClearValue" mat-icon-button type="button" class="date-input-button soft-primary-text no-print"
          (click)="value = null" [disabled]="disabled">
    <mat-icon>close</mat-icon>
  </button>
</div>
