import {Injectable} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {SessionService} from '../../services/session.service';
import {Observable} from 'rxjs';
import {QueryFilter} from '../../domain/query-filter';
import {SystemConfig} from '../../config/system-config';

@Injectable({
  providedIn: 'root'
})
export class DayMonthHoldOverviewService {

  constructor(public httpService: HttpService,
              public sessionService: SessionService) {
  }

  fetch(query: QueryFilter, day: boolean): Observable<any[]> {
    return this.httpService.post(day ? SystemConfig.Endpoints.HOLD_OVERVIEW_DAY : SystemConfig.Endpoints.HOLD_OVERVIEW_MONTH, query);
  }

  fetchPerDate(query: QueryFilter, day: boolean): Observable<any[]> {
    return this.httpService.post(day ? SystemConfig.Endpoints.HOLD_OVERVIEW_DAY_PER_DATE : SystemConfig.Endpoints.HOLD_OVERVIEW_MONTH_PER_DATE, query);
  }

  fetchPerGame(query: QueryFilter, day: boolean): Observable<any[]> {
    return this.httpService.post(day ? SystemConfig.Endpoints.HOLD_OVERVIEW_DAY_PER_GAME : SystemConfig.Endpoints.HOLD_OVERVIEW_MONTH_PER_GAME, query);
  }
}
