import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {SessionService} from './session.service';
import {SystemConfig} from '../config/system-config';
import {Observable} from 'rxjs';
import {QueryFilter} from '../domain/query-filter';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StationOverviewService {

  constructor(public httpService: HttpService,
              public sessionService: SessionService) {
  }

  fetchStationOverview(query: QueryFilter): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.STATION_OVERVIEW, query);
  }
}
