import {Injectable} from '@angular/core';
import {HttpService} from '../services/http.service';
import {SessionService} from '../services/session.service';
import {Observable, of} from 'rxjs';
import {SystemConfig} from '../config/system-config';
import {GameNumber} from '../domain/game-number';

@Injectable({
  providedIn: 'root'
})
export class TicketDetailService {

  constructor(public httpService: HttpService,
              public sessionService: SessionService) {
  }

  update(ticket: any, gameNumer: GameNumber, secureCode: string = null): Observable<any> {
    if (gameNumer === GameNumber.KENOBET || gameNumer === GameNumber.KENOBET_OVERFLOW) {
      return this.httpService.post(SystemConfig.Endpoints.TICKET_KENOBET_UPDATE, ticket);
    } else if (gameNumer === GameNumber.SMARTRACE) {
      return this.httpService.post(SystemConfig.Endpoints.TICKET_SMARTRACE_UPDATE, ticket);
    } else if (gameNumer === GameNumber.POKERBET) {
      return this.httpService.post(SystemConfig.Endpoints.TICKET_POKERBET_UPDATE, ticket);
    } else if (gameNumer === GameNumber.LIVESPORTS) {
      return this.httpService.post(SystemConfig.Endpoints.TICKET_LIVESPORTS_UPDATE, {'ticket': ticket, 'lB2SecCode': secureCode});
    }
    return of(new Error('unknown game'));
  }

  refund(bet: any, ticketNumber: number): Observable<any> {
    return this.httpService.post(SystemConfig.Endpoints.TICKET_LIVESPORT_BET_REFUND, {
      'bet': bet,
      'ticketNumber': ticketNumber,
      'cancelAllBets': true
    });
  }

}
