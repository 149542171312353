import {Injectable} from '@angular/core';
import {HomeMenuService} from './home-menu.service';
import {Searchable} from '../domain/searchable';
import {SessionService} from './session.service';
import {sortAlphabetically} from '../../utils';

@Injectable()
export class SearchService {

  private menuOptions: Searchable[] = [];
  private MAX_AMOUNT_OF_ELEMENTS = 999;

  result: Searchable [];

  constructor(private homeMenuService: HomeMenuService,
              private sessionService: SessionService) {
    // TODO: this might need adaption if menu is changed by permissions
    this.menuOptions = [...this.homeMenuService.getTurnoverMenuContent(),
      ...this.homeMenuService.getAccountingMenuContent(),
      ...this.homeMenuService.getOverviewMenuContent(),
      ...this.homeMenuService.getStatisticsMenuContent(),
      ...this.homeMenuService.getResultsMenuContent(),
      ...this.homeMenuService.getTicketsMenuContent(),
      ...this.homeMenuService.getAdministrationMenuContent(),
      ...this.homeMenuService.getLanguageMenuContent()];
  }

  updateResult(searchText: string) {
    if (searchText && searchText.length > 1) {
      this.result = this.alsoFindInTree(this.menuOptions.filter(el => this.matches(el, searchText)), searchText);
      sortAlphabetically(this.result, 'label');
    } else {
      this.clearResult();
    }
  }


  private alsoFindInTree(result: Searchable[], searchText: string): Searchable[] {
    for (let i = 0; i < this.sessionService.franchisers.length && result.length < this.MAX_AMOUNT_OF_ELEMENTS; i++) {
      const franchiser = this.sessionService.franchisers[i].node;
      if (this.matches(franchiser, searchText)) {
        result.push(franchiser);
      }
    }

    for (let i = 0; i < this.sessionService.stations.length && result.length < this.MAX_AMOUNT_OF_ELEMENTS; i++) {
      const station = this.sessionService.stations[i].node;
      if (this.matches(station, searchText)) {
        result.push(station);
      }
    }

    const normalizedTicketNumber: string = searchText.replace(/[^0-9]/g, '');
    if (normalizedTicketNumber.length === 13 || normalizedTicketNumber.length === 17) {
      result.push({
        icon: 'Ticket.png',
        label: searchText,
        link: ['/home/ticket-search', normalizedTicketNumber],
        keywords: []
      });
    }

    return result;
  }

  private matches(el: Searchable, searchText: string) {
    return [el.label.toLowerCase(), ...el.keywords].some(text => text.indexOf(searchText.toLowerCase()) > -1)
      || searchText.toLowerCase().split(' ').every(searchPart =>
        [el.label.toLowerCase(), ...el.keywords].some(text => text.indexOf(searchPart) > -1));
  }

  clearResult() {
    this.result = [];
  }

}
