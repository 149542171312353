import {Injectable} from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {TranslationService} from './services/translation.service';
import {ERROR_SNACK_CONFIG, SUCCESS_SNACK_CONFIG} from '../utils';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(public snackBar: MatSnackBar,
              public translationService: TranslationService) {
  }

  showSnackbar(label: string) {
    this.snackBar.open(this.translationService.translate(label), null, SUCCESS_SNACK_CONFIG);
  }

  showErrorSnackbar(label: string) {
    this.snackBar.open(this.translationService.translate(label), null, ERROR_SNACK_CONFIG);
  }

  showError() {
    this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
  }

  showSaved() {
    this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
  }

}
