import { AfterViewInit, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'vit-custom-mat-checkbox',
    template: `
        <mat-checkbox [(ngModel)]="checkboxValue"
                      [disabled]="disabled">
            <img style="transform: translateY(3px)" *ngIf="icon" [src]="'/assets/img/icons/' + icon "> {{label | translate}}
        </mat-checkbox>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomMatCheckboxComponent),
            multi: true
        }
    ]
})
export class CustomMatCheckboxComponent implements ControlValueAccessor, AfterViewInit {
    @Input()
    _checkboxValue = false;
    @Input()
    label = false;
    @Input()
    icon?: string;
    @Output()
    change: EventEmitter<boolean> = new EventEmitter();
    disabled = false;

    get checkboxValue() {
        return this._checkboxValue;
    }

    set checkboxValue(val) {
        this._checkboxValue = val;
        this.change.emit(val);
        this.propagateChange(this._checkboxValue);
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    propagateChange = (_: any) => {
    }

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {
    }

    writeValue(value: any) {
        this.checkboxValue = !!value;
    }

    ngAfterViewInit() {
        this.propagateChange(this._checkboxValue);
    }
}

