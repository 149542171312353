import {Component, OnInit} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {StationUtils} from '../../utils/station-utils';
import {TreeNodeType} from '../../domain/tree-node-type';
import {exportCsv, exportExcel, getContextMenuItems, isNumber} from '../../../utils';
import {loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {BalanceCashTotalService} from './balance-cash-total.service';
import {map} from 'rxjs/operators';
import {GetContextMenuItemsParams, GridReadyEvent} from 'ag-grid-community';
import {HttpClient} from '@angular/common/http';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-balance',
  templateUrl: './balance-cash-total.component.html',
  styleUrls: ['./balance-cash-total.component.scss']
})
export class BalanceCashTotalComponent implements OnInit {

  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;
  columnDefs;
  gridOptions;
  rowData;
  titleRow;

  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public balanceCashTotalService: BalanceCashTotalService,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public httpService: HttpService,
              public datePipe: DatePipe,
              public decimalPipe: DecimalPipe) {
    this.columnDefs = this.generateColumnDefs();
    this.gridOptions = this.generateGridOptions();
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('balanceCashTotal', event);
  }

  ngOnInit() {
  }

  fetch(query: QueryFilter) {
    if (query) {
      this.query = query;
      this.rowData = this.balanceCashTotalService.fetch(query).pipe(
        map(data => data.filter(d => {
          const isTitleRow = d.stationID === 0;
          if (isTitleRow) {
            this.titleRow = [d];
          }
          return !isTitleRow;
        }))
      );
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('balance.type'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'type',
        cellClass: 'type',
        width: 23,
        cellRenderer: function (params) {
          const icon = StationUtils.getIcon(TreeNodeType.STATION, params.data.type, false, params.data.softLocked, params.data.hardLocked);
          const img = '<img src=\'/assets/img/icons/' + icon + '\'>';
          return params.data.stationID === 0 ? '' : img;
        }
      },
      {
        headerName: this.translationService.translate('balanceCashTotal.stationID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationID',
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40,
        headerTooltip: this.translationService.translate('balanceCashTotal.stationID')
      },
      {
        headerName: this.translationService.translate('balanceCashTotal.stationName'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationName',
        width: BalanceCashTotalComponent.COLUMN_WIDTH_LARGE,
      },
      {
        headerName: this.translationService.translate('balanceCashTotal.cashInGross'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashInGross',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceCashTotalComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balanceCashTotal.cashInGross')
      },
      {
        headerName: this.translationService.translate('balanceCashTotal.cashOut'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashOut',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: 100,
        headerTooltip: this.translationService.translate('balanceCashTotal.cashOut')
      },
      {
        headerName: this.translationService.translate('balanceCashTotal.bonus'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'bonus',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceCashTotalComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balanceCashTotal.bonus')
      },
      {
        headerName: this.translationService.translate('balanceCashTotal.onlinePayIn'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'onlinePayIn',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceCashTotalComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balanceCashTotal.onlinePayInTooltip')
      },
      {
        headerName: this.translationService.translate('balanceCashTotal.balance'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'balance',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right text-highlight-2 text-bold',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: 160,
        headerTooltip: this.translationService.translate('balanceCashTotal.balance')
      },
      {
        headerName: this.translationService.translate('balanceCashTotal.cashIn'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashIn',
        valueFormatter: this.decimalFormatter.bind(this),
        hide: true,
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: 110,
        headerTooltip: this.translationService.translate('balanceCashTotal.cashIn')
      },
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: false,
      isRowMaster: (data) => data.stationID !== 0,
      rowClassRules: {
        'bold': (params) => params.data.stationID === 0,
        'text-align-right': (params) => isNumber(params.data.value)
      },
      onCellClicked: (params) => {
        params.node.setExpanded(!params.node.expanded);
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }

}
