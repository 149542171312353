import {Component, Inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {Station} from '../../domain/station';
import {QuestionContainer} from '../../domain/question-container';
import {TextboxQuestion} from '../../domain/question-textbox';
import {DropdownQuestion} from '../../domain/question-dropdown';
import {StationService} from '../../services/station.service';
import {TranslationService} from '../../services/translation.service';
import {DynamicFormComponent} from '../../dynamic-form/dynamic-form.component';
import {FormUtils} from '../../utils/form-utils';
import {Router} from '@angular/router';
import {CustomerTreeService} from '../../services/customer-tree.service';
import {ERROR_SNACK_CONFIG, SUCCESS_SNACK_CONFIG, TROUBLE_SNACK_CONFIG} from '../../../utils';
import {SessionService} from '../../services/session.service';

@Component({
  selector: 'vit-station-copy',
  templateUrl: './station-copy.component.html',
  styleUrls: ['./station-copy.component.scss']
})
export class StationCopyComponent implements OnInit {

  @ViewChildren(DynamicFormComponent) stationForms: QueryList<DynamicFormComponent>;
  station: Station;
  franchisers: any[];

  loading: boolean;

  successCount = 0;
  failedCount = 0;

  copyQuestions: QuestionContainer;

  newStations = [];

  constructor(public dialogRef: MatDialogRef<StationCopyComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { 'station': Station, 'franchisers': any[] },
              public stationService: StationService,
              public customerTreeService: CustomerTreeService,
              public sessionService: SessionService,
              public snackBar: MatSnackBar,
              public translationService: TranslationService,
              public router: Router) {
    this.station = data.station;
    this.franchisers = data.franchisers;
  }

  ngOnInit() {
    this.reset();
  }

  close() {
    this.customerTreeService.fetchTree();
    this.dialogRef.close();
  }

  triggerSave() {
    this.successCount = 0;
    this.failedCount = 0;
    this.newStations.forEach(s => s.status = 'processing');
    this.save();
  }

  open(station) {
    this.customerTreeService.fetchTree();
    const form = FormUtils.getFormValues(this.stationForms, 'stationCopy.form');
    this.router.navigate(['/station', +form['masterFranchiserID'], station.stationID]);
    this.dialogRef.close();
  }

  stationsInvalid() {
    return this.newStations.filter(s => s.stationID && s.name).length !== this.newStations.length;
  }

  stationsProcessed() {
    return this.newStations.filter(s => s.status).length > 0;
  }

  save() {
    const station = this.newStations.filter(s => s.status === 'processing')[0];
    const form = FormUtils.getFormValues(this.stationForms, 'stationCopy.form');

    if (!station) {
      this.loading = false;
      this.customerTreeService.fetchTree();
      if (this.failedCount === 0) {
        this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
      } else if (this.successCount === 0) {
        this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
      } else {
        this.snackBar.open(this.translationService.translate('snackBar.trouble'), null, TROUBLE_SNACK_CONFIG);
      }
      return;
    }

    this.loading = true;
    this.sessionService.loading = true;
    this.stationService.copy({
      'sourceStationID': this.station.stationID,
      'destinationFranchiserID': +form['masterFranchiserID'],
      'lstDestinationStations': [station]
    }).subscribe(() => {
      station.status = 'successful';
      this.sessionService.loading = false;
      this.successCount += 1;
      this.save();
    }, () => {
      station.status = 'failed';
      this.sessionService.loading = false;
      this.failedCount += 1;
      this.save();
    });
  }

  amountChanged() {
    const form = FormUtils.getFormValues(this.stationForms, 'stationCopy.form');
    this.newStations = [];
    this.stationService.fetchOpenIDs(+form['masterFranchiserID'], +form['amountOfStations'])
      .subscribe(newStationIDs => newStationIDs.forEach(id => this.newStations.push({'stationID': id, name: ''})));
  }

  reset() {
    this.successCount = 0;
    this.failedCount = 0;
    this.copyQuestions = new QuestionContainer('stationCopy.form', [
      new DropdownQuestion({
        key: 'masterFranchiserID',
        label: 'stationCopy.masterFranchiserID',
        value: this.station.franchiserID.toString(),
        onChange: this.amountChanged.bind(this),
        options: this.franchisers
      }),
      new DropdownQuestion({
        key: 'amountOfStations',
        label: 'stationCopy.amountOfStations',
        hideSearch: true,
        value: '1',
        onChange: this.amountChanged.bind(this),
        options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(i => {
          return {'key': i.toString(), 'value': i};
        })
      })
    ]);
    setTimeout(() => this.amountChanged());
  }

}
