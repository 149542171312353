<vit-search-form
    (submitEmiter)="fetch($event)"
    [hideIntoEuro]="true"
    [showCustomerId]="true"
    [customerIdPanelTitle]="'customerIdPanelTitle'"
    [hideMinTickets]="true"
    [hideDetails]="true"
    [hideStationRestrictions]="true"
    [showCustomerIdInHeader]="true"
    [suggestCustomerIds]="true"
    [hideFranchiser]="true"
    [hideStation]="true"
    [hideGames]="true"
    [hideTimeframe]="true">
</vit-search-form>
<ag-grid-angular
    *ngIf="rowData"
    (columnVisible)="storeColumnVisibilityFn('customerBalanceWeek', $event)"
    class="ag-theme-balham"
    style="width: 100%; flex: 1 1 1px;"
    [gridOptions]="gridOptions"
    [tooltipShowDelay]="0"
    [rowData]="rowData | async"
    [columnDefs]="columnDefs"
    (gridReady)="onGridReady($event)">
</ag-grid-angular>
