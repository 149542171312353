<vit-search-form (submitEmiter)="fetch($event)"
                 [hideGames]="true"
                 [hideMinProfit]="true"
                 [hideMinStake]="true"
                 [hideMinTickets]="true"
                 [hideDetails]="true"
                 [showSettings]="true"
                 [showUserSearch]="true"
                 [showNow]="true"
                 [hideIntoEuro]="true"
                 [hideStationRestrictions]="true"
                 [hideStation] = "true">
</vit-search-form>
<ag-grid-angular
  *ngIf="rowData"
  (columnVisible)="storeColumnVisibilityFn('cashbookControlling', $event)"
  class="ag-theme-balham"
  style="width: 100%; flex: 1 1 1px;"
  [gridOptions]="gridOptions"
  [rowData]="rowData | async"
  [columnDefs]="columnDefs"
  (gridReady)="onGridReady($event)">
</ag-grid-angular>

<!-- 1223px -->
