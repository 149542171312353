import {Component, Input, OnChanges, ViewChild} from '@angular/core';
import {GameService} from '../../services/game.service';
import {Game} from '../../domain/game';
import {PokerbetSettings} from '../../domain/pokerbet-settings';
import {PokerbetSettingsData} from '../../domain/pokerbet-settings-data';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {TranslationService} from '../../services/translation.service';
import {QuestionContainer} from '../../domain/question-container';
import {TextboxQuestion} from '../../domain/question-textbox';
import {SessionService} from '../../services/session.service';
import {TextareaQuestion} from '../../domain/question-textarea';
import {Permission} from '../../domain/permission';
import {CheckboxQuestion} from '../../domain/question-checkbox';
import {StationType} from '../../domain/station-type';
import {FormUtils} from '../../utils/form-utils';
import {Jackpot} from '../../domain/jackpot';
import {JackpotService} from '../../services/jackpot.service';
import {DropdownQuestion} from '../../domain/question-dropdown';
import {ColsComponent} from '../../cols/cols.component';
import {ERROR_SNACK_CONFIG, SUCCESS_SNACK_CONFIG} from '../../../utils';

@Component({
  selector: 'vit-pokerbet',
  templateUrl: './pokerbet.component.html',
  styleUrls: ['./pokerbet.component.scss']
})
export class PokerbetComponent implements OnChanges {

  @Input()
  stationID: number;
  settings: PokerbetSettings;
  jackpots: Jackpot[];
  selectedJackpot: Jackpot;
  hasChangePermission = false;
  gameQuestions: QuestionContainer[] = [];
  jackpotQuestionContainer: QuestionContainer;
  @Input()
  stationType: StationType;

  questions: QuestionContainer[] = [];

  loading: boolean;
  @ViewChild(ColsComponent, { static: true })
  colsComponent: ColsComponent;

  constructor(public gameService: GameService,
              public snackBar: MatSnackBar,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public jackpotService: JackpotService) {
    this.hasChangePermission = this.sessionService.hasPermission(Permission.changePB1Settings);
  }

  ngOnChanges() {
    this.loading = true;
    this.gameService.get(Game.POKER_BET, this.stationID).subscribe((sd: PokerbetSettingsData) => {
      this.settings = sd.settings;
      this.jackpots = sd.jackpots;
      this.loading = false;
      this.reset();
    }, () => {
      this.loading = false;
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  async save() {
    this.loading = true;
    const settings = this.generateSettingsFromForm();
    if (this.selectedJackpot) {
      const jackpot = this.generateJackpotFromForm();
      if (this.selectedJackpot.jackpotID === -1) {
        const createdJackpot: Jackpot = await this.jackpotService.insert(jackpot);
        this.jackpots.push(createdJackpot);
        settings.jackpotID = createdJackpot.jackpotID;
      } else if (this.jackpotService.isStationJackpot(this.selectedJackpot, this.settings.stationID)) {
        await this.jackpotService.update(jackpot);
        const index = this.jackpots.map(jp => jp.jackpotID).indexOf(jackpot.jackpotID);
        this.jackpots[index] = jackpot;
      }
    }

    this.gameService.savePokerbetSettings(settings).subscribe(() => {
this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
      this.settings = settings;
      this.loading = false;
      this.reset();
    }, () => {
      this.loading = false;
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  private generateSettingsFromForm(): PokerbetSettings {
    const stakeForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'pokerbet.settings.header.stake');
    const shopForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'pokerbet.settings.header.shop');
    const extendedOptionsForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'pokerbet.settings.header.extendedOptions');
    const singleTablePokerForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'pokerbet.settings.header.singleTablePoker');
    let jackpotConfigForm: any = null;
    if (this.selectedJackpot) {
      jackpotConfigForm = FormUtils.getFormValues(this.colsComponent.forms, 'pokerbet.settings.header.jackpotConfig');
    }

    const settings: PokerbetSettings = {
      'pbSettingID': this.settings.pbSettingID,
      'stationID': this.settings.stationID,
      'pB1GameCycleID': this.settings.pB1GameCycleID,
      'jackpotID': this.selectedJackpot ? this.selectedJackpot.jackpotID : null,
      'minStakePerTip': stakeForm['minStakePerTip'] != null ? +stakeForm['minStakePerTip'] : null,
      'maxStakePerTip': stakeForm['maxStakePerTip'] != null ? +stakeForm['maxStakePerTip'] : null,
      'players': this.settings.players,
      'winnerOdd': singleTablePokerForm['winnerOdd'] != null ? +singleTablePokerForm['winnerOdd'] : null,
      'lastSuitFactor': singleTablePokerForm['lastSuitFactor'] != null ? +singleTablePokerForm['lastSuitFactor'] : null,
      'enableCashbook': shopForm['enableCashbook'],
      'stakeButtonFactor': shopForm['stakeButtonFactor'] != null ? +shopForm['stakeButtonFactor'] : null,
      'enableMultiTable': shopForm['enableMultiTable'],
      'multiTableGraduation': shopForm['multiTableGraduation'] != null ? +shopForm['multiTableGraduation'] : null,
      'enableBlackJack': shopForm['enableBlackJack'],
      'blackJackGraduation': shopForm['blackJackGraduation'] != null ? +shopForm['blackJackGraduation'] : null,
      'hasJackpot': !!this.selectedJackpot,
      'jackpotMinStake': (this.selectedJackpot && jackpotConfigForm['jackpotMinStake'])
        ? +jackpotConfigForm['jackpotMinStake'] : this.settings.jackpotMinStake,
      'jackpotMinPercentage': (this.selectedJackpot && jackpotConfigForm['jackpotMinPercentage'])
        ? +jackpotConfigForm['jackpotMinPercentage'] : this.settings.jackpotMinPercentage,
      'jackpotMaxPercentage': (this.selectedJackpot && jackpotConfigForm['jackpotMaxPercentage'])
        ? +jackpotConfigForm['jackpotMaxPercentage'] : this.settings.jackpotMaxPercentage,
      'logoURL': extendedOptionsForm['logoURL'],
      'printTicketTitle': shopForm['printTicketTitle'],
      'tssServerHost': this.settings.tssServerHost, // not used
      'tssServerPort': this.settings.tssServerPort, // not used
    };
    return settings;
  }

  private generateJackpotFromForm(): Jackpot {
    const jackpotConfigForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'pokerbet.settings.header.jackpotConfig');

    const jackpot: Jackpot = {
      'jackpotID': this.selectedJackpot.jackpotID,
      'franchiserID': this.selectedJackpot.franchiserID,
      'applicationID': this.selectedJackpot.applicationID,
      'value': this.selectedJackpot.value,
      'minInitialValue': jackpotConfigForm['minInitialValue'] ? +jackpotConfigForm['minInitialValue'] : 0,
      'maxInitialValue': jackpotConfigForm['maxInitialValue'] ? +jackpotConfigForm['maxInitialValue'] : 0,
      'minValue': jackpotConfigForm['minValue'] ? +jackpotConfigForm['minValue'] : 0,
      'maxValue': jackpotConfigForm['maxValue'] ? +jackpotConfigForm['maxValue'] : 0,
      'hitValue': this.selectedJackpot.hitValue,
      'lastHitTime': this.selectedJackpot.lastHitTime,
      'lastHitStation': this.selectedJackpot.lastHitStation,
      'lastHitValue': this.selectedJackpot.lastHitValue,
      'name': this.selectedJackpot.name,
      'autoRaise': this.selectedJackpot.autoRaise
    };
    return jackpot;
  }

  resetJackpotForm(selectedJackpotId?: string) {
    if (selectedJackpotId != null && +selectedJackpotId !== JackpotService.NO_JACKPOT_ID) {
      this.selectedJackpot = this.jackpots.filter(jp => jp.jackpotID === +selectedJackpotId)[0];
    } else if (this.settings.jackpotID != null && +selectedJackpotId !== JackpotService.NO_JACKPOT_ID) {
      this.selectedJackpot = this.jackpots.filter(jp => jp.jackpotID === this.settings.jackpotID)[0];
    } else {
      this.selectedJackpot = null;
    }
    if (this.selectedJackpot != null) {
      this.jackpotQuestionContainer = new QuestionContainer('pokerbet.settings.header.jackpotConfig', [
        new TextboxQuestion({
          key: 'value',
          label: 'pokerbet.settings.value',
          value: this.selectedJackpot.value,
          type: 'number',
          disabled: true,
        }),
        new TextboxQuestion({
          key: 'jackpotMinStake',
          label: 'pokerbet.settings.jackpotMinStake',
          value: this.settings.jackpotMinStake,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'jackpotMinPercentage',
          label: 'pokerbet.settings.jackpotMinPercentage',
          value: this.settings.jackpotMinPercentage,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'jackpotMaxPercentage',
          label: 'pokerbet.settings.jackpotMaxPercentage',
          value: this.settings.jackpotMaxPercentage,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'minInitialValue',
          label: 'pokerbet.settings.minInitialValue',
          value: this.selectedJackpot.minInitialValue,
          type: 'number',
          readonly: !this.jackpotService.isStationJackpot(this.selectedJackpot, this.settings.stationID)
        }),
        new TextboxQuestion({
          key: 'maxInitialValue',
          label: 'pokerbet.settings.maxInitialValue',
          value: this.selectedJackpot.maxInitialValue,
          type: 'number',
          readonly: !this.jackpotService.isStationJackpot(this.selectedJackpot, this.settings.stationID)
        }),
        new TextboxQuestion({
          key: 'minValue',
          label: 'pokerbet.settings.minValue',
          value: this.selectedJackpot.minValue,
          type: 'number',
          readonly: !this.jackpotService.isStationJackpot(this.selectedJackpot, this.settings.stationID)
        }),
        new TextboxQuestion({
          key: 'maxValue',
          label: 'pokerbet.settings.maxValue',
          value: this.selectedJackpot.maxValue,
          type: 'number',
          readonly: !this.jackpotService.isStationJackpot(this.selectedJackpot, this.settings.stationID)
        })]);
      if (this.sessionService.hasPermission(Permission.initJackpot)
        && this.jackpotService.isStationJackpot(this.selectedJackpot, this.settings.stationID)
        && this.selectedJackpot.jackpotID !== -1) {
        this.jackpotQuestionContainer.addSubmit('pokerbet.settings.initJackpot', this.resetJackpotValues.bind(this));
      }
    } else {
      this.jackpotQuestionContainer = null;
    }
    this.questions = this.jackpotQuestionContainer ? [...this.gameQuestions, this.jackpotQuestionContainer] : this.gameQuestions;
  }

  resetJackpotValues() {
    this.jackpotService.init(this.selectedJackpot).subscribe((jackpot) => {
this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
      const index = this.jackpots.map(jp => jp.jackpotID).indexOf(jackpot.jackpotID);
      this.jackpots[index] = jackpot;
      this.loading = false;
      this.resetJackpotForm(jackpot.jackpotID);
    }, () => {
      this.loading = false;
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  reset() {
    this.resetSettingsQuestions();
    this.resetJackpotForm();
    this.questions = this.jackpotQuestionContainer ? [...this.gameQuestions, this.jackpotQuestionContainer] : this.gameQuestions;
  }

  mapJackpotName(id: number, name: string) {
    if (id === JackpotService.NO_JACKPOT_ID) {
      return this.translationService.translate('pokerbet.settings.noJackpot');
    } else if (name === this.settings.stationID.toString()) {
      return this.translationService.translate('pokerbet.settings.ownJackpot');
    }
    return name;
  }

  resetSettingsQuestions() {
    this.gameQuestions = [
      new QuestionContainer('pokerbet.settings.header.stake', [
        new TextboxQuestion({
          key: 'minStakePerTip',
          label: 'pokerbet.settings.minStakePerTip',
          value: this.settings.minStakePerTip,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'maxStakePerTip',
          label: 'pokerbet.settings.maxStakePerTip',
          value: this.settings.maxStakePerTip,
          type: 'number'
        })
      ]),
      new QuestionContainer('pokerbet.settings.header.singleTablePoker', [
        new TextboxQuestion({
          key: 'winnerOdd',
          label: 'pokerbet.settings.winnerOdd',
          value: this.settings.winnerOdd,
          type: 'number' // TODO: max value
        }),
        new TextboxQuestion({
          key: 'lastSuitFactor',
          label: 'pokerbet.settings.lastSuitFactor',
          value: this.settings.lastSuitFactor,
          type: 'number'
        })
      ]),
      new QuestionContainer('pokerbet.settings.header.shop', [
        new CheckboxQuestion({
          key: 'printTicketTitle',
          label: 'pokerbet.settings.printTicketTitle',
          value: this.settings.printTicketTitle
        }),
        new CheckboxQuestion({
          key: 'enableCashbook',
          label: 'pokerbet.settings.enableCashbook',
          value: this.settings.enableCashbook
        }),
        new TextboxQuestion({
          key: 'stakeButtonFactor',
          label: 'pokerbet.settings.stakeButtonFactor',
          value: this.settings.stakeButtonFactor,
          type: 'number'
        }),
        new CheckboxQuestion({
          key: 'enableMultiTable',
          label: 'pokerbet.settings.enableMultiTable',
          value: this.settings.enableMultiTable
        }),
        new TextboxQuestion({
          key: 'multiTableGraduation',
          label: 'pokerbet.settings.multiTableGraduation',
          value: this.settings.multiTableGraduation,
          type: 'number' // TODO: min, max
        }),
        new CheckboxQuestion({
          key: 'enableBlackJack',
          label: 'pokerbet.settings.enableBlackJack',
          value: this.settings.enableBlackJack
        }),
        new TextboxQuestion({
          key: 'blackJackGraduation',
          label: 'pokerbet.settings.blackJackGraduation',
          value: this.settings.blackJackGraduation,
          type: 'number' // TODO: min, max
        })
      ], null, this.isShop() ? null : Permission.notAllowed),
      new QuestionContainer('pokerbet.settings.header.extendedOptions', [
        new TextareaQuestion({
          key: 'logoURL',
          label: 'pokerbet.settings.logoURL',
          value: this.settings.logoURL,
          minRows: 3,
          viewPermission: Permission.editLogoURLs
        })
      ], null, Permission.editLogoURLs),
      new QuestionContainer('pokerbet.settings.header.jackpot', [
        new DropdownQuestion({
          key: 'jackpotID',
          label: 'pokerbet.settings.jackpotID',
          value: (this.settings.jackpotID || JackpotService.NO_JACKPOT_ID).toString(),
          options: this.jackpots.map(jp => ({
            'key': jp.jackpotID.toString(),
            'value': this.mapJackpotName(jp.jackpotID, jp.name)
          })),
          onChange: this.resetJackpotForm.bind(this)
        })], null, this.stationType === StationType.TERMINAL ? Permission.notAllowed : null)];
  }

  private isShop() {
    return this.stationType === StationType.SHOP;
  }
}
