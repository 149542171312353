<ng-container *ngFor="let questionContainer of questions">
  <mat-card>
    <mat-card-header>{{questionContainer.label | translate}}</mat-card-header>
    <mat-card-content>
      <vit-dynamic-form [questionContainer]="questionContainer"></vit-dynamic-form>
    </mat-card-content>
  </mat-card>
</ng-container>

<mat-toolbar class="footer">
  <vit-buttons>
    <button type="reset"
            (click)="reset()"
            mat-flat-button
            color="secondary">
      {{('cycle.reset') | translate}}
    </button>
    <button type="submit"
            [disabled]="!forms?.first?.form.valid"
            (click)="save()"
            mat-flat-button
            color="accent">
      {{('cycle.save') | translate}}
    </button>
  </vit-buttons>
</mat-toolbar>
