<div>
    <form [formGroup]="form">
        <div *ngFor="let question of questionContainer.questions" class="form-row">
            <vit-question [question]="question"
                          [disabled]="isNotAuthorizedToEdit()"
                          [hidden]="isNotAuthorizedToView()"
                          [form]="form"></vit-question>
        </div>
        <p style="display: flex; flex-wrap: wrap;" *ngIf="questionContainer.buttons.length > 0">
            <button style="margin-bottom: 5px; flex: 1;"
                    color="accent"
                    *ngFor="let button of questionContainer.buttons"
                    mat-flat-button
                    [class.multiple]="questionContainer.buttons.length > 1"
                    (click)="button.submitFunction(form.value)"
                    [disabled]="(!form.valid && !forceEnableForm()) || isNotAuthorizedToSubmitButton(button)">
                {{button.submitLabel | translate}}
            </button>
        </p>
    </form>
</div>
