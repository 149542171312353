<mat-tab-group [(selectedIndex)]="selectedTab"
               animationDuration="0ms"
               *ngIf="station">
  <mat-tab class="station-data"
           [class.is-locked]="station.softLocked || station.hardLocked">
    <ng-template mat-tab-label class="station-label">
      <img class="station-label-icon"
           [src]="'/assets/img/icons/' + icon">
      <span>{{station?.stationID}}</span>
    </ng-template>
    <mat-toolbar color="warn" class="locked" *ngIf="station.softLocked || station.hardLocked">
      <mat-icon>locked</mat-icon>
      <span *ngIf="station.softLocked && !station.hardLocked">{{'station.softLock' | translate}}</span>
      <span *ngIf="station.hardLocked">{{'station.hardLock' | translate}}</span>
    </mat-toolbar>

    <vit-cols [elements]="questions"></vit-cols>
    <mat-toolbar class="footer" *ngIf="station && !loading">
      <vit-buttons>
        <button type="reset"
                (click)="reset()"
                mat-flat-button
                color="secondary">
          {{'station.footer.reset' | translate}}
        </button>
        <button type="reset"
                (click)="reload()"
                mat-flat-button
                color="secondary">
          {{'station.footer.reload' | translate}}
        </button>
        <button type="submit"
                *ngIf="!isNew"
                [disabled]="!canCopy"
                (click)="copy()"
                mat-flat-button
                color="accent">
          {{'station.footer.copy' | translate}}
        </button>
        <button type="submit"
                (click)="save()"
                [disabled]="!canSave"
                mat-flat-button
                color="accent">
          {{'station.footer.save' | translate}}
        </button>
      </vit-buttons>
    </mat-toolbar>
  </mat-tab>
  <mat-tab label="Numbers" *ngIf="station.bB1Enabled">
    <ng-template matTabContent>
      <vit-kenobet [stationID]="station.stationID"
                   [stationType]="station.type"></vit-kenobet>
    </ng-template>
  </mat-tab>
  <mat-tab label="Cards" *ngIf="station.pB1Enabled">
    <ng-template matTabContent>
      <vit-pokerbet [stationID]="station.stationID"
                    [stationType]="station.type"></vit-pokerbet>
    </ng-template>
  </mat-tab>
  <mat-tab label="Race" *ngIf="station.rF2Enabled">
    <ng-template matTabContent>
      <vit-smartrace [stationID]="station.stationID"
                     [stationType]="station.type"></vit-smartrace>
    </ng-template>
  </mat-tab>
  <mat-tab label="Live" *ngIf="station.lB2Enabled">
    <ng-template matTabContent>
      <vit-livesports [stationID]="station.stationID"
                      [stationType]="station.type"></vit-livesports>
    </ng-template>
  </mat-tab>
</mat-tab-group>
