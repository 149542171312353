import {throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {SystemConfig} from '../config/system-config';
import {SessionService} from './session.service';
import {catchError, tap} from 'rxjs/operators';
import {User} from '../domain/user';
import {Router} from '@angular/router';
import {Observable} from 'rxjs/index';
import {Permission} from '../domain/permission';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {ERROR_SNACK_CONFIG, getCookie, SUCCESS_SNACK_CONFIG} from '../../utils';
import {TranslationService} from './translation.service';

@Injectable()
export class UserService {

  constructor(public httpService: HttpService,
              public sessionService: SessionService,
              public translationService: TranslationService,
              public snackBar: MatSnackBar,
              public router: Router) {
  }

  login(username: string, password: string): Observable<User> {
    const device = getCookie('x_app_dev');
    return this.httpService.post(SystemConfig.Endpoints.LOGIN, {username, password, device}, true)
      .pipe(
        tap(user => {
          this.sessionService.user = user;
          this.sessionService.user.admin = this.sessionService.hasPermission(Permission.editAdvancedAdminSettings);
        }),
        catchError((e) => {
          this.clearUser();
          return observableThrowError(e);
        })
      );
  }

  logout(): void {
    this.httpService.get(SystemConfig.Endpoints.LOGOUT).subscribe();
    this.clearUser();
    this.router.navigate([SystemConfig.Routes.LOGIN]);
  }

  async tokenLogin(): Promise<User> {
    if (this.httpService.hasToken()) {
      const virtualUser = localStorage.getItem(SystemConfig.LocalStorageConstants.VIRTUAL_USER);
      return this.httpService.get(SystemConfig.Endpoints.USER_ACCOUNT + (virtualUser ? '/' + virtualUser : ''))
        .pipe(
          tap(user => {
            this.sessionService.user = user;
            this.sessionService.user.admin = this.sessionService.hasPermission(Permission.editAdvancedAdminSettings);
            if (virtualUser) {
              this.snackBar.open(this.translationService.translate('virtualMode.active'), null, SUCCESS_SNACK_CONFIG);
            }
          }),
          catchError((e) => {
            if ((e.status === 401 || e.status === 403) && !!localStorage.getItem(SystemConfig.LocalStorageConstants.VIRTUAL_USER)) {
              this.snackBar.open(this.translationService.translate('virtualMode.authError'), null, ERROR_SNACK_CONFIG);
              localStorage.removeItem(SystemConfig.LocalStorageConstants.VIRTUAL_USER);
            } else {
              this.clearUser();
            }
            return observableThrowError(e);
          })
        ).toPromise();
    }
    return null;
  }

  private clearUser() {
    localStorage.setItem(SystemConfig.LocalStorageConstants.SESSION_REFRESH, '' + new Date(0).getTime());
    this.sessionService.user = null;
    this.httpService.clearToken();
  }

  initSession() {
    let uuid = localStorage.getItem(SystemConfig.LocalStorageConstants.DEVICE_UUID);
    if (!uuid) {
      uuid = Math.random().toString(36).slice(2) + '_' + new Date().getTime();
      localStorage.setItem(SystemConfig.LocalStorageConstants.DEVICE_UUID, uuid);
    }
    this.httpService.post(SystemConfig.Endpoints.INIT_SESSION, {
      'appVersion': this.sessionService.version,
      'bt': uuid,
      'bi': {
        appVersion: navigator.appVersion,
        userAgent: navigator.userAgent,
        language: navigator.language,
        width: screen.width,
        height: screen.height
      }
    }).subscribe(res => console.log('init session'));
  }
}
