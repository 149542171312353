import { LivesportsSettings } from './livesports-settings';

export class LivesportsSettingsData {
    settings: LivesportsSettings;
    lstLB2Design: Map<string, string>;
    operatingHours: Map<string, string>;
    lstLegalBetTypeRestriction: Map<string, string>;
    lstLB2CombiBonus: LivesportsSettings.CombiBonus[];

    constructor() {
    }
}