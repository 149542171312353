export enum TicketState {
  PENDING = 'pending',
  PENDING_1 = 'pending-1',
  PENDING_2 = 'pending-2',
  LOST = 'lost',
  WON = 'won',
  DISBURSED = 'disbursed',
  STORNO = 'storno',
  CASHOUT = 'cashout'
}
