import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserService} from './user.service';
import {CustomerTreeService} from './customer-tree.service';
import {SessionService} from './session.service';
import {TranslationService} from './translation.service';
import {HomeMenuService} from './home-menu.service';
import {environment} from '../../environments/environment';
import {WINDOW} from '../window-provider';
import {Theme} from '../domain/theme';
import {LicenseManager} from 'ag-grid-enterprise';
import {firstValueFrom} from 'rxjs';
import {getCookie, setCookie} from '../../utils';


interface StartupConfig {
  path: string;
  host: string;
  title: string;
  prepaidAdminUrl: string;
  theme: string;
}

const AG_GRID_LICENSE_KEY = 'CompanyName=VITORY GmbH,LicensedApplication=VIT-BO,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-030880,SupportServicesEnd=26_July_2023_[v2]_MTY5MDMyNjAwMDAwMA==ae5a606b4a00dd66d8f0dc05492e5d56';

@Injectable()
export class StartupService {
  constructor(private userService: UserService,
              private customerTreeService: CustomerTreeService,
              private sessionService: SessionService,
              private translationService: TranslationService,
              private homeMenuService: HomeMenuService,
              private http: HttpClient,
              @Inject(WINDOW) private window: Window) {
  }

  private static async hashHost() {
    const data = new TextEncoder().encode(window.location.host);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    return Array.from(new Uint8Array(hashBuffer))
      .map(b => b.toString(16)
        .padStart(2, '0')
      )
      .join('');
  }

  private static setEnvironment(config: StartupConfig) {
    console.log('setting environment!');
    environment.path = config.path;
    environment.host = config.host;
    environment.title = config.title;
    environment.prepaidAdminUrl = config.prepaidAdminUrl;
  }

  private static activateAGGridLicense() {
    LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);
  }

  private async loadStartupConfig() {
    const hash = await StartupService.hashHost();
    const param = crypto.randomUUID();
    return firstValueFrom(this.http.get<StartupConfig>(`./assets/${hash}.json?v=${param}`));
  }

  async tokenLogin() {
    StartupService.activateAGGridLicense();
    try {
      if (getCookie('x_app_dev') === null) {
        setCookie('x_app_dev', Math.random().toString(36).slice(2) + '_' + new Date().getTime(), 360);
      }
      const config = await this.loadStartupConfig();
      this.setTheme(config);
      StartupService.setEnvironment(config);
    } catch (e) {
      console.log('could not load config.');
    }

    const before = new Date();
    try {
      await this.translationService.init();
    } catch (e) {
      console.log('could not fetch translations');
    }
    try {
      await this.userService.tokenLogin();
      this.initializePrivateData();
    } catch (e) {
      console.log('could not fetch user with token');
    }
    const after = new Date();
    const time = 3000 - (after.getTime() - before.getTime());
    this.initializePublicData();

    setTimeout(() => {
      const loadingElement = document.querySelector('.app-loading');
      loadingElement.parentNode.removeChild(loadingElement);
    }, (time > 0 && environment.production) ? time : 0);
  }

  private setTheme(config: StartupConfig) {
    console.log('setting theme for host', window.location.hostname);
    this.sessionService.theme = config.theme as Theme;
    window.document.body.classList.add(this.sessionService.theme);
  }

  initializePublicData() {
    console.log('initializing public data...');
  }

  initializePrivateData() {
    if (this.sessionService.isLoggedIn()) {
      console.log('initializing PRIVATE data...');
      this.customerTreeService.fetchTree();
      this.homeMenuService.createMenu();
      this.userService.initSession();
    }
  }
}
