<vit-search-form
  (submitEmiter)="fetch($event)"
  [hideGames]="true"
  [hideMinProfit]="true"
  [hideMinStake]="true"
  [hideMinTickets]="true"
  [hideIntoEuro]="true"
  [hideStation]="true"
  [hideIgnoreSub]="true"
  [hideStationRestrictions]="true"
  [showUserAccountId]="true"
  [requireUserAccountId]="true"
  [hideFranchiser]="true"
  [hideIgnoreTest]="true">
</vit-search-form>
<ag-grid-angular
  *ngIf="rowData"
  (columnVisible)="storeColumnVisibilityFn('uaTimeline', $event)"
  class="ag-theme-balham"
  style="width: 100%; flex: 1 1 1px;"
  [gridOptions]="gridOptions"
  [tooltipShowDelay]="0"
  [rowData]="rowData | async"
  [columnDefs]="columnDefs"
  (gridReady)="onGridReady($event)">
</ag-grid-angular>
