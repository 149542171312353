<form [formGroup]="form" *ngIf="question.controlType !== 'hold-limiter'"
      [hidden]="isNotAuthorizedToView()"
      [style.display]="question.visible ? 'block' : 'none'"
      [ngSwitch]="question.controlType">
  <mat-form-field [class.readonly]="question.readonly"
                  [class]="question.controlType"
                  [matTooltip]="question.tooltip"
                  [matTooltipClass]="'multiline-tooltip'"
                  *ngIf="question.controlType !== 'date-time'">
    <ng-container *ngSwitchCase="'textbox'">
      <input matInput
             (input)="question.onChange($event.target.value)"
             [type]="question.type"
             autocomplete="off"
             [formControlName]="question.key"
             [matAutocomplete]="auto"
             [placeholder]="question.label | translate"
             [class.copy-input]="question.copy">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of question.autocomplete" [value]="option.key"
                    [innerHTML]="option.value"></mat-option>
      </mat-autocomplete>
      <mat-icon *ngIf="question.copy" (click)="clipboard(question.value, snackBar)" class="copy">file_copy</mat-icon>
    </ng-container>

    <textarea matInput
              [rows]="question.minRows"
              *ngSwitchCase="'textarea'"
              [formControlName]="question.key"
              [placeholder]="question.label | translate"></textarea>
    <mat-icon *ngSwitchCase="'textarea'" (click)="showBigTextArea(question.key)" class="open-in-full">open_in_full
    </mat-icon>


    <span class="info-label"
          *ngSwitchCase="'info-label'">{{question.label | translate}}</span>

    <ng-container *ngSwitchCase="'dropdown'">
      <mat-select #selectRef
                  [formControlName]="question.key"
                  (selectionChange)="question.onChange($event.value)"
                  [placeholder]="question.label | translate"
                  (openedChange)="selectOpenChange($event, searchInputRef)">
        <input class="search-input"
               #searchInputRef
               [hidden]="question.hideSearch"
               matInput
               focused="true"
               type="text"
               (keyup)="selectSearch($event.target.value);"
               (keydown)="skipAllButEnter($event, selectRef)"
               autocomplete="off"
               [placeholder]="'forms.search' | translate">
        <mat-divider></mat-divider>
        <mat-option *ngFor="let opt of filteredSelectValues"
                    [value]="opt.key">
            <span [class.empty]="!opt.icon"
                  [class] = "opt.icon | sanitize: 'html'"
                  class="icon-span"
                  *ngIf="opt.icon && selectRef.panelOpen">
              <span [innerHTML]="opt.icon | sanitize: 'html'"></span>
            </span>
          <span>{{opt.value}}</span>
        </mat-option>
        <p *ngIf="filteredSelectValues.length === 0">keine Einträge gefunden</p>
      </mat-select>
      <button class="clear-btn" *ngIf="question.clear" matSuffix mat-icon-button aria-label="Clear"
              (click)="clear(selectRef, $event)">
        <mat-icon>close</mat-icon>
      </button>
    </ng-container>

    <vit-custom-mat-checkbox
      *ngSwitchCase="'checkbox'"
      (change)="question.onChange($event)"
      [formControlName]="question.key"
      [icon]="question.icon"
      [label]="question.label">
    </vit-custom-mat-checkbox>

    <vit-custom-mat-slider
      *ngSwitchCase="'slider'"
      [formControlName]="question.key"
      [label]="question.label"
      [min]="question.min"
      [max]="question.max"
      [thumbLabel]="question.thumbLabel"
      [tickInterval]="question.tickInterval"
      [stepSize]="question.stepSize">
    </vit-custom-mat-slider>

    <mat-radio-group *ngSwitchCase="'radio'"
                     [formControlName]="question.key">
      <span class="radio-label" *ngIf="question.label">{{question.label | translate}}</span>
      <mat-radio-button *ngFor="let opt of question.options"
                        [value]="opt.key">
        {{opt.value}}
      </mat-radio-button>
    </mat-radio-group>

    <ng-container *ngSwitchCase="'cash-storage'">
      <table>
        <tr>
          <td>{{'station.station.cashStorage.sum' | translate: [question.value?.totalSum]}}</td>
          <td>{{'station.station.cashStorage.name' | translate: [question.value?.name]}}</td>
        </tr>
      </table>
      <div>
        <div class="device">
          <b>{{'station.station.cashStorage.coins' | translate}}</b>
          <br>
          <span>{{'station.station.cashStorage.sum' | translate: [question.value?.coinDevice?.sum]}}</span>
          <br>
          <span>{{'station.station.cashStorage.floatingValue' | translate: [question.value?.coinDevice?.floatingValue]}}</span>
          <br>
          <table class="cash-storage" border="0" *ngIf="question.value?.coinDevice?.channels.length">
            <tr class="header">
              <td>{{'station.station.cashStorage.header.coin' | translate}}</td>
              <td>{{'station.station.cashStorage.header.amount' | translate}}</td>
              <td>{{'station.station.cashStorage.header.sum' | translate}}</td>
            </tr>
            <tr *ngFor="let chanel of question.value?.coinDevice.channels">
              <td>{{chanel.value}}</td>
              <td>{{chanel.qty + ' (' + chanel.maxQty + ')'}}</td>
              <td>{{chanel.sum}}</td>
            </tr>
          </table>
        </div>
        <div class="device">
          <b>{{'station.station.cashStorage.notes' | translate}}</b>
          <br>
          <span>{{'station.station.cashStorage.sum' | translate: [question.value?.noteDevice?.sum]}}</span>
          <br>
          <span>{{'station.station.cashStorage.floatingValue' | translate: [question.value?.noteDevice?.floatingValue]}}</span>
          <br>
          <table class="cash-storage" border="0" *ngIf="question.value?.noteDevice?.channels.length">
            <tr class="header">
              <td>{{'station.station.cashStorage.header.note' | translate}}</td>
              <td>{{'station.station.cashStorage.header.amount' | translate}}</td>
              <td>{{'station.station.cashStorage.header.sum' | translate}}</td>
            </tr>
            <tr *ngFor="let chanel of question.value?.noteDevice.channels">
              <td>{{chanel.value}}</td>
              <td>{{chanel.qty + ' (' + chanel.maxQty + ')'}}</td>
              <td>{{chanel.sum}}</td>
            </tr>
          </table>
        </div>
      </div>
    </ng-container>

    <mat-error class="errorMessage"
               *ngIf="!form.controls[question.key].valid || form.controls[question.key].hasError('required')">
      {{question.label | translate}} is required
    </mat-error>
    <mat-error class="errorMessage" *ngIf="form.controls[question.key].hasError('required')">{{question.label |
      translate}} is required
    </mat-error>
    <mat-error class="errorMessage" *ngIf="form.controls[question.key].invalid">{{question.label | translate}} is
      required
    </mat-error>

    <input class="tempfix" matInput>

    <ng-container *ngSwitchCase="'race-cycle'">
      <table border="0" class="race-cycle">
        <tr>
          <td>
            {{'smartrace.settings.raceCycle.description' | translate}}
          </td>
          <td>
            {{'smartrace.settings.raceCycle.betAcceptancePeriod' | translate}}
          </td>
          <td>
            {{'smartrace.settings.raceCycle.activated' | translate}}
          </td>
        </tr>
        <tr *ngFor="let cycle of question.value">
          <td>{{cycle.description}}</td>
          <td>
            {{cycle.betAcceptancePeriod}}
          </td>
          <td>
            <mat-checkbox
              [(ngModel)]="cycle.isSelected"
              [disabled]="isNotAuthorizedToEdit()"
              [class.no-click]="isNotAuthorizedToEdit()"
              [ngModelOptions]="{standalone: true}">
            </mat-checkbox>
          </td>
        </tr>
      </table>
    </ng-container>
  </mat-form-field>

  <mat-form-field *ngIf="question.controlType === 'date-time'">
    <input matInput
           [formControlName]="question.key"
           [placeholder]="question.label | translate"
           (click)="picker.open()"
           [matDatepicker]="picker"
           readonly>
    <mat-icon (click)="form.controls[question.key].reset(null)"
              *ngIf="form.controls[question.key].value"
              style="cursor:pointer;"
              matSuffix
              matDatepickerToggleIcon>clear
    </mat-icon>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <vit-timeframe *ngSwitchCase="'time-frame'"
                 [formControlName]="question.key"
                 [onlyMonths]="question.onlyMonths"
                 [onlyLastMonths]="question.onlyLastMonths"
                 [onlyLastMonths2]="question.onlyLastMonths2"
                 [hideInput]="question.hideInput"
                 [value]="time">
  </vit-timeframe>

  <ng-container *ngSwitchCase="'payment-methods'">
    <table class="payment-methods" border="0">
      <tr class="header">
        <td>{{'station.station.disburseTypeInfo.header.disburseType' | translate}}</td>
        <td>{{'station.station.disburseTypeInfo.header.selected' | translate}}</td>
        <td>{{'station.station.disburseTypeInfo.header.min' | translate}}</td>
        <td>{{'station.station.disburseTypeInfo.header.max' | translate}}</td>
      </tr>
      <tr *ngFor="let disburseType of question.value"
          [class.disabled]="!disburseType.isAvailable">
        <td>{{disburseType.description}}</td>
        <td>
          <mat-checkbox [(ngModel)]="disburseType.isSelected"
                        [disabled]="isNotAuthorizedToEdit()"
                        [ngModelOptions]="{standalone: true}"></mat-checkbox>
        </td>
        <td><input matInput
                   [(ngModel)]="disburseType.minValue"
                   [disabled]="isNotAuthorizedToEdit()"
                   autocomplete="off"
                   [ngModelOptions]="{standalone: true}"
                   [type]="'number'"></td>
        <td><input matInput
                   [(ngModel)]="disburseType.maxValue"
                   [disabled]="isNotAuthorizedToEdit()"
                   [ngModelOptions]="{standalone: true}"
                   autocomplete="off"
                   [type]="'number'"></td>
      </tr>
    </table>
  </ng-container>

  <p *ngIf="question.desc">{{question.desc | translate}}</p>

</form>

<form class="hold-limiter"
      [formGroup]="form"
      *ngIf="question.controlType === 'hold-limiter'"
      [hidden]="isNotAuthorizedToView()">

  <mat-form-field class="checkbox">
    <mat-checkbox
      [(ngModel)]="question.activated"
      [disabled]="isNotAuthorizedToEdit()"
      [class.no-click]="isNotAuthorizedToEdit()"
      (click)="question.toggleActivated(!question.activated, question.value)"
      [ngModelOptions]="{standalone: true}">
      {{'station.station.holdLimiter.activated' | translate}}
    </mat-checkbox>
    <input class="tempfix" matInput>
  </mat-form-field>

  <div class="value-button-container">
    <mat-form-field class="textbox">
      <input matInput
             type="text"
             [disabled]="true"
             [(ngModel)]="question.value.value"
             [ngModelOptions]="{standalone: true}"
             [placeholder]="'station.station.holdLimiter.value' | translate">
    </mat-form-field>
    <button mat-flat-button
            [disabled]="isNotAuthorizedToEdit()"
            color="accent"
            (click)="question.reset(question.value)">
      {{'station.station.holdLimiter.renew' | translate}}
    </button>
  </div>
  <div class="value-button-container">
    <mat-form-field class="textbox">
      <input matInput
             type="number"
             [disabled]="isNotAuthorizedToEdit()"
             [(ngModel)]="question.value.defaultValue"
             [ngModelOptions]="{standalone: true}"
             [placeholder]="'station.station.holdLimiter.defaultValue' | translate">
    </mat-form-field>
    <button mat-flat-button
            [disabled]="isNotAuthorizedToEdit()"
            color="accent"
            (click)="question.submit(question.value.defaultValue, holdLimiterAddValue)">
      {{'station.station.holdLimiter.saveDefaultValue' | translate}}
    </button>
  </div>
  <div class="value-button-container">
    <mat-form-field class="textbox">
      <input matInput
             type="number"
             [disabled]="isNotAuthorizedToEdit()"
             [(ngModel)]="holdLimiterAddValue"
             [ngModelOptions]="{standalone: true}"
             [placeholder]="'station.station.holdLimiter.addValue' | translate">
    </mat-form-field>
    <button mat-flat-button
            [disabled]="isNotAuthorizedToEdit()"
            color="accent"
            (click)="question.submit(question.value.defaultValue, holdLimiterAddValue)">
      {{'station.station.holdLimiter.saveAddValue' | translate}}
    </button>
  </div>
</form>
