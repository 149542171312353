import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {ColumnApi, ColumnVisibleEvent, Environment, GetContextMenuItemsParams, GridApi, GridReadyEvent} from 'ag-grid-community';
import {HttpClient} from '@angular/common/http';
import {SystemConfig} from './app/config/system-config';
import {HttpService} from './app/services/http.service';
import {QueryFilter} from './app/domain/query-filter';

export function mapMap<T1, T2>(object: any): Map<T1, T2> {
  const values: any[][] = [];
  for (const property in object) {
    if (object.hasOwnProperty(property)) {
      values.push([property.toString(), object[property] != null ? object[property].toString() : null]);
    }
  }

  values.sort((v1, v2) => v1[1].toLowerCase() > v2[1].toLowerCase() ? 1 : -1);

  const m: any = new Map();
  for (const v of values) {
    m.set(v[0], v[1]);
  }

  return m;
}

export function enrichByStationId(data: any[], stationID) {
  data.forEach(d => d.stationID = stationID);
  return data;
}

export function getCookie(name: string): string | null {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

export function setCookie(name: string, value: string, days: number) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = '; expires=' + date.toUTCString();
  }
  const domain = removeSubdomain(document.location.hostname);
  document.cookie = name + '=' + (value || '') + expires + '; path=/; domain=.' + domain;
}

export function removeSubdomain(hostname: string) {
  const parts = hostname.split('.');
  if (parts.length > 2) {
    return parts.slice(-2).join('.');
  }
  return hostname;
}


export function enrichByFranchiserId(data: any[], franchiserId) {
  data.forEach(d => d.franchiserId = franchiserId);
  return data;
}

export function enrichByDateFromTo(data: any[], dateFrom: string, dateTo: string) {
  data.forEach(d => d.dateFrom = dateFrom);
  data.forEach(d => d.dateTo = dateTo);
  return data;
}

export function sortAlphabetically(data: any[], key: string) {
  data.sort((a, b) => (a[key].toLowerCase() > b[key].toLowerCase()) ? 1 : -1);
}

export function sortByNumber(data: any[], key: string) {
  data.sort((a, b) => (+a[key] > +b[key]) ? 1 : -1);
}

export function copyToClipboard(val: string, snackBar?: MatSnackBar) {
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = val;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
  if (snackBar) {
    snackBar.open(this.translationService.translate('snackBar.copied'), null, SUCCESS_SNACK_CONFIG);
  }
}

export function filterNulls(arr: any[]) {
  return arr.filter(v => !!v);
}

export function isNotEmpty(arr: any[]) {
  return arr && arr.length > 0;
}

export function isNumber(value: any) {
  return typeof value === 'number';
}

export function isNullOrUndefined(value: any) {
  return value === null || value === undefined;
}

export function inDays(baseDate: Date | string) {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const date = new Date(baseDate);
  date.setHours(0, 0, 0, 0);
  const differenceInTime = currentDate.getTime() - date.getTime();
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

  if (differenceInDays === 0) {
    return 'heute';
  } else if (differenceInDays === 1) {
    return 'gestern';
  } else if (differenceInDays === -1) {
    return 'morgen';
  } else if (differenceInDays === 2) {
    return 'vorgestern';
  } else if (differenceInDays === -2) {
    return 'übermorgen';
  } else if (differenceInDays > 2) {
    return `vor ${differenceInDays} Tagen`;
  } else {
    return `in ${differenceInDays} Tagen`;
  }
}

export const ERROR_SNACK_CONFIG = {'duration': 5000, panelClass: 'error-snack'};
export const TROUBLE_SNACK_CONFIG = {'duration': 5000, panelClass: 'trouble-snack'};
export const SUCCESS_SNACK_CONFIG = {'duration': 5000, panelClass: 'success-snack'};

export function loadColumnVisibility(prefix: string, event: GridReadyEvent) {
  const colStateStr = localStorage.getItem('colstate');
  if (colStateStr) {
    const colState = colStateStr ? JSON.parse(colStateStr) : {};
    Object.entries(colState).forEach(value => {
      const storedPrefix = value[0].split('_')[0];
      if (storedPrefix === prefix) {
        const id = value[0].split('_')[1];
        const visible = value[1] as boolean;
        event.columnApi.setColumnVisible(id, visible);
      }
    });
  }
}

export function getContextMenuItems(httpService: HttpService, query: QueryFilter | any, params: GetContextMenuItemsParams) {
  const items = params.defaultItems.slice();

  const exportCsvIndex = items.findIndex(item => item === 'export');
  if (exportCsvIndex > -1) {
    // @ts-ignore
    items[exportCsvIndex] = {
      name: 'Export',
      subMenu: [{
        name: 'Export CSV',
        action: () => exportCsv(params.api, httpService, query, params.api.getModel().getRowCount()),
      }, {
        name: 'Export Excel',
        action: () => exportExcel(params.api, httpService, query, params.api.getModel().getRowCount()),
      }]
    };
  }

  console.log('items', items);

  return items;
}

export function exportCsv(api: GridApi, http: HttpService, filter: any, rowCount: number) {
  http.post(SystemConfig.Endpoints.EXPORT_LOG, {
    'format': 'csv',
    url: location.href,
    rowCount,
    filter
  }).subscribe({});
  api.exportDataAsCsv();
}

export function exportExcel(api: GridApi, http: HttpService, filter: any, rowCount: number) {
  http.post(SystemConfig.Endpoints.EXPORT_LOG, {
    'format': 'excel',
    url: location.href,
    rowCount,
    filter
  }).subscribe({});
  api.exportDataAsExcel();
}

export function storeColumnVisibility(prefix: string, e: ColumnVisibleEvent) {
  if (e.source !== 'api') {
    const colStateStr = localStorage.getItem('colstate');
    const colState = colStateStr ? JSON.parse(colStateStr) : {};
    colState[prefix + '_' + e.column.getId()] = e.visible;
    localStorage.setItem('colstate', JSON.stringify(colState));
  }
}

export function unique(arr, keyProps) {
  const kvArray = arr.map(entry => {
    const key = keyProps.map(k => entry[k]).join('|');
    return [key, entry];
  });
  const map = new Map(kvArray);
  return Array.from(map.values());
}
