import {Component, NgZone, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ColsComponent} from '../../cols/cols.component';
import {DynamicFormComponent} from '../../dynamic-form/dynamic-form.component';
import {QuestionContainer} from '../../domain/question-container';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {SessionService} from '../../services/session.service';
import {TranslationService} from '../../services/translation.service';
import {getContextMenuItems, sortAlphabetically} from '../../../utils';
import {JackpotOverviewService} from './jackpot-overview.service';
import {StationUtils} from '../../utils/station-utils';
import {TreeNodeType} from '../../domain/tree-node-type';
import {FormUtils} from '../../utils/form-utils';
import {isNumber} from 'utils';
import {loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {GameNumber} from '../../domain/game-number';
import {DropdownQuestion} from '../../domain/question-dropdown';
import {DecimalPipe} from '@angular/common';
import {CustomerTreeService} from '../../services/customer-tree.service';
import {GridReadyEvent} from 'ag-grid-community';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-jackpot-overview',
  templateUrl: './jackpot-overview.component.html',
  styleUrls: ['./jackpot-overview.component.scss']
})
export class JackpotOverviewComponent implements OnInit {

  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;

  loading: boolean;
  @ViewChild(ColsComponent, {static: false})
  colsComponent: ColsComponent;
  @ViewChildren(DynamicFormComponent)
  forms: QueryList<DynamicFormComponent>;
  questions: QuestionContainer[] = [];

  columnDefs;
  gridOptions;
  rowData;
  detailParams;
  storeColumnVisibilityFn = storeColumnVisibility;

  franchisers: any[] = [];

  constructor(public jackpotOverviewService: JackpotOverviewService,
              public snackBar: MatSnackBar,
              public sessionService: SessionService,
              public decimalPipe: DecimalPipe,
              public customerTreeService: CustomerTreeService,
              public ngZone: NgZone,
              public httpService: HttpService,
              public translationService: TranslationService) {
  }

  ngOnInit() {
    this.loading = true;
    this.customerTreeService.tree.subscribe(node => {
      this.franchisers = [];
      this.franchisers.push(...this.sessionService.franchisers.map(f => {
        return {
          'key': f.node.id.toString(), 'value': f.node.label
        };
      }));
      sortAlphabetically(this.franchisers, 'value');
      this.franchisers.unshift({value: this.translationService.translate('searchForm.all'), key: '-1'});
      this.columnDefs = this.generateColumnDefs();
      this.gridOptions = this.generateGridOptions();
      this.reset();
    });
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('jackpotOverview', event);
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('jackpotOverview.type'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'type',
        cellClass: 'type',
        width: 23,
        cellRenderer: function (params) {
          const icon = StationUtils.getIcon(TreeNodeType.STATION, params.data.type, false, params.data.softLocked, params.data.hardLocked);
          const img = '<img src=\'/assets/img/icons/' + icon + '\'>';
          return params.data.stationID === 0 ? '' : img;
        }
      },
      {
        headerName: this.translationService.translate('jackpotOverview.stationID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationID',
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40,
        headerTooltip: this.translationService.translate('jackpotOverview.stationID')
      },
      {
        headerName: this.translationService.translate('jackpotOverview.stationName'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationName',
        width: JackpotOverviewComponent.COLUMN_WIDTH_LARGE,
      },
      {
        headerName: this.translationService.translate('jackpotOverview.name'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'name',
        width: JackpotOverviewComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('jackpotOverview.name')
      },
      {
        headerName: this.translationService.translate('jackpotOverview.minInitialValue'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'minInitialValue',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: JackpotOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('jackpotOverview.minInitialValue')
      },
      {
        headerName: this.translationService.translate('jackpotOverview.maxInitialValue'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'maxInitialValue',
        cellClass: 'text-align-right',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: JackpotOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('jackpotOverview.maxInitialValue')
      },
      {
        headerName: this.translationService.translate('jackpotOverview.jackpotMinPercentage'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'jackpotMinPercentage',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: JackpotOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('jackpotOverview.jackpotMinPercentage')
      },
      {
        headerName: this.translationService.translate('jackpotOverview.jackpotMaxPercentage'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'jackpotMaxPercentage',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: JackpotOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('jackpotOverview.jackpotMaxPercentage')
      },
      {
        headerName: this.translationService.translate('jackpotOverview.minValue'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'minValue',
        cellClass: 'text-align-right',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: JackpotOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('jackpotOverview.minValue')
      },
      {
        headerName: this.translationService.translate('jackpotOverview.maxValue'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'maxValue',
        cellClass: 'text-align-right',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: JackpotOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('jackpotOverview.maxValue')
      },
      {
        headerName: this.translationService.translate('jackpotOverview.value'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'value',
        cellClass: 'text-align-right',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: JackpotOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('jackpotOverview.value')
      },
      {
        headerName: this.translationService.translate('jackpotOverview.hitValue'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'hitValue',
        cellClass: 'text-align-right',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: JackpotOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('jackpotOverview.hitValue')
      }
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, { }, params),
      masterDetail: true,
      rowClassRules: {
        'text-align-right': (params) => isNumber(params.data.value),
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }

  reset() {
    this.rowData = null;
    this.questions = [];
    const games = [GameNumber.POKERBET, GameNumber.SMARTRACE, GameNumber.KENOBET];
    this.questions.push(new QuestionContainer('jackpotOverview.header', [
      new DropdownQuestion({
        key: 'franchiserID',
        label: 'jackpotOverview.franchiserID',
        value: '-1', // this.sessionService.user.franchiserID.toString(),
        options: this.franchisers
      }),
      new DropdownQuestion({
        key: 'applicationID',
        label: 'jackpotOverview.applicationID',
        options: games.map((game) => ({key: game.toString(), value: this.translationService.translate('jackpotOverview.game.' + game)})),
        value: games[0].toString()
      })
    ]));
  }

  show() {
    const form = FormUtils.getFormValues(this.forms, 'jackpotOverview.header');
    const franchiserId = +form.franchiserID;
    const applicationId = +form.applicationID;
    this.rowData = this.jackpotOverviewService.fetch(franchiserId, applicationId);
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }
}
