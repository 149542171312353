import {Injectable} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {SessionService} from '../../services/session.service';
import {Observable, tap} from 'rxjs';
import {QueryFilter} from '../../domain/query-filter';
import {SystemConfig} from '../../config/system-config';
import {TranslationService} from '../../services/translation.service';

@Injectable({
  providedIn: 'root'
})
export class WebshopHoldOverviewService {

  constructor(public httpService: HttpService,
              public translate: TranslationService,
              public sessionService: SessionService) {
  }

  fetchHoldOverview(query: QueryFilter): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.WEBSHOP_HOLD_OVERVIEW, query);
  }

  fetchCashflow(query: QueryFilter): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.WEBSHOP_CASHFLOW, query).pipe(tap(res => {
      res.forEach(el => el.cashType = this.translate.translate('webShopHoldOverview.cashTypes.' + el.cashType));
    }));
  }

  fetchPerGame(query: QueryFilter): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.WEBSHOP_HOLD_OVERVIEW_PER_GAME, query);
  }
}
