import {Component, OnInit} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {getContextMenuItems, isNumber} from 'utils';
import {loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {PokerHoldOverviewService} from './poker-hold-overview.service';
import {StationUtils} from '../../utils/station-utils';
import {TreeNodeType} from '../../domain/tree-node-type';
import {GridReadyEvent} from 'ag-grid-community';
import {map} from 'rxjs/operators';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-poker-hold-overview',
  templateUrl: './poker-hold-overview.component.html',
  styleUrls: ['./poker-hold-overview.component.scss']
})
export class PokerHoldOverviewComponent implements OnInit {

  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;

  columnDefs;
  gridOptions;
  rowData;
  titleRow;
  detailParams;

  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public pokerHoldOverviewService: PokerHoldOverviewService,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public datePipe: DatePipe,
              public decimalPipe: DecimalPipe,
              public httpService: HttpService,
              public dialog: MatDialog) {
    this.columnDefs = this.generateColumnDefs();
    this.gridOptions = this.generateGridOptions();
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('pokerHoldOverview', event);
  }

  ngOnInit() {
  }

  fetch(query: QueryFilter) {
    if (query) {
      this.query = query;
      this.rowData = this.pokerHoldOverviewService.fetch(query).pipe(
        map(data => data.filter(d => {
          const isTitleRow = d.stationID === 0;
          if (isTitleRow) {
            this.titleRow = [d];
          }
          return !isTitleRow;
        }))
      );
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('pokerHoldOverview.type'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'type',
        cellClass: 'type',
        pinned: 'left',
        width: 23,
        cellRenderer: function (params) {
          const icon = StationUtils.getIcon(TreeNodeType.STATION, params.data.type, false, params.data.softLocked, params.data.hardLocked);
          const img = '<img src=\'/assets/img/icons/' + icon + '\'>';
          return params.data.stationID === 0 ? '' : img;
        }
      },
      {
        headerName: this.translationService.translate('pokerHoldOverview.stationID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationID',
        pinned: 'left',
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40,
        headerTooltip: this.translationService.translate('pokerHoldOverview.stationID')
      },
      {
        headerName: this.translationService.translate('pokerHoldOverview.stationName'),
        headerTooltip: this.translationService.translate('pokerHoldOverview.stationName'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationName',
        pinned: 'left',
        width: PokerHoldOverviewComponent.COLUMN_WIDTH_LARGE,
      },
      {
        headerName: this.translationService.translate('pokerHoldOverview.quantity'),
        headerTooltip: this.translationService.translate('pokerHoldOverview.quantity'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'quantity',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: PokerHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('pokerHoldOverview.stakeTotal'),
        headerTooltip: this.translationService.translate('pokerHoldOverview.stakeTotal'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stakeTotal',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: PokerHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('pokerHoldOverview.stakeSingleTablePoker'),
        headerTooltip: this.translationService.translate('pokerHoldOverview.stakeSingleTablePoker'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stakeSingleTablePoker',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: PokerHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('pokerHoldOverview.holdSingleTablePoker'),
        headerTooltip: this.translationService.translate('pokerHoldOverview.holdSingleTablePoker'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'holdSingleTablePoker',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: PokerHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('pokerHoldOverview.holdSingleTablePokerPercentage'),
        headerTooltip: this.translationService.translate('pokerHoldOverview.holdSingleTablePokerPercentage'),
        valueFormatter: this.percentFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'holdSingleTablePokerPercentage',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: PokerHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('pokerHoldOverview.stakeMultiTablePoker'),
        headerTooltip: this.translationService.translate('pokerHoldOverview.stakeMultiTablePoker'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stakeMultiTablePoker',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: PokerHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('pokerHoldOverview.holdMultiTablePoker'),
        headerTooltip: this.translationService.translate('pokerHoldOverview.holdMultiTablePoker'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'holdMultiTablePoker',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: PokerHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('pokerHoldOverview.holdMultiTablePokerPercentage'),
        headerTooltip: this.translationService.translate('pokerHoldOverview.holdMultiTablePokerPercentage'),
        valueFormatter: this.percentFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'holdMultiTablePokerPercentage',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: PokerHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('pokerHoldOverview.stakeBlackJack'),
        headerTooltip: this.translationService.translate('pokerHoldOverview.stakeBlackJack'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stakeBlackJack',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: PokerHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('pokerHoldOverview.holdBlackJack'),
        headerTooltip: this.translationService.translate('pokerHoldOverview.holdBlackJack'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'holdBlackJack',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: PokerHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('pokerHoldOverview.holdBlackJackPercentage'),
        headerTooltip: this.translationService.translate('pokerHoldOverview.holdBlackJackPercentage'),
        valueFormatter: this.percentFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'holdBlackJackPercentage',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: PokerHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('pokerHoldOverview.currency'),
        headerTooltip: this.translationService.translate('pokerHoldOverview.currency'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'currency',
        cellClass: 'text-align-right',
        width: PokerHoldOverviewComponent.COLUMN_WIDTH
      },
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: false,
      detailRowHeight: 160,
      isRowMaster: (data) => data.stationID !== 0,
      rowClassRules: {
        'bold': (params) => params.data.stationID === 0,
        'text-align-right': (params) => isNumber(params.data.value)
      },
      onCellClicked: (params) => {
        params.node.setExpanded(!params.node.expanded);
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }
}
