import {Component, OnInit} from '@angular/core';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {BetLimitsService} from './bet-limits.service';
import {QueryFilter} from '../../domain/query-filter';
import {getContextMenuItems, isNumber, loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {StationUtils} from '../../utils/station-utils';
import {TreeNodeType} from '../../domain/tree-node-type';
import {Permission} from '../../domain/permission';
import {CellEditingStoppedEvent, ColumnApi, GridApi, GridReadyEvent} from 'ag-grid-community';
import {MatLegacySliderChange as MatSliderChange} from '@angular/material/legacy-slider';
import {MobileService} from '../../services/mobile.service';
import {NotificationService} from '../../notification.service';
import {ITooltipParams} from 'ag-grid-community/dist/lib/rendering/tooltipComponent';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-bet-limits',
  templateUrl: './bet-limits.component.html',
  styleUrls: ['./bet-limits.component.scss']
})
export class BetLimitsComponent implements OnInit {

  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_SMALL = 55;
  static COLUMN_WIDTH_LARGE = 150;
  gridApi: GridApi;
  colApi: ColumnApi;
  columnDefs;
  gridOptions;
  rowData;
  changed = false;

  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public betLimitsService: BetLimitsService,
              public translationService: TranslationService,
              public mobileService: MobileService,
              public sessionService: SessionService,
              public httpService: HttpService,
              public notificationService: NotificationService,
              public datePipe: DatePipe,
              public decimalPipe: DecimalPipe) {
    this.columnDefs = this.generateColumnDefs();
    this.gridOptions = this.generateGridOptions();
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    this.colApi = event.columnApi;
    loadColumnVisibility('betLimits', event);
    this.onSimpleViewChange(this.mobileService.isMobile);
  }

  ngOnInit() {
  }

  save() {
    const rows = [];
    this.gridApi.forEachNode(node => {
      if (node.data.newLimit !== node.data.limitStake) {
        rows.push(node.data);
      }
    });
    this.betLimitsService.save(rows).subscribe(res => {
      this.fetch(this.query);
      this.notificationService.showSaved();
    }, e => {
      this.notificationService.showError();
    });
  }

  onQuickFilterChanged(e?: any) {
    this.gridApi.setQuickFilter(e ? e.target.value : '');
  }

  fetch(query: QueryFilter) {
    if (query) {
      this.changed = false;
      this.query = query;
      this.rowData = this.betLimitsService.fetch(query);
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  generateColumnDefs() {
    return [
      {
        pinned: 'left',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        headerName: this.translationService.translate('betLimits.type'),
        width: 30
      },
      {
        headerName: this.translationService.translate('betLimits.type'),
        field: 'type',
        cellClass: 'type',
        width: 23,

        cellRenderer: function (params) {
          const icon = StationUtils.getIcon(TreeNodeType.STATION, params.data.type, false, params.data.softLocked, params.data.hardLocked);
          const img = '<img src=\'/assets/img/icons/' + icon + '\'>';
          return params.data.sid === 0 ? '' : img;
        }
      },
      {
        headerName: this.translationService.translate('betLimits.stationID'),
        field: 'sid',
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        sortable: true,
        filter: true,
        resizable: true,
        width: 40,
        headerTooltip: this.translationService.translate('betLimits.stationID')
      },
      {
        headerName: this.translationService.translate('betLimits.sportLabel'),
        field: 'sportLabel',
        sortable: true,
        filter: true,
        resizable: true,
        width: BetLimitsComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('betLimits.sportLabel'),

      },
      {
        headerName: this.translationService.translate('betLimits.tmt'),
        field: 'tmt',
        tooltipValueGetter: (params: ITooltipParams) => 'BrtID: ' + params.data.brtid,
        sortable: true,
        filter: true,
        resizable: true,
        width: BetLimitsComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('betLimits.tmt')
      },
      {
        headerName: this.translationService.translate('betLimits.competitors'),
        field: 'competitors',
        tooltipValueGetter: (params: ITooltipParams) => 'MatchID: ' + params.data.matchID + ", BrMatchID: "+ params.data.brMatchID,
        sortable: true,
        filter: true,
        resizable: true,
        width: BetLimitsComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('betLimits.competitors')
      },
      {
        headerName: this.translationService.translate('betLimits.utilPct'),
        field: 'utilPct',
        sortable: true,
        filter: 'agNumberColumnFilter',
        resizable: true,
        cellClass: 'text-align-right',
        width: BetLimitsComponent.COLUMN_WIDTH_SMALL,
        valueFormatter: this.percentFormatter.bind(this),
        headerTooltip: this.translationService.translate('betLimits.utilPct'),
        cellClassRules: {
          'util-green': (e) => e.value <= 50,
          'util-yellow': (e) => e.value > 50 && e.value <= 90,
          'util-orange': (e) => e.value > 90 && e.value < 100,
          'util-red': (e) => e.value >= 100
        },
      },
      {
        headerName: this.translationService.translate('betLimits.minute'),
        field: 'minute',
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'text-align-right',
        width: BetLimitsComponent.COLUMN_WIDTH_SMALL,
        headerTooltip: this.translationService.translate('betLimits.minute')
      },
      {
        headerName: this.translationService.translate('betLimits.betLabel'),
        field: 'betLabel',
        sortable: true,
        filter: true,
        resizable: true,
        tooltipValueGetter: (params: ITooltipParams) => 'BetTypeID: ' + params.data.betTypeID,
        width: BetLimitsComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('betLimits.betLabel')
      },
      {
        headerName: this.translationService.translate('betLimits.tip1'),
        field: 'tip1',
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'text-align-right',
        width: BetLimitsComponent.COLUMN_WIDTH_SMALL,
        headerTooltip: this.translationService.translate('betLimits.tip1')
      },
      {
        headerName: this.translationService.translate('betLimits.tipX'),
        field: 'tipX',
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'text-align-right',
        width: BetLimitsComponent.COLUMN_WIDTH_SMALL,
        headerTooltip: this.translationService.translate('betLimits.tipX')
      },
      {
        headerName: this.translationService.translate('betLimits.tip2'),
        field: 'tip2',
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'text-align-right',
        width: BetLimitsComponent.COLUMN_WIDTH_SMALL,
        headerTooltip: this.translationService.translate('betLimits.tip2')
      },
      {
        headerName: this.translationService.translate('betLimits.totalStake'),
        field: 'totalStake',
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'text-align-right',
        width: BetLimitsComponent.COLUMN_WIDTH,
        valueFormatter: this.decimalFormatter.bind(this),
        headerTooltip: this.translationService.translate('betLimits.totalStake')
      },
      {
        headerName: this.translationService.translate('betLimits.limitStake'),
        field: 'limitStake',
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'text-align-right',
        width: BetLimitsComponent.COLUMN_WIDTH,
        valueFormatter: this.decimalFormatter.bind(this),
        headerTooltip: this.translationService.translate('betLimits.limitStake')
      },
      {
        headerName: this.translationService.translate('betLimits.newLimit'),
        field: 'newLimit',
        sortable: true,
        filter: true,
        resizable: true,
        editable: true,
        cellClass: 'text-align-right column-blue',
        width: BetLimitsComponent.COLUMN_WIDTH,
        valueFormatter: this.decimalFormatter.bind(this),
        headerTooltip: this.translationService.translate('betLimits.newLimit')
      },
      {
        headerName: this.translationService.translate('betLimits.hitDate'),
        field: 'hitDate',
        sortable: true,
        filter: true,
        resizable: true,
        width: BetLimitsComponent.COLUMN_WIDTH,
        valueFormatter: this.dateFormatter.bind(this),
        headerTooltip: this.translationService.translate('betLimits.hitDate')
      },
      this.sessionService.hasPermission(Permission.editAdvancedAdminSettings) ? {
        headerName: this.translationService.translate('betLimits.userID'),
        field: 'userID',
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'text-align-right',
        width: BetLimitsComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('betLimits.userID')
      } : null,
      {
        headerName: this.translationService.translate('betLimits.lastChange'),
        field: 'lastChange',
        sortable: true,
        hide: true,
        filter: true,
        resizable: true,
        width: BetLimitsComponent.COLUMN_WIDTH_LARGE,
        valueFormatter: this.dateFormatter.bind(this),
        headerTooltip: this.translationService.translate('betLimits.lastChange')
      },
      {
        headerName: this.translationService.translate('betLimits.ticketCount'),
        field: 'ticketCount',
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'text-align-right',
        width: BetLimitsComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('betLimits.ticketCount')
      },
      {
        headerName: this.translationService.translate('betLimits.highestStake'),
        field: 'highestStake',
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'text-align-right',
        valueFormatter: this.decimalFormatter.bind(this),
        width: BetLimitsComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('betLimits.highestStake')
      },
      {
        headerName: this.translationService.translate('betLimits.mStart'),
        field: 'mStart',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.dateFormatter.bind(this),
        width: BetLimitsComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('betLimits.mStart')
      }
    ].filter(el => !!el);
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      isRowMaster: (data) => data.stationID !== 0,
      rowClassRules: {
        'bold': (params) => params.data.stationID === 0,
        'text-align-right': (params) => isNumber(params.data.value)
      },
      onCellClicked: (params) => {
        params.node.setExpanded(!params.node.expanded);
      },
      onCellEditingStopped: (event: CellEditingStoppedEvent) => {
        const selectedNodes = this.gridApi.getSelectedNodes();
        this.changed = true;
        selectedNodes.forEach(node => {
          node.data.newLimit = +event.newValue;
        });
        this.gridApi.redrawRows(selectedNodes as any);
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }

  changePercent(e: MatSliderChange) {
    const selectedNodes = this.gridApi.getSelectedNodes();
    selectedNodes.forEach(node => {
      console.log(node.data.limit, e.value);
      node.data.newLimit = node.data.limitStake * (1 + e.value / 100);
    });
    this.changed = true;
    this.gridApi.redrawRows(selectedNodes as any);
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }

  onSimpleViewChange(showSimple: boolean) {
    this.changeColumnVisibility('stationID', true);
    this.changeColumnVisibility('sportLabel', !showSimple);
    this.changeColumnVisibility('tmt', !showSimple);
    this.changeColumnVisibility('competitors', true);
    this.changeColumnVisibility('utilPct', true);
    this.changeColumnVisibility('minute', !showSimple);
    this.changeColumnVisibility('betLabel', true);
    this.changeColumnVisibility('tip1', !showSimple);
    this.changeColumnVisibility('tipX', !showSimple);
    this.changeColumnVisibility('tip2', !showSimple);
    this.changeColumnVisibility('totalStake', !showSimple);
    this.changeColumnVisibility('hitDate', !showSimple);
    this.changeColumnVisibility('limitStake', true);
    this.changeColumnVisibility('newLimit', true);
    this.changeColumnVisibility('hitLimit', !showSimple);
    this.changeColumnVisibility('userID', !showSimple);
    this.changeColumnVisibility('lastChange', false);
    this.changeColumnVisibility('ticketCount', !showSimple);
    this.changeColumnVisibility('highestStake', !showSimple);
    this.changeColumnVisibility('mStart', !showSimple);

    if (!showSimple) {
      loadColumnVisibility('betLimits', {columnApi: this.colApi} as GridReadyEvent);
    }
  }

  private changeColumnVisibility(key: string, visible: boolean) {
    this.colApi.setColumnVisible(key, visible);
  }
}
