<div class="tree-header">
  <button mat-icon-button color="accent" *ngIf="!sessionService.largeTree" (click)="toggleSize()">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <button mat-icon-button color="accent" *ngIf="sessionService.largeTree" (click)="toggleSize()">
    <mat-icon>chevron_right</mat-icon>
  </button>
  <button mat-icon-button color="accent" (click)="collapse()">
    <mat-icon>expand_less</mat-icon>
  </button>
  <button mat-icon-button color="accent"
          *ngIf="sessionService.hasPermission(Permission.editAdvancedAdminSettings)"
          (click)="router.navigate([routes.HOME, routes.HomeMenu.GROUP_MANAGEMENT])">
    <mat-icon>group_work</mat-icon>
  </button>
  <button mat-icon-button color="accent" (click)="refresh()">
    <mat-icon>refresh</mat-icon>
  </button>
</div>
<div class="tree-container">
  <div class="scroll-container">
    <mat-tree #tree [dataSource]="nestedDataSource" [treeControl]="nestedTreeControl">
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
        <li>
          <div class="mat-tree-node" [class.no-children]="!node.hasChildren" [class.is-root-node]="node.parentId === -1"
            (contextmenu)="openMenu($event, node)">
            <button *ngIf="node.hasChildren && node.parentId != -1" (click)="nodeClicked(node)" mat-icon-button
              matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{nestedTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <a mat-list-item [routerLink]="[node.type === 1 ? '/station' : '/franchiser', node.parentId, node.id]">
              <img [src]="'/assets/img/icons/'+node.icon"><span>{{node.name }}</span>
            </a>
          </div>
          <ul [class.tree-invisible]="!nestedTreeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </li>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
</div>
<div class="user-info mat-caption" *ngIf="!sessionService.enableTreeNodeSelection">
  <span>Version</span>
  <span [matTooltip]="buildTs">{{sessionService.version}}</span>
</div>
