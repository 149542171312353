import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {SessionService} from './session.service';
import {Jackpot} from '../domain/jackpot';
import {SystemConfig} from '../config/system-config';
import {Observable} from 'rxjs/index';

@Injectable({
  providedIn: 'root',
})
export class JackpotService {

  static readonly NO_JACKPOT_ID = -999999;

  constructor(public httpService: HttpService,
              public sessionService: SessionService) {
  }

  fetchGroupJackpots(): Observable<Jackpot[]> {
    return this.httpService.get(SystemConfig.Endpoints.GROUP_JACKPOTS);
  }

  updateGroupJackpot(jp: Jackpot): Observable<Jackpot[]> {
    return this.httpService.post(SystemConfig.Endpoints.GROUP_JACKPOTS_UPDATE, jp);
  }

  initGroupJackpot(jp: Jackpot): Observable<Jackpot> {
    return this.httpService.post(SystemConfig.Endpoints.GROUP_JACKPOTS_INIT, jp);
  }

  deleteGroupJackpot(jp: Jackpot): Observable<Jackpot[]> {
    return this.httpService.post(SystemConfig.Endpoints.GROUP_JACKPOTS_DELETE, jp);
  }

  insertGroupJackpot(jp: Jackpot): Observable<Jackpot[]> {
    return this.httpService.post(SystemConfig.Endpoints.GROUP_JACKPOTS_INSERT, jp);
  }

  init(jackpot: Jackpot): Observable<any> {
    return this.httpService.post(SystemConfig.Endpoints.JACKPOT_INIT, jackpot);
  }

  async insert(jackpot: Jackpot): Promise<Jackpot> {
    return this.httpService.post(SystemConfig.Endpoints.JACKPOT_INSERT, jackpot).toPromise();
  }

  async update(jackpot: Jackpot): Promise<Jackpot> {
    return this.httpService.post(SystemConfig.Endpoints.JACKPOT_UPDATE, jackpot).toPromise();
  }

  isStationJackpot(jackpot: Jackpot, stationId: number) {
    return jackpot.name === stationId.toString();
  }

}
