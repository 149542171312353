<mat-toolbar color="warn" class="locked" *ngIf="userDto && !userDto.isSessionAllowedToEditSettings">
  <mat-icon>locked</mat-icon>
  <span>ACHTUNG: KEINE BERECHTIGUNG!</span>
  <span>Sie besitzen nicht die Berechtigung diesen User zu bearbeiten.</span>
  <span>Wenn Sie diesen User ändern, sperren oder löschen möchten,</span>
  <span>kontaktieren Sie bitte eine Person mit höheren Rechten oder den Admin.</span>
</mat-toolbar>
<vit-cols [elements]="questions"></vit-cols>
<mat-toolbar class="footer">
  <vit-buttons>
    <button type="submit"
            *ngIf="!userDto"
            (click)="new()"
            mat-flat-button
            color="accent">
      {{'userManagement.new' | translate}}
    </button>
    <button type="reset"
            *ngIf="userDto && !isNew"
            (click)="delete()"
            [disabled]="userDto.user.userID === sessionService.user.userID"
            mat-flat-button
            color="secondary">
      {{'userManagement.delete' | translate}}
    </button>
    <button type="reset"
            *ngIf="userDto"
            (click)="reset()"
            mat-flat-button
            color="secondary">
      {{'userManagement.cancel' | translate}}
    </button>
    <button type="submit"
            *ngIf="userDto"
            (click)="save()"
            [disabled]="!userDto.isSessionAllowedToEditSettings || (newPassword && !passwordsMatch)
            || !sessionService.hasPermission(Permission.insertWebUser) || disableSubmit()"
            mat-flat-button
            color="accent">
      {{'userManagement.save' | translate}}
    </button>
  </vit-buttons>
</mat-toolbar>
