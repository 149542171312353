<vit-search-form (submitEmiter)="fetch($event)"
                 [hideGames]="true"
                 [hideMinProfit]="true"
                 [hideMinStake]="true"
                 [hideTimeframe]="true"
                 [hideDetails]="true"
                 [hideMinTickets]="true"
                 [hideStationRestrictions]="true"
                 [hideIntoEuro]="true"
                 [showMatch]="true">
</vit-search-form>
<div class="controls" *ngIf="rowData" >
  <mat-form-field class="search">
    <input matInput
           type="text"
           #searchIn
           (keyup)="onQuickFilterChanged($event)"
           [placeholder]="'betLimits.search' | translate">
    <button class="clear-btn" mat-button matSuffix mat-icon-button aria-label="Clear"
            (click)="searchIn.value = ''; onQuickFilterChanged()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-slide-toggle [checked]="mobileService.isMobile" (change)="onSimpleViewChange($event.checked)">Simple View</mat-slide-toggle>
</div>
<ag-grid-angular
  *ngIf="rowData"
  (columnVisible)="storeColumnVisibilityFn('betLimits', $event)"
  class="ag-theme-balham"
  style="width: 100%; flex: 1;"
  [gridOptions]="gridOptions"
  [rowData]="rowData | async"
  [columnDefs]="columnDefs"
  (gridReady)="onGridReady($event)">
</ag-grid-angular>
<mat-toolbar class="bottom" *ngIf="rowData">
  <button class="save" [disabled]="!changed" mat-raised-button
          (click)="save()">{{'betLimits.save' | translate}}</button>
  <ng-container *ngIf="gridApi?.getSelectedNodes()?.length > 0">
    <mat-icon>edit</mat-icon>
    <mat-slider (input)="changePercent($event)" thumbLabel min="-99" max="500" step="1" value="0"></mat-slider>
  </ng-container>
</mat-toolbar>
