<mat-card>
  <table>
    <thead>
    <tr>
      <td>{{'currencies.code' | translate}}</td>
      <td>{{'currencies.description' | translate}}</td>
      <td>{{'currencies.toEuro' | translate}}</td>
      <td *ngIf="sessionService.user.admin"></td>
    </tr>
    </thead>
    <tr *ngFor="let currency of currencies">
      <td>{{currency.currencyCode}}</td>
      <td>{{currency.description}}</td>
      <td><input matInput
                 type="number"
                 [disabled]="currency.currencyCode === 'EUR' || !sessionService.user.admin"
                 [(ngModel)]="currency.toEuro"></td>
      <td *ngIf="sessionService.user.admin">
        <button mat-flat-button
                color="accent"
                *ngIf="currency.currencyCode !== 'EUR'"
                (click)="update(currency)">{{'currencies.update' | translate}}
        </button>
      </td>
    </tr>
  </table>
  <button mat-flat-button
          class="submit"
          color="accent"
          (click)="ngOnInit()">{{'currencies.refresh' | translate}}
  </button>
</mat-card>
