import { QuestionBase } from './question-base';
import { HoldLimiter } from './hold-limiter';

export class HoldLimiterQuestion extends QuestionBase<HoldLimiter> {
    controlType = 'hold-limiter';
    submit: Function;
    reset: Function;
    activated: boolean;
    toggleActivated: Function;

    constructor(options: {} = {}) {
        super(options);

        this.reset = options['reset'];
        this.toggleActivated = options['toggleActivated'];
        this.activated = options['activated'];
        this.submit = options['submit'];
    }
}
