import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[vitAutoFocus]'
})
export class AutofocusDirective implements AfterViewInit {

  @Input()
  delay = 0;

  constructor(private element: ElementRef) {}

  ngAfterViewInit() {
    setTimeout(() => this.element.nativeElement.focus(), this.delay);
  }
}
