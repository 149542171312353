import {Component, HostBinding, Inject, Optional, OnInit} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {HoldOverviewService} from '../../services/hold-overview.service';
import {GameNumber} from '../../domain/game-number';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

@Component({
  selector: 'vit-ticket-search',
  templateUrl: './ticket-search.component.html',
  styleUrls: ['./ticket-search.component.scss']
})
export class TicketSearchComponent implements OnInit {

  error = false;
  ticketNumber;

  constructor(public translationService: TranslationService,
              public holdOverviewService: HoldOverviewService,
              public sessionService: SessionService,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: { ticketNumber: string },
              public route: ActivatedRoute) {
  }

  @HostBinding('class.embedded')
  get shouldLabelFloat() {
    return this.data;
  }

  ngOnInit() {
    if (this.data?.ticketNumber) {
      this.ticketNumber = this.data.ticketNumber;
      this.search();
    } else {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.ticketNumber = params.get('ticketnr');
        this.search();
      });
    }
  }

  remove(idx) {
    this.sessionService.searchedTickets.splice(idx, 1);
  }

  search() {
    this.error = false;
    const normalizedTicketNumbers: string[] = this.ticketNumber
      .replace(/\./g, '') // remove dots
      .replace(/[^0-9]/g, ' ') // replace all other characters with blanks
      .split(' ') // split up in individual ticket numbers
      .filter(el => !!el); // filter empty ones
    console.log('search: ' + this.ticketNumber, normalizedTicketNumbers);

    normalizedTicketNumbers.forEach(tn => this.extracted(tn));
  }

  private extracted(normalizedTicketNumber: string) {
    if (normalizedTicketNumber.length >= 13) {
      const query: QueryFilter = {
        'queryMode': null,
        'franchiserID': null,
        'stationID': null,
        'countryCode': null,
        'dateFrom': null,
        'dateTo': null,
        'ignorePB1': false,
        'ignoreRF2': false,
        'ignoreBB1': false,
        'ignoreLB2': false,
        'ignoreShop': false,
        'ignoreOnline': false,
        'ignoreOffline': false,
        'ignoreLocked': false,
        'ignoreUnlocked': false,
        'ignoreTerminal': false,
        'ignoreTerminalShop': false,
        'ignoreBridge': false,
        'ignoreWebshop': false,
        'ignoreCashier': false,
        'minStake': null,
        'minProfit': null,
        'ignoreSubFranchiser': false,
        'flag': false,
        'userID': null,
        'ignoreTest': false,
        'minTickets': null,
        'intoEuro': false,
        'customerID': null,
        'userAccountID': null,
        'eventID': 0,
        'ticketStatus': 0,
        'ticketNumber': +normalizedTicketNumber.substring(0, 17),
        'cashbookNumber': 0,
        'raceCycleID': null,
        'bingoCycleID': null,
        'groupID': null
      };

      this.holdOverviewService.fetchTicket(query, +normalizedTicketNumber[4] as GameNumber).subscribe(ticketData => {

        if (+normalizedTicketNumber[4] === 0) {
          ticketData.ticket = {number: +normalizedTicketNumber};
        }

        this.sessionService.searchedTickets = this.sessionService.searchedTickets
          .filter(t => !t.ticket || t.ticket.number !== ticketData.ticket.number);
        this.sessionService.searchedTickets.unshift(ticketData);
      }, (e) => {
        console.log('not found', e);
        this.error = true;
      });
    } else {
      this.error = true;
    }
  }
}
