<table>
  <tr>
    <td>{{'ticketDetail.ticketNumber' | translate}}</td>
    <td>{{ticket?.number}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.code' | translate}}</td>
    <td>{{ticket?.code ? ticket?.code : '---'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.stationID' | translate}}</td>
    <td>{{ticket?.stationID}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.stakeTotalGross' | translate}}</td>
    <td>{{(ticket?.stake + ticket?.tax) | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td class="indented">{{'ticketDetail.tax' | translate}}</td>
    <td>{{ticket?.tax | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td class="indented">{{'ticketDetail.stake' | translate}}</td>
    <td>{{ticket?.stake | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td class="indented">{{'ticketDetail.bonusStake' | translate}}</td>
    <td>{{ticket?.bonusStake | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.livesports.maxProfit' | translate}}</td>
    <td>{{ticket?.maxProfit | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.livesports.maxOdd' | translate}}</td>
    <td>{{ticket?.maxOdd | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.livesports.bonus' | translate}}</td>
    <td>{{ticket?.bonus | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.profit' | translate}}</td>
    <td>{{(ticket?.profit + ticket?.winTax) | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.livesports.profitTax' | translate}}</td>
    <td>{{ticket?.winTax | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.wonProfit' | translate}}</td>
    <td class="bold">{{ticket?.profit | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.paperProfit' | translate}}</td>
    <td>{{ticket?.paperProfit | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.livesports.systemRows' | translate}}</td>
    <td>{{ticket?.systemX + '/' + ticket?.systemY}}
      {{('ticketDetail.livesports.row' + (ticket?.systemRows > 1 ? 's' : '')) | translate: [ticket?.systemRows]}}
    </td>
  </tr>
  <tr>
    <td>{{'ticketDetail.livesports.wayCount' | translate}}</td>
    <td>{{ticket?.wayCount}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.inDate' | translate}}</td>
    <td>{{ticket?.inDate | date: 'medium'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.calcDate' | translate}}</td>
    <td>{{ticket?.calcDate | date: 'medium'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.outDate' | translate}}</td>
    <td>{{ticket?.outDate | date: 'medium'}}</td>
  </tr>
  <tr *ngIf="ticket?.outStationID">
    <td>{{'ticketDetail.outStationID' | translate}}</td>
    <td>{{'ticketDetail.outStationIDValue' | translate: [ticket?.outStationID]}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.livesports.legend' | translate}}</td>
    <td>{{'ticketDetail.livesports.legendText' | translate}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.livesports.ticketReleased' | translate}}</td>
    <td *ngIf="ticket?.verified">{{'ticketDetail.livesports.verified' | translate}}
    </td>
    <td *ngIf="!ticket?.verified"
        style="color: red; font-weight: bold">{{'ticketDetail.livesports.notVerified' | translate}}
    </td>
  </tr>
  <tr>
    <td>{{'ticketDetail.userAccountID' | translate}}</td>
    <td *ngIf="ticket?.userAccountID">{{ticket?.userAccountID + ' (' + ticketData?.userAccountUsername + ')'}}
      <mat-icon *ngIf="showPrepaidAdminLink" class="copy" [matTooltip]="'PrepaidAdmin'" (click)="openPrepaidAdmin(ticket.userAccountID)">search</mat-icon>
    </td>
  </tr>
  <tr>
    <td>{{'ticketDetail.customerID' | translate}}</td>
    <td>{{ticket?.customerId}}
      <mat-icon *ngIf="showCustomerAnalysisLink && ticket?.customerId > 5" class="copy" [matTooltip]="'Kundenanalyse'" (click)="openCustomerAnalysis(ticket.customerId)">search</mat-icon>
    </td>
  </tr>
  <tr>
    <td>{{'ticketDetail.ticketOrigin' | translate}}</td>
    <td>{{'ticketDetail.ticketOrigin' + ticket?.ticketOrigin | translate}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.status' | translate}}</td>
    <td>
      <vit-ticket-status [ticket]="ticket"></vit-ticket-status>
    </td>
  </tr>
</table>
<table class="bets">
  <tr>
    <td>{{'ticketDetail.livesports.match' | translate}}</td>
    <td>{{'ticketDetail.livesports.type' | translate}}</td>
    <td>{{'ticketDetail.livesports.tip' | translate}}</td>
    <td>{{'ticketDetail.livesports.odds' | translate}}</td>
    <td>{{'ticketDetail.livesports.bank' | translate}}</td>
    <td>{{'ticketDetail.livesports.cancelled' | translate}}</td>
    <td>{{'ticketDetail.livesports.status' | translate}}</td>
    <td *ngIf="!ticket.disbursed && !ticket.storno && canEditLB2TicketBets"
        style="text-align: center">{{'ticketDetail.livesports.edit' | translate}}</td>
  </tr>
  <tr *ngFor="let bet of bets">
    <td style="display: flex; align-items: center;">
      <mat-menu #liveTicketActionsMenu="matMenu" class="live-ticket-actions-menu">
        <button mat-button (click)="ticketList(bet)" *ngIf="canSeeLB2MatchTickets()">
          <mat-icon class="copy">query_stats</mat-icon>
          Alle Tickets für dieses Match
        </button>
        <button mat-button (click)="googleDefault(bet)">
          <img class="google-icon" src="/assets/img/icons/google.png" height="14px">
          Match + Datum
        </button>
        <button mat-button (click)="googleHome(bet)">
          <img class="google-icon" src="/assets/img/icons/google.png" height="14px">
          Heim Facebook/Wikipedia
        </button>
        <button mat-button (click)="googleGuest(bet)">
          <img class="google-icon" src="/assets/img/icons/google.png" height="14px">
          Gast Facebook/Wikipedia
        </button>
        <button mat-button (click)="googleTournament(bet)">
          <img class="google-icon" src="/assets/img/icons/google.png" height="14px">
          Turnier
        </button>
        <button mat-button (click)="clipboard(bet.tooltip, snackBar)">
          <mat-icon class="copy">file_copy</mat-icon>
          Info
        </button>
        <button mat-button [matTooltip]="bet.brMatchID" (click)="clipboard(bet.brMatchID, snackBar)">
          <mat-icon class="copy" >file_copy</mat-icon>
          BR-MatchID
        </button>
        <button mat-button (click)="brSupport('Warum wurde diese Wette gecancelt?', bet, ticket)">
          <mat-icon class="copy" >contact_phone</mat-icon>
          Warum Wette gecancelt?</button>
        <button mat-button (click)="brSupport('Wann wird diese Wette ausgewertet?', bet, ticket)">
          <mat-icon class="copy" >contact_phone</mat-icon>
          Wann Wette ausgewertet?</button>
        <button mat-button (click)="brSupport('Kunde: Wette wurde falsch ausgewertet', bet, ticket)">
          <mat-icon class="copy" >contact_phone</mat-icon>
          Kunde: Wette falsch ausgewertet</button>
        <button mat-button (click)="brSupport('Kunde: Ticket ist falsch berechnet', bet, ticket)">
          <mat-icon class="copy" >contact_phone</mat-icon>
          Kunde: Ticket falsch berechnet</button>
        <button mat-button (click)="brSupport('Allgemeine Frage zum Match', bet, ticket)">
          <mat-icon class="copy" >contact_phone</mat-icon>
          Allgemeine Frage Match</button>
        <button mat-button (click)="brSupport('Allgemeine Frage zum Ticket', bet, ticket)">
          <mat-icon class="copy" >contact_phone</mat-icon>
          Allgemeine Frage Ticket</button>
        <button mat-button (click)="openMatchEditor(bet)" *ngIf="canSeeMatchEditor()">
          <mat-icon class="copy">zoom_in</mat-icon>
          Match Editor
        </button>
      </mat-menu>

      <button class="edit-ticket" mat-icon-button [matMenuTriggerFor]="liveTicketActionsMenu">
        <mat-icon>more_horiz</mat-icon>
      </button>
      <span [matTooltip]="bet.tooltip" style="display: flex; align-items: center; justify-content: center; margin-left: -5px;"
            [matTooltipClass]="'multiline-tooltip'">
        <img height="14" src="/assets/img/material-icons/new_releases_red.svg"
             *ngIf="bet.simpleMatchStatus === SimpleMatchStatus.SPECIAL_INCIDENT">
        <img height="16" style="margin-right: 2px;" *ngIf="bet.sportIcon" class="sport-icon" [src]="'/assets/sport/' + bet.sportIcon + '.png'">
        {{(bet.live ? '* ' : '') + bet.match}}
        </span>
    </td>
    <td>{{bet.type}}</td>
    <td>{{bet.tip}}</td>
    <td>{{bet.odd | number: '0.2-2'}}</td>
    <td><input type="checkbox" disabled [(checked)]="bet.bank"></td>
    <td><input type="checkbox" disabled [(checked)]="bet.cancelled"></td>
    <td style="padding-top: 2px;">
      <vit-bet-status [betCalculated]="bet.calculated"
                      [betWon]="bet.won"
                      [betStorno]="bet.storno || bet.cancelled"></vit-bet-status>
    </td>
    <td *ngIf="!ticket.disbursed && !ticket.storno && canEditLB2TicketBets">
      <mat-menu #liveTicketMenu="matMenu" class="live-ticket-menu">
        <button *ngIf="!(bet.won && bet.calculated)" mat-menu-item
                (click)="setWon(bet.id)">{{'ticketDetail.livesports.setWon' | translate}}</button>
        <button *ngIf="!(!bet.won && bet.calculated)" mat-menu-item
                (click)="setLost(bet.id)">{{'ticketDetail.livesports.setLost' | translate}}</button>
        <button *ngIf="!bet.cancelled" mat-menu-item
                (click)="cancel(bet.id)">{{'ticketDetail.livesports.cancel' | translate}}</button>
        <button *ngIf="bet.cancelled" mat-menu-item
                (click)="undoCancel(bet.id)">{{'ticketDetail.livesports.undoCancel' | translate}}</button>
      </mat-menu>

      <button class="edit-ticket" mat-icon-button [matMenuTriggerFor]="liveTicketMenu">
        <mat-icon>more_horiz</mat-icon>
      </button>
    </td>
  </tr>
</table>
