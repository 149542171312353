import {Component} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {getContextMenuItems, isNumber, loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CashErrorService} from '../../services/cash-error.service';
import {GridReadyEvent} from 'ag-grid-community';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-cash-errors',
  templateUrl: './cash-errors.component.html',
  styleUrls: ['./cash-errors.component.scss']
})
export class CashErrorsComponent {

  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;

  columnDefs;
  gridOptions;
  rowData;
  detailParams;
  rowCount;

  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;
  constructor(public cashErrorService: CashErrorService,
              public translationService: TranslationService,
              public httpService: HttpService,
              public sessionService: SessionService,
              public datePipe: DatePipe,
              public decimalPipe: DecimalPipe,
              public dialog: MatDialog) {
    this.columnDefs = this.generateColumnDefs();
    this.gridOptions = this.generateGridOptions();
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('stationOverview', event);
  }

  fetchCashErrors(query: QueryFilter) {
    if (query) {
      this.query = query;
      this.rowData = this.cashErrorService.fetchCashErrors(query);
    } else {
      this.query = null;
      this.rowData = null;
    }
  }


  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('cashError.stationID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'sid',
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40,
        headerTooltip: this.translationService.translate('cashError.stationID')
      },
      {
        headerName: this.translationService.translate('cashError.name'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'sName',
        width: CashErrorsComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('cashError.name')
      },
      {
        headerName: this.translationService.translate('cashError.quantity'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'count',
        width: 50,
        cellClass: 'text-align-right',
        headerTooltip: this.translationService.translate('cashError.quantity')
      },
      {
        headerName: this.translationService.translate('cashError.amount'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'totalValue',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CashErrorsComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('cashError.amount')
      },
      {
        headerName: this.translationService.translate('cashError.first'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'first',
        valueFormatter: this.dateFormatter.bind(this),
        width: CashErrorsComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('cashError.first'),
      }
      ,
      {
        headerName: this.translationService.translate('cashError.last'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'last',
        valueFormatter: this.dateFormatter.bind(this),
        width: CashErrorsComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('cashError.last'),
      }
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: true,
      rowClassRules: {
        'bold': (params) => params.data.sid === 0
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }




}
