import {Component, OnInit} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {ResultsService} from '../../services/results.service';
import {getContextMenuItems, isNumber} from 'utils';
import {loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {GridReadyEvent} from 'ag-grid-community';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-results-bb1',
  templateUrl: './results-bb1.component.html',
  styleUrls: ['./results-bb1.component.scss']
})
export class ResultsBb1Component implements OnInit {

  static COLUMN_WIDTH_SMALL = 50;
  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;
  columnDefs;
  gridOptions;
  rowData;
  detailParams;

  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public resultsService: ResultsService,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public datePipe: DatePipe,
              public httpService: HttpService,
              public decimalPipe: DecimalPipe) {
    this.columnDefs = this.generateColumnDefs();
    this.detailParams = this.generateDetailParams();
    this.gridOptions = this.generateGridOptions();
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('resultsBb1', event);
  }

  ngOnInit() {
  }

  fetch(query: QueryFilter) {
    if (query) {
      this.query = query;
      this.rowData = this.resultsService.fetchBB1Results(this.query);
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('results.bb1.gameDate'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'gameDate',
        valueFormatter: this.dateFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: ResultsBb1Component.COLUMN_WIDTH_LARGE
      },
      {
        headerName: this.translationService.translate('results.bb1.bingoNumber'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'bingoNumber',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: ResultsBb1Component.COLUMN_WIDTH,
      },
      {
        headerName: this.translationService.translate('results.bb1.drawn'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'drawn',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: 300,
      },
      {
        headerName: this.translationService.translate('results.bb1.extra'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'extra',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: ResultsBb1Component.COLUMN_WIDTH,
      },
      {
        headerName: this.translationService.translate('results.bb1.calculated'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'calculated',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: ResultsBb1Component.COLUMN_WIDTH,
        cellRenderer: this.checkboxGenerator.bind(this)
      }
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: false,
      detailRowHeight: 160,
      rowClassRules: {
        'bold': (params) => params.data.stationID === 0,
        'text-align-right': (params) => isNumber(params.data.value)
      },
      onCellClicked: (params) => {
        params.node.setExpanded(!params.node.expanded);
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }

  generateDetailParams() {
    return {
      detailGridOptions: {
        columnDefs: [],
        onCellClicked: (params) => {
          params.node.setExpanded(!params.node.expanded);
        }
      },
    };
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  checkboxGenerator(params) {
    const eDiv = document.createElement('div');
    eDiv.innerHTML = '<input type="checkbox" disabled="true" ' + (params.value ? 'checked="true"' : '') + '">';
    return eDiv;
  }

}
