import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';
import {SystemConfig} from '../config/system-config';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  constructor(public httpService: HttpService) {
  }

  public fetchGroups(): Observable<any[]> {
    return this.httpService.get(SystemConfig.Endpoints.FRANCHISER_STATION_GROUPS);
  }
}