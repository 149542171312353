<vit-cols [elements]="questions"></vit-cols>
<mat-toolbar class="footer">
  <vit-buttons>
    <button type="submit"
            *ngIf="!roleDto"
            (click)="new()"
            mat-flat-button
            color="accent">
      {{'roleManagement.new' | translate}}
    </button>
    <button type="reset"
            *ngIf="roleDto"
            (click)="reset()"
            mat-flat-button
            color="secondary">
      {{'roleManagement.cancel' | translate}}
    </button>
    <button type="submit"
            *ngIf="roleDto"
            (click)="save()"
            mat-flat-button
            color="accent">
      {{'roleManagement.save' | translate}}
    </button>
  </vit-buttons>
</mat-toolbar>
