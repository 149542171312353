import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TicketDetailComponent} from './ticket-detail.component';
import {TicketDetailKenoComponent} from '../ticket-detail-keno/ticket-detail-keno.component';
import {TicketDetailLivesportsComponent} from '../ticket-detail-livesports/ticket-detail-livesports.component';
import {TicketDetailSmartraceComponent} from '../ticket-detail-smartrace/ticket-detail-smartrace.component';
import {TicketDetailPokerbetComponent} from '../ticket-detail-pokerbet/ticket-detail-pokerbet.component';
import {SecurecodeDialogComponent} from '../ticket-detail-livesports/securecode-dialog.component';
import {PayoutWidgetComponent} from './payout-widget/payout-widget.component';
import {SuperStornoComponent} from './super-storno/super-storno.component';
import {StornoComponent} from './storno/storno.component';
import {CancelAllBetsComponent} from './cancel-all-bets/cancel-all-bets.component';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {SharedModule} from '../../shared.module';
import {MaterialImportsModule} from '../../material-imports.module';
import {BetStatusComponent} from '../bet-status/bet-status.component';
import {TicketStatusComponent} from '../ticket-status/ticket-status.component';
import {FormsModule} from '@angular/forms';
import {ResetTicketOpenComponent} from './reset-ticket-open/reset-ticket-open.component';
import {PayoutLockComponent} from './payout-lock/payout-lock.component';
import {RefreshTicketComponent} from './refresh-ticket/refresh-ticket.component';
import {ButtonsModule} from '../buttons/buttons.module';
import {AgGridModule} from 'ag-grid-angular';
import {RefundTicketComponent} from './refund-ticket/refund-ticket.component';

@NgModule({
  declarations: [
    TicketDetailComponent,
    TicketDetailKenoComponent,
    TicketDetailLivesportsComponent,
    SecurecodeDialogComponent,
    TicketDetailSmartraceComponent,
    TicketDetailPokerbetComponent,
    PayoutWidgetComponent,
    SuperStornoComponent,
    StornoComponent,
    CancelAllBetsComponent,
    ConfirmDialogComponent,
    RefundTicketComponent,
    CancelAllBetsComponent,
    BetStatusComponent,
    TicketStatusComponent,
    ResetTicketOpenComponent,
    PayoutLockComponent,
    RefreshTicketComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    AgGridModule,
    MaterialImportsModule,
    ButtonsModule
  ],
  exports: [TicketDetailComponent]
})
export class TicketDetailModule {
}
