import {Moment} from 'moment';
import * as moment from 'moment';

export class DateUtils {
  public static setMoment(m: Moment, millis = -1, seconds = -1, minutes = -1, hours = -1, days = -1, months = -1, years = -1) {
    m.set({
      years: years !== -1 ? years : m.get('years'),
      months: months !== -1 ? months : m.get('months'),
      days: days !== -1 ? days : m.get('days'),
      hours: hours !== -1 ? hours : m.get('hours'),
      minutes: minutes !== -1 ? minutes : m.get('minutes'),
      seconds: seconds !== -1 ? seconds : m.get('seconds'),
      milliseconds: years !== -1 ? years : m.get('milliseconds')
    });
    return m;
  }

  public static set(time: number, millis = -1, seconds = -1, minutes = -1, hours = -1, days = -1, months = -1, years = -1) {
    const m = moment(time);
    m.set({
      years: years !== -1 ? years : m.get('years'),
      months: months !== -1 ? months : m.get('months'),
      days: days !== -1 ? days : m.get('days'),
      hours: hours !== -1 ? hours : m.get('hours'),
      minutes: minutes !== -1 ? minutes : m.get('minutes'),
      seconds: seconds !== -1 ? seconds : m.get('seconds'),
      milliseconds: years !== -1 ? years : m.get('milliseconds')
    });
    return m;
  }

  public static setTimeToZero(time: number) {
    return DateUtils.setToZero(time, true, true, true, true);
  }

  public static setToZero(time: number, millis = false, seconds = false, minutes = false, hours = false, days = false, months = false, years = false) {
    const m = moment(time);
    m.set({
      years: years ? 0 : m.get('years'),
      months: months ? 0 : m.get('months'),
      days: days ? 0 : m.get('days'),
      hours: hours ? 0 : m.get('hours'),
      minutes: minutes ? 0 : m.get('minutes'),
      seconds: seconds ? 0 : m.get('seconds'),
      milliseconds: years ? 0 : m.get('milliseconds')
    });
    return m.valueOf();
  }
}
