<vit-custom-mat-slider
  [label]="'customerHold.slider' | translate"
  min="0"
  *ngIf="rowData"
  [max]="monthMax"
  [thumbLabel]="true"
  (valueChange)="onSliderChange($event)"
></vit-custom-mat-slider>
<ag-grid-angular
  *ngIf="rowData"
  (columnVisible)="storeColumnVisibilityFn('customerHold', $event)"
  class="ag-theme-balham"
  style="width: 100%; flex: 1 1 1px;"
  [gridOptions]="gridOptions"
  [tooltipShowDelay]="0"
  [rowData]="rowData | async"
  [columnDefs]="columnDefs"
  (gridReady)="onGridReady($event)">
</ag-grid-angular>
