import {Injectable} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {SessionService} from '../../services/session.service';
import {Observable} from 'rxjs';
import {QueryFilter} from '../../domain/query-filter';
import {SystemConfig} from '../../config/system-config';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerInfoService {

  constructor(public httpService: HttpService,
              public sessionService: SessionService) {
  }

  fetch(): Observable<any[]> {
    return this.httpService.get(SystemConfig.Endpoints.CUSTOMER_HOLD).pipe(map(res => res.customerHoldList));
  }
}
