<ng-container *ngFor="let questionContainer of questions">
  <mat-card>
    <mat-card-header>{{questionContainer.label | translate}}</mat-card-header>
    <mat-card-content>
      <vit-dynamic-form [questionContainer]="questionContainer"></vit-dynamic-form>
    </mat-card-content>
  </mat-card>
</ng-container>

<mat-toolbar class="footer">
  <vit-buttons>
    <button type="reset"
            (click)="reset()"
            mat-flat-button
            color="secondary">
      {{'groupJackpots.footer.reset' | translate}}
    </button>
    <button type="submit"
            [disabled]="!forms?.first?.form.valid"
            (click)="save()"
            mat-flat-button
            color="accent">
      {{(selectedJackpot?.jackpotID === -1 ? 'groupJackpots.footer.new': 'groupJackpots.footer.save') | translate}}
    </button>
    <button type="submit"
            *ngIf="selectedJackpot?.jackpotID !== -1"
            (click)="delete()"
            mat-flat-button
            color="accent">
      {{'groupJackpots.footer.delete' | translate}}
    </button>
    <button type="submit"
            (click)="init()"
            *ngIf="selectedJackpot?.jackpotID !== -1"
            mat-flat-button
            color="accent">
      {{'groupJackpots.footer.init' | translate}}
    </button>
  </vit-buttons>
</mat-toolbar>
