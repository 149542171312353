import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SystemConfig } from '../config/system-config';
import { tap } from 'rxjs/operators';
import {SessionService} from './session.service';
import {environment} from '../../environments/environment';

@Injectable()
export class TranslationService {

    translations: Map<string, any> = new Map();

    constructor(public http: HttpClient,
                public sessionService: SessionService,
                @Inject(LOCALE_ID) private language: string) {
    }

    setLanguage(lang: string) {
      this.language = lang;
    }

    getLanguage() {
      return this.language;
    }

    translateArgs(key: string, ...args: string[]): string {
        try {
            return this.replaceArgsInTranslation(this.getTranslation(key), ...args);
        } catch (e) {
            return key;
        }
    }

    translate(key: string): string {
        try {
            return this.getTranslation(key);
        } catch (e) {
            return key;
        }
    }

    async init() {
      const queryParam = `?${this.sessionService.version}-${environment.buildTime}`; // used for invalidating browser caches.
      await this.http.get('/assets/lang/' + this.language + '.json' + queryParam).pipe(
        tap(json => this.translations.set(this.language, json))).toPromise();
    }

    private getTranslation(keyString: string): string {
        const keyPath: string[] = keyString.split(SystemConfig.Constants.LOCALE_SEPARATOR);
        return [this.translations.get(this.language), ...keyPath].reduce((result: string, key: string) => {
            return result[key] !== undefined ? result[key] : keyString;
        });
    }

    private replaceArgsInTranslation(str: string, ...args: string[]) {
        if (args.length === 0 || !str) {
            return '';
        }
        const mapObj: { [key: string]: string } = {};
        args.forEach((arg, idx) => mapObj[SystemConfig.Constants.LOCALE_ARGS + idx] = arg);
        const regExp: RegExp = new RegExp(Object.keys(mapObj).join('|'), 'gi');
        return str.replace(regExp, matched => mapObj[matched.toLowerCase()]);
    }
}
