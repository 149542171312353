import {NgModule} from '@angular/core';
import {CommonModule, DatePipe, DecimalPipe} from '@angular/common';
import {MaterialImportsModule} from '../../material-imports.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DynamicFormModule} from '../dynamic-form/dynamic-form.module';
import {SharedModule} from '../../shared.module';
import {TicketListComponent} from './ticket-list.component';
import {AgGridModule} from 'ag-grid-angular';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
    imports: [
        CommonModule,
        AgGridModule,
        MaterialImportsModule,
        FormsModule,
        MatDialogModule,
        ReactiveFormsModule,
        DynamicFormModule,
        SharedModule
    ],
    declarations: [
        TicketListComponent
    ],
    providers: [DatePipe, DecimalPipe],
    exports: [TicketListComponent]
})
export class TicketListModule {
}
