import {Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LinkedElement} from '../domain/linked-element';
import {MobileService} from '../services/mobile.service';
import {SearchService} from '../services/search.service';
import {UntypedFormControl} from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import {SessionService} from '../services/session.service';
import {NavigationStart, Router} from '@angular/router';
import {SystemConfig} from '../config/system-config';
import {TranslationService} from '../services/translation.service';
import {CustomerTreeService} from '../services/customer-tree.service';
import {UserService} from '../services/user.service';
import {environment} from '../../environments/environment';
import {HttpService} from '../services/http.service';
import {Permission} from '../domain/permission';
import {copyToClipboard} from '../../utils';
import {MatLegacySnackBar} from '@angular/material/legacy-snack-bar';
@Component({
  selector: 'vit-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  routes = SystemConfig.Routes;

  buildTime = environment.buildTime;

  private readonly UNKNOWN_PATH = '...';

  @ViewChild('searchInput', { static: false }) searchInput: MatInput;
  @ViewChild(MatAutocomplete, { static: false }) matAutocomplete: MatAutocomplete;

  oldSearchValue;
  searchCtrl: UntypedFormControl = new UntypedFormControl();
  search = false;
  searchTimeout: any;
  rootFranchiser = '';
  routeName = '';

  permissions = Permission;

  @Input()
  selectedMenu: LinkedElement;

  @Output()
  menuToggle: EventEmitter<string> = new EventEmitter();
  @Output()
  toggleTree: EventEmitter<string> = new EventEmitter();
  @Output()
  logout: EventEmitter<boolean> = new EventEmitter();
  clipboard:any;

  get showBackToHome() {
    return ['live-admin/category-editor', 'live-admin/match-editor'].some(url => this.router.url.includes(url));
  }

  @HostBinding('class.is-mobile')
  get isMobileClass() {
    return this.mobileService.isMobile;
  }

  constructor(public mobileService: MobileService,
              public userService: UserService,
              public httpService: HttpService,
              public sessionService: SessionService,
              public searchService: SearchService,
              public customerTreeService: CustomerTreeService,
              public translationService: TranslationService,
              public snackBar: MatLegacySnackBar,
              public router: Router) {
    this.subscribeToInputChanges();
    this.subscribeToTree();
    this.subscribeToRouter();
    this.routeName = this.cleanupRoutingName(router.url);
    this.clipboard = copyToClipboard.bind(this);
  }

  enter() {
    if (this.matAutocomplete.options && this.matAutocomplete.options.first) {
      this.matAutocomplete.options.first.select();
    }
  }

  private subscribeToRouter() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        this.search = false;
        this.routeName = this.cleanupRoutingName(event.url);
      }
    });
  }

  public get breadcrumb() {
    if (this.routeName.startsWith(SystemConfig.Routes.LIVE_ADMIN)) {
      return 'Live Admin > ' + this.translationService.translate(this.selectedMenu?.label);
    } else if (this.routeName.startsWith(SystemConfig.Routes.FRANCHISER)) {
      return this.sessionService.selectedFranchiser ?
        this.sessionService.selectedFranchiser.franchiser.name : this.UNKNOWN_PATH;
    } else if (this.routeName.startsWith(SystemConfig.Routes.STATION)) {
      return this.sessionService.selectedStation ?
        (this.sessionService.selectedStation.station.stationID
          + ' - ' + this.sessionService.selectedStation.station.name)
        : this.UNKNOWN_PATH;
    } else {
      return this.selectedMenu ? this.translationService.translate(this.selectedMenu.label) : this.UNKNOWN_PATH;
    }
  }

  private subscribeToTree() {
    this.customerTreeService.tree.subscribe(tree => {
      if (tree) {
        this.rootFranchiser = tree.name;
      }
    });
  }

  ngOnInit() {
  }

  select(event: MatAutocompleteSelectedEvent) {
    this.oldSearchValue = '';
    this.searchInput.value = '';
    this.searchCtrl.setValue('');
    this.search = false;
    this.router.navigate(event.option.value.link);
  }

  toggleSearch() {
    if (!this.search) {
      this.enableSearch();
    } else {
      this.search = false;
    }
  }

  backToHome() {
    this.sessionService.showMatchEditorFilter = true;
    this.sessionService.showMatchEditorList = true;
    this.sessionService.treeObs.next('narrow');
    this.sessionService.menuObs.next('narrow');
    this.router.navigate(['/']);
  }

  enableSearch() {
    if (!this.search) {
      this.search = true;
      this.customerTreeService.fetchTreeMaybe();
      setTimeout(() => this.searchInput.focus());
    }
  }

  private cleanupRoutingName(name: string): string {
    return name.replace(/;.*/, '');
  }

  private subscribeToInputChanges() {
    this.searchCtrl.valueChanges.subscribe(searchValue => {
      if (!this.oldSearchValue || searchValue) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => this.searchService.updateResult(searchValue), 300);
      }
      this.oldSearchValue = searchValue;
    });
  }

  toggleWidth() {
    const nextState = this.sessionService.treeObs.value === 'wide' ? 'narrow' : 'wide';
    this.sessionService.treeObs.next(nextState);
    this.sessionService.menuObs.next(nextState);
  }

  duplicate() {
    window.open(location.origin, '_blank');
  }

  logoutUser() {
    this.userService.logout();
    this.logout.emit(true);
  }

}
