export enum BetTypeKey {
  NONE = -1,
  OTHER = 0,
  WAY_3 = 1,
  REST_OF_MATCH = 2,
  REST_OF_MATCH_H1 = 3,
  REST_OF_MATCH_OT = 4,
  UNDER_OVER = 5,
  UNDER_OVER_H1 = 6,
  NEXT_GOAL = 7,
  NEXT_GOAL_OT = 8,
  HANDICAP = 9,
  KICKOFF = 10,
  PENALTY_SHOOTOUT = 11,
  UNDER_OVER_OT = 12,
  DOUBLE_CHANCE = 13,
  DNB = 14,
  TENNIS_MATCH_WINNER = 15,
  TENNIS_SET_WINNER = 16,
  TENNIS_GAMES_WINNER = 17,
  TENNIS_RESULTS_BEST3 = 18,
  TENNIS_RESULTS_BEST5 = 19,
  TENNIS_SETS_COUNT_3 = 20,
  TENNIS_SETS_COUNT_5 = 21,
  WAY_3_H1 = 22,
  HT_FT = 23,
  CORRECT_SCORE = 24,
  GOAL_NOGOAL = 25,
  GOAL_HOMETEAM = 26,
  GOAL_AWAYTEAM = 27,
  WAY_3_OT = 28,
  WAY_2 = 29,
  PERIOD_WINNER = 30,
  RACE_X_POINTS = 31,
  SCORE_X_POINT = 32,
  TOTAL_GOALS = 33,
  TENNIS_UNDER_OVER = 34,
  NEXT_GOAL_H1 = 35,
  TENNIS_GAME_X_WINNER = 36,
  TENNIS_SET_UNDER_OVER = 37,
  CORRECT_SCORE_LIVE = 38,
  GOAL_ODDEVEN = 39,
  GOAL_NOGOAL_H1 = 40,
  FIRST_GOAL_INT_15 = 41,
  LAST_GOAL = 42,
  HIGHEST_SCORE_HALF = 43,
  WAY_3_H2 = 44,
  UNDER_OVER_H2 = 45,
  PLAYER_SENT_OFF = 46,
  CORNER_WAY3 = 47,
  CORNER_TOTAL = 48,
    /// <summary> Outright - Winner of Championship, etc.</summary>
  OUTRIGHT_WINNER = 49,
    /// <summary>
    /// Soccer - Matchbet and Total (Combination of way3 and over/under= 1u, Xu, 2u, 1o, Xo, 2o)
    /// </summary>
  WAY_3_UNDER_OVER = 50,
    /// <summary>
    /// Tennis= correct score of a set (6=0, 6=1, 6=2, 6=3, 6=4, 7=5, 7=6 ...)
    /// </summary>
  TENNIS_SET_CORRECT_SCORE = 51,
    /// <summary>
    /// Which team will advance to the next round (qualify) or win the final; "To Qualify" in fixtures feed
    /// </summary>
  ADVANCE_NEXT_ROUND = 52,
    /// <summary>Soccer - Correct Score H1 (Fixtures only)</summary>
  CORRECT_SCORE_H1 = 53,
    /// <summary>Soccer - Correct Score H1 (Live only)</summary>
  CORRECT_SCORE_LIVE_H1 = 54,
    /// <summary>Handicap 1st half (Soccer, IceHockey, B-Ball)</summary>
  HANDICAP_H1 = 55,
    /// <summary>Handicap 2nd half (Soccer, IceHockey, B-Ball)</summary>
  HANDICAP_H2 = 56,
    /// <summary>Doublechance 1st half (Soccer, IceHockey)</summary>
  DOUBLE_CHANCE_H1 = 57,
    /// <summary>Doublechance 2nd half (Soccer, IceHockey)</summary>
  DOUBLE_CHANCE_H2 = 58,
    /// <summary> Total goals (exact)</summary>
  TOTAL_GOALS_EXACT = 59,
    /// <summary> Total goals 1st half (exact)</summary>
  TOTAL_GOALS_EXACT_H1 = 60,
    /// <summary> Total goals 2nd half (exact)</summary>
  TOTAL_GOALS_EXACT_H2 = 61,
    /// <summary>Over/Under Hometeam (Soccer, IceHockey, B-Ball)</summary>
  UNDER_OVER_HOMETEAM = 62,
    /// <summary>Over/Under Awayteam (Soccer, IceHockey, B-Ball)</summary>
  UNDER_OVER_AWAYTEAM = 63,
    /// <summary>Over/Under Corner (Soccer)</summary>
  UNDER_OVER_CORNER = 64,
    /// <summary>Soccer - 2nd half Goal/NoGoal (Both teams to score)</summary>
  GOAL_NOGOAL_H2 = 65,
    /// <summary>Soccer - Matchbet and Both teams to score (Combination of Way3 and both team to score)</summary>
  WAY_3_GOAL_NOGOAL = 66,
    /// <summary>Soccer - Under/Over and Both teams to score (Combination of Under/Over and both team to score)</summary>
  UNDER_OVER_GOAL_NOGOAL = 67,
    /// <summary> Soccer - Way3 Winner Interval 10 minutes</summary>
  WAY_3_INT_10 = 68,
  /// <summary>Over/Under Hometeam H1(Soccer) uofid=69</summary>

  UNDER_OVER_HOMETEAM_H1 = 69,

  /// <summary>Over/Under Awayteam H1(Soccer) uofid=70</summary>
  UNDER_OVER_AWAYTEAM_H1 = 70,

  /// <summary>Soccer - Matchbet and Total H1 (Combination of way3 and over/under: 1u, Xu, 2u, 1o, Xo, 2o) uofif=79</summary>
  WAY_3_UNDER_OVER_H1 = 71,

  /// <summary>Soccer - Matchbet and Both teams to score H1 (Combination of Way3 and both team to score) uofif=78</summary>
  WAY_3_GOAL_NOGOAL_H1 = 72,

  /// <summary>Soccer - Doublechance and Total  uofid=547</summary>
  DOUBLE_CHANCE_UNDER_OVER = 73,

  /// <summary>Soccer - Doublechance and both teams to score  uofid=546</summary>
  DOUBLE_CHANCE_GOAL_NOGOAL = 74,

  /// <summary>Over/Under in xth Period/Quarter (Basketball uofid=236, IceHockey  uofid=446)</summary>
  UNDER_OVER_PERIOD = 75,

  /// <summary>xth goal in xth Period (IceHockey  uofid=444)</summary>
  XTH_GOAL_PERIOD = 76,

  /// <summary>which team wins the rest in xth Period (IceHockey  uofid=458)</summary>
  REST_OF_MATCH_PERIOD = 77,

  /// <summary> Which period has the most goals (IceHockey  uofid=432, 1s2, 2nd, 3rd, equal)</summary>
  HIGHEST_SCORE_PERIOD = 78,
}
