import {Component, OnInit} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {ResultsService} from '../../services/results.service';
import {getContextMenuItems, isNumber} from 'utils';
import {enrichByStationId, loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {StationUtils} from '../../utils/station-utils';
import {TreeNodeType} from '../../domain/tree-node-type';
import {GridReadyEvent} from 'ag-grid-community';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-results-pb1',
  templateUrl: './results-pb1.component.html',
  styleUrls: ['./results-pb1.component.scss']
})
export class ResultsPb1Component implements OnInit {

  static COLUMN_WIDTH_SMALL = 50;
  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;
  static COLUMN_WIDTH_LARGE_2 = 400;
  columnDefs;
  gridOptions;
  rowData;
  detailParams;

  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public resultsService: ResultsService,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public datePipe: DatePipe,
              public httpService: HttpService,
              public decimalPipe: DecimalPipe) {
    this.columnDefs = this.generateColumnDefs();
    this.detailParams = this.generateDetailParams();
    this.gridOptions = this.generateGridOptions();
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('resultsPb1', event);
  }

  ngOnInit() {
  }

  fetch(query: QueryFilter) {
    if (query) {
      this.query = query;
      this.rowData = this.resultsService.fetchPB1Stations(this.query);
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('results.pb1.type'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'type',
        cellClass: 'type',
        width: 23,
        cellRenderer: function (params) {
          const icon = StationUtils.getIcon(TreeNodeType.STATION, params.data.type, false, params.data.softLocked, params.data.hardLocked);
          const img = '<img src=\'/assets/img/icons/' + icon + '\'>';
          return params.data.stationID === 0 ? '' : img;
        }
      },
      {
        headerName: this.translationService.translate('results.pb1.stationID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationID',
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40
      },
      {
        headerName: this.translationService.translate('results.pb1.name'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'name',
        width: 300,
      }
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: true,
      detailRowHeight: 300,
      rowClassRules: {
        'bold': (params) => params.data.stationID === 0,
        'text-align-right': (params) => isNumber(params.data.value)
      },
      onCellClicked: (params) => {
        params.node.setExpanded(!params.node.expanded);
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }

  generateDetailParams() {
    return {
      detailGridOptions: {
        columnDefs: [
          {
            headerName: this.translationService.translate('results.pb1.startGameDate'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'startGameDate',
            valueFormatter: this.dateFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: ResultsPb1Component.COLUMN_WIDTH_LARGE
          },
          {
            headerName: this.translationService.translate('results.pb1.gameNumber'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'gameNumber',
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: ResultsPb1Component.COLUMN_WIDTH,
          },
          {
            headerName: this.translationService.translate('results.pb1.gameID'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'gameID',
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: ResultsPb1Component.COLUMN_WIDTH,
          },
          {
            headerName: this.translationService.translate('results.pb1.cards'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'cards',
            width: ResultsPb1Component.COLUMN_WIDTH_LARGE_2,
          },
          {
            headerName: this.translationService.translate('results.pb1.calculated'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'calculated',
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: ResultsPb1Component.COLUMN_WIDTH_SMALL,
            cellRenderer: this.checkboxGenerator.bind(this)
          }
        ],
        onCellClicked: (params) => {
          params.node.setExpanded(!params.node.expanded);
        }
      },
      getDetailRowData: (params) => {
        this.query.stationID = params.data.stationID;
        this.resultsService.fetchPB1Results(this.query).subscribe(data => {
          params.successCallback(enrichByStationId(data, params.data.stationID));
        });
      }
    };
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  checkboxGenerator(params) {
    const eDiv = document.createElement('div');
    eDiv.innerHTML = '<input type="checkbox" disabled="true" ' + (params.value ? 'checked="true"' : '') + '">';
    return eDiv;
  }

}
