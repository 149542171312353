import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
  selector: 'vit-text-area-dialog',
  templateUrl: './text-area-dialog.component.html',
  styleUrls: ['./text-area-dialog.component.scss']
})
export class TextAreaDialogComponent implements OnInit {

  text = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any = '',
              public matDialogRef: MatDialogRef<TextAreaDialogComponent>) {
    this.text = data;
  }

  ngOnInit(): void {
  }

}
