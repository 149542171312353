import {TreeNodeType} from './tree-node-type';
import {StationType} from './station-type';
import {Searchable} from './searchable';
import {SystemConfig} from '../config/system-config';
import {StationUtils} from '../utils/station-utils';

export class CustomerTreeNode implements Searchable {
  name: string;
  id: number;
  parentId: number;
  parentType: TreeNodeType;
  type: TreeNodeType;
  stationType: StationType;
  isOnline: boolean;
  isSoftLocked: boolean;
  isHardLocked: boolean;
  isTest: boolean;
  children: CustomerTreeNode[];
  hasChildren: boolean;

  get icon() {
    return StationUtils.getIcon(this.type, this.stationType, this.isTest, this.isSoftLocked, this.isHardLocked);
  }

  get label() {
    return this.name;
  }

  get link(): string[] {
    return [this.type === TreeNodeType.FRANCHISER ? SystemConfig.Routes.FRANCHISER : SystemConfig.Routes.STATION,
      this.parentId.toString(), this.id.toString()];
  }

  get keywords() {
    if (this.type === TreeNodeType.STATION) {
      return [this.name, this.id.toString()];
    } else {
      return [this.name];
    }
  }

  static mapCloned(json: CustomerTreeNode): CustomerTreeNode {
    const node: CustomerTreeNode = new CustomerTreeNode();
    node.name = json.name;
    node.id = json.id;
    node.parentId = json.parentId;
    node.parentType = json.parentType;
    node.type = json.type;
    node.stationType = json.stationType;
    node.isOnline = json.isOnline;
    node.isSoftLocked = json.isSoftLocked;
    node.isHardLocked = json.isHardLocked;
    node.isTest = json.isTest;
    node.children = json.children.length > 0 ? json.children.map(childrenJson => CustomerTreeNode.mapCloned(childrenJson)) : [];
    node.hasChildren = json.children.length > 0 || json.hasChildren;
    return node;
  }
}
