import {QuestionBase} from './question-base';

export class DateTimeQuestion extends QuestionBase<string> {
  controlType = 'date-time';
  hideTime: boolean;

  constructor(options: {} = {}) {
    super(options);
    this.hideTime = !!options['hideTime'];
  }
}

