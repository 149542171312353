import {Component, ElementRef, HostBinding, Input, OnChanges, QueryList, ViewChildren} from '@angular/core';
import {QuestionContainer} from '../domain/question-container';
import {MobileService} from '../services/mobile.service';
import {SessionService} from '../services/session.service';
import {DynamicFormComponent} from '../dynamic-form/dynamic-form.component';

@Component({
  selector: 'vit-cols',
  templateUrl: './cols.component.html',
  styleUrls: ['./cols.component.scss']
})
export class ColsComponent implements OnChanges {

  @Input()
  elements: QuestionContainer[] = [];
  @Input()
  singleRow = false;

  @ViewChildren(DynamicFormComponent)
  forms: QueryList<DynamicFormComponent>;

  amountOfCols;
  ts = 0;

  @HostBinding('style.height.px') height = 500;
  @HostBinding('style.flex') flexHeight = '0 0 500px';

  constructor(private mobileService: MobileService,
              private elementRef: ElementRef,
              private sessionService: SessionService) {
    this.ts = new Date().getTime();
    this.amountOfCols = this.mobileService.isMobile ? 1 : 2;
  }

  logTs(msg: string) {
    console.log('COLS: ' + msg + ' ' + (new Date().getTime() - this.ts) + 'ms since component start');
  }

  ngOnChanges(ch) {
    this.logTs('on changes');
    this.elements.forEach(e => {
      e.y = null;
      e.x = null;
      e.width = null;
      e.last = null;
    });

    this.logTs('pre timeout');
    setTimeout(() => {
      const forcedAmountOfCols = this.singleRow ? 1 : this.amountOfCols;
      this.height = 500;
      this.elements
        .filter(e => !e.isNotAuthorizeToView(this.sessionService))
        .forEach((el, idx, all) => {
          el.width = this.amountOfCols === 1 ? 'calc(100% - 16px)' : '450px';
          el.x = ((idx % forcedAmountOfCols) * 458) + 'px';
          if (idx >= forcedAmountOfCols) {
            setTimeout(() => {
              const aboveElement: HTMLElement = document.querySelector('#qc-' + all[idx - forcedAmountOfCols].id);
              const currentElement: HTMLElement = document.querySelector('#qc-' + el.id);
              el.y = (aboveElement.offsetTop + aboveElement.clientHeight) + 'px';
              this.height = Math.max(this.height, aboveElement.offsetTop + aboveElement.clientHeight + currentElement.clientHeight + 8);
              this.flexHeight = '0 0 ' + this.height + 'px';
              el.last = idx >= all.length - forcedAmountOfCols;
            });
          } else {
            setTimeout(() => {
              const currentElement: HTMLElement = document.querySelector('#qc-' + el.id);
              this.height = Math.max(this.height, currentElement.clientHeight + 8);
              this.flexHeight = '0 0 ' + this.height + 'px';
              el.last = idx >= all.length - forcedAmountOfCols;
            });
          }
        });
      this.logTs('elements updated');
    }, 50);
  }

  isNotAuthorizeToView(questionContainer: QuestionContainer): boolean {
    return (questionContainer.viewPermission && !this.sessionService.hasPermission(questionContainer.viewPermission))
      || questionContainer.questions.every(q => !this.sessionService.hasPermission(q.viewPermission));
  }
}
