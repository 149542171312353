import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'vit-ticket-detail-pokerbet',
  templateUrl: './ticket-detail-pokerbet.component.html',
  styleUrls: ['./ticket-detail-pokerbet.component.scss']
})
export class TicketDetailPokerbetComponent implements OnInit {

  private readonly cardStringLength = 3;
  private readonly amountOfCommunityCards = 5;
  private readonly amountOfCardsPerPlayer = 2;

  @Input()
  ticketData: any;
  ticket: any;

  communityCards: string[] = [];
  playerCards: string[][] = [];
  blackJackCards: string[][] = [];

  constructor() {
  }

  ngOnInit() {
    this.ticket = this.ticketData.ticket;

    if (this.ticket.applicationSubType === 1 || this.ticket.applicationSubType === 2) {
      this.setCommunityCards();
      this.setPlayerCards();
    } else if (this.ticket.applicationSubType === 3) {
      this.setBlackJackCards();
    }
  }

  private setBlackJackCards() {
    for (let gameIdx = 0; gameIdx < this.ticketData.games.length; gameIdx++) {
      this.blackJackCards[gameIdx] = [];
      this.blackJackCards[gameIdx][0] = this.ticketData.games[gameIdx].cards.substring(0, this.cardStringLength * 7 - 1);
      this.blackJackCards[gameIdx][1] = this.ticketData.games[gameIdx].cards.substring(this.cardStringLength * 7, this.cardStringLength * 13 - 1);
      this.blackJackCards[gameIdx][2] = this.ticketData.games[gameIdx].cards.substring(this.cardStringLength * 13);
    }
  }

  private setPlayerCards() {
    for (let gameIdx = 0; gameIdx < this.ticketData.games.length; gameIdx++) {
      this.playerCards[gameIdx] = [];
      const cards = this.ticketData.games[gameIdx].cards.split(',');

      for (let cardIdx = 0; cardIdx < cards.length; cardIdx++) {
        const playersCount = this.ticketData.games[gameIdx].playersCount;
        if (cardIdx < playersCount * this.amountOfCardsPerPlayer) {
          const playerIdx = Math.floor(cardIdx / this.amountOfCardsPerPlayer);
          if (this.playerCards[gameIdx][playerIdx] === undefined) {
            this.playerCards[gameIdx][playerIdx] = cards[cardIdx];
          } else {
            this.playerCards[gameIdx][playerIdx] += '\'' + cards[cardIdx];
          }
        }
      }
    }
  }

  private setCommunityCards() {
    this.communityCards = this.ticketData.games.map(g =>
      g.cards.substr(this.amountOfCardsPerPlayer * this.cardStringLength * g.playersCount,
        this.cardStringLength * this.amountOfCommunityCards - 1));
  }
}
