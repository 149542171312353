import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {QuestionBase} from '../../domain/question-base';
import {SessionService} from '../../services/session.service';
import {DropdownQuestion} from '../../domain/question-dropdown';
import {TimeRange} from '../custom-time-frame/custom-timeframe.component';
import {TimeFrameQuestion} from '../../domain/question-timeframe';
import {MatLegacySelect as MatSelect} from '@angular/material/legacy-select';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {copyToClipboard} from '../../../utils';
import {TranslationService} from '../../services/translation.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {TextAreaDialogComponent} from '../../text-area-dialog/text-area-dialog.component';

@Component({
  selector: 'vit-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormQuestionComponent implements OnInit {
  @Input() question: QuestionBase<any>;
  @Input() form: UntypedFormGroup;
  @Input() disabled: boolean;

  time: TimeRange;
  holdLimiterAddValue = 0;
  filteredSelectValues: { key: string, value: string, icon?: string }[] = [];
  clipboard;

  constructor(public sessionService: SessionService,
              public snackBar: MatSnackBar,
              public dialog: MatDialog,
              public cd: ChangeDetectorRef,
              public translationService: TranslationService) {
    this.clipboard = copyToClipboard.bind(this);
  }

  ngOnInit() {
    if (this.disabled || this.question.disabled || this.isNotAuthorizedToEdit()) {
      this.form.controls[this.question.key].disable();
    }
    if (this.question.controlType === 'dropdown') {
      this.filteredSelectValues = [];
      const options = (this.question as DropdownQuestion).options;
      if (options.length > 100) {
        setTimeout(() => {
          this.filteredSelectValues = options || [];
          this.cd.detectChanges();
        }, 200);
      } else {
        this.filteredSelectValues = options || [];
      }
    }
    if (this.question.controlType === 'time-frame') {
      this.time = {
        beginTime: (this.question as TimeFrameQuestion).from.getTime(),
        endTime: (this.question as TimeFrameQuestion).to.getTime()
      };
    }
  }

  trackByOptionId(index: number, option: any): number {
    return option.key;
  }

  skipAllButEnter(e: KeyboardEvent, selectRef: MatSelect) {
    if (e.code === 'Enter' && this.filteredSelectValues.length > 0) {
      this.form.controls[this.question.key].setValue(this.filteredSelectValues[0].key);
      this.question.onChange(this.form.getRawValue()[this.question.key]);
      selectRef.close();
    }
    e.stopPropagation();
  }

  isNotAuthorizedToEdit(): boolean {
    return this.disabled
      || (this.question.editPermission && !this.sessionService.hasPermission(this.question.editPermission))
      || (this.question.editPermissionsSome.length > 0 && !this.sessionService.hasSomePermissions(...this.question.editPermissionsSome));
  }

  isNotAuthorizedToView(): boolean {
    return this.question.viewPermission && !this.sessionService.hasPermission(this.question.viewPermission);
  }

  selectSearch(query: string) {
    this.filteredSelectValues = this.filterSelectItems(query);
  }

  filterSelectItems(query: string): { key: string, value: string }[] {
    const result: { key: string, value: string }[] = [];
    for (const el of (this.question as DropdownQuestion).options) {
      if (el.value.toLowerCase().indexOf(query.toLowerCase()) > -1 || el.key === '0') {
        result.push(el);
      }
    }
    return result;
  }

  showBigTextArea(key) {
    const dialogRef = this.dialog.open(TextAreaDialogComponent, {
      width: '60%',
      disableClose: true,
      data: this.form.controls[key].value
    });

    dialogRef.afterClosed().subscribe(res => this.form.controls[key].patchValue(res));
  }

  clear(ref: MatSelect, event: Event) {
    this.selectSearch('');
    this.form.controls[this.question.key].patchValue((ref.options && ref.options.length > 0)
      ? (this.question as DropdownQuestion).clearTo : null);
    this.question.onChange(this.form.getRawValue()[this.question.key]);
    event.stopPropagation();
    event.preventDefault();
  }

  selectOpenChange(open: boolean, el: any) {
    el.value = '';
    el.focus();
  }
}
