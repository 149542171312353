import {Component, OnInit} from '@angular/core';
import {SystemConfig} from '../../config/system-config';

@Component({
  selector: 'vit-virtual-mode-logout',
  templateUrl: './virtual-mode-logout.component.html',
  styleUrls: ['./virtual-mode-logout.component.scss']
})
export class VirtualModeLogoutComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    localStorage.removeItem(SystemConfig.LocalStorageConstants.VIRTUAL_USER);
    location.replace(location.origin);
  }

}
