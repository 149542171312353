import {Component, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'vit-new-tab',
  templateUrl: './new-tab.component.html',
  styleUrls: ['./new-tab.component.scss']
})
export class NewTabComponent implements OnInit, ICellRendererAngularComp {

  constructor() {
  }

  ngOnInit() {
  }

  open() {
  }

  // called on init
  agInit(params: any): void {
  }

  refresh(params: any): boolean {
    return false;
  }

}
