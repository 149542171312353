export class AccordionModel<T> {
    label: string;
    icon: string;
    content: T;

    constructor(label: string, content: T, icon?: string) {
        this.label = label;
        this.content = content;
        this.icon = icon;
    }
}
