import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GameNumber} from '../../domain/game-number';
import {TicketDetailService} from '../ticket-detail.service';
import {SessionService} from '../../services/session.service';
import {Permission} from '../../domain/permission';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';
import {TicketDetailLivesportsService} from '../../ticket-detail-livesports/ticket-detail-livesports.service';
import {TranslationService} from '../../services/translation.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'vit-refund-ticket',
  templateUrl: './refund-ticket.component.html',
  styleUrls: ['./refund-ticket.component.scss']
})
export class RefundTicketComponent implements OnInit {

  @Input()
  ticket: any;

  @Input()
  bet: any;

  @Input()
  gameNumer: GameNumber;

  @Output()
  update: EventEmitter<void> = new EventEmitter();

  constructor(private ticketDetailService: TicketDetailService,
              public translationService: TranslationService,
              public dialog: MatDialog,
              private sessionService: SessionService) {
  }

  ngOnInit() {
  }

  click() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        'message': this.translationService.translate('ticketDetail.livesports.refundMessage')
      }
    });

    dialogRef.afterClosed().subscribe(secureCode => {
      const confirmed = secureCode as boolean;
      if (confirmed) {
        this.ticketDetailService.refund(this.bet, this.ticket.number).subscribe(() => {
          this.ticket.won = true;
          this.update.emit();
        });
      }
    });
  }

  isAllowed() {
    return this.sessionService.hasPermission(Permission.stornoLB2Tickets)
      && !this.ticket.disbursed && !this.ticket.cashOut && !this.ticket.cancelled;
  }

}
