<div class="search-container">
  <mat-form-field>
    <input matInput
           [placeholder]="'ticketNumber.ticketNumber' | translate"
           (keyup.enter)="search()"
           [(ngModel)]="ticketNumber">
  </mat-form-field>
  <button [disabled]="ticketNumber?.length < 13"
          mat-flat-button color="accent" (click)="search()">{{'ticketNumber.search' | translate}}</button>
  <span class="error" *ngIf="error">{{'ticketNumber.error' | translate}}</span>
</div>
<mat-accordion [multi]="true">
  <mat-expansion-panel [expanded]="idx === 0"
                       *ngFor="let ticketData of sessionService.searchedTickets; let idx = index">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <button mat-icon-button (click)="remove(idx)"><mat-icon>close</mat-icon></button>
        {{'ticketDetail.ticketNumber' | translate}}: {{ticketData?.ticket?.number || 'CashTicket'}}
      </mat-panel-title>
      <mat-panel-description>
        {{ticketData?.ticket?.inDate | date: 'medium'}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <vit-ticket-detail
      [noFixedButtons]="true"
      [gameNumber]="+((''+(ticketData?.ticket?.number || '00000'))[4])"
      [ticketData]="ticketData"></vit-ticket-detail>
  </mat-expansion-panel>
</mat-accordion>
