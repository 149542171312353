import {Component, OnInit} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {StationUtils} from '../../utils/station-utils';
import {TreeNodeType} from '../../domain/tree-node-type';
import {getContextMenuItems, isNumber} from 'utils';
import {CashTicketOverviewService} from '../../services/cash-ticket-overview.service';
import {clone} from '../../utils/basic-utils';
import {Permission} from '../../domain/permission';
import {NotificationService} from '../../notification.service';
import {enrichByStationId, loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';
import {GridReadyEvent} from 'ag-grid-community';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-cash-ticket-overview',
  templateUrl: './cash-ticket-overview.component.html',
  styleUrls: ['./cash-ticket-overview.component.scss']
})
export class CashTicketOverviewComponent implements OnInit {

  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;
  columnDefs;
  gridOptions;
  rowData;
  detailParams;
  rowCount;

  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public cashTicketOverviewService: CashTicketOverviewService,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public datePipe: DatePipe,
              public notificationService: NotificationService,
              public httpService: HttpService,
              public decimalPipe: DecimalPipe,
              public dialog: MatDialog) {
    this.columnDefs = this.generateColumnDefs();
    this.detailParams = this.generateDetailParams();
    this.gridOptions = this.generateGridOptions();
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('cashTicketOverview', event);
  }

  ngOnInit() {
  }

  fetchCashbookStations(query: QueryFilter) {
    if (query) {
      this.query = query;
      this.rowData = this.cashTicketOverviewService.fetchCashTicketStations(query);
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('cashTicketOverview.stationType'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationType',
        cellClass: 'type',
        width: 23,
        cellRenderer: function (params) {
          const icon = StationUtils.getIcon(TreeNodeType.STATION, params.data.stationType, false, false, false);
          const img = '<img src=\'/assets/img/icons/' + icon + '\'>';
          return params.data.stationID === 0 ? '' : img;
        }
      },
      {
        headerName: this.translationService.translate('cashTicketOverview.stationID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationID',
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40,
        headerTooltip: this.translationService.translate('cashTicketOverview.stationID')
      },
      {
        headerName: this.translationService.translate('cashTicketOverview.stationName'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationName',
        width: CashTicketOverviewComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('cashTicketOverview.stationName')
      },
      {
        headerName: this.translationService.translate('cashTicketOverview.num'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'num',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CashTicketOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('cashTicketOverview.num')
      },
      {
        headerName: this.translationService.translate('cashTicketOverview.valueSum'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'valueSum',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CashTicketOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('cashTicketOverview.valueSum')
      }
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: true,
      rowClassRules: {
        'text-align-right': (params) => isNumber(params.data.value)
      },
      onCellClicked: (params) => {
        params.node.setExpanded(!params.node.expanded);
      }
    };
  }

  generateDetailParams() {
    return {
      detailGridOptions: {
        detailRowHeight: 300,
        rowClassRules: {
          'vit-red': (params) => !params.data.verified,
        },
        columnDefs: [
          {
            headerName: this.translationService.translate('cashTicketOverview.number'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'number',
            width: CashTicketOverviewComponent.COLUMN_WIDTH_LARGE,
            headerTooltip: this.translationService.translate('cashTicketOverview.number'),
            cellRenderer: (params) => {
              const eDiv = document.createElement('div');
              eDiv.innerHTML = params.data.number + ' <span class="material-icons" style="font-size: 1.2em;position:absolute;top:5px;">locked</span>';
              return params.data.verified ? params.data.number : eDiv;
            }
          },
          {
            headerName: this.translationService.translate('cashTicketOverview.value'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'value',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: CashTicketOverviewComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('cashTicketOverview.value')
          },
          {
            headerName: this.translationService.translate('cashTicketOverview.disburseTypeID'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'disburseTypeID',
            valueFormatter: this.typeGenerator.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: CashTicketOverviewComponent.COLUMN_WIDTH_LARGE,
            headerTooltip: this.translationService.translate('cashTicketOverview.disburseTypeID')
          },
          {
            headerName: this.translationService.translate('cashTicketOverview.inDate'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'inDate',
            valueFormatter: this.dateFormatter.bind(this),
            width: CashTicketOverviewComponent.COLUMN_WIDTH_LARGE,
            headerTooltip: this.translationService.translate('cashTicketOverview.inDate')
          },
          {
            headerName: this.translationService.translate('cashTicketOverview.outDate'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'outDate',
            valueFormatter: this.dateFormatter.bind(this),
            width: CashTicketOverviewComponent.COLUMN_WIDTH_LARGE,
            headerTooltip: this.translationService.translate('cashTicketOverview.outDate')
          },
          {
            headerName: this.translationService.translate('cashTicketOverview.outStationID'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'outStationID',
            width: CashTicketOverviewComponent.COLUMN_WIDTH_LARGE,
            headerTooltip: this.translationService.translate('cashTicketOverview.outStationID')
          },
          {
            headerName: this.translationService.translate('cashTicketOverview.voucherCode'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'voucherCode',
            cellClass: 'text-align-right',
            width: CashTicketOverviewComponent.COLUMN_WIDTH_LARGE,
            headerTooltip: this.translationService.translate('cashTicketOverview.voucherCode')
          },
          this.sessionService.hasPermission(Permission.stationOwnerPin) ? {
            headerName: this.translationService.translate('cashTicketOverview.redeemManually'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'number',
            width: CashTicketOverviewComponent.COLUMN_WIDTH_LARGE,
            cellRenderer: (params) => {
              const eDiv = document.createElement('div');
              eDiv.innerHTML = '<span class="my-css-class"><button mat-stroked-button class="btn-redeem-manually">'
                + this.translationService.translate('cashTicketOverview.redeemManuallyBtn') + '</button></span>';
              const eButton = eDiv.querySelectorAll('.btn-redeem-manually')[0];
              eButton.addEventListener('click', () => this.redeemManually(params));
              const isAllowed = params.data.disburseTypeID === 1 && params.data.outDate === null;
              return isAllowed ? eDiv : '';
            }
          } : null,
          this.sessionService.hasPermission(Permission.stationOwnerPin) ? {
            headerName: this.translationService.translate('cashTicketOverview.redeemAndPayinManually'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'number',
            width: 180,
            cellRenderer: (params) => {
              const eDiv = document.createElement('div');
              eDiv.innerHTML = '<span class="my-css-class"><button mat-stroked-button class="btn-redeem-manually">'
                + this.translationService.translate('cashTicketOverview.redeemAndPayinManuallyBtn') + '</button></span>';
              const eButton = eDiv.querySelectorAll('.btn-redeem-manually')[0];
              eButton.addEventListener('click', () => this.redeemAndPayinManually(params));
              const isAllowed = params.data.disburseTypeID === 1 && params.data.outDate === null;
              return isAllowed ? eDiv : '';
            }
          } : null,
          this.sessionService.hasPermission(Permission.editAdvancedAdminSettings) ? {
            headerName: this.translationService.translate('cashTicketOverview.lockPayoutManually'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'number',
            width: 180,
            cellRenderer: (params) => {
              const eDiv = document.createElement('div');
              eDiv.innerHTML = '<span class="my-css-class"><button mat-stroked-button class="btn-lock-manually">'
                + this.translationService.translate('cashTicketOverview.' + (params.data.verified ? '' : 'un') + 'lockPayoutManuallyBtn') + '</button></span>';
              const eButton = eDiv.querySelectorAll('.btn-lock-manually')[0];
              eButton.addEventListener('click', () => this.unOrLockPayoutManually(params));
              const isAllowed = params.data.outDate === null;
              return isAllowed ? eDiv : '';
            }
          } : null,
          {
            headerName: this.translationService.translate('cashTicketOverview.userID'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'userID',
            hide: true,
            width: CashTicketOverviewComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('cashTicketOverview.userID')
          },
          {
            headerName: this.translationService.translate('cashTicketOverview.username'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'username',
            hide: true,
            width: CashTicketOverviewComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('cashTicketOverview.username')
          }
        ].filter(el => !!el),
        onCellClicked: (params) => {
          params.node.setExpanded(!params.node.expanded);
        },
        sideBar: {
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnExpandAll: true,
              },
            },
          ],
          defaultToolPanel: '',
        }
      },
      getDetailRowData: (params) => {
        const query: QueryFilter = clone(this.query);
        query.stationID = params.data.stationID;
        this.cashTicketOverviewService.fetchCashTickets(query).subscribe(data => {
          params.successCallback(enrichByStationId(data, params.data.stationID));
        });
      }
    };
  }

  redeemManually(params) {
    this.dialog.open(ConfirmDialogComponent).afterClosed().subscribe(result => {
      if (result) {
        const query = clone(this.query) as QueryFilter;
        query.ticketNumber = params.value;
        this.cashTicketOverviewService.redeemManually(query).subscribe(res => {
          this.fetchCashbookStations(this.query);
          this.notificationService.showSaved();
        }, e => {
          this.fetchCashbookStations(this.query);
          this.notificationService.showError();
        });
      }
    });
  }

  redeemAndPayinManually(params) {
    this.dialog.open(ConfirmDialogComponent).afterClosed().subscribe(result => {
      if (result) {
        const query = clone(this.query) as QueryFilter;
        query.ticketNumber = params.value;
        this.cashTicketOverviewService.redeemAndPayinManually(query).subscribe(res => {
          this.fetchCashbookStations(this.query);
          this.notificationService.showSaved();
        }, e => {
          this.fetchCashbookStations(this.query);
          this.notificationService.showError();
        });
      }
    });
  }

  unOrLockPayoutManually(params) {
    this.dialog.open(ConfirmDialogComponent).afterClosed().subscribe(result => {
      if (result) {
        const query = clone(this.query) as QueryFilter;
        query.ticketNumber = params.value;
        this.cashTicketOverviewService.lockPayoutManually(query).subscribe(res => {
          this.fetchCashbookStations(this.query);
          this.notificationService.showSaved();
        }, e => {
          this.fetchCashbookStations(this.query);
          this.notificationService.showError();
        });
      }
    });
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  typeGenerator(x: any) {
    let text = '';
    if (x.value === 1) {
      if (x.data.outDate != null) {
        text = this.translationService.translate('cashTicketOverview.cashTicket');
      } else {
        text = this.translationService.translate('cashTicketOverview.cashTicketOpen');
      }
    } else if (x.data.voucherCode != null && x.data.userAccountID != null) {
      text = this.translationService.translateArgs('cashTicketOverview.voucherUserAccountId', x.data.userAccountID);
    } else if (x.data.voucherCode != null && x.data.userAccountID == null) {
      text = this.translationService.translate('cashTicketOverview.voucher');
    } else {
      text = 'unknown';
    }
    return text;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }

}
