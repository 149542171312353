import {Component, OnDestroy, OnInit} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {StationUtils} from '../../utils/station-utils';
import {TreeNodeType} from '../../domain/tree-node-type';
import {getContextMenuItems, isNumber} from 'utils';
import {BalanceLivesportsService} from './balance-livesports.service';
import {enrichByStationId, loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {GridApi, GridReadyEvent} from 'ag-grid-community';
import {map} from 'rxjs/operators';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-balance-livesports',
  templateUrl: './balance-livesports.component.html',
  styleUrls: ['./balance-livesports.component.scss']
})
export class BalanceLivesportsComponent implements OnInit, OnDestroy {

  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;

  columnDefs;
  gridOptions;
  rowData;
  titleRow;
  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public balanceService: BalanceLivesportsService,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public datePipe: DatePipe,
              public httpService: HttpService,
              public decimalPipe: DecimalPipe) {
    this.columnDefs = this.generateColumnDefs();
    this.gridOptions = this.generateGridOptions();
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('balance', event);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  fetch(query: QueryFilter) {
    if (query) {
      this.query = query;
      this.rowData = this.balanceService.fetch(query).pipe(
        map(data => data.filter(d => {
          const isTitleRow = d.stationID === 0;
          if (isTitleRow) {
            this.titleRow = [d];
          }
          return !isTitleRow;
        }))
      );
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('balanceLivesports.type'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'type',
        cellClass: 'type',
        width: 23,
        cellRenderer: function (params) {
          const icon = StationUtils.getIcon(TreeNodeType.STATION, params.data.type, false, params.data.softLocked, params.data.hardLocked);
          const img = '<img src=\'/assets/img/icons/' + icon + '\'>';
          return params.data.stationID === 0 ? '' : img;
        }
      },
      {
        headerName: this.translationService.translate('balanceLivesports.stationID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationID',
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40,
        headerTooltip: this.translationService.translate('balanceLivesports.stationID')
      },
      {
        headerName: this.translationService.translate('balanceLivesports.stationName'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationName',
        width: BalanceLivesportsComponent.COLUMN_WIDTH_LARGE,
      },
      {
        headerName: this.translationService.translate('balanceLivesports.identifier'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'identifier',
        hide: true,
        width: BalanceLivesportsComponent.COLUMN_WIDTH_LARGE,
      },
      {
        headerName: this.translationService.translate('balanceLivesports.currencyCode'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'currencyCode',
        width: BalanceLivesportsComponent.COLUMN_WIDTH,
      },
      {
        headerName: this.translationService.translate('balanceLivesports.qtyLive'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'qtyLive',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceLivesportsComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balanceLivesports.qtyLive')
      },
      {
        headerName: this.translationService.translate('balanceLivesports.stakeNetLive'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stakeNetLive',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceLivesportsComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balanceLivesports.stakeNetLive')
      },
      {
        headerName: this.translationService.translate('balanceLivesports.profitGrossLive'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'profitGrossLive',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceLivesportsComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balanceLivesports.profitGrossLive')
      },
      {
        headerName: this.translationService.translate('balanceLivesports.taxLive'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'taxLive',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceLivesportsComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balanceLivesports.taxLive')
      },
      {
        headerName: this.translationService.translate('balanceLivesports.winTaxLive'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'winTaxLive',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        hide: true,
        width: BalanceLivesportsComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balanceLivesports.winTaxLive')
      },
      {
        headerName: this.translationService.translate('balanceLivesports.balanceLive'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'balanceLive',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceLivesportsComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balanceLivesports.balanceLive')
      }


      ,
      {
        headerName: this.translationService.translate('balanceLivesports.qtyPrematch'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'qtyPrematch',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceLivesportsComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balanceLivesports.qtyPrematch')
      },
      {
        headerName: this.translationService.translate('balanceLivesports.stakeNetPrematch'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stakeNetPrematch',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceLivesportsComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balanceLivesports.stakeNetPrematch')
      },
      {
        headerName: this.translationService.translate('balanceLivesports.profitGrossPrematch'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'profitGrossPrematch',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceLivesportsComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balanceLivesports.profitGrossPrematch')
      },
      {
        headerName: this.translationService.translate('balanceLivesports.taxPrematch'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'taxPrematch',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceLivesportsComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balanceLivesports.taxPrematch')
      },
      {
        headerName: this.translationService.translate('balanceLivesports.winTaxPrematch'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'winTaxPrematch',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        hide: true,
        width: BalanceLivesportsComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balanceLivesports.winTaxPrematch')
      },
      {
        headerName: this.translationService.translate('balanceLivesports.balancePrematch'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'balancePrematch',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: BalanceLivesportsComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balanceLivesports.balancePrematch')
      }
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: false,
      isRowMaster: (data) => data.stationID !== 0,
      rowClassRules: {
        'bold': (params) => params.data.stationID === 0,
        'text-align-right': (params) => isNumber(params.data.value)
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }

}
