import { QuestionBase } from './question-base';

export class SliderQuestion extends QuestionBase<string> {
    controlType = 'slider';
    min: number;
    max: number;
    stepSize: number;
    tickInterval: number | string;
    thumbLabel: boolean;

    constructor(options: {} = {}) {
        super(options);
        this.min = options['min'] || 0;
        this.max = options['max'] || 100;
        this.stepSize = options['stepSize'] || 1;
        this.tickInterval = options['tickInterval'] || 'auto';
        this.thumbLabel = options['thumbLabel'] || true;
    }
}
