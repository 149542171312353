import {Component, HostListener, Inject, Input, OnInit, Optional} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {TranslationService} from '../services/translation.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {GameNumber} from '../domain/game-number';
import {HoldOverviewService} from '../services/hold-overview.service';
import {TicketDetailComponent} from '../ticket-detail/ticket-detail.component';
import {loadColumnVisibility, storeColumnVisibility} from '../../utils';
import {GridApi, GridReadyEvent, SideBarDef, StatusPanelDef} from 'ag-grid-community';
import {MEObject} from '../live-admin/match-editor/match-detail/MEObject';
import {TicketsDataRow} from '../domain/tickets-data-row';
import {BetTypeKeyMapper} from '../ticket-detail-livesports/livesports-bettype-mapper.service';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatchEditorService} from '../live-admin/match-editor/match-editor.service';
import {SessionService} from '../services/session.service';
import {Permission} from '../domain/permission';

@Component({
  selector: 'vit-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss']
})
export class TicketListComponent implements OnInit {

  static COLUMN_WIDTH = 70;
  static COLUMN_WIDTH_LARGE = 140;

  @Input() data: any;
  @Input() selectedEvent: MEObject;
  @Input() isForMatchEditor = false;
  @Input() onlyOpen = false;
  @Input() allTickets = false;
  @Input() matchStartTs: Date;

  storeColumnVisibilityFn = storeColumnVisibility;

  status: any;
  lastID?: number;
  rowData = [];
  columnDefs;
  frameworkComponents;
  ALL_LABEL: string;
  sidebar: SideBarDef;
  excelStyles = [
    {
      id: 'stringType',
      dataType: 'String',
    }
  ];

  statusBar: { statusPanels: StatusPanelDef[] } = {
    statusPanels: [
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
      },
    ],
  };

  titleRow = [];
  settlementDelayMinutes = 180;
  matchStartMinutesAgo = 0;

  // filters
  allFilterEnabled = true;
  dateFilterEnabled = false;
  winableFilterEnabled = false;
  edgeFilterEnabled = false;
  wonFilterEnabled = false;
  lostFilterEnabled = false;
  paidOutFilterEnabled = false;
  notPaidOutFilterEnabled = false;
  cashoutFilterEnabled = false;
  lockedFilterEnabled = false;
  stornoFilterEnabled = false;
  notDecidedFilterEnabled = false;
  singleBetFilterEnabled = false;
  uaidFilterEnabled = false;
  cidFilterEnabled = false;
  btFilterEnabled = false;
  btExactFilterEnabled = false;
  additionalFiltersEnabled = false;
  minStake = 0;
  minProfit = 0;
  minDate = null;
  maxDate = null;
  api: GridApi;
  enhancedTicketList = false;

  rowClassRules = {
    'bold': (params) => params.data.number === this.ALL_LABEL,
    'on-the-edge': (params) => params.data.isOnTheEdge
  };

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  constructor(@Optional() public dialogRef: MatDialogRef<TicketListComponent>,
              public translationService: TranslationService,
              public holdOverviewService: HoldOverviewService,
              public datePipe: DatePipe,
              public betTypeKeyMapper: BetTypeKeyMapper,
              public sessionService: SessionService,
              public matDialog: MatDialog,
              public decimalPipe: DecimalPipe,
              @Optional() @Inject(MAT_DIALOG_DATA) data: any) {
    this.data = data;
    this.matchStartTs = data?.matchStartTs;
    this.enhancedTicketList = !!this.data?.enhancedTicketList;
    this.sidebar = this.generateSideBarDef();
    this.frameworkComponents = {ticketDetailCellRenderer: TicketDetailComponent};
    this.ALL_LABEL = this.translationService.translate('tickets.sum');
  }

  onFilterChanged() {
    this.titleRow = this.titleRow.map(row => ({
      ...row,
      'numOpenBets': 0,
      'stake': 0.0,
      'profit': 0.0,
      'maxProfit': 0.0,
      'profitTotalGross': 0.0,
      'stakeTax': 0.0,
      'winTax': 0.0
    }));
    this.api.forEachNodeAfterFilter((node) => {
      this.titleRow[0].numOpenBets += node.data.numOpenBets;
      this.titleRow[0].stake += node.data.stake;
      this.titleRow[0].profit += node.data.profit;
      this.titleRow[0].maxProfit += node.data.maxProfit;
      this.titleRow[0].profitTotalGross += node.data.profitTotalGross;
      this.titleRow[0].stakeTax += node.data.stakeTax;
      this.titleRow[0].winTax += node.data.winTax;
    });
  }

  ngOnInit() {
    console.log('this.data', this.data);
    this.columnDefs = this.generateColumnDefs();
    const element = document.querySelector('vit-ticket-list .ag-body-viewport');
    const pos = element ? element.scrollTop : 0;
    // calculate minutes between this.matchStartTsAndNow
    this.matchStartMinutesAgo = (new Date().getTime() - new Date(this.matchStartTs).getTime()) / (1000 * 60 * 60);

    if (this.enhancedTicketList) {
      this.holdOverviewService.fetchEnhancedTicketList(this.data.query).subscribe(data => {
        this.init(data, pos);
      });
    } else if (this.selectedEvent || this.enhancedTicketList) {
      this.holdOverviewService.fetchTicketListForMatchEditor({
        lBMatchID: this.selectedEvent.match.lB_MatchID,
        brMatchID: this.selectedEvent.match.brMatchID,
        settlementDelayMinutes: this.onlyOpen ? this.settlementDelayMinutes : undefined
      }, this.allTickets).subscribe(data => {
        this.init(data, pos);
      });
    } else {
      this.holdOverviewService.fetchTicketList(this.data.query, this.data.gameNumber as GameNumber, this.lastID)
        .subscribe(data => this.init(data, pos));
    }
  }

  showInfoWarning() {
    this.matDialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Information',
        message: this.translationService.translate('matchEditor.detail.settlementDelayInfo'),
        yes: 'OK',
        hideNo: true
      }
    });
  }

  private init(data: { tickets: TicketsDataRow[] }, pos: number) {
    console.log('data.tickets', data.tickets);
    this.status = data;
    this.lastID = data.tickets?.length > 0 ? data.tickets[data.tickets.length - 1].number : undefined;
    this.rowData = [...this.rowData, ...(data.tickets ? data.tickets.filter(el => {
      const isTitleRow = el.number === -1;
      if (isTitleRow && this.sessionService.hasPermission(Permission.seeHoldOverview)) {
        el.number = this.ALL_LABEL as any;
        el.state = null;
        this.titleRow = [el];
      }
      el.queryData = this.data?.query;
      return !isTitleRow;
    }) : [])].filter(el => !!el);
    // TODO: filter onlyOpen
    setTimeout(() => {
      const element1 = document.querySelector('vit-ticket-list .ag-body-viewport');
      console.log(element1, pos);
      element1.scrollTop = pos;
    }, 500);
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('ticketList-' + this.isForMatchEditor, event);
    this.api = event.api;
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('tickets.number'),
        cellRenderer: 'agGroupCellRenderer',
        field: 'number',
        cellDataType: false,
        cellClass: 'stringType',
        sortable: true,
        filter: true,
        resizable: true,
        width: TicketListComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('tickets.number')
      },
      {
        headerName: this.translationService.translate('tickets.date'),
        field: 'date',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.dateFormatter.bind(this),
        width: TicketListComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('tickets.date')
      },
      {
        headerName: this.translationService.translate('tickets.eventID'),
        field: 'eventID',
        sortable: true,
        filter: true,
        resizable: true,
        width: TicketListComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('tickets.eventID')
      },
      {
        headerName: this.translationService.translate('tickets.stake'),
        field: 'stake',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.decimalFormatter.bind(this),
        width: TicketListComponent.COLUMN_WIDTH,
        cellClass: 'text-align-right',
        headerTooltip: this.translationService.translate('tickets.stake')
      },
      (this.isForMatchEditor ? null : {
        headerName: this.translationService.translate('tickets.profit'),
        field: 'profit',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.decimalFormatter.bind(this),
        width: TicketListComponent.COLUMN_WIDTH,
        cellClass: 'text-align-right',
        headerTooltip: this.translationService.translate('tickets.profit')
      }),
      (this.isForMatchEditor ? {
        headerName: this.translationService.translate('tickets.maxProfit'),
        field: 'maxProfit',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.decimalFormatter.bind(this),
        width: TicketListComponent.COLUMN_WIDTH,
        cellClass: 'text-align-right',
        headerTooltip: this.translationService.translate('tickets.maxProfit')
      } : null),
      (this.isForMatchEditor ? {
        headerName: this.translationService.translate('tickets.betType'),
        field: 'origin',
        sortable: true,
        filter: true,
        resizable: true,
        valueGetter: (params: any) => this.betTypeKeyMapper.getText(params.data?.openBTKey, params.data?.openBTSport, params.data?.openBTSOV),
        width: TicketListComponent.COLUMN_WIDTH * 2.5,
        headerTooltip: this.translationService.translate('tickets.betType')
      } : null),
      (this.isForMatchEditor ? {
        headerName: this.translationService.translate('ticketDetail.ticketOrigin'),
        field: 'origin',
        sortable: true,
        filter: true,
        resizable: true,
        valueGetter: (params: any) => this.translationService.translate('ticketDetail.ticketOrigin' + params.data?.origin),
        width: TicketListComponent.COLUMN_WIDTH * 1.3,
        headerTooltip: this.translationService.translate('ticketDetail.origin')
      } : null),
      {
        headerName: this.translationService.translate('tickets.state'),
        field: 'state',
        sortable: true,
        filter: true,
        resizable: true,
        width: TicketListComponent.COLUMN_WIDTH,
        cellClass: 'state align-center',
        headerTooltip: this.translationService.translate('tickets.state'),
        cellClassRules: {
          'state': 'true',
          'state-storno': 'x === "storno"',
          'state-won': 'x === "won"',
          'state-lost': 'x === "lost"',
          'state-disbursed': 'x === "disbursed"',
          'state-pending': 'x === "pending"',
          'state-pending-1': 'x === "pending-1"',
          'state-pending-2': 'x === "pending-2"',
          'state-cashout': 'x === "cashout"',
        },
        tooltip: (params) => this.translationService.translate('tickets.states.' + params.value),
        cellRenderer: (params) => {
          const img = '<img  style="height: 15px; right: 2px; top: 4px; position: absolute;" src=\'/assets/img/material-icons/skull_black.png\'>';
          return '<div class="box" style="width: 15px; height: 15px"></div>' + (params.data.isUncloseable ? img : '');
        }
      }, (!!this.selectedEvent ?
        {
          headerName: this.translationService.translate('tickets.numOpenBets'),
          field: 'numOpenBets',
          sortable: true,
          filter: true,
          resizable: true,
          width: TicketListComponent.COLUMN_WIDTH * 1.5,
          cellClass: 'text-align-right',
          headerTooltip: this.translationService.translate('tickets.numOpenBets')
        } : null),
    ].filter(el => !!el);
  }

  generateSideBarDef(): SideBarDef {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnExpandAll: true,
          },
        },
      ],
      defaultToolPanel: '',
    };
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  isExternalFilterPresent = () => true;

  doesExternalFilterPass = (params) => {
    return this.allFilterEnabled || (
      (!this.winableFilterEnabled || (params.data.preCalcStatus !== 2 && !params.data.cashOut && !params.data.calculated)) &&
      (!this.edgeFilterEnabled || params.data.isOnTheEdge) &&
      (!this.wonFilterEnabled || params.data.won || params.data.cashOut) &&
      (!this.lostFilterEnabled || (params.data.calculated && !params.data.won && !params.data.cashOut) || (params.data.preCalcStatus === 2)) &&
      (!this.paidOutFilterEnabled || params.data.disbursed) &&
      (!this.notPaidOutFilterEnabled || (params.data.won && !params.data.disbursed)) &&
      (!this.cashoutFilterEnabled || params.data.cashOut) &&
      (!this.stornoFilterEnabled || params.data.matchBetCancelled) &&
      (!this.lockedFilterEnabled || params.data.locked) &&
      (!this.notDecidedFilterEnabled || (!params.data.calculated && params.data.preCalcStatus !== 2)) &&
      (!this.singleBetFilterEnabled || params.data.eventID === 1) &&
      (!this.uaidFilterEnabled || params.data.uaid === this.data.uaid) &&
      (!this.cidFilterEnabled || params.data.cid === this.data.cid) &&
      (!this.minStake || params.data.stake >= +this.minStake) &&
      (!this.minProfit || params.data.profit >= +this.minProfit) &&
      (!this.btFilterEnabled || params.data.btKeys.indexOf(this.data.btKey) > -1) &&
      (!this.btExactFilterEnabled || params.data.btKeys.indexOf(this.data.btKey) > -1 && params.data.btSovs.indexOf(this.data.btSov) > -1) &&
      (!this.dateFilterEnabled || !this.minDate || new Date(params.data.date).getTime() >= this.minDate) &&
      (!this.dateFilterEnabled || !this.maxDate || new Date(params.data.date).getTime() <= this.maxDate)
    );
  };

  toggleAdditionalFilters() {
    this.additionalFiltersEnabled = !this.additionalFiltersEnabled;
  }

  toggleDateFilter() {
    const twoHours = 1000 * 60 * 60 * 2;
    this.dateFilterEnabled = !this.dateFilterEnabled;
    this.minDate = this.dateFilterEnabled ? new Date(this.matchStartTs).getTime() : null;
    this.maxDate = this.dateFilterEnabled ? this.minDate + twoHours : null;
    this.api?.onFilterChanged();
  }

  toggleAll() {
    this.allFilterEnabled = !this.allFilterEnabled;
    if (this.allFilterEnabled) {
      this.winableFilterEnabled = false;
      this.edgeFilterEnabled = false;
      this.wonFilterEnabled = false;
      this.lostFilterEnabled = false;
      this.paidOutFilterEnabled = false;
      this.notPaidOutFilterEnabled = false;
      this.cashoutFilterEnabled = false;
      this.lockedFilterEnabled = false;
      this.stornoFilterEnabled = false;
      this.notDecidedFilterEnabled = false;
      this.singleBetFilterEnabled = false;
      this.uaidFilterEnabled = false;
      this.cidFilterEnabled = false;
      this.minStake = 0;
      this.minProfit = 0;
      this.dateFilterEnabled = false;
      this.btFilterEnabled = false;
      this.btExactFilterEnabled = false;
      this.minDate = null;
      this.maxDate = null;
    }
    this.api?.onFilterChanged();
  }

  toggleWinnable() {
    this.winableFilterEnabled = !this.winableFilterEnabled;
    this.allFilterEnabled = false;
    this.api.onFilterChanged();
  }

  toggleEdge() {
    this.edgeFilterEnabled = !this.edgeFilterEnabled;
    this.allFilterEnabled = false;
    this.api.onFilterChanged();
  }

  toggleWon() {
    this.wonFilterEnabled = !this.wonFilterEnabled;
    this.allFilterEnabled = false;
    this.api.onFilterChanged();
  }

  toggleLost() {
    this.lostFilterEnabled = !this.lostFilterEnabled;
    this.allFilterEnabled = false;
    this.api.onFilterChanged();
  }

  togglePaidOut() {
    this.paidOutFilterEnabled = !this.paidOutFilterEnabled;
    this.allFilterEnabled = false;
    this.api.onFilterChanged();
  }

  toggleNotPaidOut() {
    this.notPaidOutFilterEnabled = !this.notPaidOutFilterEnabled;
    this.allFilterEnabled = false;
    this.api.onFilterChanged();
  }

  toggleCashout() {
    this.cashoutFilterEnabled = !this.cashoutFilterEnabled;
    this.allFilterEnabled = false;
    this.api.onFilterChanged();
  }

  toggleLocked() {
    this.lockedFilterEnabled = !this.lockedFilterEnabled;
    this.allFilterEnabled = false;
    this.api.onFilterChanged();
  }

  toggleStorno() {
    this.stornoFilterEnabled = !this.stornoFilterEnabled;
    this.allFilterEnabled = false;
    this.api.onFilterChanged();
  }

  toggleNotDecided() {
    this.notDecidedFilterEnabled = !this.notDecidedFilterEnabled;
    this.allFilterEnabled = false;
    this.api.onFilterChanged();
  }

  toggleSingleBet() {
    this.singleBetFilterEnabled = !this.singleBetFilterEnabled;
    this.allFilterEnabled = false;
    this.api.onFilterChanged();
  }

  toggleUAID() {
    this.uaidFilterEnabled = !this.uaidFilterEnabled;
    this.allFilterEnabled = false;
    this.api.onFilterChanged();
  }

  toggleCID() {
    this.cidFilterEnabled = !this.cidFilterEnabled;
    this.allFilterEnabled = false;
    this.api.onFilterChanged();
  }

  toggleBT() {
    this.btFilterEnabled = !this.btFilterEnabled;
    this.allFilterEnabled = false;
    this.api.onFilterChanged();
  }

  toggleBTExact() {
    this.btExactFilterEnabled = !this.btExactFilterEnabled;
    this.allFilterEnabled = false;
    this.api.onFilterChanged();
  }

  getBtLabel() {
    const label = this.betTypeKeyMapper.getText(this.data.btKey, this.data.brSportID,
      MatchEditorService.DONT_MAP_SOV_FOR_BT.includes(this.data.btKey) ? undefined : this.data.btSov);
    return label.length < 20 ? label : label.substring(0, 20) + '...';
  }
}
