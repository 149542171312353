import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GameNumber} from '../../domain/game-number';
import {TicketDetailService} from '../ticket-detail.service';
import {SessionService} from '../../services/session.service';
import {Permission} from '../../domain/permission';

@Component({
  selector: 'vit-reset-ticket-open',
  templateUrl: './reset-ticket-open.component.html',
  styleUrls: ['./reset-ticket-open.component.scss']
})
export class ResetTicketOpenComponent implements OnInit {

  @Input()
  ticket: any;

  @Input()
  gameNumer: GameNumber;

  @Output()
  update: EventEmitter<void> = new EventEmitter();

  constructor(private ticketDetailService: TicketDetailService,
              private sessionService: SessionService) {
  }

  ngOnInit() {
  }

  click() {
    this.ticket.calculated = false;
    this.ticketDetailService.update(this.ticket, this.gameNumer).subscribe(() => {
      this.update.emit();
    });
  }

  isAllowed() {
    return this.sessionService.hasPermission(Permission.editAdvancedAdminSettings) && this.ticket.calculated && !this.ticket.disbursed;
  }

}
