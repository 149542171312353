
import {throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {SessionService} from './session.service';
import {catchError, tap} from 'rxjs/operators';
import {SystemConfig} from '../config/system-config';
import {Router} from '@angular/router';
import {Observable} from 'rxjs/index';
import {NotificationService} from '../notification.service';
import {ERROR_SNACK_CONFIG} from '../../utils';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {TranslationService} from './translation.service';


@Injectable()
export class HttpService {

  private token: string;

  constructor(public sessionService: SessionService,
              public http: HttpClient,
              public router: Router,
              public snackBar: MatSnackBar,
              public translationService: TranslationService,
              public notificationService: NotificationService) {
    this.token = localStorage.getItem(SystemConfig.LocalStorageConstants.AUTH_TOKEN);
  }

  post(url: string, body?: any, skipAuthError?: boolean): Observable<any> {
    console.log('[post] ' + url, body);
    this.sessionService.loading = true;
    return this.http.post(url, body, this.requestOptions)
      .pipe(
        tap((data) => this.handleData(data)),
        catchError((error) => this.handleError(error, false, skipAuthError))
      );
  }

  get(url: string, skipLoading = false): Observable<any> {
    console.log('[get] ' + url);
    if (!skipLoading) {
      this.sessionService.loading = true;
    }
    return this.http.get(url, this.requestOptions)
      .pipe(
        tap((data) => this.handleData(data, skipLoading)),
        catchError((error: HttpErrorResponse) => this.handleError(error, skipLoading))
      );
  }

  getText(url: string): Observable<any> {
    console.log('[get] ' + url);
    this.sessionService.loading = true;
    const options = this.requestOptions;
    options.responseType = 'text';
    return this.http.get(url, options)
      .pipe(
        tap((data) => this.handleData(data)),
        catchError((error) => this.handleError(error))
      );
  }

  hasToken() {
    return !!this.token;
  }

  private handleData(data: any, skipLoading = false) {
    this.sessionService.updateSessionTs();
    if (!skipLoading) {
      this.sessionService.loading = false;
    }
    console.log('data', data);

    if (data && typeof data !== 'string' && 'token' in data) {
      this.setToken(data.token);
    }
  }

  public clearToken() {
    this.token = null;
    localStorage.removeItem(SystemConfig.LocalStorageConstants.AUTH_TOKEN);
  }

  public getToken() {
    return this.token;
  }

  private setToken(token: string) {
    this.token = token;
    localStorage.setItem(SystemConfig.LocalStorageConstants.AUTH_TOKEN, token);
  }

  private handleError(error: HttpErrorResponse, skipLoading = false, skipAuthError = false) {
    if (!skipLoading) {
      this.sessionService.loading = false;
    }
    console.error('An error occurred:', error);

    if (error.status === 401 && !skipAuthError) {
      if (!localStorage.getItem(SystemConfig.LocalStorageConstants.VIRTUAL_USER)) {
        this.clearToken();
        this.sessionService.logout();
        this.router.navigate([SystemConfig.Routes.LOGIN]);
        this.snackBar.open(this.translationService.translate('snackBar.sessionTimeout'), null, ERROR_SNACK_CONFIG);
      }
    } else if (!skipAuthError) {
      this.notificationService.showError();
    }
    // return an ErrorObservable with a user-facing error message
    return observableThrowError(error);
  }

  private get requestOptions(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'X-AUTH-TOKEN': this.token ? this.token : ''
      })
    };
  }
}
