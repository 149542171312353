import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FranchiserRoutingModule} from './franchiser-routing.module';
import {FranchiserComponent} from './franchiser.component';
import {MaterialImportsModule} from '../../material-imports.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DynamicFormModule} from '../dynamic-form/dynamic-form.module';
import {SharedModule} from '../../shared.module';
import {ButtonsModule} from '../buttons/buttons.module';
import {ColsModule} from '../cols/cols.module';

@NgModule({
  imports: [
    CommonModule,
    FranchiserRoutingModule,
    MaterialImportsModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicFormModule,
    ButtonsModule,
    SharedModule,
    ColsModule
  ],
  declarations: [FranchiserComponent]
})
export class FranchiserModule {
}
