import {QuestionBase} from './question-base';

export class DropdownQuestion extends QuestionBase<string> {
  controlType = 'dropdown';
  options: { key: string, value: string }[] = [];
  hideSearch: boolean;
  clear: boolean;
  clearTo: string;

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
    this.clear = !!options['clear'];
    this.clearTo = options['clearTo'];
    this.hideSearch = !!options['hideSearch'];
  }
}
