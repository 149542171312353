import {Component, Input, OnChanges, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {GameService} from '../../services/game.service';
import {Game} from '../../domain/game';
import {KenobetSettings} from '../../domain/kenobet-settings';
import {QuestionContainer} from '../../domain/question-container';
import {StationType} from '../../domain/station-type';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {Permission} from '../../domain/permission';
import {KenobetSettingsData} from '../../domain/kenobet-settings-data';
import {TextboxQuestion} from '../../domain/question-textbox';
import {TextareaQuestion} from '../../domain/question-textarea';
import {CheckboxQuestion} from '../../domain/question-checkbox';
import {DropdownQuestion} from '../../domain/question-dropdown';
import {FormUtils} from '../../utils/form-utils';
import {Jackpot} from '../../domain/jackpot';
import {JackpotService} from '../../services/jackpot.service';
import {ColsComponent} from '../../cols/cols.component';
import {ERROR_SNACK_CONFIG, SUCCESS_SNACK_CONFIG} from '../../../utils';

@Component({
  selector: 'vit-kenobet',
  templateUrl: './kenobet.component.html',
  styleUrls: ['./kenobet.component.scss']
})
export class KenobetComponent implements OnChanges {

  @Input()
  stationID: number;
  settings: KenobetSettings;
  jackpots: Jackpot[];
  selectedJackpot: Jackpot;
  gameCycles: Map<string, string>;
  winningPlanDescriptions: string[];
  gameQuestions: QuestionContainer[] = [];
  jackpotQuestionContainer: QuestionContainer;
  hasChangePermission = false;
  @Input()
  stationType: StationType;

  questions: QuestionContainer[] = [];

  loading: boolean;
  @ViewChild(ColsComponent, { static: true })
  colsComponent: ColsComponent;

  constructor(public gameService: GameService,
              public snackBar: MatSnackBar,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public jackpotService: JackpotService) {
    this.hasChangePermission = this.sessionService.hasPermission(Permission.changeBB1Settings);
  }

  ngOnChanges() {
    this.loading = true;
    this.gameService.get(Game.KENO_BET, this.stationID).subscribe((kd: KenobetSettingsData) => {
      this.settings = kd.settings;
      this.jackpots = kd.jackpots;
      this.gameCycles = kd.gameCycles;
      this.winningPlanDescriptions = kd.winningPlanDescriptions;
      this.loading = false;
      this.reset();
    }, () => {
      this.loading = false;
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  async save() {
    this.loading = true;
    const settings = this.generateSettingsFromForm();
    if (this.selectedJackpot) {
      const jackpot = this.generateJackpotFromForm();
      if (this.selectedJackpot.jackpotID === -1) {
        const createdJackpot: Jackpot = await this.jackpotService.insert(jackpot);
        this.jackpots.push(createdJackpot);
        settings.jackpotID = createdJackpot.jackpotID;
      } else if (this.jackpotService.isStationJackpot(this.selectedJackpot, this.settings.stationID)) {
        await this.jackpotService.update(jackpot);
        const index = this.jackpots.map(jp => jp.jackpotID).indexOf(jackpot.jackpotID);
        this.jackpots[index] = jackpot;
      }
    }
    this.gameService.saveKenobetSettings(settings).subscribe(() => {
      this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
      this.settings = settings;
      this.loading = false;
      this.reset();
    }, () => {
      this.loading = false;
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  private generateSettingsFromForm(): KenobetSettings {
    const stakeForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'kenobet.settings.header.stake');
    const shopForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'kenobet.settings.header.shop');
    const extendedOptionsForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'kenobet.settings.header.extendedOptions');
    const cyclesForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'kenobet.settings.header.cycles');
    const luckySixForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'kenobet.settings.header.luckySix');
    let jackpotConfigForm: any = null;
    if (this.selectedJackpot) {
      jackpotConfigForm = FormUtils.getFormValues(this.colsComponent.forms, 'kenobet.settings.header.jackpotConfig');
    }

    const settings: KenobetSettings = {
      'bB1SettingID': this.settings.bB1SettingID,
      'stationID': this.settings.stationID,
      'bingoCycleID': +cyclesForm['bingoCycleID'],
      'jackpotID': this.selectedJackpot ? this.selectedJackpot.jackpotID : null,
      'minStakePerTip': stakeForm['minStakePerTip'] != null ? +stakeForm['minStakePerTip'] : null,
      'maxStakePerTip1': stakeForm['maxStakePerTip1'] != null ? +stakeForm['maxStakePerTip1'] : null,
      'maxStakePerTip2': stakeForm['maxStakePerTip2'] != null ? +stakeForm['maxStakePerTip2'] : null,
      'maxStakePerTip3': stakeForm['maxStakePerTip3'] != null ? +stakeForm['maxStakePerTip3'] : null,
      'maxStakePerTip4': stakeForm['maxStakePerTip4'] != null ? +stakeForm['maxStakePerTip4'] : null,
      'maxStakePerTip5': stakeForm['maxStakePerTip5'] != null ? +stakeForm['maxStakePerTip5'] : null,
      'maxStakePerTip6': stakeForm['maxStakePerTip6'] != null ? +stakeForm['maxStakePerTip6'] : null,
      'maxStakePerTip7': stakeForm['maxStakePerTip7'] != null ? +stakeForm['maxStakePerTip7'] : null,
      'maxStakePerTip8': stakeForm['maxStakePerTip8'] != null ? +stakeForm['maxStakePerTip8'] : null,
      'maxDrawingRounds': shopForm['maxDrawingRounds'] != null ? +shopForm['maxDrawingRounds'] : null,
      'enableCashbook': shopForm['enableCashbook'],
      'stakeButtonFactor': shopForm['stakeButtonFactor'] != null ? +shopForm['stakeButtonFactor'] : null,
      'drawingPeriod': shopForm['drawingPeriod'] != null ? +shopForm['drawingPeriod'] : null,
      'hasJackpot': !!this.selectedJackpot,
      'jackpotMinStake': (this.selectedJackpot && jackpotConfigForm['jackpotMinStake'])
        ? +jackpotConfigForm['jackpotMinStake'] : this.settings.jackpotMinStake,
      'jackpotMinHold': (this.selectedJackpot && jackpotConfigForm['jackpotMinHold'])
        ? +jackpotConfigForm['jackpotMinHold'] : this.settings.jackpotMinHold,
      'jackpotMinPercentage': (this.selectedJackpot && jackpotConfigForm['jackpotMinPercentage'])
        ? +jackpotConfigForm['jackpotMinPercentage'] : this.settings.jackpotMinPercentage,
      'jackpotMaxPercentage': (this.selectedJackpot && jackpotConfigForm['jackpotMaxPercentage'])
        ? +jackpotConfigForm['jackpotMaxPercentage'] : this.settings.jackpotMaxPercentage,
      'logoURL': extendedOptionsForm['logoURL'],
      'terminalBettingMode': this.settings.terminalBettingMode, // depricated, can be ignored
      'ticketTitle': extendedOptionsForm['ticketTitle'],
      'printTicketTitle': extendedOptionsForm['printTicketTitle'],
      'tssServerHost': this.settings.tssServerHost, // not used
      'tssServerPort': this.settings.tssServerPort, // not used
      'lS_Enabled': luckySixForm['lS_Enabled'],
      'lS_MinStake': luckySixForm['lS_MinStake'] != null ? +luckySixForm['lS_MinStake'] : null,
      'lS_MinSystemStake': luckySixForm['lS_MinSystemStake'] != null ? +luckySixForm['lS_MinSystemStake'] : null,
      'lS_MaxStake': luckySixForm['lS_MaxStake'] != null ? +luckySixForm['lS_MaxStake'] : null,
      'lS_UserInterfaceURL': luckySixForm['lS_UserInterfaceURL'],
      'lS_TVInterfaceURL': luckySixForm['lS_TVInterfaceURL'],
      'kenoEnabled': stakeForm['kenoEnabled'],
      'lS_DrawDuration': luckySixForm['lS_DrawDuration']
    };
    return settings;
  }

  private generateJackpotFromForm(): Jackpot {
    const jackpotConfigForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'kenobet.settings.header.jackpotConfig');

    const jackpot: Jackpot = {
      'jackpotID': this.selectedJackpot.jackpotID,
      'franchiserID': this.selectedJackpot.franchiserID,
      'applicationID': this.selectedJackpot.applicationID,
      'value': this.selectedJackpot.value,
      'minInitialValue': jackpotConfigForm['minInitialValue'] ? +jackpotConfigForm['minInitialValue'] : 0,
      'maxInitialValue': jackpotConfigForm['maxInitialValue'] ? +jackpotConfigForm['maxInitialValue'] : 0,
      'minValue': jackpotConfigForm['minValue'] ? +jackpotConfigForm['minValue'] : 0,
      'maxValue': jackpotConfigForm['maxValue'] ? +jackpotConfigForm['maxValue'] : 0,
      'hitValue': this.selectedJackpot.hitValue,
      'lastHitTime': this.selectedJackpot.lastHitTime,
      'lastHitStation': this.selectedJackpot.lastHitStation,
      'lastHitValue': this.selectedJackpot.lastHitValue,
      'name': this.selectedJackpot.name,
      'autoRaise': this.selectedJackpot.autoRaise
    };
    return jackpot;
  }

  resetJackpotForm(selectedJackpotId?: string) {
    if (selectedJackpotId != null && +selectedJackpotId !== JackpotService.NO_JACKPOT_ID) {
      this.selectedJackpot = this.jackpots.filter(jp => jp.jackpotID === +selectedJackpotId)[0];
    } else if (this.settings.jackpotID != null && +selectedJackpotId !== JackpotService.NO_JACKPOT_ID) {
      this.selectedJackpot = this.jackpots.filter(jp => jp.jackpotID === this.settings.jackpotID)[0];
    } else {
      this.selectedJackpot = null;
    }
    if (this.selectedJackpot != null) {
      this.jackpotQuestionContainer = new QuestionContainer('kenobet.settings.header.jackpotConfig', [
        new TextboxQuestion({
          key: 'value',
          label: 'kenobet.settings.value',
          value: this.selectedJackpot.value,
          type: 'number',
          disabled: true,
        }),
        new TextboxQuestion({
          key: 'jackpotMinStake',
          label: 'kenobet.settings.jackpotMinStake',
          value: this.settings.jackpotMinStake,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'jackpotMinPercentage',
          label: 'kenobet.settings.jackpotMinPercentage',
          value: this.settings.jackpotMinPercentage,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'jackpotMaxPercentage',
          label: 'kenobet.settings.jackpotMaxPercentage',
          value: this.settings.jackpotMaxPercentage,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'minInitialValue',
          label: 'kenobet.settings.minInitialValue',
          value: this.selectedJackpot.minInitialValue,
          type: 'number',
          readonly: !this.jackpotService.isStationJackpot(this.selectedJackpot, this.settings.stationID)
        }),
        new TextboxQuestion({
          key: 'maxInitialValue',
          label: 'kenobet.settings.maxInitialValue',
          value: this.selectedJackpot.maxInitialValue,
          type: 'number',
          readonly: !this.jackpotService.isStationJackpot(this.selectedJackpot, this.settings.stationID)
        }),
        new TextboxQuestion({
          key: 'minValue',
          label: 'kenobet.settings.minValue',
          value: this.selectedJackpot.minValue,
          type: 'number',
          readonly: !this.jackpotService.isStationJackpot(this.selectedJackpot, this.settings.stationID)
        }),
        new TextboxQuestion({
          key: 'maxValue',
          label: 'kenobet.settings.maxValue',
          value: this.selectedJackpot.maxValue,
          type: 'number',
          readonly: !this.jackpotService.isStationJackpot(this.selectedJackpot, this.settings.stationID)
        })]);
      if (this.sessionService.hasPermission(Permission.initJackpot)
        && this.jackpotService.isStationJackpot(this.selectedJackpot, this.settings.stationID)
        && this.selectedJackpot.jackpotID !== -1) {
        this.jackpotQuestionContainer.addSubmit('kenobet.settings.initJackpot', this.resetJackpotValues.bind(this));
      }
    } else {
      this.jackpotQuestionContainer = null;
    }
    this.questions = this.jackpotQuestionContainer ? [...this.gameQuestions, this.jackpotQuestionContainer] : this.gameQuestions;
  }

  resetJackpotValues() {
    this.jackpotService.init(this.selectedJackpot).subscribe((jackpot) => {
      this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
      const index = this.jackpots.map(jp => jp.jackpotID).indexOf(jackpot.jackpotID);
      this.jackpots[index] = jackpot;
      this.loading = false;
      this.resetJackpotForm(jackpot.jackpotID);
    }, () => {
      this.loading = false;
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  reset() {
    this.resetSettingsQuestions();
    this.resetJackpotForm();
    this.questions = this.jackpotQuestionContainer ? [...this.gameQuestions, this.jackpotQuestionContainer] : this.gameQuestions;
  }

  mapJackpotName(id: number, name: string) {
    if (id === JackpotService.NO_JACKPOT_ID) {
      return this.translationService.translate('kenobet.settings.noJackpot');
    } else if (name === this.settings.stationID.toString()) {
      return this.translationService.translate('kenobet.settings.ownJackpot');
    }
    return name;
  }

  resetSettingsQuestions() {
    const cycleOptions = Array.from(this.gameCycles.entries()).map(([key, value]) => ({
      key,
      value
    }));
    this.gameQuestions = [
      new QuestionContainer('kenobet.settings.header.stake', [
        new CheckboxQuestion({
          key: 'kenoEnabled',
          label: 'kenobet.settings.kenoEnabled',
          value: this.settings.kenoEnabled
        }),
        new TextboxQuestion({
          key: 'minStakePerTip',
          label: 'kenobet.settings.minStakePerTip',
          value: this.settings.minStakePerTip,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'maxStakePerTip1',
          label: 'kenobet.settings.maxStakePerTip1',
          value: this.settings.maxStakePerTip1,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'maxStakePerTip2',
          label: 'kenobet.settings.maxStakePerTip2',
          value: this.settings.maxStakePerTip2,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'maxStakePerTip3',
          label: 'kenobet.settings.maxStakePerTip3',
          value: this.settings.maxStakePerTip3,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'maxStakePerTip4',
          label: 'kenobet.settings.maxStakePerTip4',
          value: this.settings.maxStakePerTip4,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'maxStakePerTip5',
          label: 'kenobet.settings.maxStakePerTip5',
          value: this.settings.maxStakePerTip5,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'maxStakePerTip6',
          label: 'kenobet.settings.maxStakePerTip6',
          value: this.settings.maxStakePerTip6,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'maxStakePerTip7',
          label: 'kenobet.settings.maxStakePerTip7',
          value: this.settings.maxStakePerTip7,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'maxStakePerTip8',
          label: 'kenobet.settings.maxStakePerTip8',
          value: this.settings.maxStakePerTip8,
          type: 'number'
        })
      ]),
      new QuestionContainer('kenobet.settings.header.shop', [
        new TextboxQuestion({
          key: 'stakeButtonFactor',
          label: 'kenobet.settings.stakeButtonFactor',
          value: this.settings.stakeButtonFactor,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'drawingPeriod',
          label: 'kenobet.settings.drawingPeriod',
          value: this.settings.drawingPeriod,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'maxDrawingRounds',
          label: 'kenobet.settings.maxDrawingRounds',
          value: this.settings.maxDrawingRounds,
          type: 'number'
        }),
        new CheckboxQuestion({
          key: 'enableCashbook',
          label: 'kenobet.settings.enableCashbook',
          value: this.settings.enableCashbook
        })
      ], null, this.isWebshopBridgeOrShop() ? null : Permission.notAllowed),
      new QuestionContainer('kenobet.settings.header.luckySix', [
        new CheckboxQuestion({
          key: 'lS_Enabled',
          label: 'kenobet.settings.lS_Enabled',
          value: this.settings.lS_Enabled
        }),
        new TextboxQuestion({
          key: 'lS_MinStake',
          label: 'kenobet.settings.lS_MinStake',
          value: this.settings.lS_MinStake,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'lS_MinSystemStake',
          label: 'kenobet.settings.lS_MinSystemStake',
          value: this.settings.lS_MinSystemStake,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'lS_MaxStake',
          label: 'kenobet.settings.lS_MaxStake',
          value: this.settings.lS_MaxStake,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'lS_DrawDuration',
          label: 'kenobet.settings.lS_DrawDuration',
          value: this.settings.lS_DrawDuration,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'lS_UserInterfaceURL',
          label: 'kenobet.settings.lS_UserInterfaceURL',
          value: this.settings.lS_UserInterfaceURL,
          viewPermission: Permission.editAdvancedAdminSettings
        }),
        new TextboxQuestion({
          key: 'lS_TVInterfaceURL',
          label: 'kenobet.settings.lS_TVInterfaceURL',
          value: this.settings.lS_TVInterfaceURL,
          viewPermission: Permission.editAdvancedAdminSettings
        }),
      ], null, this.isShop() ? null : Permission.notAllowed),
      new QuestionContainer('kenobet.settings.header.extendedOptions', [
        new TextareaQuestion({
          key: 'logoURL',
          label: 'kenobet.settings.logoURL',
          value: this.settings.logoURL,
          minRows: 3,
          viewPermission: Permission.editLogoURLs
        }),
        new TextareaQuestion({
          key: 'ticketTitle',
          label: 'kenobet.settings.ticketTitle',
          minRows: 3,
          value: this.settings.ticketTitle
        }),
        new CheckboxQuestion({
          key: 'printTicketTitle',
          label: 'kenobet.settings.printTicketTitle',
          value: this.settings.printTicketTitle
        })
      ]),
      new QuestionContainer('kenobet.settings.header.jackpot', [
        new DropdownQuestion({
          key: 'jackpotID',
          label: 'kenobet.settings.jackpotID',
          value: (this.settings.jackpotID || JackpotService.NO_JACKPOT_ID).toString(),
          options: this.jackpots.map(jp => ({
            'key': jp.jackpotID.toString(),
            'value': this.mapJackpotName(jp.jackpotID, jp.name)
          })),
          onChange: this.resetJackpotForm.bind(this)
        })], null, this.stationType === StationType.TERMINAL ? Permission.notAllowed : null),
      new QuestionContainer('kenobet.settings.header.cycles', [
        new DropdownQuestion({
          key: 'bingoCycleID',
          label: 'kenobet.settings.bingoCycleID',
          value: this.settings.bingoCycleID ? this.settings.bingoCycleID.toString() : null,
          options: cycleOptions,
          viewPermission: (!this.isWebshopBridgeOrShop() || !this.sessionService.hasPermission(Permission.selectBB1Cycle)
            || cycleOptions.length === 0) ? Permission.notAllowed : null
        }),
        new TextareaQuestion({
          key: 'winningPlanDescriptions',
          label: 'kenobet.settings.winningPlanDescriptions',
          value: this.winningPlanDescriptions ? this.winningPlanDescriptions.join('\n') : '',
          disabled: true,
          minRows: this.winningPlanDescriptions ? this.winningPlanDescriptions.length : 1,
          viewPermission: Permission.editAdvancedAdminSettings
        }),
      ])];
  }

  private isWebshopBridgeOrShop() {
    return this.stationType === StationType.SHOP
      || this.stationType === StationType.WEBSHOP
      || this.stationType === StationType.WEBBRIDGE;
  }

  private isShop() {
    return this.stationType === StationType.SHOP;
  }
}
