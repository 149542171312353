import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GameNumber} from '../../domain/game-number';
import {TicketDetailService} from '../ticket-detail.service';
import {SessionService} from '../../services/session.service';
import {Permission} from '../../domain/permission';

@Component({
  selector: 'vit-super-storno',
  templateUrl: './super-storno.component.html',
  styleUrls: ['./super-storno.component.scss']
})
export class SuperStornoComponent implements OnInit {

  @Input()
  ticket: any;

  @Input()
  gameNumer: GameNumber;

  @Output()
  update: EventEmitter<void> = new EventEmitter();

  constructor(private ticketDetailService: TicketDetailService,
              private sessionService: SessionService) {
  }

  ngOnInit() {
  }

  click() {
    if (this.isSuperStornoEnabled()) {
      this.ticket.superStorno = true;
    } else {
      this.ticket.superStorno = false;
    }

    this.ticketDetailService.update(this.ticket, this.gameNumer).subscribe(() => {
      this.update.emit();
    });
  }

  isSuperStornoEnabled() {
    return !this.ticket.superStorno;
  }

  isAllowed() {
    return this.sessionService.hasPermission(Permission.activateSuperStorno) && !this.ticket.storno && !this.ticket.disbursed;
  }

}
