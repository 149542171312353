export enum Bb1LsBetType {
  Standard = 1,
  FirstBall_EO = 2,
  FirstBall_UO = 3,
  Preballs_EO = 4,
  Preballs_UO = 5,
  Preball_Sel = 6,
  FirstBall_Color = 7,
  LastBall_Color = 8,
  Systembet = 9,
  MostColor = 10,
  DrawnBalls_EO = 11
}
