import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'vit-ref',
  templateUrl: './ref.component.html',
  styleUrls: ['./ref.component.scss']
})
export class RefComponent implements OnInit {

  constructor(public route: ActivatedRoute,
              public router: Router) {
  }

  ngOnInit() {
    const refUrl = this.route.snapshot.queryParams['ref'] || '/';
    this.router.navigateByUrl(refUrl);
  }

}
