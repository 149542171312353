<mat-radio-group [formControl]="presetTimeFrame"
                 #radioGroup
                 (change)="presetChanged($event)">
  <mat-radio-button name="preset-{{i}}"
                    *ngFor="let opt of presetOptions; let i = idx"
                    [value]="opt.value">
    {{opt.key | translate}}
  </mat-radio-button>
</mat-radio-group>
<mat-divider style="width: 50%; margin-left: 25%"></mat-divider>
<div class="custom-timeframe" *ngIf="!hideInput">
  <vit-date-input [formControl]="fromControl"></vit-date-input>
  <vit-date-input [formControl]="toControl"></vit-date-input>
  <mat-error *ngIf="errorState && !sessionService.user.admin">{{'forms.maxMonths' | translate: [maxMonths]}}</mat-error>
</div>
