import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RefComponent} from './ref.component';
import {RefRoutingModule} from './ref-routing.module';

@NgModule({
  declarations: [RefComponent],
  imports: [
    CommonModule,
    RefRoutingModule
  ]
})
export class RefModule {
}
