import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NgControl} from '@angular/forms';
import * as moment from 'moment';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import {Subject, Subscription} from 'rxjs';
import {FocusMonitor} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
  selector: 'vit-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: DateInputComponent}],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateInputComponent implements OnInit, OnDestroy, MatFormFieldControl<number>, ControlValueAccessor {
  static nextId = 0;

  dateControl: UntypedFormControl = new UntypedFormControl();
  timeControl: UntypedFormControl = new UntypedFormControl();

  stateChanges = new Subject<void>();
  focused: boolean;
  errorState = false;
  controlType = 'kix-date-input';

  private _placeholder: string;
  private _required = false;
  private _disabled = false;
  private _onChange: Function;
  private _onTouched: Function;
  private subscription: Subscription;

  @Input()
  get value(): number | null {
    if (this.dateControl.value && this.timeControl.value) {
      const date: moment.Moment = this.dateControl.value;
      const time = moment(this.timeControl.value, 'HH:mm');
      return date
        .hours(time.hours())
        .minutes(time.minutes())
        .seconds(0)
        .milliseconds(0)
        .valueOf();
    } else {
      return null;
    }
  }

  set value(date: number | null) {
    if (date) {
      try {
        this.dateControl.setValue(moment(date));
        this.timeControl.setValue(moment(date).format('HH:mm'));
      } catch (e) {
        this.dateControl.setValue(moment());
        this.timeControl.setValue(moment().format('HH:mm'));
      }
    } else {
      this.dateControl.setValue(null);
      this.timeControl.setValue(null);
    }
    this.updateErrorState();
    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(placeholder) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this._required;
  }

  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled() {
    return this._disabled;
  }

  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    if (this._disabled) {
      this.dateControl.disable();
      this.timeControl.disable();
    } else {
      this.dateControl.enable();
      this.timeControl.enable();
    }

    this.stateChanges.next();
  }

  @Input() showClearValue = false;

  @Input() showTimeControl = true;

  @Output()
  valueChange: EventEmitter<number | null> = new EventEmitter();

  get empty() {
    return !this.dateControl.value && !this.timeControl.value;
  }

  @HostBinding() id = `kix-date-input-${DateInputComponent.nextId++}`;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @HostBinding('attr.aria-describedby') describedBy = '';

  constructor(private focusMonitor: FocusMonitor, private elementRef: ElementRef,
              @Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    focusMonitor.monitor(elementRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      if (this._onTouched && !this.focused) {
        this._onTouched();
        this.updateErrorState();
      }
      this.stateChanges.next();
    });
  }

  ngOnInit() {
    const notifyValueChanges = (value) => {
      this.valueChange.emit(this.value);
      if (this._onChange) {
        this._onChange(this.value);
      }
      this.updateErrorState();
    };
    this.subscription = this.dateControl.valueChanges.subscribe(notifyValueChanges);
    this.subscription.add(this.timeControl.valueChanges.subscribe(notifyValueChanges));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input'
      && !(event.target as Element).classList.contains('placeholder')) {
      this.elementRef.nativeElement.querySelector('input').focus();
    }

    if (this.timeControl.getRawValue() == null) {
      this.timeControl.setValue('00:00');
    }
    if ((event.target as Element).classList.contains('clear-icon')) {
      this.dateControl.setValue(null);
      this.timeControl.setValue(null);
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  previousDay(event?: Event) {
    this.stopEvent(event);
    if (this.value) {
      this.value = moment(this.value).subtract(1, 'days').valueOf();
    } else {
      this.value = Date.now();
    }
  }

  nextDay(event?: Event) {
    this.stopEvent(event);
    if (this.value) {
      this.value = moment(this.value).add(1, 'days').valueOf();
    } else {
      this.value = Date.now();
    }
  }

  previousHour(event?: Event) {
    this.stopEvent(event);
    if (this.value || this.dateControl.value) {
      const mom = moment(this.value || this.dateControl.value).seconds(0);
      if (mom.get('hours') === 0) {
        mom.set('hours', 23);
      } else {
        mom.subtract(1, 'hours');
      }
      this.value = mom.valueOf();
    } else {
      this.value = Date.now();
    }
  }

  nextHour(event?: Event) {
    this.stopEvent(event);
    if (this.value || this.dateControl.value) {
      const mom = moment(this.value || this.dateControl.value).seconds(0);
      if (mom.get('hours') === 23) {
        mom.set('hours', 0);
      } else {
        mom.add(1, 'hours');
      }
      this.value = mom.valueOf();
    } else {
      this.value = Date.now();
    }
  }

  private stopEvent(event: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  private updateErrorState() {
    if (this.ngControl) {
      const oldState = this.errorState;
      const requiredAndEmpty = this._required && (!this.dateControl.value || !this.timeControl.value);
      const newState = this.ngControl.touched && (requiredAndEmpty || !!this.ngControl.errors);
      if (newState !== oldState) {
        this.errorState = newState;
        this.stateChanges.next();
      }
    }
  }
}
