import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {SessionService} from '../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class LoginPageGuard  {

  constructor(private sessionService: SessionService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!!this.sessionService.user) {
      console.log('user exists, go to home');
      this.router.navigate(['home']);
      return false;
    } else {
      console.log('no user exists, login is fine');
      return true;
    }
  }
}
