<vit-cols [elements]="questions"></vit-cols>
<mat-toolbar class="footer">
    <vit-buttons>
        <button type="submit" *ngIf="!groupDto" (click)="new()" mat-flat-button color="accent">
            {{'groupManagement.new' | translate}}
        </button>
        <button type="reset" *ngIf="groupDto" (click)="reset()" mat-flat-button color="secondary">
            {{'groupManagement.cancel' | translate}}
        </button>
        <button type="submit" *ngIf="groupDto" (click)="delete()" mat-flat-button color="secondary">
            {{'groupManagement.delete' | translate}}
        </button>
        <button type="submit" *ngIf="groupDto" (click)="save()" mat-flat-button color="accent">
            {{'groupManagement.save' | translate}}
        </button>
    </vit-buttons>
</mat-toolbar>