<ng-container [ngSwitch]="gameNumber" *ngIf="ticketData">
  <ag-grid-angular
    *ngSwitchCase="0"
    class="ag-theme-balham"
    style="width: 100%; flex: 1 1 1px;"
    [gridOptions]="gridOptions"
    (columnVisible)="storeColumnVisibilityFn('ticketDetail', $event)"
    [rowData]="ticketData"
    [columnDefs]="columnDefs"
    [detailCellRendererParams]="detailParams"
    (gridReady)="onGridReady($event)">
  </ag-grid-angular>
  <vit-ticket-detail-pokerbet *ngSwitchCase="1"
                              [ticketData]="ticketData"></vit-ticket-detail-pokerbet>
  <vit-ticket-detail-smartrace *ngSwitchCase="2"
                               [ticketData]="ticketData"></vit-ticket-detail-smartrace>
  <vit-ticket-detail-keno *ngSwitchCase="3"
                          [ticketData]="ticketData"></vit-ticket-detail-keno>
  <vit-ticket-detail-keno *ngSwitchCase="6"
                          [ticketData]="ticketData"></vit-ticket-detail-keno>
  <vit-ticket-detail-livesports *ngSwitchCase="4"
                                (betsChanged)="agInit(params)"
                                [ticketData]="ticketData"></vit-ticket-detail-livesports>
  <vit-ticket-detail-livesports *ngSwitchCase="5"
                                (betsChanged)="agInit(params)"
                                [ticketData]="ticketData"></vit-ticket-detail-livesports>
  <div class="buttons" [class.not-fixed]="noFixedButtons" *ngIf="gameNumber !== 0">
    <vit-buttons>
      <vit-payout-widget [ticket]="ticketData.ticket"
                         [gameNumer]="gameNumber"
                         (update)="agInit(params)"></vit-payout-widget>
      <vit-super-storno [ticket]="ticketData.ticket"
                        [gameNumer]="gameNumber"
                        (update)="agInit(params)"></vit-super-storno>
      <vit-storno [ticket]="ticketData.ticket"
                  [gameNumer]="gameNumber"
                  (update)="agInit(params)"></vit-storno>

      <vit-cancel-all-bets *ngIf="gameNumber === 4 || gameNumber === 5"
                           [ticket]="ticketData.ticket"
                           [gameNumer]="gameNumber"
                           (update)="agInit(params)"></vit-cancel-all-bets>

      <vit-reset-ticket-open *ngIf="gameNumber === 4 || gameNumber === 5"
                             [ticket]="ticketData.ticket"
                             [gameNumer]="gameNumber"
                             (update)="agInit(params)"></vit-reset-ticket-open>

      <vit-payout-lock *ngIf="gameNumber === 4 || gameNumber === 5"
                       [ticket]="ticketData.ticket"
                       [gameNumer]="gameNumber"
                       (update)="agInit(params)"></vit-payout-lock>

      <vit-refund-ticket *ngIf="gameNumber === 4 || gameNumber === 5"
                         [ticket]="ticketData.ticket"
                         [bet]="ticketData.bets[0]"
                         [gameNumer]="gameNumber"
                         (update)="agInit(params)">
      </vit-refund-ticket>

      <vit-refresh-ticket *ngIf="gameNumber === 4 || gameNumber === 5"
                          (click)="agInit(params)"></vit-refresh-ticket>

    </vit-buttons>
  </div>
</ng-container>
<p class="loading" *ngIf="!ticketData">Loading...</p>
