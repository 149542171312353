import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {QuestionControlService} from '../services/question-control.service';
import {QuestionContainer} from '../domain/question-container';
import {SessionService} from '../services/session.service';

@Component({
  selector: 'vit-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [QuestionControlService]
})
export class DynamicFormComponent implements OnInit {

  @Input()
  questionContainer: QuestionContainer;
  form: UntypedFormGroup;

  constructor(private qcs: QuestionControlService,
              private sessionService: SessionService) {
  }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.questionContainer.questions);
  }

  forceEnableForm() {
    return this.questionContainer.questions.filter(q => !q.disabled).length === 0;
  }

  isNotAuthorizedToEdit(): boolean {
    return this.questionContainer.editPermission && !this.sessionService.hasPermission(this.questionContainer.editPermission);
  }

  isNotAuthorizedToView(): boolean {
    return (this.questionContainer.viewPermission && !this.sessionService.hasPermission(this.questionContainer.viewPermission))
      || this.questionContainer.questions.every(q => !this.sessionService.hasPermission(q.viewPermission));
  }

  isNotAuthorizedToSubmitButton(button: any): boolean {
    return button.submitPermission && !this.sessionService.hasPermission(button.submitPermission);
  }
}
