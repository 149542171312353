import {Component, HostBinding, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {SystemConfig} from '../config/system-config';
import {StationService} from '../services/station.service';
import {Station} from '../domain/station';
import {Language} from '../domain/language';
import {Country} from '../domain/country';
import {Currency} from '../domain/currency';
import {Credit} from '../domain/credit';
import {HoldLimiter} from '../domain/hold-limiter';
import {MappableMkCompany} from '../domain/mappable-mk-company';
import {DisburseTypeInfo} from '../domain/disburse-type-info';
import {CashStorageInfo} from '../domain/cash-storage-info';
import {TextboxQuestion} from '../domain/question-textbox';
import {DropdownQuestion} from '../domain/question-dropdown';
import {QuestionContainer} from '../domain/question-container';
import {Permission} from '../domain/permission';
import {TextareaQuestion} from '../domain/question-textarea';
import {StationType} from '../domain/station-type';
import {MobileService} from '../services/mobile.service';
import {CheckboxQuestion} from '../domain/question-checkbox';
import {RadioQuestion} from '../domain/question-radio';
import {PaymentMethodsQuestion} from '../domain/question-payment-methods';
import {CashStorageQuestion} from '../domain/question-cash-storage';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {TranslationService} from '../services/translation.service';
import {HoldLimiterQuestion} from '../domain/question-hold-limiter';
import {SessionService} from '../services/session.service';
import {FormUtils} from '../utils/form-utils';
import {StationCopyComponent} from './station-copy/station-copy.component';
import {DatePipe} from '@angular/common';
import {StationUtils} from '../utils/station-utils';
import {TreeNodeType} from '../domain/tree-node-type';
import {ColsComponent} from '../cols/cols.component';
import {CustomerTreeService} from '../services/customer-tree.service';
import {ERROR_SNACK_CONFIG, SUCCESS_SNACK_CONFIG} from '../../utils';
import {DateTimeQuestion} from '../domain/question-datetime';
import {environment} from '../../environments/environment';

@Component({
  selector: 'vit-station',
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.scss']
})
export class StationComponent {

  @HostBinding('class.is-mobile')
  get isMobileClass() {
    return this.mobileService.isMobile;
  }

  @HostBinding('class.is-locked')
  get isLocked() {
    return this.station && (this.station.hardLocked || this.station.softLocked);
  }

  @ViewChild(ColsComponent, { static: false })
  colsComponent: ColsComponent;

  parentFranchiserId: number;
  isNew = false;
  loading: boolean;
  station: Station;
  franchisers: Map<string, string>;
  languages: Language[];
  countries: Country[];
  currencies: Currency[];
  credit: Credit;
  holdLimiter: HoldLimiter;
  mappableMKCompany: MappableMkCompany;
  disburseTypeInfos: DisburseTypeInfo[];
  cashStorageInfo: CashStorageInfo;
  webshops: Map<string, string>;
  terminalDefaultApps: Map<string, string>;
  stationsConnectedToWebshop: string[];
  franchiserHierarchy: string;
  selectedTab = 0;

  stationQuestions: QuestionContainer[] = [];
  cashStorageQuestions: QuestionContainer[] = [];
  holdLimiterQuestions: QuestionContainer[] = [];
  paymentMethodsQuestions: QuestionContainer[] = [];

  userAccountCashMode_allowTerminalLogin: boolean;
  userAccountCashMode_lockCashSystemWhenLoggedOut: boolean;
  userAccountCashMode_allowPayout: boolean;
  icon: string;

  questions: QuestionContainer[] = [];

  canSave = false;
  canCopy = false;

  constructor(public route: ActivatedRoute,
              public stationService: StationService,
              public sessionService: SessionService,
              public snackBar: MatSnackBar,
              public mobileService: MobileService,
              public translationService: TranslationService,
              public customerTreeService: CustomerTreeService,
              public dialog: MatDialog,
              public datePipe: DatePipe) {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.parentFranchiserId = +params.get(SystemConfig.Routes.Params.PARENT_FRANCHISER_ID);
      this.fetchStation(+params.get(SystemConfig.Routes.Params.STATION_ID));
      this.selectedTab = 0;
    });
  }

  fetchStation(stationId: number): void {
    this.loading = true;
    this.sessionService.selectedStation = null;
    this.stationService.fetchStationData(stationId).subscribe((stationData) => {
      if (stationData.station.franchiserID === -1) {
        stationData.station.franchiserID = this.parentFranchiserId;
        this.isNew = true;
      } else {
        this.isNew = false;
      }
      this.terminalDefaultApps = stationData.terminalDefaultApps;
      this.station = stationData.station;
      this.franchisers = stationData.lstFranchisers;
      this.languages = stationData.lstLanguage;
      this.countries = stationData.lstCountry;
      this.currencies = stationData.lstCurrency;
      this.credit = stationData.credit;
      this.holdLimiter = stationData.holdLimiter;
      this.mappableMKCompany = stationData.mappableMKCompany;
      this.disburseTypeInfos = stationData.lstDisburseTypeInfo;
      this.cashStorageInfo = stationData.cashStorageInfo;
      this.webshops = stationData.lstWebshops;
      this.stationsConnectedToWebshop = stationData.lstStationsConnectedToWebshop;
      this.franchiserHierarchy = stationData.franchiserHierarchy;

      switch (this.station.userAccountCashModes) {
        case 7:
          this.userAccountCashMode_allowTerminalLogin = true;
          this.userAccountCashMode_lockCashSystemWhenLoggedOut = false;
          this.userAccountCashMode_allowPayout = false;
          break;
        case 6:
          this.userAccountCashMode_allowTerminalLogin = true;
          this.userAccountCashMode_lockCashSystemWhenLoggedOut = true;
          this.userAccountCashMode_allowPayout = false;
          break;
        case 14:
          this.userAccountCashMode_allowTerminalLogin = true;
          this.userAccountCashMode_lockCashSystemWhenLoggedOut = true;
          this.userAccountCashMode_allowPayout = true;
          break;
        case 15:
          this.userAccountCashMode_allowTerminalLogin = true;
          this.userAccountCashMode_lockCashSystemWhenLoggedOut = false;
          this.userAccountCashMode_allowPayout = true;
          break;
        case 1:
        default:
          this.userAccountCashMode_allowTerminalLogin = false;
          this.userAccountCashMode_lockCashSystemWhenLoggedOut = false;
          this.userAccountCashMode_allowPayout = false;
          break;
      }

      this.icon = StationUtils.getIcon(TreeNodeType.STATION, stationData.station.type, false, stationData.station.softLocked,
        stationData.station.hardLocked);

      this.reset();
      this.loading = false;
      this.sessionService.selectedStation = stationData;

      this.canSave = this.sessionService.hasSomePermissions(Permission.editBasicStationData, Permission.editStationLimited)
        && (this.station.type !== StationType.WEBBRIDGE || this.sessionService.hasPermission(Permission.editAdvancedAdminSettings));
      this.canCopy = this.sessionService.hasPermission(Permission.insertStation, Permission.editBasicStationData,
        Permission.editAdditionalStationData);

    }, () => {
      this.loading = false;
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  reload() {
    this.fetchStation(this.station.stationID);
  }

  reset() {
    this.resetCashStorageQuestions();
    this.resetHoldLimiterQuestions();
    this.resetPaymentMethodsQuestion();
    this.resetStationQuestions();
    this.questions = [
      ...this.stationQuestions,
      ...this.paymentMethodsQuestions,
      ...this.holdLimiterQuestions,
      ...this.cashStorageQuestions];
  }

  save() {
    this.loading = true;
    const station = this.generateStationFromForm();
    const result = this.isNew ? this.stationService.insert(station) : this.stationService.update(station);
    result.subscribe(() => {
      this.loading = false;
      this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
      this.fetchStation(station.stationID);
      this.customerTreeService.fetchTree();
    }, () => {
      this.loading = false;
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  private generateStationFromForm() {
    const basicForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'station.station.header.basic');
    const franchiserForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'station.station.header.franchiser');
    const webshopForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'station.station.header.webshop');
    const settingsForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'station.station.header.settings');
    const adminForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'station.station.header.admin');
    const customerPlayCodeForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'station.station.header.customerPlayCode');
    const terminalSettings2Form: any = FormUtils.getFormValues(this.colsComponent.forms, 'station.station.header.terminalSettings2');
    const localizationForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'station.station.header.localization');
    const ticketForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'station.station.header.ticket');
    const info1Form: any = FormUtils.getFormValues(this.colsComponent.forms, 'station.station.header.info1');
    const info2Form: any = FormUtils.getFormValues(this.colsComponent.forms, 'station.station.header.info2');
    const extendedOptionsForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'station.station.header.extendedOptions');
    const browserForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'station.station.header.browser');
    const taxForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'station.station.header.tax');
    const conditionsForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'station.station.header.conditions');
    const gamesForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'station.station.header.games');
    const mkCompanyExportForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'station.station.header.publicRevenueExport');
    const userAccountForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'station.station.header.userAccount');

    let userAccountCashModes;
    if (userAccountForm['userAccountCashMode_allowTerminalLogin']) {
      if (userAccountForm['userAccountCashMode_lockCashSystemWhenLoggedOut']) {
        userAccountCashModes = 6;
      } else {
        userAccountCashModes = 7;
      }
      if (userAccountForm['userAccountCashMode_allowPayout']) {
        userAccountCashModes += 8;
      }
    } else {
      userAccountCashModes = 1;
    }

    const station: Station = {
      'id': this.station.id,
      'stationID': basicForm['stationID'] != null ? +basicForm['stationID'] : null,
      'registrationDate': basicForm['registrationDate'] != null ? new Date(basicForm['registrationDate']).toISOString() : null,
      'deregistrationDate': basicForm['deregistrationDate'] != null ? new Date(basicForm['deregistrationDate']).toISOString() : null,
      'franchiserID': franchiserForm['franchiserID'] ? +franchiserForm['franchiserID'] : null,
      'webStationID': webshopForm['webStationID'] ? +webshopForm['webStationID'] : null,
      'type': +settingsForm['type'],
      'name': basicForm['name'],
      'identifier': basicForm['identifier'],
      'hardwareSerialNumber': basicForm['hardwareSerialNumber'],
      'hardwareType': basicForm['hardwareType'],
      'pin': basicForm['pin'],
      'piN2': basicForm['pin2'],
      'softLocked': settingsForm['softLocked'],
      'hardLocked': adminForm['hardLocked'],
      'online': this.station.online,
      'pB1Enabled': gamesForm['pB1Enabled'],
      'rF2Enabled': gamesForm['rF2Enabled'],
      'bB1Enabled': gamesForm['bB1Enabled'],
      'lB2Enabled': gamesForm['lB2Enabled'],
      'cT1Enabled': gamesForm['cT1Enabled'],
      'thirdPartyEnabled': gamesForm['thirdPartyEnabled'],
      'shopMode': settingsForm['shopMode'],
      'mac': this.station.mac,
      'lastLogin': this.station.lastLogin,
      'lastLogout': this.station.lastLogout,
      'info': this.station.info,
      'clientIP': this.station.clientIP,
      'defaultLanguage': settingsForm['defaultLanguage'],
      'ticketText': ticketForm['ticketText'],
      'teamviewerID': this.station.teamviewerID,
      'currencyCode': localizationForm['currencyCode'],
      'countryCode': localizationForm['countryCode'],
      'address': localizationForm['address'],
      'printAddress': ticketForm['printAddress'],
      'rF2MultiCycle': adminForm['rF2MultiCycle'],
      'startupText': extendedOptionsForm['startupText'],
      'taxRate': taxForm['taxRate'] != null ? +taxForm['taxRate'] : null,
      'winTaxRate': taxForm['winTaxRate'] != null ? +taxForm['winTaxRate'] : null,
      'winTaxMode': +taxForm['winTaxMode'],
      'holdLimiterEnabled': this.station.holdLimiterEnabled,
      'browserEnabled': browserForm['browserEnabled'],
      'browserTimeout': browserForm['browserTimeout'] != null ? +browserForm['browserTimeout'] : null,
      'browserKeyword': browserForm['browserKeyword'],
      'provisionPercent': conditionsForm['provisionPercent'] != null ? +conditionsForm['provisionPercent'] : null,
      'bookmakerLicense': conditionsForm['bookmakerLicense'] != null ? +conditionsForm['bookmakerLicense'] : null,
      'bookmakerTax': conditionsForm['bookmakerTax'] != null ? +conditionsForm['bookmakerTax'] : null,
      'termsAckRequired': conditionsForm['termsAckRequired'],
      'termsAckURL': conditionsForm['termsAckURL'],
      'terminalMenuTitle': adminForm['terminalMenuTitle'],
      'terminalTicketPrint': terminalSettings2Form['terminalTicketPrint'],
      'terminalDefaultApp': terminalSettings2Form['terminalDefaultApp'] != null ? +terminalSettings2Form['terminalDefaultApp'] : null,
      'terminalCanExit': terminalSettings2Form['terminalCanExit'],
      'terminalAutoExitTimeout': terminalSettings2Form['terminalAutoExitTimeout'] != null ?
        +terminalSettings2Form['terminalAutoExitTimeout'] : null,
      'terminalNoCashSystem': terminalSettings2Form['terminalNoCashSystem'],
      'tssServerHost': this.station.tssServerHost,
      'tssServerPort': this.station.tssServerPort,
      'mK_CompanyID': mkCompanyExportForm['mK_CompanyIDActive'] ? this.mappableMKCompany.mK_CompanyID : null,
      'allowedAuthPaymentTypes': this.station.allowedAuthPaymentTypes, // TODO !!!
      'userAccountCashModes': userAccountCashModes,
      'info1': info1Form['info1'],
      'info2': info2Form['info2'],
      'qrCodeURL': adminForm['qrCodeURL'],
      'customerPlayCodeEnabled': customerPlayCodeForm['customerPlayCodeEnabled'],
      'customerPlayCodeIsLocked': customerPlayCodeForm['customerPlayCodeIsLocked'],
      'cashTicketPayinAllowed': settingsForm['cashTicketPayinAllowed'],
      'cashTicketManualOut': settingsForm['cashTicketManualOut'],
      'highestPossCashIn': terminalSettings2Form['highestPossCashIn'] != null ? +terminalSettings2Form['highestPossCashIn'] : null,
      'printAppDetailsOnCashbook': extendedOptionsForm['printAppDetailsOnCashbook'],
      'userID': this.station.userID,
      'lastChange': this.station.lastChange
    };
    return station;
  }

  submitCashStorage(csi: any) {
    this.stationService.submitCashStorage(csi).subscribe(csiNew => {
      this.reload();
      this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
    }, () => {
      this.resetCashStorageQuestions();
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  saveDisburseTypeInfos(dti: any[]) {
    this.stationService.updateDisburseTypeInfos(dti['']).subscribe(() => {
      this.reload();
      this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
    }, () => {
      this.resetPaymentMethodsQuestion();
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  submitHoldLimiter(defaultValue: number | any, addValue = 0) {
    if (defaultValue.defaultValue != null) {
      defaultValue = defaultValue.defaultValue;
    }
    this.stationService.createHoldLimiter(this.station.stationID, defaultValue, addValue).subscribe(hlNew => {
      this.holdLimiter = hlNew;
      this.reset();
      this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
    }, () => {
      this.reset();
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  resetHoldLimiter(holdLimiter: HoldLimiter) {
    this.stationService.resetHoldLimiter(holdLimiter).subscribe(hlNew => {
      this.holdLimiter = hlNew;
      this.reset();
      this.snackBar.open(this.translationService.translate('snackBar.holdLimiter.reset'), null, SUCCESS_SNACK_CONFIG);
    }, () => {
      this.reset();
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  toggleActivatedHoldLimiter(activated: boolean, holdLimiter: HoldLimiter) {
    this.stationService.toggleActivateHoldLimiter(activated, holdLimiter).subscribe(() => {
      this.resetHoldLimiterQuestions();
      this.snackBar.open(this.translationService.translate('snackBar.holdLimiter.' + (activated ? '' : 'de') + 'activate'),
        null, SUCCESS_SNACK_CONFIG);
      this.station.holdLimiterEnabled = activated;
      this.resetHoldLimiterQuestions();
    }, () => {
      this.resetHoldLimiterQuestions();
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  resetMacAddress() {
    this.stationService.resetMacAddress(this.station.stationID).subscribe(() => {
      const form = FormUtils.getForm(this.colsComponent.forms, 'station.station.header.terminalSettings');
      const currentValues = form.getRawValue();
      currentValues['mac'] = null;
      form.patchValue(currentValues);
      this.snackBar.open(this.translationService.translate('snackBar.station.resetMacAddress'), null, SUCCESS_SNACK_CONFIG);
    }, () => {
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  private resetHoldLimiterQuestions() {
    this.holdLimiterQuestions = [];
    if (this.holdLimiter) {
      const holdLimiterQuestionContainer = new QuestionContainer('station.station.header.holdLimiter', [
        new HoldLimiterQuestion({
          key: 'holdLimiter',
          value: this.holdLimiter,
          activated: this.station.holdLimiterEnabled,
          submit: this.submitHoldLimiter.bind(this),
          reset: this.resetHoldLimiter.bind(this),
          toggleActivated: this.toggleActivatedHoldLimiter.bind(this)
        })
      ], Permission.editHoldLimiter);
      this.holdLimiterQuestions.push(holdLimiterQuestionContainer);
    } else {
      const holdLimiterQuestionContainer = new QuestionContainer('station.station.header.holdLimiter', [
        new TextboxQuestion({
          key: 'defaultValue',
          label: 'station.station.holdLimiter.defaultValue',
          value: 0
        })
      ], Permission.editHoldLimiter, null);
      holdLimiterQuestionContainer.addSubmit('station.station.holdLimiter.create', this.submitHoldLimiter.bind(this),
        Permission.editHoldLimiter);
      this.holdLimiterQuestions.push(holdLimiterQuestionContainer);
    }
  }

  private resetPaymentMethodsQuestion() {
    this.paymentMethodsQuestions = [];

    const paymentMethodsContainer = new QuestionContainer('station.station.header.paymentMethods', [
      new PaymentMethodsQuestion({
        value: this.disburseTypeInfos
      })
    ], Permission.editDisburseTypeLimits, null);
    if (this.sessionService.hasPermission(Permission.editDisburseTypeLimits)) {
      paymentMethodsContainer.addSubmit('station.station.paymentMethods.save', this.saveDisburseTypeInfos.bind(this));
    }
    this.paymentMethodsQuestions.push(paymentMethodsContainer);
  }

  private resetCashStorageQuestions() {
    this.cashStorageQuestions = [];
    const cashStorageQuestionContainer = new QuestionContainer('station.station.header.cashStorage', [
      new CashStorageQuestion({
        key: 'cashStorageInfo',
        label: 'station.station.cashStorageInfo',
        value: this.cashStorageInfo
      }),
      new TextboxQuestion({
        key: 'floatingValueCoins',
        label: 'station.station.cashStorage.floatingValueCoins',
        editPermission: Permission.editFloatingValues,
        value: (this.cashStorageInfo && this.cashStorageInfo.coinDevice) ? this.cashStorageInfo.coinDevice.floatingValue : null
      }),
      new TextboxQuestion({
        key: 'floatingValueNotes',
        label: 'station.station.cashStorage.floatingValueNotes',
        editPermission: Permission.editFloatingValues,
        value: (this.cashStorageInfo && this.cashStorageInfo.noteDevice) ? this.cashStorageInfo.noteDevice.floatingValue : null
      }),
      new TextboxQuestion({
        key: 'initialStorageNotes',
        label: 'station.station.cashStorage.initialStorageNotes',
        editPermission: Permission.editFloatingValues,
        value: (this.cashStorageInfo && this.cashStorageInfo.noteDevice) ? this.cashStorageInfo.noteDevice.initialStorage : null
      })
    ], null, this.cashStorageInfo ? null : Permission.notAllowed);
    cashStorageQuestionContainer.addSubmit('station.station.cashStorage.saveFloatingValues', this.submitCashStorage.bind(this),
      Permission.editFloatingValues);
    this.cashStorageQuestions.push(cashStorageQuestionContainer);
  }

  private resetStationQuestions() {
    this.stationQuestions = [];
    this.stationQuestions.push(new QuestionContainer('station.station.header.basic', [
      new TextboxQuestion({
        key: 'stationID',
        label: 'station.station.stationID',
        value: this.station.stationID,
        disabled: !this.isNew,
        required: true,
        editPermission: Permission.editBasicStationData
      }),
      new TextboxQuestion({
        key: 'name',
        label: 'station.station.name',
        value: this.station.name,
        editPermission: Permission.editBasicStationData
      }),
      new TextboxQuestion({
        key: 'identifier',
        label: 'station.station.identifier',
        value: this.station.identifier,
        editPermission: Permission.editBasicStationData
      }),
      new TextboxQuestion({
        key: 'pin',
        label: 'station.station.pin',
        value: this.station.pin,
        required: true,
        editPermission: Permission.editBasicStationData
      }),
      new TextboxQuestion({
        key: 'pin2',
        label: 'station.station.pin2',
        value: this.station.piN2,
        required: true,
        editPermissionsSome: [Permission.editBasicStationData, Permission.editStationLimitedPIN2]
      }),
      new TextboxQuestion({
        key: 'hardwareSerialNumber',
        label: 'station.station.hardwareSerialNumber',
        value: this.station.hardwareSerialNumber,
        editPermission: Permission.editBasicStationData
      }),
      new TextboxQuestion({
        key: 'hardwareType',
        label: 'station.station.hardwareType',
        value: this.station.hardwareType,
        editPermission: Permission.editBasicStationData
      }),
      new DateTimeQuestion({
        key: 'registrationDate',
        label: 'station.station.registrationDate',
        value: this.station.registrationDate,
        hideTime: true,
        editPermission: Permission.editBasicStationData
      }),
      new DateTimeQuestion({
        key: 'deregistrationDate',
        label: 'station.station.deregistrationDate',
        value: this.station.deregistrationDate,
        hideTime: true,
        editPermission: Permission.editBasicStationData
      })
    ]));

    const terminalSettingsQuestionContainer = new QuestionContainer('station.station.header.terminalSettings', [
      new CheckboxQuestion({
        key: 'online',
        label: 'station.station.online',
        value: this.station.online,
        disabled: true
      }),
      new TextboxQuestion({
        key: 'credit',
        label: 'station.station.credit',
        value: this.credit ? this.credit.value : 0,
        disabled: true
      }),
      new TextboxQuestion({
        key: 'bonusCashValue',
        label: 'station.station.bonusCashValue',
        value: this.credit ? this.credit.bonusCashValue : 0,
        disabled: true
      }),
      new TextboxQuestion({
        key: 'realCashValue',
        label: 'station.station.realCashValue',
        value: this.credit ? this.credit.realCashValue : 0,
        disabled: true
      }),
      new TextboxQuestion({
        key: 'lastLogin',
        label: 'station.station.lastLogin',
        value: this.datePipe.transform(this.station.lastLogin, 'medium'),
        disabled: true
      }),
      new TextboxQuestion({
        key: 'lastLogout',
        label: 'station.station.lastLogout',
        value: this.datePipe.transform(this.station.lastLogout, 'medium'),
        type: 'datetime',
        disabled: true
      }),
      new TextboxQuestion({
        key: 'teamviewerID',
        label: 'station.station.teamviewerID',
        value: this.station.teamviewerID,
        disabled: true,
        copy: true
      }),
      new TextboxQuestion({
        key: 'mac',
        label: 'station.station.mac',
        value: `MAC: ${this.station.mac}      IP: ${this.station.clientIP}`,
        disabled: true
      }),
      new TextareaQuestion({
        key: 'info',
        label: 'station.station.info',
        value: this.station.info,
        minRows: 5,
        disabled: true
      })
    ]);
    if (this.sessionService.hasPermission(Permission.resetMAC)) {
      terminalSettingsQuestionContainer.addSubmit('station.station.resetMacAddress', this.resetMacAddress.bind(this));
    }
    this.stationQuestions.push(terminalSettingsQuestionContainer);

    const franchiserSettingsQuestionContainer = new QuestionContainer('station.station.header.franchiser', [
      new DropdownQuestion({
        key: 'franchiserID',
        label: 'station.station.franchiserID',
        value: this.station.franchiserID.toString(),
        editPermission: Permission.changeFranchiser,
        options: Array.from(this.franchisers.entries()).map(([key, value]) => ({key, value}))
      }),
      new TextareaQuestion({
        key: 'franchiserHierarchy',
        label: 'station.station.franchiserHierarchy',
        value: this.franchiserHierarchy,
        minRows: 5,
        disabled: true
      })
    ]);
    franchiserSettingsQuestionContainer.addSubmit('station.station.showInTree',
      () => this.customerTreeService.showTreeNode.next(
        {id: this.station.stationID, type: TreeNodeType.STATION}));
    this.stationQuestions.push(franchiserSettingsQuestionContainer);

    this.stationQuestions.push(new QuestionContainer('station.station.header.admin', [
      new CheckboxQuestion({
        key: 'hardLocked',
        label: 'station.station.hardLocked',
        value: this.station.hardLocked,
        editPermissionsSome: [Permission.editBasicStationData, Permission.editStationLimitedHardLock]
      }),
      new CheckboxQuestion({
        key: 'rF2MultiCycle',
        label: 'station.station.rF2MultiCycle',
        value: this.station.rF2MultiCycle,
        viewPermission: this.isTerminal() ? null : Permission.notAllowed,
        editPermission: Permission.editBasicStationData
      }),
      new TextboxQuestion({
        key: 'terminalMenuTitle',
        label: 'station.station.terminalMenuTitle',
        value: this.station.terminalMenuTitle,
        viewPermission: this.isTerminal() ? null : Permission.notAllowed,
        editPermission: Permission.editBasicStationData
      }),
      new TextareaQuestion({
        key: 'qrCodeURL',
        label: 'station.station.qrCodeURL',
        value: this.station.qrCodeURL,
        minRows: 2,
        editPermission: Permission.editBasicStationData
      })
    ], null, Permission.editStationLimited));

    this.stationQuestions.push(new QuestionContainer('station.station.header.webshop', [
      new DropdownQuestion({
        key: 'webStationID',
        label: 'station.station.webStationID',
        value: (this.station.webStationID || '0').toString(),
        options: Array.from(this.webshops.entries()).map(([key, value]) => ({key, value}))
      }),
    ], Permission.editWebshopConnection, this.isWebShop() ? Permission.notAllowed : null));

    this.stationQuestions.push(new QuestionContainer('station.station.header.webshopConnections', [
      new TextboxQuestion({
        key: 'stationsConnectedToWebshopCount',
        label: 'station.station.stationsConnectedToWebshopCount',
        value: this.stationsConnectedToWebshop.length.toString(),
        disabled: true
      }),
      new TextareaQuestion({
        key: 'stationsConnectedToWebshop',
        label: 'station.station.stationsConnectedToWebshop',
        value: this.stationsConnectedToWebshop.join('\n'),
        disabled: true,
        minRows: Math.max(this.stationsConnectedToWebshop.length || 1, 5)
      }),
    ], null, this.isWebShop() ? null : Permission.notAllowed));


    this.stationQuestions.push(new QuestionContainer('station.station.header.customerPlayCode', [
      new CheckboxQuestion({
        key: 'customerPlayCodeEnabled',
        label: 'station.station.customerPlayCodeEnabled',
        value: this.station.customerPlayCodeEnabled,
        editPermission: Permission.editBasicStationData
      }),
      new CheckboxQuestion({
        key: 'customerPlayCodeIsLocked',
        label: 'station.station.customerPlayCodeIsLocked',
        value: this.station.customerPlayCodeIsLocked,
        disabled: !this.station.customerPlayCodeEnabled,
        editPermission: Permission.editBasicStationData
      })
    ], null, Permission.insertStation));

    this.stationQuestions.push(new QuestionContainer('station.station.header.settings', [
      new DropdownQuestion({
        key: 'defaultLanguage',
        label: 'station.station.defaultLanguage',
        value: this.station.defaultLanguage,
        options: this.languages.map(language => ({'key': language.languageCode, 'value': language.name})),
        editPermission: Permission.editBasicStationData
      }),
      new DropdownQuestion({
        key: 'type',
        label: 'station.station.type',
        value: this.station.type.toString(),
        editPermission: this.sessionService.hasPermission(Permission.changeStationType, Permission.editAdditionalStationData)
          ? null : Permission.notAllowed,
        options: Object.values(StationType)
          .map(stationType => !isNaN(Number(stationType)) ? ({
            'key': stationType.toString(),
            'value': StationType[stationType]
          }) : null)
          .filter(pair => !!pair)
      }),
      new CheckboxQuestion({
        key: 'shopMode',
        label: 'station.station.shopMode',
        editPermission: Permission.editAdditionalStationData,
        value: this.station.shopMode
      }),
      new CheckboxQuestion({
        key: 'softLocked',
        label: 'station.station.softLocked',
        value: this.station.softLocked,
        editPermissionsSome: [Permission.editBasicStationData, Permission.editStationLimitedSoftLock]
      }),
      new CheckboxQuestion({
        key: 'cashTicketPayinAllowed',
        label: 'station.station.cashTicketPayinAllowed',
        value: this.station.cashTicketPayinAllowed,
        editPermission: Permission.editTerminalNoCashSystem
      }),
      new CheckboxQuestion({
        key: 'cashTicketManualOut',
        label: 'station.station.cashTicketManualOut',
        value: this.station.cashTicketManualOut,
        editPermission: Permission.editAdvancedAdminSettings
      })
    ]));

    this.stationQuestions.push(new QuestionContainer('station.station.header.games', [
      new CheckboxQuestion({
        key: 'pB1Enabled',
        label: 'station.station.pB1Enabled',
        editPermission: Permission.editAdditionalStationData,
        value: this.station.pB1Enabled
      }),
      new CheckboxQuestion({
        key: 'rF2Enabled',
        label: 'station.station.rF2Enabled',
        editPermission: Permission.editAdditionalStationData,
        value: this.station.rF2Enabled
      }),
      new CheckboxQuestion({
        key: 'bB1Enabled',
        label: 'station.station.bB1Enabled',
        editPermission: Permission.editAdditionalStationData,
        value: this.station.bB1Enabled
      }),
      new CheckboxQuestion({
        key: 'lB2Enabled',
        label: 'station.station.lB2Enabled',
        editPermission: Permission.editAdditionalStationData,
        value: this.station.lB2Enabled
      }),
      new CheckboxQuestion({
        key: 'cT1Enabled',
        label: 'station.station.cT1Enabled',
        editPermission: Permission.editAdditionalStationData,
        value: this.station.cT1Enabled
      }),
      new CheckboxQuestion({
        key: 'thirdPartyEnabled',
        label: 'station.station.thirdPartyEnabled',
        editPermission: Permission.editAdditionalStationData,
        value: this.station.thirdPartyEnabled
      })
    ]));

    this.stationQuestions.push(new QuestionContainer('station.station.header.terminalSettings2', [
      new DropdownQuestion({
        key: 'terminalDefaultApp',
        label: 'station.station.terminalDefaultApp',
        value: this.station.terminalDefaultApp.toString(),
        options: Array.from(this.terminalDefaultApps.entries()).map(([key, value]) => ({
          key: key.toString(),
          value: this.translationService.translate('station.station.terminalDefaultApps.' + value)
        })),
        editPermission: Permission.editBasicStationData
      }),
      new CheckboxQuestion({
        key: 'terminalTicketPrint',
        label: 'station.station.terminalTicketPrint',
        value: this.station.terminalTicketPrint,
        editPermission: Permission.editBasicStationData
      }),
      new CheckboxQuestion({
        key: 'terminalCanExit',
        label: 'station.station.terminalCanExit',
        value: this.station.terminalCanExit,
        editPermission: Permission.editBasicStationData
      }),
      new TextboxQuestion({
        key: 'terminalAutoExitTimeout',
        label: 'station.station.terminalAutoExitTimeout',
        value: this.station.terminalAutoExitTimeout,
        type: 'number',
        editPermission: Permission.editBasicStationData
      }),
      new CheckboxQuestion({
        key: 'terminalNoCashSystem',
        label: 'station.station.terminalNoCashSystem',
        viewPermission: Permission.editTerminalNoCashSystem,
        value: this.station.terminalNoCashSystem,
        editPermission: Permission.editBasicStationData
      }),
      new TextboxQuestion({
        key: 'highestPossCashIn',
        label: 'station.station.highestPossCashIn',
        value: this.station.highestPossCashIn,
        type: 'number',
        editPermission: Permission.editBasicStationData
      })
    ], null, this.station.type === StationType.TERMINAL ? null : Permission.notAllowed));

    this.stationQuestions.push(new QuestionContainer('station.station.header.localization', [
      new DropdownQuestion({
        key: 'countryCode',
        label: 'station.station.countryCode',
        value: this.station.countryCode,
        editPermission: Permission.editAdditionalStationData,
        options: this.countries.map(country => ({'key': country.countryCode, 'value': country.name}))
      }),
      new DropdownQuestion({
        key: 'currencyCode',
        label: 'station.station.currencyCode',
        value: this.station.currencyCode,
        editPermission: Permission.editAdditionalStationData,
        options: this.currencies.map(currency => ({
          'key': currency.currencyCode,
          'value': currency.description
        }))
      }),
      new TextareaQuestion({
        key: 'address',
        label: 'station.station.address',
        value: this.station.address,
        minRows: 5,
        editPermission: Permission.editAdditionalStationData
      })
    ], Permission.editAdditionalStationData, null));

    this.stationQuestions.push(new QuestionContainer('station.station.header.ticket', [
      new CheckboxQuestion({
        key: 'printAddress',
        label: 'station.station.printAddress',
        value: this.station.printAddress,
        editPermission: Permission.editAdditionalStationData
      }),
      new TextareaQuestion({
        key: 'ticketText',
        label: 'station.station.ticketText',
        value: this.station.ticketText,
        minRows: 5,
        editPermission: Permission.editBasicStationData
      })
    ]));

    this.stationQuestions.push(new QuestionContainer('station.station.header.info1', [
      new TextareaQuestion({
        key: 'info1',
        label: 'station.station.info1',
        value: this.station.info1,
        minRows: 4,
        editPermission: Permission.editBasicStationData
      })
    ]));
    this.stationQuestions.push(new QuestionContainer('station.station.header.extendedOptions', [
      new TextareaQuestion({
        key: 'startupText',
        label: 'station.station.startupText',
        value: this.station.startupText,
        minRows: 2,
        editPermission: Permission.editBasicStationData
      }),
      new CheckboxQuestion({
        key: 'printAppDetailsOnCashbook',
        label: 'station.station.printAppDetailsOnCashbook',
        value: this.station.printAppDetailsOnCashbook,
        editPermission: Permission.editBasicStationData
      })
    ]));
    this.stationQuestions.push(new QuestionContainer('station.station.header.info2', [
      new TextareaQuestion({
        key: 'info2',
        label: 'station.station.info2',
        value: this.station.info2,
        editPermissionsSome: [Permission.editBasicStationData, Permission.editStationLimitedInfo2],
        minRows: 4
      })
    ]));
    this.stationQuestions.push(new QuestionContainer('station.station.header.userAccount', [
      new CheckboxQuestion({
        key: 'userAccountCashMode_allowTerminalLogin',
        label: 'station.station.userAccountCashMode_allowTerminalLogin',
        value: this.userAccountCashMode_allowTerminalLogin
      }),
      new CheckboxQuestion({
        key: 'userAccountCashMode_lockCashSystemWhenLoggedOut',
        label: 'station.station.userAccountCashMode_lockCashSystemWhenLoggedOut',
        value: this.userAccountCashMode_lockCashSystemWhenLoggedOut
      }),
      new CheckboxQuestion({
        key: 'userAccountCashMode_allowPayout',
        label: 'station.station.userAccountCashMode_allowPayout',
        value: this.userAccountCashMode_allowPayout
      }),
    ], Permission.editWebshopConnection, null));

    this.stationQuestions.push(new QuestionContainer('station.station.header.conditions', [
      new TextboxQuestion({
        key: 'provisionPercent',
        label: 'station.station.provisionPercent',
        value: this.station.provisionPercent,
        type: 'number',
        editPermission: Permission.editBasicStationData
      }),
      new TextboxQuestion({
        key: 'bookmakerLicense',
        label: 'station.station.bookmakerLicense',
        value: this.station.bookmakerLicense,
        type: 'number',
        editPermission: Permission.editBasicStationData
      }),
      new TextboxQuestion({
        key: 'bookmakerTax',
        label: 'station.station.bookmakerTax',
        value: this.station.bookmakerTax,
        type: 'number',
        editPermission: Permission.editBasicStationData
      }),
      new CheckboxQuestion({
        key: 'termsAckRequired',
        label: 'station.station.termsAckRequired',
        value: this.station.termsAckRequired,
        viewPermission: this.isTerminal() ? null : Permission.notAllowed,
        editPermission: Permission.editBasicStationData
      }),
      new TextareaQuestion({
        key: 'termsAckURL',
        label: 'station.station.termsAckURL',
        value: this.station.termsAckURL,
        viewPermission: this.isTerminal() ? null : Permission.notAllowed,
        minRows: 2,
        editPermission: Permission.editBasicStationData
      }),
    ], null, Permission.editTermsConditions));
    this.stationQuestions.push(new QuestionContainer('station.station.header.browser', [
      new CheckboxQuestion({
        key: 'browserEnabled',
        label: 'station.station.browserEnabled',
        value: this.station.browserEnabled,
        editPermission: Permission.editBasicStationData
      }),
      new TextboxQuestion({
        key: 'browserTimeout',
        label: 'station.station.browserTimeout',
        value: this.station.browserTimeout,
        type: 'number',
        editPermission: Permission.editBasicStationData
      }),
      new TextboxQuestion({
        key: 'browserKeyword',
        label: 'station.station.browserKeyword',
        value: this.station.browserKeyword,
        editPermission: Permission.editBasicStationData
      }),
    ], null, Permission.editBrowserMode));
    this.stationQuestions.push(new QuestionContainer('station.station.header.tax', [
      new RadioQuestion({
        key: 'winTaxMode',
        label: 'station.station.winTaxMode',
        value: this.station.winTaxMode.toString(),
        options: [{'key': '0', 'value': 'Basis = Gewinn - Einsatz'},
          {'key': '1', 'value': 'Basis = Gewinn'},
          {'key': '2', 'value': 'Basis = Gewinn (Steuer ganzzahlig)'}]
      }),
      new TextboxQuestion({
        key: 'taxRate',
        label: 'station.station.taxRate',
        value: this.station.taxRate,
        type: 'number'
      }),
      new TextboxQuestion({
        key: 'winTaxRate',
        label: 'station.station.winTaxRate',
        value: this.station.winTaxRate,
        type: 'number'
      }),
    ], Permission.editGamesTaxRates, null));
    this.stationQuestions.push(new QuestionContainer('station.station.header.publicRevenueExport', [
      new CheckboxQuestion({
        key: 'mK_CompanyIDActive',
        label: 'station.station.mappableMKCompany.mK_CompanyIDActive',
        value: this.station.mK_CompanyID != null,
        editPermission: Permission.editMKTicketExportDetails
      }),
      new TextareaQuestion({
        key: 'mK_CompanyID',
        label: 'station.station.mappableMKCompany.mK_CompanyID',
        value: this.station.mK_CompanyID != null ? this.mappableMKCompany.companyCode : null,
        disabled: true
      })
    ], null, this.mappableMKCompany ? Permission.viewMKTicketExportDetails : Permission.notAllowed));
  }

  private isWebShop() {
    return this.station.type === StationType.WEBSHOP;
  }

  private isShop() {
    return this.station.type === StationType.SHOP;
  }

  private isTerminal() {
    return this.station.type === StationType.TERMINAL;
  }

  copy(): void {
    this.dialog.open(StationCopyComponent, {
      width: '40%',
      minWidth: '300px',
      data: {
        'station': this.station,
        'franchisers': Array.from(this.franchisers.entries()).map(([key, value]) => ({key, value}))
      }
    });
  }
}
