import {Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {QueryFilter} from '../domain/query-filter';
import {QuestionContainer} from '../domain/question-container';
import {FormUtils} from '../utils/form-utils';
import {DropdownQuestion} from '../domain/question-dropdown';
import {SessionService} from '../services/session.service';
import {TextboxQuestion} from '../domain/question-textbox';
import {CheckboxQuestion} from '../domain/question-checkbox';
import {TranslationService} from '../services/translation.service';
import {TimeFrameQuestion} from '../domain/question-timeframe';
import * as moment from 'moment';
import {DurationInputArg2} from 'moment';
import {environment} from '../../environments/environment';
import {Permission} from '../domain/permission';
import {DropdownValue} from '../dynamic-form/dropdown-value';
import {BalanceService} from '../home/balance/balance.service';
import {DateUtils} from '../utils/date-utils';
import {TicketStatus} from '../domain/ticket-status';
import {SystemConfig} from '../config/system-config';
import {ColsComponent} from '../cols/cols.component';
import {SearchTicketStatus} from './search-ticket-status';
import {MobileService} from '../services/mobile.service';
import {mapMap, sortAlphabetically, sortByNumber} from '../../utils';
import {ResultsService} from '../services/results.service';
import {CustomerTreeService} from '../services/customer-tree.service';
import {StationType} from '../domain/station-type';
import {UserManagementService} from '../home/user-management/user-management.service';
import {DatePipe} from '@angular/common';
import {CustomerTreeNode} from '../domain/customer-tree-node';
import {ActivatedRoute} from '@angular/router';
import {StationUtils} from '../utils/station-utils';
import {TreeNodeType} from '../domain/tree-node-type';
import {GroupsService} from '../services/groups.service';
import {SubSink} from 'subsink';


@Component({
  selector: 'vit-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit, OnDestroy {

  @HostBinding('style.maxHeight.px') maxHeight;
  initialMaxHeight: number;

  @Input()
  submitted = false;
  @Input()
  hideGames = false;
  @Input()
  hideStation = false;
  @Input()
  hideStationRestrictions = false;
  @Input()
  hideTimeframe = false;
  @Input()
  hideIgnoreTest = false;
  @Input()
  hideMinTickets = false;
  @Input()
  hideMinStake = false;
  @Input()
  hideMinProfit = false;
  @Input()
  hideIntoEuro = false;
  @Input()
  hideDetails = false;
  @Input()
  onlyMonths = false;
  @Input()
  onlyLastMonths = false;
  @Input()
  onlyLastMonths2 = false;
  @Input()
  hideTimeInput = false;
  @Input()
  showNow = false;
  @Input()
  showDateOfLastAccountingButton = false;
  @Input()
  showCashTicket = false;
  @Input()
  showTicket = false;
  @Input()
  showUserSearch = false;
  @Input()
  showSettings = false;
  @Input()
  sumNotRepresentable = false;
  @Input()
  rowCount;
  @Input()
  submitLabel;
  @Input()
  showRaceCycleID = false;
  @Input()
  showBingoCycleID = false;
  @Input()
  hideFranchiser = false;
  @Input()
  showGroups = false;
  @Input()
  hideIgnoreSub = false;
  @Input()
  showUserAccountId = false;
  @Input()
  showCustomerId = false;
  @Input()
  showWebshops = false;
  @Input()
  showWebshopsAll = false;
  @Input()
  hideAllStations = false;
  @Input()
  showCustomerIdInHeader = false;
  @Input()
  suggestCustomerIds = false;
  @Input()
  showIgnoreOnline = false;
  @Input()
  showIgnoreOffline = false;
  @Input()
  showIgnoreLocked = false;
  @Input()
  showIgnoreUnlocked = false;
  @Input()
  requireUserAccountId = false;
  @Input()
  onlyTerminals = false;
  @Input()
  showExtendedOptions = false;
  @Input()
  showMatch = false;
  @Input()
  customerIdPanelTitle = '';
  @Input()
  anomaly = false;
  @Output()
  anomalyTrigger = new EventEmitter<void>();

  selectedFranchiser;
  selectedStation;
  selectedGroup;

  @Output()
  submitEmiter: EventEmitter<QueryFilter> = new EventEmitter();

  @ViewChild(ColsComponent, {static: true})
  colsComponent: ColsComponent;

  franchisers: DropdownValue[] = [];
  groups: DropdownValue[] = [];
  pb1Stations: DropdownValue[] = [];
  rf2RaceCycles: DropdownValue[] = [];
  bb1RaceCycles: DropdownValue[] = [];
  selectedFranchiserID: string;

  loading: boolean;
  submitDate: Date;

  searchFormQuestions: QuestionContainer[] = [];
  query: QueryFilter;

  params: any;

  ts = 0;

  private subs = new SubSink();

  constructor(public sessionService: SessionService,
              public elementRef: ElementRef,
              public mobileService: MobileService,
              public userManagementService: UserManagementService,
              public translationService: TranslationService,
              public customerTreeService: CustomerTreeService,
              public balanceService: BalanceService,
              public route: ActivatedRoute,
              public datePipe: DatePipe,
              public groupsService: GroupsService,
              public resultsService: ResultsService) {
    this.ts = new Date().getTime();
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
  }

  logTs(msg: string) {
    console.log('SF: ' + msg + ' ' + (new Date().getTime() - this.ts) + 'ms since component start');
  }

  ngOnInit() {
    if (this.sessionService.franchisers.length === 0) {
      setTimeout(() => this.ngOnInit(), 500);
      return;
    }
    this.logTs('ngOnInit');
    this.sessionService.loading = true;
    setTimeout(() => {
      this.sessionService.user.permissions = this.sessionService.user.permissions.filter(p => p !== Permission.dateOfLastAccounting);
      this.initialMaxHeight = 9999;
      this.maxHeight = this.initialMaxHeight;

      if (!this.hideFranchiser) {
        this.customerTreeService.tree.subscribe(node => {
          this.logTs('processing tree');
          if (node && !this.submitted) {
            this.franchisers = [];
            this.franchisers.push(...this.sessionService.franchisers.map(f => {
              return {
                'key': f.node.id.toString(),
                'value': f.node.label
              };
            }));
            this.franchisers.unshift({value: this.translationService.translate('searchForm.all'), key: '-1'});
            this.logTs('fetched franchisers');
            sortAlphabetically(this.franchisers, 'value');
            this.logTs('sorted franchisers');
            this.reset();
          }
        });
      } else {
        this.franchisers = [];
        this.logTs('franchisers are empty');
        this.reset();
      }

      if (this.showRaceCycleID) {
        this.resultsService.fetchRF2Cycles().subscribe(rcs => {
          this.rf2RaceCycles = Array.from(mapMap(rcs).entries()).map(([key, value]) => ({
            key: key.toString(),
            value: value.toString()
          }));
          this.logTs('fetched rf2 cycles');
          this.rf2RaceCycles.unshift({value: this.translationService.translate('searchForm.all'), key: '0'});
          this.reset();
          this.logTs('reset rf2 cycles');
        });
      }

      if (this.showBingoCycleID) {
        this.resultsService.fetchBB1Cycles().subscribe(bcs => {
          this.bb1RaceCycles = Array.from(mapMap(bcs).entries()).map(([key, value]) => ({
            key: key.toString(),
            value: value.toString()
          }));
          this.logTs('fetched bb1 cycles');
          this.bb1RaceCycles.unshift({value: this.translationService.translate('searchForm.all'), key: '0'});
          this.reset();
          this.logTs('reset rf2 cycles');
        });
      }

      if (this.showGroups && this.sessionService.hasPermission(Permission.editAdvancedAdminSettings)) {
        // load groups
        this.groupsService.fetchGroups().subscribe(groups => {
          this.groups = groups.map(entity => {
            return {
              key: entity.franchiserStationGroupID,
              value: entity.name
            };
          });
          this.logTs('fetched groups');
          this.groups.unshift({value: this.translationService.translate('searchForm.all'), key: '0'});
          this.sessionService.groups = this.groups;
          this.reset();
          this.logTs('reset groups');
        });
      }

      // TODO: delete this its just for dev
      if (!environment.production) {
        //  setTimeout(() => this.submit(), 500);
      }

      this.subs.sink = this.sessionService.treeNodeSelected.subscribe((node: CustomerTreeNode) => {
        this.logTs('tree node selected');
        if (this.sessionService.enableTreeNodeSelection) {

          // if (node.type === 0 && this.hideFranchiser) {
          //   return;
          // }
          if (node.type === 1 && this.hideStation && !this.showWebshops && !this.showWebshopsAll) {
            return;
          }


          if (node.type === 0) {
            // franchiser
            const form = FormUtils.getForm(this.colsComponent.forms, 'searchForm.header.franchiser');
            form.patchValue({franchiserID: '' + node.id, stationID: null});
            this.logTs('patch franchiser id');
          } else if (node.type === 1) {
            // station
            const form = FormUtils.getForm(this.colsComponent.forms, 'searchForm.header.franchiser');
            form.patchValue({stationID: '' + node.id, franchiserID: null});
            this.logTs('patch station id');
          }
          if (!this.submitted) {
            if (node.type === 0) {
              this.franchiserChanged(node.id);
              this.logTs('frachiser changed');
            } else if (node.type === 1) {
              this.onStationIDChange('' + node.id);
              this.logTs('station change');
            }
          } else {
            this.query = this.generateSearchFormQuery();
            this.selectedStation = this.sessionService.stations.filter(s => s.node.id === this.query.stationID)[0];
            this.selectedFranchiser = this.sessionService.franchisers.filter(s => s.node.id === this.query.franchiserID)[0];
            this.refresh();
            this.logTs('refresh');
          }
        }
      });

      if (!this.hideFranchiser) {
        this.sessionService.enableTreeNodeSelection = true;
      } else if (!this.hideStation) {
        this.sessionService.enableTreeNodeSelection = true;
      } else if (this.showGroups) {
        this.sessionService.enableTreeNodeSelection = true; // mpo: should probably be false, but need to check this first
      } else if (this.showWebshops || this.showWebshopsAll) {
        this.sessionService.enableTreeNodeSelection = true;
      } else {
        this.sessionService.enableTreeNodeSelection = false;
      }
      this.sessionService.loading = false;
    }, 50);
  }

  ngOnDestroy() {
    this.sessionService.enableTreeNodeSelection = false;
    this.subs.unsubscribe();
  }

  timeBack() {
    const timeFrameForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'searchForm.header.timeFrame');
    const diff = moment(timeFrameForm['timeFrame'].beginTime).diff(moment(timeFrameForm['timeFrame'].endTime), 'days');
    this.changeTime(diff);
  }


  timeForward() {
    const timeFrameForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'searchForm.header.timeFrame');
    const diff = moment(timeFrameForm['timeFrame'].endTime).diff(moment(timeFrameForm['timeFrame'].beginTime), 'days');
    this.changeTime(diff);
  }

  changeTime(diff: number) {
    const direction: number = diff > 0 ? 1 : -1;
    const timeFrameForm = FormUtils.getForm(this.colsComponent.forms, 'searchForm.header.timeFrame');

    diff = Math.abs(diff);
    const beginTime = moment(timeFrameForm.value['timeFrame'].beginTime);
    const endTime = moment(timeFrameForm.value['timeFrame'].endTime);

    let unit: DurationInputArg2 = 'days';
    if (diff === 1) {
      unit = 'days';
    } else if (diff < 10) {
      unit = 'week';
    } else {
      unit = 'month';
    }

    beginTime.add(direction, unit);
    endTime.add(direction, unit);

    timeFrameForm.patchValue({
      timeFrame: {
        beginTime: beginTime.valueOf(),
        endTime: endTime.valueOf()
      }
    });

    this.query = this.generateSearchFormQuery();
    this.refresh();
  }

  submitDisabled() {
    if (!this.colsComponent || !this.colsComponent.forms) {
      return true;
    }

    const ticketForm: any = FormUtils.getFormValues(this.colsComponent.forms,
      (this.showUserAccountId && !this.showTicket ? 'userAccount' : 'ticket'));
    if (ticketForm == null) {
      return true;
    }

    if (this.requireUserAccountId && !ticketForm['userAccountID']) {
      return false;
    }

    const gameRestrictionForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'searchForm.header.gameRestriction');
    if (gameRestrictionForm == null) {
      return true;
    }

    const ignorePB1 = gameRestrictionForm['ignorePB1'];
    const ignoreRF2 = gameRestrictionForm['ignoreRF2'];
    const ignoreBB1 = gameRestrictionForm['ignoreBB1'];
    const ignoreLB2 = gameRestrictionForm['ignoreLB2'];

    let selectedCount = 0;
    selectedCount += ignorePB1 ? 1 : 0;
    selectedCount += ignoreRF2 ? 1 : 0;
    selectedCount += ignoreBB1 ? 1 : 0;
    selectedCount += ignoreLB2 ? 1 : 0;

    return ticketForm['eventId'] === '' || selectedCount === 3;
  }

  submit() {
    this.submitDate = new Date();
    this.maxHeight = this.mobileService.isMobile ? 40 : 110;
    setTimeout(() => {
      const query = this.generateSearchFormQuery();
      this.query = query;
      this.selectedStation = this.sessionService.stations.filter(s => s.node.id === query.stationID)[0];
      this.selectedFranchiser = this.sessionService.franchisers.filter(s => s.node.id === query.franchiserID)[0];
      this.selectedGroup = this.sessionService.groups.filter(g => +g.key === query.groupID)[0];

      if (!this.submitted) {
        this.submitEmiter.emit(JSON.parse(JSON.stringify(this.query)));
      }
      this.submitted = true;
    }, 300);
  }

  back() {
    this.submitted = false;
    this.maxHeight = this.initialMaxHeight;
    this.submitEmiter.emit(null);
  }

  refresh() {
    this.submitDate = new Date();
    this.submitEmiter.emit(JSON.parse(JSON.stringify(this.query)));
  }

  reset() {
    this.logTs('reset');
    const stations = this.sessionService.stations
      .filter(s => !this.onlyTerminals || s.node.stationType === StationType.TERMINAL)
      .map(s => ({
        key: s.node.id.toString(),
        value: s.node.name,
        icon: StationUtils.getTextIcon(TreeNodeType.STATION, s.node.stationType)
      }));
    const webshops = this.sessionService.stations.filter(s => s.node.stationType === StationType.WEBSHOP)
      .map(s => ({'key': s.node.id.toString(), 'value': s.node.name}));
    sortByNumber(webshops, 'key');
    if (this.showWebshopsAll) {
      webshops.unshift({value: this.translationService.translate('searchForm.all'), key: '0'});
    }
    this.logTs('webshops mapped');

    const customerId = this.params['customerId'];
    const userAccountId = this.params['userAccountId'];

    this.submitted = false;
    this.maxHeight = this.initialMaxHeight;
    this.submitEmiter.emit(null);
    this.searchFormQuestions = [];
    const franchiserContainer = new QuestionContainer('searchForm.header.franchiser', [
      new DropdownQuestion({
        key: 'franchiserID',
        label: 'searchForm.franchiserID',
        value: '-1',
        clear: true,
        clearTo: '-1',
        options: this.franchisers,
        onChange: this.franchiserChanged.bind(this),
        visible: !this.hideFranchiser
      }),
      this.hideStation ? null : new DropdownQuestion({
        key: 'stationID',
        label: 'searchForm.stationID',
        value: (this.hideAllStations && stations.length > 0) ? stations[0].key : '0',
        clear: true,
        clearTo: '0',
        onChange: this.onStationIDChange.bind(this),
        options: (() => {
          sortByNumber(stations, 'key');
          if (!this.hideAllStations) {
            stations.unshift({value: this.translationService.translate('searchForm.all'), key: '0', icon: ''});
          }
          return stations;
        })()
      }),
      (this.showWebshops || this.showWebshopsAll) ? new DropdownQuestion({
        key: 'stationID',
        label: 'searchForm.webshopID',
        clear: this.showWebshopsAll,
        clearTo: '0',
        value: (!this.showWebshopsAll && webshops.length > 0) ? webshops[0].key : '0',
        options: webshops
      }) : null,
      this.showGroups ? new DropdownQuestion({
        key: 'groupID',
        label: 'searchForm.groupID',
        value: (this.showGroups && this.groups.length > 0) ? this.groups[0].key : '0',
        clear: true,
        clearTo: '0',
        options: this.groups
      }) : null
    ].filter(el => !!el));

    this.searchFormQuestions.push(franchiserContainer);

    const userContainer = new QuestionContainer('searchForm.header.user', [
      new DropdownQuestion({
        key: 'franchiserID',
        label: 'searchForm.franchiserID',
        value: '-1',
        clear: true,
        clearTo: '-1',
        options: this.franchisers
      }),
      new DropdownQuestion({
        key: 'userID',
        label: 'searchForm.userID',
        value: '0',
        clear: true,
        clearTo: '0',
        options: []
      })], null, this.showUserSearch ? null : Permission.notAllowed);
    this.searchFormQuestions.push(userContainer);

    if (this.showUserSearch) {
      setTimeout(() => {
        this.onUserSearchFranchiserChanged(-1);
      }, 500);
    }

    const timeframeContainer = new QuestionContainer('searchForm.header.timeFrame', [
      new TimeFrameQuestion({
        key: 'timeFrame',
        label: 'franchiser.franchiser.timeFrame',
        onlyMonths: this.onlyMonths,
        onlyLastMonths: this.onlyLastMonths,
        onlyLastMonths2: this.onlyLastMonths2,
        hideInput: this.hideTimeInput
      })
    ], null, this.hideTimeframe ? Permission.notAllowed : null);
    if (this.showDateOfLastAccountingButton) {
      timeframeContainer.addSubmit('searchForm.dateOfLastAccounting',
        this.dateOfLastAccounting.bind(this), Permission.dateOfLastAccounting);
    }
    this.searchFormQuestions.push(timeframeContainer);

    const cashTicketContainer = new QuestionContainer('searchForm.header.cashTicket', [
      new DropdownQuestion({
        key: 'ticketStatus',
        label: 'searchForm.ticketStatus',
        value: '0',
        clear: true,
        clearTo: '0',
        options: Object.values(TicketStatus)
          .map(ticketStatus => !isNaN(Number(ticketStatus)) ? ({
            'key': ticketStatus.toString(),
            'value': this.translationService.translate('searchForm.cashTicketStatus_' + TicketStatus[ticketStatus])
          }) : null)
          .filter(pair => !!pair)
      }),
      new TextboxQuestion({
        key: 'ticketNumber',
        label: 'searchForm.ticketNumber',
        value: '',
      })
    ], null, this.showCashTicket ? null : Permission.notAllowed);

    this.searchFormQuestions.push(cashTicketContainer);
    const ticketContainer = new QuestionContainer(this.getCustomerContainerLabel(), [
      new DropdownQuestion({
        key: 'ticketStatus',
        label: 'searchForm.ticketStatus',
        value: '0',
        clear: true,
        clearTo: '0',
        options: Object.values(SearchTicketStatus)
          .map(ticketStatus => !isNaN(Number(ticketStatus)) ? ({
            'key': ticketStatus.toString(),
            'value': this.translationService.translate('searchForm.ticketStatus_' + SearchTicketStatus[ticketStatus])
          }) : null)
          .filter(pair => !!pair),
        viewPermission: this.showTicket ? null : Permission.notAllowed
      }),
      new TextboxQuestion({
        key: 'ticketNumber',
        label: 'searchForm.ticketNumber',
        value: '',
        viewPermission: this.showTicket ? null : Permission.notAllowed
      }),
      new TextboxQuestion({
        key: 'eventId',
        label: 'searchForm.eventId',
        value: '',
        viewPermission: this.showTicket ? null : Permission.notAllowed
      }),
      new TextboxQuestion({
        key: 'customerID',
        label: 'searchForm.customerID',
        value: customerId ? customerId : '',
        type: 'number',
        viewPermission: this.showCustomerId ? null : Permission.notAllowed,
        autocomplete: JSON.parse(localStorage.getItem(SystemConfig.LocalStorageConstants.RECENT_CUSTOMER_IDS) || '[]')
      }),
      new TextboxQuestion({
        key: 'userAccountID',
        label: 'searchForm.userAccountID',
        value: userAccountId ? userAccountId : '',
        type: 'number',
        viewPermission: this.showUserAccountId ? null : Permission.notAllowed,
        autocomplete: JSON.parse(localStorage.getItem(SystemConfig.LocalStorageConstants.RECENT_USER_ACCOUNT_IDS) || '[]')
      })
    ], null, this.showTicket || this.showUserAccountId || this.showCustomerId ? null : Permission.notAllowed);

    this.searchFormQuestions.push(ticketContainer);
    const gameRestrictionContainer = new QuestionContainer('searchForm.header.gameRestriction', [
      new CheckboxQuestion({
        key: 'ignoreRF2',
        label: 'searchForm.ignoreRF2',
        value: false
      }),
      new CheckboxQuestion({
        key: 'ignorePB1',
        label: 'searchForm.ignorePB1',
        value: false
      }),
      new CheckboxQuestion({
        key: 'ignoreBB1',
        label: 'searchForm.ignoreBB1',
        value: false
      }),
      new CheckboxQuestion({
        key: 'ignoreLB2',
        label: 'searchForm.ignoreLB2',
        value: false
      }),
    ], null, this.hideGames ? Permission.notAllowed : null);
    this.searchFormQuestions.push(gameRestrictionContainer);


    if (this.showMatch) {
      const matchContainer = new QuestionContainer('searchForm.header.match', [
        new TextboxQuestion({
          key: 'brMatchID',
          label: 'searchForm.brMatchID',
          value: ''
        }),
        new TextboxQuestion({
          key: 'brTmtID',
          label: 'searchForm.brTmtID',
          value: '',
        }),
        new TextboxQuestion({
          key: 'limitUtilPct',
          label: 'searchForm.limitUtilPct',
          type: 'number',
          value: '50',
        })
      ]);
      this.searchFormQuestions.push(matchContainer);
    }

    const stationRestrictionContainer = new QuestionContainer('searchForm.header.stationRestriction', [
      new CheckboxQuestion({
        key: 'ignoreShop',
        label: 'searchForm.ignoreShop',
        value: false
      }),
      new CheckboxQuestion({
        key: 'ignoreTerminal',
        label: 'searchForm.ignoreTerminal',
        value: false
      }),
      new CheckboxQuestion({
        key: 'ignoreWebshop',
        label: 'searchForm.ignoreWebshop',
        value: false
      }),
      new CheckboxQuestion({
        key: 'ignoreBridge',
        label: 'searchForm.ignoreBridge',
        value: false
      }),
      new CheckboxQuestion({
        key: 'ignoreCashier',
        label: 'searchForm.ignoreCashier',
        value: false
      })
    ], null, this.hideStationRestrictions ? Permission.notAllowed : null);
    this.searchFormQuestions.push(stationRestrictionContainer);

    const stationStatusContainer = new QuestionContainer('searchForm.header.stationStatus', [
      new CheckboxQuestion({
        key: 'ignoreOnline',
        label: 'searchForm.ignoreOnline',
        value: false,
        viewPermission: this.showIgnoreOnline ? null : Permission.notAllowed,
      }),
      new CheckboxQuestion({
        key: 'ignoreOffline',
        label: 'searchForm.ignoreOffline',
        value: false,
        viewPermission: this.showIgnoreOffline ? null : Permission.notAllowed,
      }),
      new CheckboxQuestion({
        key: 'ignoreLocked',
        label: 'searchForm.ignoreLocked',
        value: false,
        viewPermission: this.showIgnoreLocked ? null : Permission.notAllowed,
      }),
      new CheckboxQuestion({
        key: 'ignoreUnlocked',
        label: 'searchForm.ignoreUnlocked',
        value: false,
        viewPermission: this.showIgnoreUnlocked ? null : Permission.notAllowed,
      }),
    ]);
    if (this.showIgnoreOnline || this.showIgnoreOffline || this.showIgnoreLocked || this.showIgnoreUnlocked) {
      this.searchFormQuestions.push(stationStatusContainer);
    }

    const extendedOptionsContainer = new QuestionContainer('searchForm.header.extendedOptions', [
      new CheckboxQuestion({
        key: 'queryModeJustStations',
        label: 'searchForm.queryModeJustStations',
        value: false,
        onChange: (value) => {
          if (value) {
            FormUtils.getForm(this.colsComponent.forms, 'searchForm.header.extendedOptions').controls['queryModeAtLeastOneStation'].patchValue(false);
          }
        },
        desc: 'searchForm.queryModeJustStationsDesc',
      }),
      new CheckboxQuestion({
        key: 'queryModeAtLeastOneStation',
        label: 'searchForm.queryModeAtLeastOneStation',
        value: false,
        onChange: (value) => {
          if (value) {
            FormUtils.getForm(this.colsComponent.forms, 'searchForm.header.extendedOptions').controls['queryModeJustStations'].patchValue(false);
          }
        },
        desc: 'searchForm.queryModeAtLeastOneStationDesc',
      })], null, this.showExtendedOptions ? null : Permission.notAllowed);
    this.searchFormQuestions.push(extendedOptionsContainer);

    const detailRestrictionContainer = new QuestionContainer('searchForm.header.detailRestriction', [
      new CheckboxQuestion({
        key: 'ignoreSubFranchiser',
        label: 'searchForm.ignoreSubFranchiser',
        viewPermission: this.hideIgnoreSub ? Permission.notAllowed : null,
        value: false
      }),
      this.hideIgnoreTest ? null : new CheckboxQuestion({
        key: 'ignoreTest',
        label: 'searchForm.ignoreTest',
        value: true
      }),
      new TextboxQuestion({
        key: 'minTickets',
        label: 'searchForm.minTickets',
        value: 1,
        type: 'number',
        viewPermission: this.hideMinTickets ? Permission.notAllowed : null
      }),
      new TextboxQuestion({
        key: 'minStake',
        label: 'searchForm.minStake',
        value: 0,
        type: 'number',
        viewPermission: this.hideMinStake ? Permission.notAllowed : null
      }),
      new TextboxQuestion({
        key: 'minProfit',
        label: 'searchForm.minProfit',
        value: 0,
        type: 'number',
        viewPermission: this.hideMinProfit ? Permission.notAllowed : null
      }),
      new CheckboxQuestion({
        key: 'intoEuro',
        label: 'searchForm.intoEuro',
        value: localStorage.getItem(SystemConfig.LocalStorageConstants.CALC_IN_EUR) === 'true',
        viewPermission: this.hideIntoEuro ? Permission.notAllowed : null,
        onChange: (value) => localStorage.setItem(SystemConfig.LocalStorageConstants.CALC_IN_EUR, '' + value)
      }),
      new DropdownQuestion({
        key: 'raceCycleID',
        label: 'searchForm.raceCycleID',
        value: '0',
        clear: true,
        clearTo: '0',
        options: this.rf2RaceCycles,
        viewPermission: this.showRaceCycleID ? null : Permission.notAllowed
      }),
      new DropdownQuestion({
        key: 'bingoCycleID',
        label: 'searchForm.bingoCycleID',
        value: '0',
        clear: true,
        clearTo: '0',
        options: this.bb1RaceCycles,
        viewPermission: this.showBingoCycleID ? null : Permission.notAllowed
      })
    ].filter(el => !!el), null, this.hideDetails ? Permission.notAllowed : null);
    this.searchFormQuestions.push(detailRestrictionContainer);

    const settingsContainer = new QuestionContainer('searchForm.header.settings', [
      new CheckboxQuestion({
        key: 'includeAnonymousAccountings',
        label: 'searchForm.includeAnonymousAccountings',
        value: false
      })], null, this.showSettings ? null : Permission.notAllowed);
    this.searchFormQuestions.push(settingsContainer);

    this.logTs('all containers initiated');
  }

  isNotAuthorizeToView(questionContainer: QuestionContainer): boolean {
    return questionContainer.viewPermission && !this.sessionService.hasPermission(questionContainer.viewPermission);
  }

  private getCustomerContainerLabel() {
    return 'searchForm.header.' + (this.customerIdPanelTitle ? 'customerIdPanelTitle' : (this.showUserAccountId && !this.showTicket ? 'userAccount' : 'ticket'));
  }

  private dateOfLastAccounting() {
    const franchiserForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'searchForm.header.franchiser');
    this.balanceService.fetchDateOfLastAccounting(franchiserForm['stationID']).subscribe((dateString: string) => {
      const form = FormUtils.getForm(this.colsComponent.forms, 'searchForm.header.timeFrame');
      const currentValues = form.getRawValue();
      const newBeginTime = moment(dateString, 'YYYY-MM-DDTHH:mm:ss.SSSZ').valueOf();
      currentValues['timeFrame'].beginTime = newBeginTime;
      currentValues['timeFrame'].endTime = DateUtils.setTimeToZero(moment().add(1, 'days').valueOf());
      form.patchValue(currentValues);
    });
  }

  private onUserSearchFranchiserChanged(franchiserID: number) {
    // TODO: find stationID dropdown dynamically!!
    this.userManagementService.fetchUsers(+franchiserID === -1 ? 0 : franchiserID).subscribe((options: any[]) => {
      const questions = this.searchFormQuestions.find(sf => sf.label === 'searchForm.header.user').questions;
      const question = questions.find(q => q.key === 'userID');
      options.unshift({value: this.translationService.translate('searchForm.all'), key: '0'});
      (question as DropdownQuestion).options = options;
      questions[1] = Object.assign({}, question);
      FormUtils.getForm(this.colsComponent.forms, 'searchForm.header.user').controls['userID'].patchValue('0');
    });
  }

  private onStationIDChange(id: string) {
    if (this.showDateOfLastAccountingButton) {
      if (id === '0') {
        this.sessionService.user.permissions = this.sessionService.user.permissions.filter(p => p !== Permission.dateOfLastAccounting);
      } else {
        this.sessionService.user.permissions.push(Permission.dateOfLastAccounting);
      }
    }
  }

  private generateSearchFormQuery() {
    const franchiserForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'searchForm.header.franchiser');
    const timeFrameForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'searchForm.header.timeFrame');
    const gameRestrictionForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'searchForm.header.gameRestriction');
    const stationRestrictionForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'searchForm.header.stationRestriction');
    const matchForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'searchForm.header.match');
    const stationStatusForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'searchForm.header.stationStatus');
    const detailRestrictionForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'searchForm.header.detailRestriction');
    const ticketForm: any = FormUtils.getFormValues(this.colsComponent.forms, this.getCustomerContainerLabel());
    const cashTicketForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'searchForm.header.cashTicket');
    const extendedOptionsForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'searchForm.header.extendedOptions');

    const userForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'searchForm.header.user');
    const settingsForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'searchForm.header.settings');

    const from = moment(timeFrameForm['timeFrame'].beginTime);
    const to = moment(timeFrameForm['timeFrame'].endTime);

    const queryModeJustStations = extendedOptionsForm['queryModeJustStations'];
    const queryModeAtLeastOneStation = extendedOptionsForm['queryModeAtLeastOneStation'];

    const queryMode = queryModeJustStations ? 'franchisers_containing_just_stations'
      : queryModeAtLeastOneStation ? 'franchisers_containing_at_least_one_station' : null;

    // if (!this.sessionService.hasPermission(Permission.editAdvancedAdminSettings) && to.diff(from, 'months') > 6) {
    //   from = moment(to).subtract(6, 'months');
    // }

    let franchiserId = (franchiserForm['franchiserID'] && franchiserForm['franchiserID'] !== '-1') ? +franchiserForm['franchiserID'] : 0;

    if (this.showUserSearch) {
      franchiserId = (userForm['franchiserID'] && userForm['franchiserID'] !== '-1') ? +userForm['franchiserID'] : 0;
    }

    const query: QueryFilter = {
      'queryMode': queryMode,
      'franchiserID': franchiserId,
      'stationID': franchiserForm['stationID'] ? +franchiserForm['stationID'] : 0,
      'countryCode': '',
      'dateFrom': from.format('YYYY-MM-DD') + 'T' + from.format('HH:mm:ss'),
      'dateTo': to.format('YYYY-MM-DD') + 'T' + to.format('HH:mm:ss'),
      'ignorePB1': gameRestrictionForm['ignorePB1'],
      'ignoreRF2': gameRestrictionForm['ignoreRF2'],
      'ignoreBB1': gameRestrictionForm['ignoreBB1'],
      'ignoreLB2': gameRestrictionForm['ignoreLB2'],
      'brTmtID': matchForm ? matchForm['brTmtID'] : undefined,
      'brMatchID': matchForm ? matchForm['brMatchID'] : undefined,
      'limitUtilPct': matchForm && matchForm['limitUtilPct'] ? matchForm['limitUtilPct'] : undefined,
      'ignoreOnline': stationStatusForm ? stationStatusForm['ignoreOnline'] : false,
      'ignoreOffline': stationStatusForm ? stationStatusForm['ignoreOffline'] : false,
      'ignoreLocked': stationStatusForm ? stationStatusForm['ignoreLocked'] : false,
      'ignoreUnlocked': stationStatusForm ? stationStatusForm['ignoreUnlocked'] : false,
      'customerID': ticketForm['customerID'],
      'userAccountID': ticketForm['userAccountID'],
      'ignoreShop': stationRestrictionForm['ignoreShop'],
      'ignoreTerminal': stationRestrictionForm['ignoreTerminal'],
      'flag': settingsForm['includeAnonymousAccountings'],
      'userID': userForm['userID'] ? +userForm['userID'] : null,
      'ignoreTerminalShop': false,
      'ignoreBridge': stationRestrictionForm['ignoreBridge'],
      'ignoreWebshop': stationRestrictionForm['ignoreWebshop'],
      'ignoreCashier': stationRestrictionForm['ignoreCashier'],
      'minStake': detailRestrictionForm['minStake'] != null ? +detailRestrictionForm['minStake'] : 0,
      'minProfit': detailRestrictionForm['minProfit'] != null ? +detailRestrictionForm['minProfit'] : 0,
      'ignoreSubFranchiser': detailRestrictionForm['ignoreSubFranchiser'],
      'ignoreTest': !!detailRestrictionForm['ignoreTest'],
      'minTickets': detailRestrictionForm['minTickets'] != null ? +detailRestrictionForm['minTickets'] : 0,
      'intoEuro': detailRestrictionForm['intoEuro'],
      'eventID': ticketForm['eventId'] != null ? +ticketForm['eventId'] : 0,
      'ticketStatus': this.showTicket ? (ticketForm['ticketStatus'] != null ? +ticketForm['ticketStatus'] : 0) :
        (cashTicketForm['ticketStatus'] != null ? +cashTicketForm['ticketStatus'] : 0),
      'ticketNumber': this.showTicket ? (ticketForm['ticketNumber'] != null ? +ticketForm['ticketNumber'] : 0) :
        (cashTicketForm['ticketNumber'] != null ? +cashTicketForm['ticketNumber'] : 0),
      'cashbookNumber': 0,
      'raceCycleID': detailRestrictionForm['raceCycleID'] ? +detailRestrictionForm['raceCycleID'] : null,
      'bingoCycleID': detailRestrictionForm['bingoCycleID'] ? +detailRestrictionForm['bingoCycleID'] : null,
      'groupID': franchiserForm['groupID'] ? +franchiserForm['groupID'] : 0
    };
    return query;
  }

  private franchiserChanged(franchiserID: number, stationId = 0) {
    console.log('ID', franchiserID);
    this.selectedFranchiserID = franchiserID.toString();
    // TODO: find stationID dropdown dynamically!!
    const stations = this.sessionService.stations.filter(s =>
      s.parentIds.indexOf(+this.selectedFranchiserID) > -1)
      .map(s => ({'key': s.node.id.toString(), 'value': s.node.name}));
    sortByNumber(stations, 'key');
    stations.unshift({value: this.translationService.translate('searchForm.all'), key: '0'});
    if (this.searchFormQuestions[0].questions[1]) {
      (this.searchFormQuestions[0].questions[1] as DropdownQuestion).options = stations;
      this.searchFormQuestions[0].questions[1] = Object.assign({}, this.searchFormQuestions[0].questions[1]);
      FormUtils.getForm(this.colsComponent.forms, 'searchForm.header.franchiser').controls['stationID'].patchValue('' + stationId);
      this.onStationIDChange('0');
    }
  }

}
