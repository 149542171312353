import { QuestionBase } from './question-base';

export class TextareaQuestion extends QuestionBase<string> {
    controlType = 'textarea';
    minRows;

    constructor(options: {} = {}) {
        super(options);
        this.minRows = options['minRows'] || 1;
    }
}
