import {Component, OnInit} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {getContextMenuItems, isNumber} from 'utils';
import {loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {NumbersHoldOverviewService} from './numbers-hold-overview.service';
import {StationUtils} from '../../utils/station-utils';
import {TreeNodeType} from '../../domain/tree-node-type';
import {GridApi, GridReadyEvent} from 'ag-grid-community';
import {map} from 'rxjs/operators';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-numbers-hold-overview',
  templateUrl: './numbers-hold-overview.component.html',
  styleUrls: ['./numbers-hold-overview.component.scss']
})
export class NumbersHoldOverviewComponent implements OnInit {

  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;

  columnDefs;
  gridOptions;
  rowData;
  titleRow;
  detailParams;

  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public numbersHoldOverviewService: NumbersHoldOverviewService,
              public translationService: TranslationService,
              public httpService: HttpService,
              public sessionService: SessionService,
              public datePipe: DatePipe,
              public decimalPipe: DecimalPipe,
              public dialog: MatDialog) {
    this.columnDefs = this.generateColumnDefs();
    this.gridOptions = this.generateGridOptions();
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('numbersHoldOverview', event);
  }

  ngOnInit() {
  }

  fetch(query: QueryFilter) {
    if (query) {
      this.query = query;
      this.rowData = this.numbersHoldOverviewService.fetch(query).pipe(
        map(data => data.filter(d => {
          const isTitleRow = d.stationID === 0;
            if (isTitleRow) {
              this.titleRow = [d];
            }
            return !isTitleRow;
        }))
      );
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('numbersHoldOverview.type'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'type',
        cellClass: 'type',
        pinned: 'left',
        width: 23,
        cellRenderer: function (params) {
          const icon = StationUtils.getIcon(TreeNodeType.STATION, params.data.type, false, params.data.softLocked, params.data.hardLocked);
          const img = '<img src=\'/assets/img/icons/' + icon + '\'>';
          return params.data.stationID === 0 ? '' : img;
        }
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.stationID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationID',
        pinned: 'left',
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40,
        headerTooltip: this.translationService.translate('numbersHoldOverview.stationID')
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.stationName'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.stationName'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationName',
        pinned: 'left',
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH_LARGE,
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.toEuroFactor'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.toEuroFactorTT'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'toEuroFactor',
        cellClass: 'text-align-right',
        hide: true,
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.num'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.numTT'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'num',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.stake'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.stakeTT'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stake',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.profit'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.profitTT'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'profit',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.profitNotDisbursed'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.profitNotDisbursedTT'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'profitNotDisbursed',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.jackpotProfit'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.jackpotProfitTT'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'jackpotProfit',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.hold'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.holdTT'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'hold',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.holdPercent'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.holdPercentTT'),
        valueFormatter: this.percentFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'holdPercent',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.kNum'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.kNumTT'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'kNum',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.kStake'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.kStakeTT'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'kStake',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.kProfit'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.kProfitTT'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'kProfit',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.kProfitNotDisbursed'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.kProfitNotDisbursedTT'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'kProfitNotDisbursed',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.kJackpotProfit'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.kJackpotProfitTT'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'kJackpotProfit',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.kHold'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.kHoldTT'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'kHold',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.kHoldPercent'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.kHoldPercentTT'),
        valueFormatter: this.percentFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'kHoldPercent',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.lsNum'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.lsNumTT'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'lsNum',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.lsStake'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.lsStakeTT'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'lsStake',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.lsProfit'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.lsProfitTT'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'lsProfit',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.lsProfitNotDisbursed'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.lsProfitNotDisbursedTT'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'lsProfitNotDisbursed',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.lsJackpotProfit'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.lsJackpotProfitTT'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'lsJackpotProfit',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.lsHold'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.lsHoldTT'),
        valueFormatter: this.decimalFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'lsHold',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      },
      {
        headerName: this.translationService.translate('numbersHoldOverview.lsHoldPercent'),
        headerTooltip: this.translationService.translate('numbersHoldOverview.lsHoldPercentTT'),
        valueFormatter: this.percentFormatter.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'lsHoldPercent',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: NumbersHoldOverviewComponent.COLUMN_WIDTH
      }
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: false,
      detailRowHeight: 160,
      isRowMaster: (data) => data.stationID !== 0,
      rowClassRules: {
        'bold': (params) => params.data.stationID === 0,
        'text-align-right': (params) => isNumber(params.data.value)
      },
      onCellClicked: (params) => {
        params.node.setExpanded(!params.node.expanded);
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }
}
