import {Injectable} from '@angular/core';
import {SessionService} from './session.service';
import {HttpService} from './http.service';
import {CustomerTreeNode} from '../domain/customer-tree-node';
import {SystemConfig} from '../config/system-config';
import {TreeNodeType} from '../domain/tree-node-type';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerTreeService {

  _tree: BehaviorSubject<CustomerTreeNode> = new BehaviorSubject(null);
  showTreeNode: Subject<{id: number, type: TreeNodeType}> = new Subject();
  treeAge = 0;
  maxTreeAge = 10 * 60 * 1000;


  get tree(): BehaviorSubject<CustomerTreeNode> {
    this.fetchTreeMaybe();
    return this._tree;
  }

  set tree(tree: BehaviorSubject<CustomerTreeNode>) {
    this._tree = tree;
  }

  constructor(public httpService: HttpService,
              public sessionService: SessionService) {
  }

  fetchTreeMaybe() {
    const isTreeExpired = new Date().getTime() - this.treeAge > this.maxTreeAge;
    if (isTreeExpired && this.sessionService.isLoggedIn()) {
      this.fetchTree(true);
    }
  }

  fetchTree(skipLoading = false) {
    this.treeAge = new Date().getTime();
    this.httpService.get(SystemConfig.Endpoints.TREE, skipLoading).subscribe((tree: CustomerTreeNode) => {
      this.sessionService.franchisers = [];
      this.sessionService.stations = [];
      this.sessionService.groups = [];
      this.tree.next(this.mapAndProcess([-1], null, tree));
      this.sessionService.franchisers.sort((v1, v2) => v1.node.label.toLowerCase() > v2.node.label.toLowerCase() ? 1 : -1);
    });
  }

  getTreeNode(nodeToFind: CustomerTreeNode, node?: CustomerTreeNode) {
    node = node ? node : this.tree.getValue();
    if (node.id === nodeToFind.id && node.type === nodeToFind.type) {
      return node;
    } else if (node.children != null) {
      let i;
      let result = null;
      for (i = 0; result == null && i < node.children.length; i++) {
        result = this.getTreeNode(nodeToFind, node.children[i]);
      }
      return result;
    }
    return null;
  }

  private mapAndProcess(parentIds: number[], parentType: TreeNodeType, json: any): CustomerTreeNode {
    const node: CustomerTreeNode = new CustomerTreeNode();
    node.name = json.name;
    node.id = json.id;
    node.parentId = parentIds[0];
    node.parentType = parentType;
    node.type = json.type;
    node.stationType = json.stationType;
    node.isOnline = json.isOnline;
    node.isSoftLocked = json.isSoftLocked;
    node.isHardLocked = json.isHardLocked;
    node.isTest = json.isTest;
    node.hasChildren = json.children.length > 0;
    node.children = json.children.map(childrenJson => this.mapAndProcess([node.id, ...parentIds], node.type, childrenJson));

    if (node.type === TreeNodeType.FRANCHISER) {
      this.sessionService.franchisers.push({'parentIds': parentIds, 'node': node});
    } else {
      this.sessionService.stations.push({'parentIds': parentIds, 'node': node});
    }
    return node;
  }
}
