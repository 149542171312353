import { Station } from './station';
import { Language } from './language';
import { Country } from './country';
import { Currency } from './currency';
import { Credit } from './credit';
import { HoldLimiter } from './hold-limiter';
import { MappableMkCompany } from './mappable-mk-company';
import { DisburseTypeInfo } from './disburse-type-info';
import { CashStorageInfo } from './cash-storage-info';

export class StationData {
    station: Station;
    lstFranchisers: Map<string, string>;
    lstLanguage: Language[];
    lstCountry: Country[];
    lstCurrency: Currency[];
    credit: Credit;
    holdLimiter: HoldLimiter;
    mappableMKCompany: MappableMkCompany;
    lstDisburseTypeInfo: DisburseTypeInfo[];
    cashStorageInfo: CashStorageInfo;
    lstWebshops: Map<string, string>;
    terminalDefaultApps: Map<string, string>;
    lstStationsConnectedToWebshop: string[];
    franchiserHierarchy: string;
}
