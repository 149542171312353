import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {SessionService} from './session.service';
import {SystemConfig} from '../config/system-config';
import {map} from 'rxjs/operators';
import {mapMap} from '../../utils';
import {Observable} from 'rxjs/index';
import {FranchiserData} from '../domain/franchiser-data';
import {Franchiser} from '../domain/franchiser';

@Injectable({
  providedIn: 'root',
})
export class FranchiserService {

  update(franchiser: Franchiser): Observable<null> {
    return this.httpService.post(SystemConfig.Endpoints.FRANCHISER_UPDATE, franchiser);
  }

  insert(franchiser: Franchiser): Observable<Franchiser> {
    return this.httpService.post(SystemConfig.Endpoints.FRANCHISER_INSERT, franchiser);
  }

  constructor(public httpService: HttpService,
              public sessionService: SessionService) {
  }

  fetchFranchiserData(franchiserId: number): Observable<FranchiserData> {
    if (franchiserId === -1) {
      return this.httpService.get(SystemConfig.Endpoints.FRANCHISER_DUMMY).pipe(map((json) => this.map(json)));

    } else {
      return this.httpService.get(SystemConfig.Endpoints.FRANCHISER + franchiserId).pipe(map((json) => this.map(json)));
    }
  }

  map(json: any): FranchiserData {
    const franchiserData: FranchiserData = new FranchiserData();
    franchiserData.franchiser = json.franchiser;
    franchiserData.lstMasterFranchisers = mapMap(json.lstMasterFranchisers);
    franchiserData.usersForFranchiser = json.usersForFranchiser ? json.usersForFranchiser : [];
    franchiserData.treeStats = mapMap(json.treeStats);
    franchiserData.lstInvoicePaymentTypes = mapMap(json.lstInvoicePaymentTypes);
    franchiserData.lstInvoiceDeliveryTypes = mapMap(json.lstInvoiceDeliveryTypes);
    franchiserData.lstPaymentTypes = mapMap(json.lstPaymentTypes);
    franchiserData.franchiserHierarchy = json.franchiserHierarchy;
    franchiserData.userAccountManagerDescription = json.userAccountManagerDescription;
    return franchiserData;
  }

}
