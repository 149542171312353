import { Component, OnInit } from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Component({
  selector: 'vit-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {

  start;
  class;
  value;
  valueStr;

  constructor(public decimalPipe: DecimalPipe) { }

  ngOnInit() {
    this.start = new Date().getTime();
    setInterval(() => {
      this.value = (new Date().getTime() - this.start) / 1000;
      this.valueStr = this.decimalPipe.transform((new Date().getTime() - this.start) / 1000, '0.1-1');
    }, 50);
  }

}
