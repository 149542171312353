import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {SystemConfig} from '../../config/system-config';
import {GameNumber} from '../../domain/game-number';
import {QueryFilter} from '../../domain/query-filter';

@Component({
  selector: 'vit-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

  data: { query: QueryFilter, gameNumber: GameNumber };

  constructor(public route: ActivatedRoute) {
  }


  ngOnInit() {
    // TODO: maybe hide the menu point or add some kind of usability if its not routed via new-tab on turnover
    this.route.paramMap.subscribe((params: ParamMap) => {
      const query = JSON.parse(atob(decodeURI(params.get(SystemConfig.Routes.Params.QUERY))));
      const gameNumber = +params.get(SystemConfig.Routes.Params.GAME_NUMBER);
      this.data = {query: query, gameNumber: gameNumber};
    });
  }
}
