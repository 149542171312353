import {Component, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {QuestionContainer} from '../../../domain/question-container';
import {TextboxQuestion} from '../../../domain/question-textbox';
import {TranslationService} from '../../../services/translation.service';
import {DynamicFormComponent} from '../../../dynamic-form/dynamic-form.component';
import {Router} from '@angular/router';
import {GameService} from '../../../services/game.service';
import {ERROR_SNACK_CONFIG, SUCCESS_SNACK_CONFIG} from '../../../../utils';
import {DropdownQuestion} from '../../../domain/question-dropdown';

@Component({
  selector: 'vit-combi-bonus-copy',
  templateUrl: './combi-bonus-copy.component.html',
  styleUrls: ['./combi-bonus-copy.component.scss']
})
export class CombiBonusCopyComponent implements OnInit {

  @ViewChild(DynamicFormComponent, { static: true })
  stationForm: DynamicFormComponent;

  stationID: number;
  loading: boolean;

  copyQuestions: QuestionContainer;

  constructor(public dialogRef: MatDialogRef<CombiBonusCopyComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { 'stationID': number },
              public gameService: GameService,
              public ngZone: NgZone,
              public snackBar: MatSnackBar,
              public translationService: TranslationService,
              public router: Router) {
    this.stationID = data.stationID;
  }

  ngOnInit() {
    this.reset();
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.loading = true;
    const form = this.stationForm.form.value;
    console.log('form', form);
    this.gameService.copyCombiBonus({
      'sourceStationID': this.stationID,
      'destinationStationID': form['type'] === 'copyToDestinationStation' ? (+form['destinationStationID'] || 0) : 0,
      'copyToStationsOnSameFranchiserLevel': form['type'] === 'copyToStationsOnSameFranchiserLevel',
      'copyToStationsOnSameAndSubFranchiserLevel': form['type'] === 'copyToStationsOnSameAndSubFranchiserLevel'
    }).subscribe(() => {
      this.loading = false;
      this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
      this.dialogRef.close();
    }, () => {
      this.loading = false;
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  reset() {
    this.copyQuestions = new QuestionContainer('combiBonusCopy.form', [
      new DropdownQuestion({
        key: 'type',
        label: 'combiBonusCopy.type',
        value: 'copyToDestinationStation',
        onChange: this.onSelectionChange.bind(this),
        options: ['copyToDestinationStation', 'copyToStationsOnSameFranchiserLevel', 'copyToStationsOnSameAndSubFranchiserLevel']
          .map(label => {
            return {
              'key': label,
              'value': this.translationService.translate('combiBonusCopy.' + label)
            };
          })
      }),
      new TextboxQuestion({
        key: 'destinationStationID',
        label: 'combiBonusCopy.destinationStationID',
        type: 'number'
      })
    ]);
  }

  private onSelectionChange(val: string) {
    if (val === 'copyToDestinationStation') {
      this.stationForm.form.controls['destinationStationID'].enable();
    } else {
      this.stationForm.form.controls['destinationStationID'].disable();
    }
  }
}
