export enum Bb1LsColors {
  Red = 1,
  Green = 2,
  Blue = 3,
  Magenta = 4,
  Brown = 5,
  Yellow = 6,
  Orange = 7,
  Black = 8,
}
