import {Component, OnInit} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {StationUtils} from '../../utils/station-utils';
import {TreeNodeType} from '../../domain/tree-node-type';
import {getContextMenuItems, isNumber} from 'utils';
import {ThirdPartyBalanceService} from './third-party-balance.service';
import {enrichByStationId, loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {GridReadyEvent} from 'ag-grid-community';
import {map} from 'rxjs/operators';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-third-party-balance',
  templateUrl: './third-party-balance.component.html',
  styleUrls: ['./third-party-balance.component.scss']
})
export class ThirdPartyBalanceComponent implements OnInit {

  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;
  columnDefs;
  gridOptions;
  rowData;
  titleRow;
  detailParams;

  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public thirdPartyBalanceService: ThirdPartyBalanceService,
              public translationService: TranslationService,
              public httpService: HttpService,
              public sessionService: SessionService,
              public datePipe: DatePipe,
              public decimalPipe: DecimalPipe) {
    this.columnDefs = this.generateColumnDefs();
    this.detailParams = this.generateDetailParams();
    this.gridOptions = this.generateGridOptions();
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('thirdPartyBalance', event);
  }

  ngOnInit() {
  }

  fetch(query: QueryFilter) {
    if (query) {
      this.query = query;
      this.rowData = this.thirdPartyBalanceService.fetch(query).pipe(
        map(data => data.filter(d => {
          const isTitleRow = d.stationID === 0;
          if (isTitleRow) {
            this.titleRow = [d];
          }
          return !isTitleRow;
        }))
      );
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('balanceThirdParty.type'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'type',
        cellClass: 'type',
        width: 23,
        cellRenderer: function (params) {
          const icon = StationUtils.getIcon(TreeNodeType.STATION, params.data.type, false, params.data.softLocked, params.data.hardLocked);
          const img = '<img src=\'/assets/img/icons/' + icon + '\'>';
          return params.data.stationID === 0 ? '' : img;
        }
      },
      {
        headerName: this.translationService.translate('balanceThirdParty.stationID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationID',
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40,
        headerTooltip: this.translationService.translate('balanceThirdParty.stationID')
      },
      {
        headerName: this.translationService.translate('balanceThirdParty.stationName'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationName',
        width: ThirdPartyBalanceComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('balanceThirdParty.stationName')
      },
      {
        headerName: this.translationService.translate('balanceThirdParty.moneyToCasino'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'moneyToCasino',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: ThirdPartyBalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balanceThirdParty.moneyToCasino')
      },
      {
        headerName: this.translationService.translate('balanceThirdParty.moneyFromCasino'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'moneyFromCasino',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: ThirdPartyBalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balanceThirdParty.moneyFromCasino')
      },
      {
        headerName: this.translationService.translate('balanceThirdParty.balance'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'balance',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: ThirdPartyBalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balanceThirdParty.balance')
      },
      {
        headerName: this.translationService.translate('balanceThirdParty.balancePercent'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'balancePercent',
        valueFormatter: this.percentFormatter.bind(this),
        hide: true,
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: ThirdPartyBalanceComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('balanceThirdParty.balancePercent')
      }
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: true,
      detailRowHeight: 290,
      isRowMaster: (data) => data.stationID !== 0,
      rowClassRules: {
        'bold': (params) => params.data.stationID === 0,
        'text-align-right': (params) => isNumber(params.data.value)
      },
      onCellClicked: (params) => {
        params.node.setExpanded(!params.node.expanded);
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }


  generateDetailParams() {
    return {
      detailGridOptions: {
        toolPanelSuppressSideButtons: false,
        enableSorting: true,
        toolPanelSuppressValues: true,
        toolPanelSuppressRowGroups: true,
        toolPanelSuppressPivotMode: true,
        enableFilter: true,
        enableColResize: true,
        columnDefs: [
          {
            headerName: this.translationService.translate('balanceThirdParty.userAccountID'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'userAccountID',
            width: ThirdPartyBalanceComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balanceThirdParty.userAccountID')
          },
          {
            headerName: this.translationService.translate('balanceThirdParty.username'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'username',
            width: ThirdPartyBalanceComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balanceThirdParty.username')
          },
          {
            headerName: this.translationService.translate('balanceThirdParty.numGames'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'numGames',
            cellClass: 'text-align-right',
            width: ThirdPartyBalanceComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balanceThirdParty.numGames')
          },
          {
            headerName: this.translationService.translate('balanceThirdParty.moneyToCasino'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'moneyToCasino',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: ThirdPartyBalanceComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balanceThirdParty.moneyToCasino')
          },
          {
            headerName: this.translationService.translate('balanceThirdParty.moneyFromCasino'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'moneyFromCasino',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: ThirdPartyBalanceComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balanceThirdParty.moneyFromCasino')
          },
          {
            headerName: this.translationService.translate('balanceThirdParty.balance'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'balance',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: ThirdPartyBalanceComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balanceThirdParty.balance')
          }
        ],
        onCellClicked: (params) => {
          params.node.setExpanded(!params.node.expanded);
        }
      },
      getDetailRowData: (params) => {
        this.query.stationID = params.data.stationID;
        this.thirdPartyBalanceService.fetchPerUserAccount(this.query).subscribe(data => {
          params.successCallback(enrichByStationId(data, params.data.stationID));
        });
      }
    };
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }

}
