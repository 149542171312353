import {Component, OnInit} from '@angular/core';
import {MobileService} from '../services/mobile.service';

@Component({
  selector: 'vit-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss']
})
export class ButtonsComponent implements OnInit {

  constructor(public mobileService: MobileService) {
  }

  ngOnInit() {
  }

}
