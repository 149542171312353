import {Injectable} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {SessionService} from '../../services/session.service';
import {Observable} from 'rxjs';
import {QueryFilter} from '../../domain/query-filter';
import {SystemConfig} from '../../config/system-config';
import {tap} from 'rxjs/operators';
import {TranslationService} from '../../services/translation.service';
import {BetTypeKeyMapper} from '../../ticket-detail-livesports/livesports-bettype-mapper.service';

@Injectable({
  providedIn: 'root'
})
export class BetLimitsService {

  constructor(public httpService: HttpService,
              public translationService: TranslationService,
              public betTypeKeyMapper: BetTypeKeyMapper,
              public sessionService: SessionService) {
  }

  fetch(query: QueryFilter): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.BET_LIMITS, query).pipe(tap(arr => {
      arr.forEach(el => {
        const sportLabel = this.translationService.translate('customerInfo.sport.' + el.sportID);
        const betLabel = this.betTypeKeyMapper.getText(el.btKey, el.sportId, el.brsov);
        el.sportLabel = sportLabel;
        el.betLabel = betLabel;
      });
    }));
  }

  save(saved: any[]): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.BET_LIMITS_UPDATE, saved);
  }
}
