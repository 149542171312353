import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {SessionService} from '../services/session.service';
import {RouterStateSnapshot} from '@angular/router';
import {Permission} from '../domain/permission';
import {HomeMenuService} from '../services/home-menu.service';
import {LinkedElement} from '../domain/linked-element';

@Injectable()
export class HomeMenuPageguard {

  constructor(private router: Router,
              public sessionService: SessionService,
              public homeMenuService: HomeMenuService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!!this.sessionService.user) {
      if (state.url === '/home') {
        if (this.sessionService.hasPermission(Permission.seeHoldOverview)) {
          this.router.navigate(['/home/turnover']);
          return;
        } else {
          this.router.navigate([this.homeMenuService.menu
            .filter(m => m.content.length > 0)[0].content[0].link.join('/')]);
          return;
        }
      }

      console.log('user exists, check permissions for ' + state.url);

      if (state.url.startsWith('/station/') || state.url.startsWith('/franchiser/') || state.url.startsWith('/home/tickets/')) {
        // skip, those have dedicated guards
        return true;
      }

      const menuEntries = this.homeMenuService.menu
        .map(m => m.content
          .filter(c => c.link.length > 0 && state.url.includes(this.getLinkString(c))))
        .filter(m => m.length > 0)
        .reduce((a, b) => a.concat(b), [])
        .sort((a, b) => this.getLinkString(a).length - this.getLinkString(b).length > 0 ? -1 : 1);

      const bestMatchingMenuEntry = menuEntries[0];
      let isAllowed = false;
      if (bestMatchingMenuEntry) {
        if (Array.isArray(bestMatchingMenuEntry.permission)) {
          isAllowed = !bestMatchingMenuEntry.permission || this.sessionService.hasPermission(...bestMatchingMenuEntry.permission as ReadonlyArray<Permission>);
        }
        isAllowed = !bestMatchingMenuEntry.permission || this.sessionService.hasPermission(bestMatchingMenuEntry.permission as Permission);
      }
      if (!isAllowed) {
        console.log('user does not have permission for ' + state.url);
        this.sessionService.logout();
        this.router.navigate(['/login']);
        return false;
      }
      return true;
    } else {
      this.router.navigate(['/login']);
    }
  }

  private getLinkString(c: LinkedElement) {
    const paths = c.link || [];
    if (paths?.[0] === 'ref') {
      return c.queryParams?.ref;
    }
    return paths.join('/');
  }
}
