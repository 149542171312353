import {AfterViewInit, Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'vit-custom-mat-slider',
  template: `
    <span style="flex-basis: 100%">{{label | translate}}&nbsp;{{sliderValue | number: numberFormat}}</span>
    <span>{{min | number: numberFormat}}</span>
    <mat-slider
      style="flex: 1;"
      [disabled]="disabled"
      [max]="max"
      [min]="min"
      [step]="stepSize"
      [thumbLabel]="thumbLabel"
      [tickInterval]="tickInterval"
      [(ngModel)]="sliderValue"
      [vertical]="false">
    </mat-slider>
    <span>{{max | number: numberFormat}}</span>
  `,
  styles: [`
    :host {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    ::ng-deep .mat-slider-wrapper {

      .mat-slider-thumb-container {
        .mat-slider-thumb-label {
          width: 60px !important;
          height: 60px !important;
          top: -70px !important;
          right: -30px !important;
        }
      }
    }
  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomMatSliderComponent),
      multi: true
    }
  ]
})
export class CustomMatSliderComponent implements ControlValueAccessor, AfterViewInit {
  @Input()
  _sliderValue = 0;
  @Input()
  label = false;
  @Input()
  min: number;
  @Input()
  max: number;
  @Input()
  stepSize: number;
  @Input()
  thumbLabel: boolean;
  @Input()
  tickInterval: boolean;
  @Input()
  numberFormat = '0.0-2';
  @Output()
  valueChange = new EventEmitter<number>();
  disabled = false;

  get sliderValue() {
    return this._sliderValue;
  }

  set sliderValue(val) {
    this._sliderValue = val;
    this.propagateChange(this._sliderValue);
    this.valueChange.emit(this.sliderValue);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  propagateChange = (_: any) => {
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
  }

  writeValue(value: any) {
    this.sliderValue = value;
  }

  ngAfterViewInit() {
    this.propagateChange(this._sliderValue);
  }
}
