import {Component, OnInit} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {isNumber, isNullOrUndefined, loadColumnVisibility, storeColumnVisibility, getContextMenuItems} from '../../../utils';
import {RecyclerTimelineService} from './recycler-timeline.service';
import {GridReadyEvent} from 'ag-grid-community';
import {Router} from '@angular/router';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {TicketSearchComponent} from '../ticket-search/ticket-search.component';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-recycler-timeline',
  templateUrl: './recycler-timeline.component.html',
  styleUrls: ['./recycler-timeline.component.scss']
})
export class RecyclerTimelineComponent implements OnInit {

  columnDefs;
  gridOptions;
  rowData;
  api;

  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;

  hasAnomalies = false;
  lastAnomalyNode = null;

  constructor(public recyclerTimelineService: RecyclerTimelineService,
              public translationService: TranslationService,
              public router: Router,
              public httpService: HttpService,
              public dialog: MatDialog,
              public sessionService: SessionService,
              public datePipe: DatePipe,
              public decimalPipe: DecimalPipe) {
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('recyclerTimeline', event);
    this.api = event.api;
  }

  ngOnInit() {
  }

  fetch(query: QueryFilter) {
    if (query) {
      this.query = query;
      this.recyclerTimelineService.fetchTimeline(query).subscribe((res: any) => {
        console.log('X', res);
          this.columnDefs = this.generateColumnDefs(res.values);
          this.gridOptions = this.generateGridOptions();
          this.rowData = res.cashUnitDistributionTimeline;
        });
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  nextAnomaly(resetable = true) {
    let done = false;
    let inBlock = true;
    this.gridOptions.api.forEachNode(node => {
      if (!!node.data.note) {
        if (inBlock && (!this.lastAnomalyNode || node.rowIndex > this.lastAnomalyNode?.rowIndex)) {
          this.lastAnomalyNode = node;
          this.gridOptions.api.ensureIndexVisible(node.rowIndex, 'middle');
          if (!done) {
            done = true;
          }
        }
      } else if (done) {
        inBlock = false;
      }
    });
    if (resetable && !done) {
      this.lastAnomalyNode = null;
      this.nextAnomaly(false);
    }
  }

  generateColumnDefs(values: any) {
    return [
      {
        headerName: this.translationService.translate('recyclerTimeline.timestamp'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'timestamp',
        width: 140,
        valueFormatter: this.dateFormatter.bind(this),
        headerTooltip: this.translationService.translate('recyclerTimeline.timestamp')
      },
      {
        headerName: this.translationService.translate('recyclerTimeline.totalQty'),
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        field: 'totalQty',
        width: 50,
        headerTooltip: this.translationService.translate('recyclerTimeline.totalQty')
      },
      {
        headerName: this.translationService.translate('recyclerTimeline.total'),
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        field: 'total',
        width: 70,
        headerTooltip: this.translationService.translate('recyclerTimeline.total')
      },
      {
        headerName: this.translationService.translate('recyclerTimeline.delta'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'delta',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: 80,
        headerTooltip: this.translationService.translate('recyclerTimeline.delta')
      },
      ...Object.keys(values).map((key: any) => {
        return {
          headerName: values[key],
          sortable: true,
          filter: true,
          resizable: true,
          cellClass: 'text-align-right',
          cellClassRules: {
            'smaller-font': this.isLargeNumber.bind(this)
          },
          hide: !(values[key] > 0),
          field: key + 'Qty',
          width: 50,
          headerTooltip: values[key]
        };
      })
    ];
  }

  openLink(params: any) {
    const split = params.data.link.split(':');
    const prefix = split[0];
    const value = split[1];
    if (prefix === 'TN' || prefix === 'CTN') {
      const normalizedTicketNumber: string = value.replace(/[^0-9]/g, '');
      this.dialog.open(TicketSearchComponent, {
        height: '90%',
        width: '80%',
        data: {
          ticketNumber: normalizedTicketNumber,
        }
      }).afterClosed().subscribe(result => {
      });
    } else if (prefix === 'CID') {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/home/customer-info'],
        {queryParams: {customerId: value}}));
      window.open('#' + url, '_blank');
    } else if (prefix === 'UAID') {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/home/user-account-timeline'],
        {queryParams: {userAccountId: value}}));
      window.open('#' + url, '_blank');
    }
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: false,
      detailRowHeight: 160,
      rowClassRules: {
        'bold': (params) => params.data.deltaCredit === 0,
        'text-align-right': (params) => isNumber(params.data.value),
        'vit-red': (params) => !isNullOrUndefined(params.data.note) && params.data.note !== '',
        'vit-green': (params) => !isNullOrUndefined(params.data.type) && params.data.type === -2,
        'vit-blue': (params) => !isNullOrUndefined(params.data.type) && params.data.type >= 20,
      },
      onCellClicked: (params) => {
        params.node.setExpanded(!params.node.expanded);
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }

}
