import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SystemConfig} from '../config/system-config';
import {TranslationService} from '../services/translation.service';

@Component({
  selector: 'vit-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {

  constructor(public route: ActivatedRoute, public router: Router, public translationService: TranslationService) {
  }

  ngOnInit() {
    const lang = this.route.snapshot.params['lang'];
    localStorage.setItem(SystemConfig.LocalStorageConstants.LANGUAGE, lang);
    this.translationService.setLanguage(lang);
    location.replace(location.origin);
  }

}
