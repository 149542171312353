import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GameNumber} from '../../domain/game-number';
import {TicketDetailService} from '../ticket-detail.service';
import {SessionService} from '../../services/session.service';
import {Permission} from '../../domain/permission';

@Component({
  selector: 'vit-payout-lock',
  templateUrl: './payout-lock.component.html',
  styleUrls: ['./payout-lock.component.scss']
})
export class PayoutLockComponent implements OnInit {

  @Input()
  ticket: any;

  @Input()
  gameNumer: GameNumber;

  @Output()
  update: EventEmitter<void> = new EventEmitter();

  constructor(private ticketDetailService: TicketDetailService,
              private sessionService: SessionService) {
  }

  ngOnInit() {
  }

  click() {
    this.ticket.verified = !this.ticket.verified;
    this.ticketDetailService.update(this.ticket, this.gameNumer).subscribe(() => {
      this.update.emit();
    });
  }

  isAllowed() {
    return !this.ticket.disbursed;
  }
}
