import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StationComponent } from './station.component';
import {HomeMenuPageguard} from '../home/home-menu.pageguard';
import {StationPageguard} from './station.pageguard';

const routes: Routes = [{
    path: 'station/:parent-franchiser-id/:station-id', component: StationComponent, canActivate: [HomeMenuPageguard, StationPageguard]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StationRoutingModule { }
