<ng-container *ngIf="franchiserData">
  <mat-toolbar color="warn" class="locked"
               *ngIf="franchiserData.franchiser.softLocked || franchiserData.franchiser.hardLocked">
    <mat-icon>locked</mat-icon>
    <span *ngIf="franchiserData.franchiser.softLocked && !franchiserData.franchiser.hardLocked">{{'franchiser.softLock' | translate}}</span>
    <span *ngIf="franchiserData.franchiser.hardLocked">{{'franchiser.hardLock' | translate}}</span>
  </mat-toolbar>
  <vit-cols [elements]="questions"></vit-cols>
<mat-toolbar class="footer" *ngIf="franchiserData && !loading">
  <vit-buttons>
    <button type="reset"
            (click)="reset()"
            mat-flat-button
            color="secondary">
      {{'franchiser.footer.reset' | translate}}
    </button>
    <button type="submit"
            *ngIf="!isNew"
            [disabled]="!hasInsertFranchiserPermission"
            (click)="router.navigate(['franchiser', franchiserData.franchiser.franchiserID, -1])"
            mat-flat-button
            color="accent">
      {{'franchiser.footer.newFranchiser' | translate}}
    </button>
    <button type="submit"
            *ngIf="!isNew"
            [disabled]="!hasInsertStationPermission"
            (click)="router.navigate(['station', franchiserData.franchiser.franchiserID, -1])"
            mat-flat-button
            color="accent">
      {{'franchiser.footer.newStation' | translate}}
    </button>
    <button type="submit"
            (click)="save()"
            [disabled]="!(hasInsertFranchiserPermission || hasEditFranchiserLimited)"
            mat-flat-button
            color="accent">
      {{'franchiser.footer.save' | translate}}
    </button>
  </vit-buttons>
</mat-toolbar>
