import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NgControl} from '@angular/forms';
import * as moment from 'moment';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import {Subject, Subscription} from 'rxjs';
import {FocusMonitor} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {TranslationService} from '../../services/translation.service';
import {DateUtils} from '../../utils/date-utils';
import {Permission} from '../../domain/permission';
import {SessionService} from '../../services/session.service';

export interface TimeRange {
  beginTime: number;
  endTime: number;
}

// component that offers fromTime | toTime
@Component({
  selector: 'vit-timeframe',
  templateUrl: './custom-timeframe.component.html',
  styleUrls: ['./custom-timeframe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomTimeframeComponent implements OnInit, OnDestroy, MatFormFieldControl<any>, ControlValueAccessor {
  static nextId = 0;
  maxMonths = 6000;

  prevent = true;
  presetOptions: { key: string, value: TimeRange }[] = [];
  presetTimeFrame: UntypedFormControl = new UntypedFormControl();
  fromControl: UntypedFormControl = new UntypedFormControl();
  toControl: UntypedFormControl = new UntypedFormControl();

  stateChanges = new Subject<void>();
  focused: boolean;

  errorState = false;

  private _placeholder: string;
  private _required = false;
  private _disabled = false;
  private _onChange: Function;
  private _onTouched: Function;
  private subscription: Subscription;

  @Input()
  onlyMonths = false;
  @Input()
  onlyLastMonths = false;
  @Input()
  onlyLastMonths2 = false;
  @Input()
  hideInput = false;

  @Input()
  get value(): TimeRange {
    if (this.fromControl.value && this.toControl.value) {
      return {
        beginTime: this.fromControl.value,
        endTime: this.toControl.value
      };
    } else {
      return null;
    }
  }

  set value(value: TimeRange) {
    if (value && value.beginTime && value.endTime) {
      this.fromControl.setValue(moment(value.beginTime).valueOf());
      this.toControl.setValue(moment(value.endTime).valueOf());
    } else {
      this.fromControl.setValue(null);
      this.toControl.setValue(null);
    }
    this.updateErrorState();
    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(placeholder) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this._required;
  }

  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled() {
    return this._disabled;
  }

  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    if (this._disabled) {
      this.fromControl.disable();
      this.toControl.disable();
    } else {
      this.fromControl.enable();
      this.toControl.enable();
    }

    this.stateChanges.next();
  }

  @Input() showClearValue = false;

  @Output()
  valueChange: EventEmitter<TimeRange | null> = new EventEmitter();

  get empty() {
    return !this.fromControl.value && !this.toControl.value;
  }

  @HostBinding() id = `vit-time-range-input-${CustomTimeframeComponent.nextId++}`;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @HostBinding('attr.aria-describedby') describedBy = '';

  constructor(private focusMonitor: FocusMonitor,
              private elementRef: ElementRef,
              @Self() @Optional() public ngControl: NgControl,
              private translate: TranslationService,
              public sessionService: SessionService) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    focusMonitor.monitor(elementRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      if (this._onTouched && !this.focused) {
        this._onTouched();
        this.updateErrorState();
      }
      this.stateChanges.next();
    });
  }

  ngOnInit() {
    if (this.onlyMonths) {
      this.presetOptions = this.generateMonthOptions();
    } else if (this.onlyLastMonths) {
      this.presetOptions = this.generateLastMonthOptions();
    } else if (this.onlyLastMonths2) {
      this.presetOptions = this.generateLastMonthOptions2();
    } else {
      this.presetOptions = this.generateDefaultOptions();
    }

    this.presetTimeFrame.setValue(this.presetOptions[this.onlyMonths ? 1 : 0].value);
    const notifyValueChanges = (value) => {
      if (!this.prevent) {
        this.presetTimeFrame.setValue(undefined);
      }
      this.valueChange.emit(this.value);
      if (this._onChange) {
        this._onChange(this.value);
      }
      this.updateErrorState();
    };
    this.subscription = this.fromControl.valueChanges.subscribe(notifyValueChanges);
    this.subscription.add(this.toControl.valueChanges.subscribe(notifyValueChanges));

    this.value = this.presetOptions[(this.onlyMonths) ? 1 : 0].value;

    this.prevent = false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
  }

  presetChanged(e) {
    this.prevent = true;
    setTimeout(() => this.prevent = false, 500);
    this.fromControl.setValue(e.value.beginTime);
    this.toControl.setValue(e.value.endTime);
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input'
      && !(event.target as Element).classList.contains('placeholder')) {
      this.elementRef.nativeElement.querySelector('input').focus();
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private updateErrorState() {
    if (this.ngControl) {
      const oldState = this.errorState;
      const newState = !this.fromControl.value || !this.toControl.value || this.maxMonthsExceeded();
      if (newState !== oldState) {
        this.errorState = newState;
        this.stateChanges.next();
      }
    }
  }

  private maxMonthsExceeded() {
    return !this.hideInput && !this.sessionService.hasPermission(Permission.editAdvancedAdminSettings) &&
      moment(this.value.endTime).diff(moment(this.value.beginTime), 'months') > this.maxMonths;
  }

  private generateDefaultOptions() {
    return [
      {
        key: 'forms.currentDay',
        value: {
          beginTime: DateUtils.setTimeToZero(moment().valueOf()),
          endTime: DateUtils.setTimeToZero(moment().add(1, 'days').valueOf())
        }
      },
      {
        key: 'forms.currentWeek',
        value: {
          beginTime: DateUtils.setTimeToZero(moment().weekday(1).valueOf()),
          endTime: DateUtils.setTimeToZero(moment().weekday(8).valueOf())
        }
      },
      {
        key: 'forms.currentMonth',
        value: {
          beginTime: DateUtils.setTimeToZero(moment().startOf('month').valueOf()),
          endTime: DateUtils.setTimeToZero(moment().month(moment().month() + 1).startOf('month').valueOf()),
        }
      },
      {
        key: 'forms.lastDay',
        value: {
          beginTime: DateUtils.setTimeToZero(moment().add(-1, 'days').valueOf()),
          endTime: DateUtils.setTimeToZero(moment().valueOf())
        }
      },
      {
        key: 'forms.lastWeek',
        value: {
          beginTime: DateUtils.setTimeToZero(moment().week(moment().week() - 1).weekday(1).valueOf()),
          endTime: DateUtils.setTimeToZero(moment().week(moment().week() - 1).weekday(8).valueOf())
        }
      },
      {
        key: 'forms.lastMonth',
        value: {
          beginTime: DateUtils.setTimeToZero(moment().month(moment().month() - 1).startOf('month').valueOf()),
          endTime: DateUtils.setTimeToZero(moment().startOf('month').valueOf()),
        }
      },
      {
        key: 'forms.lastDay2',
        value: {
          beginTime: DateUtils.setTimeToZero(moment().add(-2, 'days').valueOf()),
          endTime: DateUtils.setTimeToZero(moment().add(-1, 'days').valueOf()),
        }
      },
      {
        key: 'forms.lastWeek2',
        value: {
          beginTime: DateUtils.setTimeToZero(moment().week(moment().week() - 2).weekday(1).valueOf()),
          endTime: DateUtils.setTimeToZero(moment().week(moment().week() - 2).weekday(8).valueOf())
        }
      },
      {
        key: 'forms.lastMonth2',
        value: {
          beginTime: DateUtils.setTimeToZero(moment().month(moment().month() - 2).startOf('month').valueOf()),
          endTime: DateUtils.setTimeToZero(moment().month(moment().month() - 1).startOf('month').valueOf()),
        }
      },
    ];
  }

  private generateLastMonthOptions() {

    const options = [];
    for (let i = 1; i <= 12; i++) {
      const date = moment().month(moment().month() - i);
      options.push({
        key: this.translate.translate('forms.lastMonths_' + date.format('M')) + ' ' + date.format('YYYY'),
        value: {
          beginTime: DateUtils.setTimeToZero(date.startOf('month').valueOf()),
          endTime: DateUtils.setTimeToZero(moment(date.valueOf()).add(1, 'month').startOf('month').valueOf()),
        }
      });
    }

    options.sort((o1, o2) => o1.value.beginTime - o2.value.beginTime);

    return options;
  }

  private generateLastMonthOptions2() {

    const options = [];
    for (let i = 0; i < 12; i++) {
      const date = moment().month(moment().month() - i);
      options.push({
        key: this.translate.translate('forms.lastMonths_' + date.format('M')) + ' ' + date.format('YYYY'),
        value: {
          beginTime: DateUtils.setTimeToZero(date.startOf('month').valueOf()),
          endTime: DateUtils.setTimeToZero(moment(date.valueOf()).add(1, 'month').startOf('month').valueOf()),
        }
      });
    }

    options.sort((o1, o2) => o1.value.beginTime - o2.value.beginTime);

    return options;
  }

  private generateMonthOptions() {
    return [
      {
        key: 'forms.all',
        value: {
          beginTime: DateUtils.setTimeToZero(moment('01/01/2008', 'DD/MM/YYYY').valueOf()),
          endTime: DateUtils.setTimeToZero(moment().add(1, 'days').valueOf())
        }
      },
      {
        key: 'forms.lastMonth',
        value: {
          beginTime: DateUtils.setTimeToZero(moment().month(moment().month() - 1).startOf('month').valueOf()),
          endTime: DateUtils.setTimeToZero(moment().startOf('month').valueOf()),
        }
      },
      {
        key: 'forms.last3Months',
        value: {
          beginTime: DateUtils.setTimeToZero(moment().month(moment().month() - 3).startOf('month').valueOf()),
          endTime: DateUtils.setTimeToZero(moment().startOf('month').valueOf()),
        }
      },
      {
        key: 'forms.last6Months',
        value: {
          beginTime: DateUtils.setTimeToZero(moment().month(moment().month() - 6).startOf('month').valueOf()),
          endTime: DateUtils.setTimeToZero(moment().startOf('month').valueOf()),
        }
      },
      {
        key: 'forms.last9Months',
        value: {
          beginTime: DateUtils.setTimeToZero(moment().month(moment().month() - 9).startOf('month').valueOf()),
          endTime: DateUtils.setTimeToZero(moment().startOf('month').valueOf()),
        }
      },
      {
        key: 'forms.last12Months',
        value: {
          beginTime: DateUtils.setTimeToZero(moment().month(moment().month() - 12).startOf('month').valueOf()),
          endTime: DateUtils.setTimeToZero(moment().startOf('month').valueOf()),
        }
      }
    ];
  }
}
