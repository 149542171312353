
<vit-search-form (submitEmiter)="fetch($event)"
                 [hideMinProfit]="true"
                 [hideMinStake]="true"
                 [hideFranchiser]="true"
                 [showGroups]="true"
                 [hideStation]="true"
                 [showDateOfLastAccountingButton]="true"
                 [hideMinTickets]="true"
                 [hideIntoEuro]="true"
                 [sumNotRepresentable]="sumNotRepresentable">
</vit-search-form>
<ag-grid-angular
  *ngIf="query"
  (columnVisible)="storeColumnVisibilityFn('balance', $event)"
  class="ag-theme-balham"
  style="width: 100%; flex: 1;"
  [gridOptions]="gridOptions"
  [pinnedTopRowData]="titleRow"
  [columnDefs]="columnDefs"
  [detailCellRendererParams]="detailParams"
  (gridReady)="onGridReady($event)">
</ag-grid-angular>
