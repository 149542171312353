export class Jackpot {
    jackpotID: number;
    franchiserID: number;
    applicationID: number;
    value: number;
    minInitialValue: number;
    maxInitialValue: number;
    minValue: number;
    maxValue: number;
    hitValue: number;
    lastHitTime: string;
    lastHitStation: string;
    lastHitValue: number;
    name: string;
    autoRaise: boolean;
}
