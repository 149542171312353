import {Component, OnInit, ViewChild} from '@angular/core';
import {BalanceActualizationService} from './balance-actualization.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {TranslationService} from '../../services/translation.service';
import {SearchFormComponent} from '../../search-form/search-form.component';
import {ERROR_SNACK_CONFIG, SUCCESS_SNACK_CONFIG} from '../../../utils';

@Component({
  selector: 'vit-balance-actualization',
  templateUrl: './balance-actualization.component.html',
  styleUrls: ['./balance-actualization.component.scss']
})
export class BalanceActualizationComponent implements OnInit {


  @ViewChild(SearchFormComponent, { static: true })
  searchFormComponent: SearchFormComponent;

  constructor(public balanceActualizationService: BalanceActualizationService,
              public snackBar: MatSnackBar,
              public translationService: TranslationService) {
  }

  ngOnInit() {
  }

  actualize(filter) {
    if (filter) {
      setTimeout(() => this.searchFormComponent.back());
      this.balanceActualizationService.update(filter).subscribe(() =>
          this.snackBar.open(this.translationService.translate('snackBar.updated'), null, SUCCESS_SNACK_CONFIG),
        () =>
          this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG));
    }
  }

}
