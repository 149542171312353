import {Injectable} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {SessionService} from '../../services/session.service';
import {Observable} from 'rxjs';
import {QueryFilter} from '../../domain/query-filter';
import {SystemConfig} from '../../config/system-config';

@Injectable({
  providedIn: 'root'
})
export class CustomerInfoService {

  constructor(public httpService: HttpService,
              public sessionService: SessionService) {
  }

  fetch(query: QueryFilter): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.CUSTOMER_INFO, query);
  }
}
