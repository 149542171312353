import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ColsComponent} from './cols.component';
import {MaterialImportsModule} from '../../material-imports.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DynamicFormModule} from '../dynamic-form/dynamic-form.module';
import {SharedModule} from '../../shared.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialImportsModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicFormModule,
    SharedModule
  ],
  declarations: [ColsComponent],
  exports: [ColsComponent]
})
export class ColsModule {
}
