import {Component, Input, OnInit} from '@angular/core';
import {TicketState} from '../domain/ticket-state';

@Component({
  selector: 'vit-bet-status',
  templateUrl: './bet-status.component.html',
  styleUrls: ['./bet-status.component.scss']
})
export class BetStatusComponent implements OnInit {

  @Input()
  betCalculated: boolean;
  @Input()
  betWon: boolean;
  @Input()
  betStorno: boolean;

  status: TicketState;

  constructor() {
  }

  ngOnInit() {
    if (this.betStorno) {
      this.status = TicketState.STORNO;
    } else if (!this.betCalculated) {
      this.status = TicketState.PENDING;
    } else if (this.betWon) {
      this.status = TicketState.WON;
    } else {
      this.status = TicketState.LOST;
    }
  }
}
