<div class="search-form"
     [class.submitted]="submitted"
     [hidden]="submitted">
  <vit-cols [elements]="searchFormQuestions"></vit-cols>
</div>
<div class="search-info" *ngIf="submitted && query">
  <div class="params" *ngIf="!mobileService.isMobile">
    <table border="0" *ngIf="!hideTimeframe">
      <tr>
        <td rowspan="3">
          <button (click)="timeBack()" mat-icon-button color="accent">
            <mat-icon>arrow_back_ios</mat-icon>
          </button>
        </td>
        <td><b>{{'searchForm.info.from' | translate}}</b></td>
        <td>{{query.dateFrom | date: 'medium'}}</td>
        <td rowspan="3">
          <button (click)="timeForward()" mat-icon-button color="accent">
            <mat-icon>arrow_forward_ios</mat-icon>
          </button>
        </td>
      </tr>
      <tr>
        <td><b>{{'searchForm.info.to' | translate}}</b></td>
        <td>{{query.dateTo | date: 'medium'}}</td>
      </tr>
      <tr *ngIf="selectedStation">
        <td><b>{{'searchForm.info.station' | translate}}</b></td>
        <td>{{selectedStation?.node?.name}}</td>
      </tr>
      <tr *ngIf="showCustomerIdInHeader">
        <td><b>{{'searchForm.customerID' | translate}}</b></td>
        <td>{{query?.customerID}}</td>
      </tr>
    </table>
    <table border="0" style="margin-left: 10px" *ngIf="showNow || rowCount !== undefined || selectedFranchiser || selectedGroup">
      <tr *ngIf="showNow">
        <td><b>{{'searchForm.info.submitDate' | translate}}</b></td>
        <td>{{submitDate | date: 'medium'}}</td>
      </tr>
      <tr *ngIf="rowCount !== undefined">
        <td><b>{{'searchForm.info.rows' | translate}}</b></td>
        <td>{{rowCount}}</td>
      </tr>
      <tr *ngIf="selectedFranchiser">
        <td><b>{{'searchForm.info.franchiser' | translate}}</b></td>
        <td>{{selectedFranchiser?.node?.name}}</td>
      </tr>
      <tr *ngIf="selectedGroup && +selectedGroup.key !== 0">
        <td><b>{{'searchForm.info.group' | translate}}</b></td>
        <td>{{selectedGroup?.value}}</td>
      </tr>
      <tr *ngIf="!hideIntoEuro && !hideDetails">
        <td><b>{{'searchForm.info.intoEuro' | translate}}</b></td>
        <td>{{'searchForm.info.euro' + query.intoEuro | translate}}</td>
      </tr>
    </table>
    <table border="0" style="margin-left: 10px">
      <tr *ngIf="query?.queryMode">
        <td><b>{{'searchForm.queryMode' | translate}}</b></td>
        <td>{{'searchForm.queryModes.' + query?.queryMode | translate}}</td>
      </tr>
    </table>
    <ng-content></ng-content>
  </div>
  <div class="buttons">
    <button [matTooltip]="'searchForm.info.nextAnomaly' | translate"
      *ngIf="anomaly" type="reset" (click)="anomalyTrigger.emit()" mat-icon-button color="warn">
      <mat-icon>policy</mat-icon>
    </button>
    <button type="reset" (click)="back()" mat-icon-button color="accent">
      <mat-icon>arrow_back_ios</mat-icon>
    </button>
    <button (click)="refresh()" mat-icon-button color="accent">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
</div>

<div class="sum-not-applicable" *ngIf="sumNotRepresentable">
  {{'searchForm.info.sumNotApplicable' | translate }}
</div>

<mat-toolbar class="footer" *ngIf="!submitted">
  <button type="reset"
          (click)="reset()"
          mat-flat-button
          color="secondary">
    {{'searchForm.footer.reset' | translate}}
  </button>
  <button type="submit"
          (click)="submit()"
          [disabled]="!submitDisabled()"
          mat-flat-button
          color="accent">
    {{submitLabel ? submitLabel : ('searchForm.footer.search' | translate)}}
  </button>
  <span class="restrict-games" *ngIf="!submitDisabled() && !requireUserAccountId">{{'searchForm.footer.restrictGames' | translate}}</span>
</mat-toolbar>
