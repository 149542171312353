import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
  selector: 'vit-markdown-info',
  templateUrl: './markdown-info.component.html',
  styleUrls: ['./markdown-info.component.scss']
})
export class MarkdownInfoComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MarkdownInfoComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { markdown: string }) {
  }

  ngOnInit(): void {
  }

}
