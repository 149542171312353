import {Component, OnInit} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {StationUtils} from '../../utils/station-utils';
import {TreeNodeType} from '../../domain/tree-node-type';
import {getContextMenuItems, isNumber} from 'utils';
import {TicketListStationsService} from './ticket-list-stations.service';
import {environment} from '../../../environments/environment';
import {SystemConfig} from '../../config/system-config';
import {TicketListComponent} from '../../ticket-list/ticket-list.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {NewTabComponent} from '../../new-tab/new-tab.component';
import {enrichByStationId, loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {GridReadyEvent} from 'ag-grid-community';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-ticket-list-stations',
  templateUrl: './ticket-list-stations.component.html',
  styleUrls: ['./ticket-list-stations.component.scss']
})
export class TicketListStationsComponent implements OnInit {

  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;
  columnDefs;
  gridOptions;
  rowData;
  detailParams;

  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public ticketListStationsService: TicketListStationsService,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public httpService: HttpService,
              public dialog: MatDialog,
              public datePipe: DatePipe,
              public decimalPipe: DecimalPipe) {
    this.columnDefs = this.generateColumnDefs();
    this.detailParams = this.generateDetailParams();
    this.gridOptions = this.generateGridOptions();
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('ticketListStations', event);
  }

  ngOnInit() {
  }

  fetch(query: QueryFilter) {
    if (query) {
      this.query = query;
      this.rowData = this.ticketListStationsService.fetchStations(query);
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('ticketListStations.type'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'type',
        cellClass: 'type',
        width: 23,
        cellRenderer: function (params) {
          const icon = StationUtils.getIcon(TreeNodeType.STATION, params.data.type, false, params.data.softLocked, params.data.hardLocked);
          const img = '<img src=\'/assets/img/icons/' + icon + '\'>';
          return params.data.stationID === 0 ? '' : img;
        }
      },
      {
        headerName: this.translationService.translate('ticketListStations.stationID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationID',
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40,
        headerTooltip: this.translationService.translate('ticketListStations.stationID')
      },
      {
        headerName: this.translationService.translate('ticketListStations.name'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'name',
        width: 300,
        headerTooltip: this.translationService.translate('ticketListStations.name')
      }
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      context: {
        componentParent: this
      },
      masterDetail: true,
      detailRowHeight: 160,
      isRowMaster: (data) => data.stationID !== 0,
      rowClassRules: {
        'bold': (params) => params.data.stationID === 0,
        'text-align-right': (params) => isNumber(params.data.value)
      },
      onCellClicked: (params) => {
        params.node.setExpanded(!params.node.expanded);
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }


  generateDetailParams() {
    return {
      detailGridOptions: {
        context: {
          componentParent: this
        },
        columnDefs: [
          {
            headerName: this.translationService.translate('ticketListStations.newTab'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'newTab',
            width: 23,
            cellClass: 'new-tab',
            cellRenderer: NewTabComponent
          },
          {
            headerName: this.translationService.translate('ticketListStations.game'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'game',
            width: TicketListStationsComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('ticketListStations.game')
          },
          {
            headerName: this.translationService.translate('ticketListStations.numTickets'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'numTickets',
            width: TicketListStationsComponent.COLUMN_WIDTH,
            cellClass: 'text-align-right',
            headerTooltip: this.translationService.translate('ticketListStations.numTickets')
          }
        ],
        onCellClicked: (params) => {
          this.query.stationID = params.data.stationID;
          if (params.colDef.cellRenderer === NewTabComponent) {
            // TODO: use service-worker instead to pass info
            const url = environment.host + SystemConfig.Routes.TICKET_LIST + '/' + params.data.gameNumber
              + '/' + btoa(JSON.stringify(this.query));
            window.open(encodeURI(url), '_blank');
          } else {
            this.dialog.open(TicketListComponent, {
              height: '90%',
              width: '80%',
              data: {query: this.query, gameNumber: params.data.gameNumber},
              disableClose: true
            });
          }
        }
      },
      getDetailRowData: (params) => {
        this.query.stationID = params.data.stationID;
        this.ticketListStationsService.fetchPerGame(this.query).subscribe(data => {
          params.successCallback(enrichByStationId(data, params.data.stationID));
        });
      }
    };
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }

}
