<ng-container *ngFor="let qc of elements">
  <mat-card [id]="'qc-'+qc.id"
            [hidden]="isNotAuthorizeToView(qc)"
            [style.left]="qc.x"
            [style.width]="qc.width"
            [class.last]="qc.last"
            [style.top]="qc.y">
    <mat-card-header>{{qc.label | translate}}</mat-card-header>
    <mat-card-content>
      <vit-dynamic-form [questionContainer]="qc"></vit-dynamic-form>
    </mat-card-content>
  </mat-card>
</ng-container>
