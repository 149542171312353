<mat-card>
  <mat-form-field>
    <input matInput
           type="password"
           [placeholder]="'changePassword.passwordOld' | translate"
           [(ngModel)]="passwordOld">
  </mat-form-field>
  <mat-form-field>
    <input matInput
           type="password"
           autocomplete="off"
           [placeholder]="'changePassword.passwordNew1' | translate"
           [(ngModel)]="passwordNew1">
  </mat-form-field>
  <mat-form-field>
    <input matInput
           type="password"
           autocomplete="off"
           [placeholder]="'changePassword.passwordNew2' | translate"
           [(ngModel)]="passwordNew2">
  </mat-form-field>
  <button [disabled]="!passwordOld || !passwordNew1 || passwordNew1 !== passwordNew2 || passwordNew1?.length < minLength"
          mat-flat-button
          (click)="submit()">{{'changePassword.change' | translate}}
  </button>
  <span class="error" *ngIf="passwordNew1 !== passwordNew2 && passwordNew2?.length > 0">
    {{'changePassword.errorPasswordsNotEqual' | translate}}
  </span>
  <span class="error" *ngIf="passwordNew1?.length < minLength && passwordNew2?.length > 0">
    {{'changePassword.errorMinLength' | translate: [minLength]}}
  </span>
</mat-card>
