import {Franchiser} from './franchiser';

export class FranchiserData {
  franchiser: Franchiser;
  lstMasterFranchisers: Map<string, string>;
  usersForFranchiser: string[];
  treeStats: Map<string, string>;
  lstInvoicePaymentTypes: Map<string, string>;
  lstInvoiceDeliveryTypes: Map<string, string>;
  lstPaymentTypes: Map<String, String>;
  franchiserHierarchy: string;
  userAccountManagerDescription: String;
}
