<vit-search-form
  (submitEmiter)="fetchCashbookStations($event)"
  [hideGames]="true"
  [hideMinProfit]="true"
  [hideMinStake]="true"
  [hideMinTickets]="true"
  [hideIntoEuro]="true"
  [hideStation]="true"
  [hideIgnoreTest]="true"
  [hideTimeframe]="true"
  [showIgnoreOnline]="true"
  [showIgnoreOffline]="true"
  [showIgnoreLocked]="true"
  [showIgnoreUnlocked]="true">
</vit-search-form>
<ag-grid-angular
  *ngIf="rowData"
  (columnVisible)="storeColumnVisibilityFn('stationOverview', $event)"
  class="ag-theme-balham"
  style="width: 100%; flex: 1 1 1px;"
  [gridOptions]="gridOptions"
  [rowData]="rowData | async"
  [columnDefs]="columnDefs"
  (gridReady)="onGridReady($event)">
</ag-grid-angular>
<div class="info-bar vit-red"
     *ngIf="rowData">
  <span>{{'stationOverview.infoText' | translate}}</span>
</div>
