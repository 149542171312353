export enum Sport {
  NONE = 0,
  SOCCER = 1,
  BASKETBALL = 2,
  BASEBALL = 3,
  ICE_HOCKEY = 4,
  TENNIS = 5,
  HANDBALL = 6,
  MOTORSPORT = 11,
  RUGBY = 12,
  WINTERSPORT = 14,
  FOOTBALL = 16,
  SNOOKER = 19,
  TABLE_TENNIS = 20,
  DARTS = 22,
  VOLLEYBALL = 23,
  BEACHVOLLEYBALL = 34,
  ESOCCER = 137
}
