import {Injectable} from '@angular/core';
import {HttpService} from '../services/http.service';
import {SessionService} from '../services/session.service';
import {Observable, of} from 'rxjs';
import {SystemConfig} from '../config/system-config';
import {GameNumber} from '../domain/game-number';

@Injectable({
  providedIn: 'root'
})
export class TicketDetailLivesportsService {

  constructor(public httpService: HttpService,
              public sessionService: SessionService) {
  }

  update(bet: any, ticketNumber: number, cancelAllBets: boolean, secureCode: string): Observable<any> {
    return this.httpService.post(SystemConfig.Endpoints.TICKET_LIVESPORT_BET_UPDATE, {
      'bet': bet,
      'ticketNumber': ticketNumber,
      'cancelAllBets': cancelAllBets,
      'lB2SecCode': secureCode
    });
  }

}
