import {Component, OnInit} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {StationUtils} from '../../utils/station-utils';
import {TreeNodeType} from '../../domain/tree-node-type';
import {getContextMenuItems, isNumber} from 'utils';
import {loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {CashbookService} from '../../services/cashbook.service';
import {StationOverviewService} from '../../services/station-overview.service';
import {Permission} from '../../domain/permission';
import {GridReadyEvent} from 'ag-grid-community';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-station-overview',
  templateUrl: './station-overview.component.html',
  styleUrls: ['./station-overview.component.scss']
})
export class StationOverviewComponent implements OnInit {

  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;
  static COLUMN_WIDTH_CHECKBOX = 50;

  columnDefs;
  gridOptions;
  rowData;
  detailParams;
  rowCount;

  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public stationOverviewService: StationOverviewService,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public httpService: HttpService,
              public datePipe: DatePipe,
              public decimalPipe: DecimalPipe,
              public dialog: MatDialog) {
    this.columnDefs = this.generateColumnDefs();
    this.gridOptions = this.generateGridOptions();
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('stationOverview', event);
  }

  ngOnInit() {
  }

  fetchCashbookStations(query: QueryFilter) {
    if (query) {
      this.query = query;
      this.rowData = this.stationOverviewService.fetchStationOverview(query);
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('stationOverview.type'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'type',
        cellClass: 'type',
        width: 23,
        cellRenderer: function (params) {
          const icon = StationUtils.getIcon(TreeNodeType.STATION, params.data.type, false, params.data.softLocked, params.data.hardLocked);
          const img = '<img src=\'/assets/img/icons/' + icon + '\'>';
          return params.data.stationID === 0 ? '' : img;
        }
      },
      {
        headerName: this.translationService.translate('stationOverview.stationID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationID',
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40,
        headerTooltip: this.translationService.translate('stationOverview.stationID')
      },
      {
        headerName: this.translationService.translate('stationOverview.name'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'name',
        width: StationOverviewComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('stationOverview.name')
      },
      {
        headerName: this.translationService.translate('stationOverview.online'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'online',
        width: StationOverviewComponent.COLUMN_WIDTH_CHECKBOX,
        cellRenderer: this.checkboxGenerator.bind(this),
        headerTooltip: this.translationService.translate('stationOverview.online')
      },
      {
        headerName: this.translationService.translate('stationOverview.rF2Enabled'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'rF2Enabled',
        width: StationOverviewComponent.COLUMN_WIDTH_CHECKBOX,
        cellRenderer: this.checkboxGenerator.bind(this),
        headerTooltip: this.translationService.translate('stationOverview.rF2Enabled')
      },
      {
        headerName: this.translationService.translate('stationOverview.pB1Enabled'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'pB1Enabled',
        width: StationOverviewComponent.COLUMN_WIDTH_CHECKBOX,
        cellRenderer: this.checkboxGenerator.bind(this),
        headerTooltip: this.translationService.translate('stationOverview.pB1Enabled')
      },
      {
        headerName: this.translationService.translate('stationOverview.bB1Enabled'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'bB1Enabled',
        width: StationOverviewComponent.COLUMN_WIDTH_CHECKBOX,
        cellRenderer: this.checkboxGenerator.bind(this),
        headerTooltip: this.translationService.translate('stationOverview.bB1Enabled')
      },
      {
        headerName: this.translationService.translate('stationOverview.lB2Enabled'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'lB2Enabled',
        width: StationOverviewComponent.COLUMN_WIDTH_CHECKBOX,
        cellRenderer: this.checkboxGenerator.bind(this),
        headerTooltip: this.translationService.translate('stationOverview.lB2Enabled')
      },
      {
        headerName: this.translationService.translate('stationOverview.cT1Enabled'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cT1Enabled',
        width: StationOverviewComponent.COLUMN_WIDTH_CHECKBOX,
        cellRenderer: this.checkboxGenerator.bind(this),
        headerTooltip: this.translationService.translate('stationOverview.cT1Enabled')
      },
      {
        headerName: this.translationService.translate('stationOverview.thirdPartyEnabled'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'thirdPartyEnabled',
        width: StationOverviewComponent.COLUMN_WIDTH_CHECKBOX,
        cellRenderer: this.checkboxGenerator.bind(this),
        headerTooltip: this.translationService.translate('stationOverview.thirdPartyEnabled')
      },
      {
        headerName: this.translationService.translate('stationOverview.currencyCode'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'currencyCode',
        width: StationOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('stationOverview.currencyCode')
      },
      {
        headerName: this.translationService.translate('stationOverview.lastLogin'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'lastLogin',
        valueFormatter: this.dateFormatter.bind(this),
        width: StationOverviewComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('stationOverview.lastLogin')
      },
      {
        headerName: this.translationService.translate('stationOverview.lastLogout'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'lastLogout',
        valueFormatter: this.dateFormatter.bind(this),
        width: StationOverviewComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('stationOverview.lastLogout')
      },
      {
        headerName: this.translationService.translate('stationOverview.offlineSinceDays'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'lastLogout',
        valueFormatter: (params) => this.calculateDaysOffline(params.value),
        cellClass: 'station-id text-align-right',
        width: StationOverviewComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('stationOverview.offlineSinceDays')
      },
      {
        headerName: this.translationService.translate('stationOverview.hardwareType'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'hardwareType',
        width: StationOverviewComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('stationOverview.hardwareType')
      },
      {
        headerName: this.translationService.translate('stationOverview.hardwareSerialNumber'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'hardwareSerialNumber',
        width: StationOverviewComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('stationOverview.hardwareSerialNumber')
      }
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: true,
      rowClassRules: {
        'text-align-right': (params) => isNumber(params.data.value),
        'vit-red': (params) => this.calculateDaysOffline(params.data.lastLogin) >= 3
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }

  private calculateDaysOffline(lastLogout) {
    return lastLogout ? Math.floor((new Date().getTime() - new Date(lastLogout).getTime()) / (1000 * 60 * 60 * 24)) : null;
  }

  checkboxGenerator(params) {
    const eDiv = document.createElement('div');
    eDiv.innerHTML = '<input type="checkbox" disabled="true" ' + (params.value ? 'checked="true"' : '') + '">';
    return eDiv;
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }

}
