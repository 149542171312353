import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../services/translation.service';

@Pipe({
    name: 'translate'
})
export class TranslationPipe implements PipeTransform {

    constructor(public translationService: TranslationService) {
    }

    transform(value: any, args: string[] = []): any {
        if (args && args.length > 0) {
            return this.translationService.translateArgs(value, ...args);
        } else {
            return this.translationService.translate(value);
        }
    }
}
