import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {copyToClipboard} from '../../../../utils';

@Component({
  selector: 'vit-action-button-cell-renderer',
  templateUrl: './action-button-cell-renderer.html',
  styleUrls: ['./action-button-cell-renderer.scss']
})
// tslint:disable-next-line:component-class-suffix
export class ActionButtonCellRenderer implements ICellRendererAngularComp {
  hidden = false;

  params: any;
  prefix = '';
  clipboard = copyToClipboard.bind(this);

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (params.data.link) {
      const split = params.data.link.split(':');
      this.prefix = split[0];
    }
  }

  btnClickedHandler(event: MouseEvent) {
    this.params.clicked(this.params);
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
