import {Component, OnInit} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {StationUtils} from '../../utils/station-utils';
import {TreeNodeType} from '../../domain/tree-node-type';
import {getContextMenuItems, isNumber} from 'utils';
import {CashbookService} from '../../services/cashbook.service';
import {enrichByStationId, loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {clone} from '../../utils/basic-utils';
import {GridReadyEvent} from 'ag-grid-community';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-cashbook',
  templateUrl: './cashbook.component.html',
  styleUrls: ['./cashbook.component.scss']
})
export class CashbookComponent implements OnInit {

  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;
  columnDefs;
  gridOptions;
  rowData;
  detailParams;
  rowCount;

  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public cashbookService: CashbookService,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public datePipe: DatePipe,
              public httpService: HttpService,
              public decimalPipe: DecimalPipe,
              public dialog: MatDialog) {
    this.columnDefs = this.generateColumnDefs();
    this.detailParams = this.generateDetailParams();
    this.gridOptions = this.generateGridOptions();
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('cashbook', event);
  }

  ngOnInit() {
  }

  fetchCashbookStations(query: QueryFilter) {
    if (query) {
      this.query = query;
      this.rowData = this.cashbookService.fetchCashbookStations(query);
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('cashbook.type'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationType',
        cellClass: 'type',
        width: 23,
        cellRenderer: function (params) {
          const icon = StationUtils.getIcon(TreeNodeType.STATION, params.data.stationType, false, false, false);
          const img = '<img src=\'/assets/img/icons/' + icon + '\'>';
          return params.data.stationID === 0 ? '' : img;
        }
      },
      {
        headerName: this.translationService.translate('cashbook.stationID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationID',
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40,
        headerTooltip: this.translationService.translate('cashbook.stationID')
      },
      {
        headerName: this.translationService.translate('cashbook.name'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationName',
        width: CashbookComponent.COLUMN_WIDTH_LARGE,
      },
      {
        headerName: this.translationService.translate('cashbook.cashbookNumber'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashbookNumber',
        cellClass: 'text-align-right',
        width: 50
      },
      {
        headerName: this.translationService.translate('cashbook.date'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'date',
        width: CashbookComponent.COLUMN_WIDTH_LARGE,
        valueFormatter: this.dateFormatter.bind(this),
        headerTooltip: this.translationService.translate('cashbook.date')
      },
      {
        headerName: this.translationService.translate('cashbook.cashIn'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashIn',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CashbookComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('cashbook.cashIn')
      },
      {
        headerName: this.translationService.translate('cashbook.cashInRealCash'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashInRealCash',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CashbookComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('cashbook.cashInRealCash')
      },
      {
        headerName: this.translationService.translate('cashbook.cashOut'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashOut',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CashbookComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('cashbook.cashOut')
      },
      {
        headerName: this.translationService.translate('cashbook.cashOutStorage'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashOutStorage',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CashbookComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('cashbook.cashOutStorage')
      },
      {
        headerName: this.translationService.translate('cashbook.cashToUserAccount'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashToUserAccount',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CashbookComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('cashbook.cashToUserAccount')
      },
      {
        headerName: this.translationService.translate('cashbook.cashFromUserAccount'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashFromUserAccount',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CashbookComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('cashbook.cashFromUserAccount')
      },
      {
        headerName: this.translationService.translate('cashbook.cashToBTC'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashToBTC',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CashbookComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('cashbook.cashToBTC')
      },
      {
        headerName: this.translationService.translate('cashbook.cashInBonusCash'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashInBonusCash',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CashbookComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('cashbook.cashInBonusCash')
      },
      {
        headerName: this.translationService.translate('cashbook.storno'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'storno',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CashbookComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('cashbook.storno')
      },
      {
        headerName: this.translationService.translate('cashbook.withdrawal'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'withdrawal',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CashbookComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('cashbook.withdrawal')
      },
      {
        headerName: this.translationService.translate('cashbook.balance'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'balance',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CashbookComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('cashbook.balance')
      },
      {
        headerName: this.translationService.translate('cashbook.cashStorageTotal'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'cashStorageTotal',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: CashbookComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('cashbook.cashStorageTotal')
      },
      {
        headerName: this.translationService.translate('cashbook.username'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'username',
        width: CashbookComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('cashbook.username')
      }
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: true,
      rowClassRules: {
        'text-align-right': (params) => isNumber(params.data.value),
        'vit-red': (params) => params.data.cashbookNumber === 0,
        'vit-bold': (params) => params.data.isAccounting
      },
      onCellClicked: (params) => {
        params.node.setExpanded(!params.node.expanded);
      }
    };
  }

  generateDetailParams() {
    return {
      detailGridOptions: {
        detailRowHeight: 160,
        rowClassRules: {
          'text-align-right': (params) => isNumber(params.data.value),
          'vit-red': (params) => params.data.cashbookNumber === 0,
          'vit-bold': (params) => params.data.isAccounting
        },
        columnDefs: [
          {
            headerName: this.translationService.translate('cashbook.applicationName'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'applicationName',
            width: CashbookComponent.COLUMN_WIDTH
          },
          {
            headerName: this.translationService.translate('cashbook.stake'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'cashIn',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: CashbookComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('cashbook.stake')
          },
          {
            headerName: this.translationService.translate('cashbook.profit'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'cashOut',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: CashbookComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('cashbook.profit')
          },
          {
            headerName: this.translationService.translate('cashbook.storno'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'storno',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: CashbookComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('cashbook.storno')
          },
          {
            headerName: this.translationService.translate('cashbook.withdrawal'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'withdrawal',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: CashbookComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('cashbook.withdrawal')
          },
          {
            headerName: this.translationService.translate('cashbook.balance'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'balance',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: CashbookComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('cashbook.balance')
          },
          {
            headerName: this.translationService.translate('cashbook.isAccounting'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'isAccounting',
            valueFormatter: (params) => this.translationService.translate('cashbook.accounting' + params.value.toString()),
            width: CashbookComponent.COLUMN_WIDTH_LARGE,
            headerTooltip: this.translationService.translate('cashbook.isAccounting')
          }
        ],
        onCellClicked: (params) => {
          params.node.setExpanded(!params.node.expanded);
        },
        sideBar: {
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnExpandAll: true,
              },
            },
          ],
          defaultToolPanel: '',
        }
      },
      getDetailRowData: (params) => {
        const query = clone(this.query);
        query.stationID = params.data.stationID;
        query.cashbookNumber = params.data.cashbookNumber;
        this.cashbookService.fetchCashbooksPerGame(query).subscribe(data => {
          params.successCallback(enrichByStationId(data, params.data.stationID));
        });
      }
    };
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }

}
