export enum Permission {
  insertStation = 'insertStation',
  editStationLimited = 'editStationLimited',
  editStationLimitedPIN2 = 'editStationLimitedPIN2',
  editStationLimitedSoftLock = 'editStationLimitedSoftLock',
  editStationLimitedHardLock = 'editStationLimitedHardLock',
  editStationLimitedInfo2 = 'editStationLimitedInfo2',
  insertFranchiser = 'insertFranchiser',
  insertWebUser = 'insertWebUser',
  activateSuperStorno = 'activateSuperStorno',
  changeFranchiser = 'changeFranchiser',
  changeMasterFranchiser = 'changeMasterFranchiser',
  insertJackpot = 'insertJackpot',
  changeRF2Settings = 'changeRF2Settings',
  changePB1Settings = 'changePB1Settings',
  seeHoldOverview = 'seeHoldOverview',
  seeDayStatistic = 'seeDayStatistic',
  seeMonthStatistic = 'seeMonthStatistic',
  seeJackpotOverview = 'seeJackpotOverview',
  seeCashbook = 'seeCashbook',
  seeTicketlist = 'seeTicketlist',
  seeTipStatistic = 'seeTipStatistic',
  seeCashOverview = 'seeCashOverview',
  resetMAC = 'resetMAC',
  seeRaceResult = 'seeRaceResult',
  seePB1GameResult = 'seePB1GameResult',
  seeBalanceOverview = 'seeBalanceOverview',
  seeStationOverview = 'seeStationOverview',
  changeBB1Settings = 'changeBB1Settings',
  activateCsvExport = 'activateCsvExport',
  editTermsConditions = 'editTermsConditions',
  seeBB1GameResult = 'seeBB1GameResult',
  lockFranchiser = 'lockFranchiser',
  payoutTicket = 'payoutTicket',
  seeFranchiserHoldOverview = 'seeFranchiserHoldOverview',
  seeShopCashbook = 'seeShopCashbook',
  changeLB2Settings = 'changeLB2Settings',
  editHoldLimiter = 'editHoldLimiter',
  editLB2RiskManagement = 'editLB2RiskManagement',
  stationOperatorPin = 'stationOperatorPin',
  stationOwnerPin = 'stationOwnerPin',
  editBasicStationData = 'editBasicStationData',
  editAdditionalStationData = 'editAdditionalStationData',
  changeStationType = 'changeStationType',
  editTerminalNoCashSystem = 'editTerminalNoCashSystem',
  editFloatingValues = 'editFloatingValues',
  editWebshopConnection = 'editWebshopConnection',
  editBrowserMode = 'editBrowserMode',
  editAdvancedAdminSettings = 'editAdvancedAdminSettings',
  editLogoURLs = 'editLogoURLs',
  selectBB1Cycle = 'selectBB1Cycle',
  editGamesTaxRates = 'editGamesTaxRates',
  viewRF2Gradation = 'viewRF2Gradation',
  editRF2Gradation = 'viewRF2Gradation',
  stornoTickets = 'stornoTickets',
  stornoLB2Tickets = 'stornoLB2Tickets',
  editLB2Design = 'editLB2Design',
  editLB2CombiBonus = 'editLB2CombiBonus',
  initJackpot = 'initJackpot',
  editLB2TicketBets = 'editLB2TicketBets',
  viewMKTicketExportDetails = 'viewMKTicketExportDetails',
  editMKTicketExportDetails = 'editMKTicketExportDetails',
  lockWebUser = 'lockWebUser',
  editUAACurrentCredit = 'editUAACurrentCredit',
  updateBalance = 'updateBalance',
  editBingoCycle = 'editBingoCycle',
  editGameCycle = 'editGameCycle',
  editRaceCycle = 'editRaceCycle',
  seeWebShopHoldOverview = 'seeWebShopHoldOverview',
  seeBetaFeatures = 'seeBetaFeatures',
  seeCustomerAnalysis = 'seeCustomerAnalysis',
  showVirtualModeControls = 'showVirtualModeControls',
  seeFranchiserData = 'seeFranchiserData',
  seeStationData = 'seeStationData',
  seeVirtualMode = 'seeVirtualMode',
  editFranchiserLimited = 'editFranchiserLimited',
  editFranchiserLimitedSoftLock = 'editFranchiserLimitedSoftLock',
  editDisburseTypeLimits = 'editDisburseTypeLimits',
  // helper permissions
  dateOfLastAccounting = 'dateOfLastAccounting',
  notAllowed = 'XXX_NOT_ALLOWED_XXX'
}
