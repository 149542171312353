import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { QuestionBase } from '../domain/question-base';


@Injectable()
export class QuestionControlService {
    constructor(private formBuilder: UntypedFormBuilder) {
    }

    toFormGroup(questions: QuestionBase<any>[]) {
        const group: {
            [key: string]: AbstractControl;
        } = {};

        questions.forEach(question => {
            const validators: ValidatorFn[] = [];
            if (question.required) {
                validators.push(Validators.required);
            }
            const fc: UntypedFormControl = new UntypedFormControl();
            fc.setValue(question.value);
            fc.setValidators(Validators.compose(validators));
            fc.setErrors({'required': true});
            group[question.key] = fc;
        });
        return this.formBuilder.group(group);
    }
}
