import {Component, Input, OnChanges, ViewChild} from '@angular/core';
import {GameService} from '../../services/game.service';
import {Game} from '../../domain/game';
import {LivesportsSettings} from '../../domain/livesports-settings';
import {LivesportsSettingsData} from '../../domain/livesports-settings-data';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {TranslationService} from '../../services/translation.service';
import {QuestionContainer} from '../../domain/question-container';
import {TextboxQuestion} from '../../domain/question-textbox';
import {SessionService} from '../../services/session.service';
import {Permission} from '../../domain/permission';
import {CheckboxQuestion} from '../../domain/question-checkbox';
import {StationType} from '../../domain/station-type';
import {FormUtils} from '../../utils/form-utils';
import {DropdownQuestion} from '../../domain/question-dropdown';
import {TextareaQuestion} from '../../domain/question-textarea';
import {SliderQuestion} from '../../domain/question-slider';
import {CombiBonusCopyComponent} from './combi-bonus-copy/combi-bonus-copy.component';
import CombiBonus = LivesportsSettings.CombiBonus;
import {ColsComponent} from '../../cols/cols.component';
import {InfoLabelQuestion} from '../../domain/question-info-label';
import {ERROR_SNACK_CONFIG, SUCCESS_SNACK_CONFIG} from '../../../utils';

@Component({
  selector: 'vit-livesports',
  templateUrl: './livesports.component.html',
  styleUrls: ['./livesports.component.scss']
})
export class LivesportsComponent implements OnChanges {

  @Input()
  stationID: number;
  settings: LivesportsSettings;
  lstLB2Design: Map<string, string>;
  operatingHours: Map<string, string>;
  lstLegalBetTypeRestriction: Map<string, string>;
  lstLB2CombiBonus: LivesportsSettings.CombiBonus[];
  questions: QuestionContainer[] = [];
  hasChangePermission = false;
  @Input()
  stationType: StationType;

  loading: boolean;
  @ViewChild(ColsComponent, { static: true })
  colsComponent: ColsComponent;

  constructor(public gameService: GameService,
              public snackBar: MatSnackBar,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public dialog: MatDialog) {
    this.hasChangePermission = this.sessionService.hasPermission(Permission.changeLB2Settings);
  }

  ngOnChanges() {
    this.loading = true;
    this.gameService.get(Game.LIVESPORTS, this.stationID).subscribe((sd: LivesportsSettingsData) => {
      this.settings = sd.settings;
      this.lstLB2Design = sd.lstLB2Design;
      this.lstLegalBetTypeRestriction = sd.lstLegalBetTypeRestriction;
      this.operatingHours = sd.operatingHours;
      this.lstLB2CombiBonus = sd.lstLB2CombiBonus;
      this.loading = false;
      this.reset();
    }, () => {
      this.loading = false;
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  save() {
    this.loading = true;
    const settings = this.generateSettingsFromForm();
    this.gameService.saveLivesportsSettings(settings).subscribe(() => {
      this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
      this.settings = settings;
      this.loading = false;
      this.reset();
    }, () => {
      this.loading = false;
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  private generateSettingsFromForm(): LivesportsSettings {
    const ticketLimitsForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'livesports.settings.header.ticketLimits');
    const limitsPerBetGroupForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'livesports.settings.header.limitsPerBetGroup');
    const stornoForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'livesports.settings.header.storno');
    const cashoutForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'livesports.settings.header.cashout');
    const localizationForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'livesports.settings.header.localization');
    const waitingTimeForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'livesports.settings.header.waitingTime');
    const sportbettingForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'livesports.settings.header.sportbetting');
    const legalBetTypeRestrictionForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'livesports.settings.header.legalBetTypeRestriction');
    const operatingHoursForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'livesports.settings.header.operatingHours');
    const betTypeSelectionForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'livesports.settings.header.betTypeSelection');
    const oddShiftForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'livesports.settings.header.oddShift');
    const designSelectionForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'livesports.settings.header.designSelection');
    const shopForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'livesports.settings.header.shop');
    const ticketForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'livesports.settings.header.ticket');
    const extendedOptionsForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'livesports.settings.header.extendedOptions');
    const serverSettingsForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'livesports.settings.header.serverSettings');
    const terminalForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'livesports.settings.header.terminal');
    const combiBonusForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'livesports.settings.header.combiBonus');

    const settings: LivesportsSettings = {
      'lB2SettingID': this.settings.lB2SettingID,
      'stationID': this.settings.stationID,
      'liveServerHost': serverSettingsForm['liveServerHost'],
      'liveServerPort': serverSettingsForm['liveServerPort'] != null ? serverSettingsForm['liveServerPort'] : null,
      'ticketSendDelay': waitingTimeForm['ticketSendDelay'] != null ? +waitingTimeForm['ticketSendDelay'] : null,
      'ticketDisburseDelay': waitingTimeForm['ticketDisburseDelay'] != null ? +waitingTimeForm['ticketDisburseDelay'] : null,
      'enableCashbook': (this.isShop() && shopForm['enableCashbook']) || (!this.isShop() && terminalForm['guiBettingEnabled']),
      'minStake': ticketLimitsForm['minStake'] != null ? +ticketLimitsForm['minStake'] : null,
      'minStakePerRow': ticketLimitsForm['minStakePerRow'] != null ? +ticketLimitsForm['minStakePerRow'] : null,
      'maxStake': ticketLimitsForm['maxStake'] != null ? +ticketLimitsForm['maxStake'] : null,
      'maxOdd': ticketLimitsForm['maxOdd'] != null ? +ticketLimitsForm['maxOdd'] : null,
      'maxProfit': ticketLimitsForm['maxProfit'] != null ? +ticketLimitsForm['maxProfit'] : null,
      'minStakePerRowExactMultiples': ticketLimitsForm['minStakePerRowExactMultiples'],
      'allowAddCentsInExactMultiples': ticketLimitsForm['allowAddCentsInExactMultiples'],
      'maxAnonymousStake': ticketLimitsForm['maxAnonymousStake'] != null ? +ticketLimitsForm['maxAnonymousStake'] : null,
      'limitWay3': limitsPerBetGroupForm['limitWay3'] != null ? +limitsPerBetGroupForm['limitWay3'] : null,
      'limitRestOfMatch': limitsPerBetGroupForm['limitRestOfMatch'] != null ? +limitsPerBetGroupForm['limitRestOfMatch'] : null,
      'limitNextGoal': limitsPerBetGroupForm['limitNextGoal'] != null ? +limitsPerBetGroupForm['limitNextGoal'] : null,
      'limitUnderOver': limitsPerBetGroupForm['limitUnderOver'] != null ? +limitsPerBetGroupForm['limitUnderOver'] : null,
      'limitHandicap': limitsPerBetGroupForm['limitHandicap'] != null ? +limitsPerBetGroupForm['limitHandicap'] : null,
      'limitHalftimeFulltime': limitsPerBetGroupForm['limitHalftimeFulltime'] != null ?
        +limitsPerBetGroupForm['limitHalftimeFulltime'] : null,
      'limitGoalSpecial': limitsPerBetGroupForm['limitGoalSpecial'] != null ? +limitsPerBetGroupForm['limitGoalSpecial'] : null,
      'limitCorrectScore': limitsPerBetGroupForm['limitCorrectScore'] != null ? +limitsPerBetGroupForm['limitCorrectScore'] : null,
      'limitSoccerSpecial': limitsPerBetGroupForm['limitSoccerSpecial'] != null ? +limitsPerBetGroupForm['limitSoccerSpecial'] : null,
      'limitTennisWinner': limitsPerBetGroupForm['limitTennisWinner'] != null ? +limitsPerBetGroupForm['limitTennisWinner'] : null,
      'limitTennisCorrectScore': limitsPerBetGroupForm['limitTennisCorrectScore'] != null ?
        +limitsPerBetGroupForm['limitTennisCorrectScore'] : null,
      'limitTennisUnderOver': limitsPerBetGroupForm['limitTennisUnderOver'] != null ?
        +limitsPerBetGroupForm['limitTennisUnderOver'] : null,
      'limitOutright': limitsPerBetGroupForm['limitOutright'] != null ? +limitsPerBetGroupForm['limitOutright'] : null,
      'limitDefault': limitsPerBetGroupForm['limitDefault'] != null ? +limitsPerBetGroupForm['limitDefault'] : null,
      'showResultBet': betTypeSelectionForm['showResultBet'],
      'showNextGoalBet': betTypeSelectionForm['showNextGoalBet'],
      'showNextGoalBetFirstPeriod': betTypeSelectionForm['showNextGoalBetFirstPeriod'],
      'showNextGoalBetSecondPeriod': betTypeSelectionForm['showNextGoalBetSecondPeriod'],
      'showFirstHalfBetsNotStarted': betTypeSelectionForm['showFirstHalfBetsNotStarted'],
      'showHandicapBet': betTypeSelectionForm['showHandicapBet'],
      'showKickoff': betTypeSelectionForm['showKickoff'],
      'showDoubleChance': betTypeSelectionForm['showDoubleChance'],
      'showDNB': betTypeSelectionForm['showDNB'],
      'showGoalNogoal': betTypeSelectionForm['showGoalNogoal'],
      'showHomeAwayGoals': betTypeSelectionForm['showHomeAwayGoals'],
      'showHalftimeFulltime': betTypeSelectionForm['showHalftimeFulltime'],
      'showCorrectScore': betTypeSelectionForm['showCorrectScore'],
      'showTennis': betTypeSelectionForm['showTennis'],
      'showHockey': betTypeSelectionForm['showHockey'],
      'showBasketball': betTypeSelectionForm['showBasketball'],
      'showHandball': betTypeSelectionForm['showHandball'],
      'showVolleyball': betTypeSelectionForm['showVolleyball'],
      'showFootball': betTypeSelectionForm['showFootball'],
      'showRugby': betTypeSelectionForm['showRugby'],
      'showBaseball': betTypeSelectionForm['showBaseball'],
      'showWintersport': betTypeSelectionForm['showWintersport'],
      'showMotorsport': betTypeSelectionForm['showMotorsport'],
      'showFutsal': betTypeSelectionForm['showFutsal'],
      'showEsport': betTypeSelectionForm['showEsport'],
      'showSimulatedReality': betTypeSelectionForm['showSimulatedReality'],
      'ticketText': ticketForm['ticketText'],
      'helpURL': serverSettingsForm['helpURL'],
      'termsURL': sportbettingForm['termsURL'],
      'oddSheetURL': extendedOptionsForm['oddSheetURL'],
      'externalURL': this.settings.externalURL, // TODO !!
      'enableLive': sportbettingForm['enableLive'],
      'enableSports': sportbettingForm['enableSports'],
      'isReservationStation': terminalForm['isReservationStation'],
      'enableKeyboardUI': shopForm['enableKeyboardUI'],
      'taxRate': localizationForm['taxRate'] != null ? +localizationForm['taxRate'] : null,
      'winTaxRate': localizationForm['winTaxRate'] != null ? +localizationForm['winTaxRate'] : null,
      'winTaxThreshold': this.settings.winTaxThreshold, // TODO !!
      'oddShiftA': oddShiftForm['oddShiftA'] != null ? +oddShiftForm['oddShiftA'] : null,
      'oddShiftBC': oddShiftForm['oddShiftBC'] != null ? +oddShiftForm['oddShiftBC'] : null,
      'oddShiftD': oddShiftForm['oddShiftD'] != null ? +oddShiftForm['oddShiftD'] : null,
      'singleDecimalOdds': localizationForm['singleDecimalOdds'],
      'logoURL': extendedOptionsForm['logoURL'],
      'logo2URL': extendedOptionsForm['logo2URL'],
      'lB2DesignID': designSelectionForm['lB2DesignID'],
      'minCombiEnabled': sportbettingForm['minCombiEnabled'],
      'liveOddsMinCombiEnabled': sportbettingForm['liveOddsMinCombiEnabled'],
      'combiBonusEnabled': combiBonusForm['combiBonusEnabled'],
      'maxCombi': ticketLimitsForm['maxCombi'] != null ? +ticketLimitsForm['maxCombi'] : null,
      'stornoEnabled': stornoForm['stornoEnabled'],
      'validDays': ticketLimitsForm['validDays'] != null ? +ticketLimitsForm['validDays'] : null,
      'legalBetTypeRestriction': legalBetTypeRestrictionForm['legalBetTypeRestriction'] != null ?
        +legalBetTypeRestrictionForm['legalBetTypeRestriction'] : null,
      'printShortTicket': ticketForm['printShortTicket'],
      'printTicketTitle': ticketForm['printTicketTitle'],
      'offerTimeOnlySortOrder': sportbettingForm['offerTimeOnlySortOrder'],
      'inactivityTimeout': terminalForm['inactivityTimeout'] != null ? +terminalForm['inactivityTimeout'] : null,
      'operatingHourFrom': operatingHoursForm['operatingHourFrom'],
      'operatingHourTo': operatingHoursForm['operatingHourTo'],
      'enableCashOut': cashoutForm['enableCashOut'],
      'cashoutMargin': cashoutForm['cashoutMargin'] != null ? +cashoutForm['cashoutMargin'] : null,
      'useProtoBuf': serverSettingsForm['useProtoBuf'],
      'userID': this.settings.userID,
      'lastChange': this.settings.lastChange
    };
    return settings;
  }

  reset() {
    this.resetSettingsQuestions();
  }

  resetSettingsQuestions() {
    const combiBonusQuestions = [];
    for (let i = 1; i <= this.lstLB2CombiBonus.length; i++) {
      const combiBonus = this.lstLB2CombiBonus.filter(cb => cb.combi === i)[0];
      combiBonusQuestions.push(new TextboxQuestion({
        key: 'combiBonus' + i,
        label: 'livesports.settings.combiBonus' + i,
        value: combiBonus.bonus,
        type: 'number'
      }));
    }

    this.questions = [new QuestionContainer('livesports.settings.header.ticketLimits', [
      new TextboxQuestion({
        key: 'minStake',
        label: 'livesports.settings.minStake',
        value: this.settings.minStake,
        type: 'number'
      }),
      new TextboxQuestion({
        key: 'maxStake',
        label: 'livesports.settings.maxStake',
        value: this.settings.maxStake,
        type: 'number'
      }),
      new TextboxQuestion({
        key: 'maxOdd',
        label: 'livesports.settings.maxOdd',
        value: this.settings.maxOdd,
        type: 'number'
      }),
      new TextboxQuestion({
        key: 'maxProfit',
        label: 'livesports.settings.maxProfit',
        value: this.settings.maxProfit,
        type: 'number'
      }),
      new TextboxQuestion({
        key: 'validDays',
        label: 'livesports.settings.validDays',
        value: this.settings.validDays,
        type: 'number'
      }),
      new TextboxQuestion({
        key: 'maxCombi',
        label: 'livesports.settings.maxCombi',
        value: this.settings.maxCombi,
        type: 'number'
      }),
      new TextboxQuestion({
        key: 'minStakePerRow',
        label: 'livesports.settings.minStakePerRow',
        value: this.settings.minStakePerRow,
        type: 'number'
      }),
      new TextboxQuestion({
        key: 'maxAnonymousStake',
        label: 'livesports.settings.maxAnonymousStake',
        value: this.settings.maxAnonymousStake,
        type: 'number'
      }),
      new CheckboxQuestion({
        key: 'minStakePerRowExactMultiples',
        label: 'livesports.settings.minStakePerRowExactMultiples',
        value: this.settings.minStakePerRowExactMultiples
      }),
      new CheckboxQuestion({
        key: 'allowAddCentsInExactMultiples',
        label: 'livesports.settings.allowAddCentsInExactMultiples',
        value: this.settings.allowAddCentsInExactMultiples
      })
    ]),
      new QuestionContainer('livesports.settings.header.localization', [
        new TextboxQuestion({
          key: 'taxRate',
          label: 'livesports.settings.taxRate',
          value: this.settings.taxRate,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'winTaxRate',
          label: 'livesports.settings.winTaxRate',
          value: this.settings.winTaxRate,
          type: 'number'
        }),
        new CheckboxQuestion({
          key: 'singleDecimalOdds',
          label: 'livesports.settings.singleDecimalOdds',
          value: this.settings.singleDecimalOdds,
          viewPermission: this.isWebShop() ? Permission.notAllowed : null
        })
      ]),
      new QuestionContainer('livesports.settings.header.waitingTime', [
        new TextboxQuestion({
          key: 'ticketSendDelay',
          label: 'livesports.settings.ticketSendDelay',
          value: this.settings.ticketSendDelay,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'ticketDisburseDelay',
          label: 'livesports.settings.ticketDisburseDelay',
          value: this.settings.ticketDisburseDelay,
          type: 'number'
        })
      ]),
      new QuestionContainer('livesports.settings.header.sportbetting', [
        new CheckboxQuestion({
          key: 'enableSports',
          label: 'livesports.settings.enableSports',
          value: this.settings.enableSports
        }),
        new CheckboxQuestion({
          key: 'enableLive',
          label: 'livesports.settings.enableLive',
          value: this.settings.enableLive
        }),
        new CheckboxQuestion({
          key: 'minCombiEnabled',
          label: 'livesports.settings.minCombiEnabled',
          value: this.settings.minCombiEnabled
        }),
        new CheckboxQuestion({
          key: 'liveOddsMinCombiEnabled',
          label: 'livesports.settings.liveOddsMinCombiEnabled',
          value: this.settings.liveOddsMinCombiEnabled
        }),
        new CheckboxQuestion({
          key: 'offerTimeOnlySortOrder',
          label: 'livesports.settings.offerTimeOnlySortOrder',
          value: this.settings.offerTimeOnlySortOrder
        }),
        new TextareaQuestion({
          key: 'termsURL',
          label: 'livesports.settings.termsURL',
          value: this.settings.termsURL,
          minRows: 2,
        })
      ], null, this.isWebShop() ? Permission.notAllowed : null),
      new QuestionContainer('livesports.settings.header.limitsPerBetGroup', [
        new TextboxQuestion({
          key: 'limitWay3',
          label: 'livesports.settings.limitWay3',
          value: this.settings.limitWay3,
          tooltip: this.generateTooltip('limitWay3'),
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'limitRestOfMatch',
          label: 'livesports.settings.limitRestOfMatch',
          value: this.settings.limitRestOfMatch,
          tooltip: this.generateTooltip('limitRestOfMatch'),
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'limitNextGoal',
          label: 'livesports.settings.limitNextGoal',
          value: this.settings.limitNextGoal,
          tooltip: this.generateTooltip('limitNextGoal'),
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'limitUnderOver',
          label: 'livesports.settings.limitUnderOver',
          value: this.settings.limitUnderOver,
          tooltip: this.generateTooltip('limitUnderOver'),
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'limitHandicap',
          label: 'livesports.settings.limitHandicap',
          value: this.settings.limitHandicap,
          tooltip: this.generateTooltip('limitHandicap'),
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'limitHalftimeFulltime',
          label: 'livesports.settings.limitHalftimeFulltime',
          value: this.settings.limitHalftimeFulltime,
          tooltip: this.generateTooltip('limitHalftimeFulltime'),
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'limitGoalSpecial',
          label: 'livesports.settings.limitGoalSpecial',
          value: this.settings.limitGoalSpecial,
          tooltip: this.generateTooltip('limitGoalSpecial'),
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'limitCorrectScore',
          label: 'livesports.settings.limitCorrectScore',
          value: this.settings.limitCorrectScore,
          tooltip: this.generateTooltip('limitCorrectScore'),
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'limitSoccerSpecial',
          label: 'livesports.settings.limitSoccerSpecial',
          value: this.settings.limitSoccerSpecial,
          tooltip: this.generateTooltip('limitSoccerSpecial'),
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'limitTennisWinner',
          label: 'livesports.settings.limitTennisWinner',
          value: this.settings.limitTennisWinner,
          tooltip: this.generateTooltip('limitTennisWinner'),
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'limitTennisCorrectScore',
          label: 'livesports.settings.limitTennisCorrectScore',
          value: this.settings.limitTennisCorrectScore,
          tooltip: this.generateTooltip('limitTennisCorrectScore'),
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'limitTennisUnderOver',
          label: 'livesports.settings.limitTennisUnderOver',
          value: this.settings.limitTennisUnderOver,
          tooltip: this.generateTooltip('limitTennisUnderOver'),
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'limitOutright',
          label: 'livesports.settings.limitOutright',
          value: this.settings.limitOutright,
          tooltip: this.generateTooltip('limitOutright'),
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'limitDefault',
          label: 'livesports.settings.limitDefault',
          value: this.settings.limitDefault,
          tooltip: this.generateTooltip('limitDefault'),
          type: 'number'
        })
      ]),
      new QuestionContainer('livesports.settings.header.legalBetTypeRestriction', [
        new DropdownQuestion({
          key: 'legalBetTypeRestriction',
          label: 'livesports.settings.legalBetTypeRestriction',
          value: this.settings.legalBetTypeRestriction.toString() || '0',
          options: Array.from(this.lstLegalBetTypeRestriction.entries()).map(([key, value]) => ({
            key,
            value: this.translationService.translate('livesports.settings.legalBetTypes.' + value)
          }))
        })
      ], null, this.isWebShop() ? Permission.notAllowed : null),
      new QuestionContainer('livesports.settings.header.operatingHours', [
        new DropdownQuestion({
          key: 'operatingHourFrom',
          label: 'livesports.settings.operatingHourFrom',
          value: this.settings.operatingHourFrom || '00:00:00',
          options: Array.from(this.operatingHours.entries()).map(([key, value]) => ({key, value}))
        }), new DropdownQuestion({
          key: 'operatingHourTo',
          label: 'livesports.settings.operatingHourTo',
          value: this.settings.operatingHourTo || '00:00:00',
          options: Array.from(this.operatingHours.entries()).map(([key, value]) => ({key, value}))
        })
      ], null, this.isWebShop() ? Permission.notAllowed : null),
      new QuestionContainer('livesports.settings.header.betTypeSelection', [
        new CheckboxQuestion({
          key: 'showResultBet',
          label: 'livesports.settings.showResultBet',
          value: this.settings.showResultBet
        }),
        new CheckboxQuestion({
          key: 'showNextGoalBet',
          label: 'livesports.settings.showNextGoalBet',
          value: this.settings.showNextGoalBet
        }),
        new CheckboxQuestion({
          key: 'showNextGoalBetFirstPeriod',
          label: 'livesports.settings.showNextGoalBetFirstPeriod',
          value: this.settings.showNextGoalBetFirstPeriod
        }),
        new CheckboxQuestion({
          key: 'showNextGoalBetSecondPeriod',
          label: 'livesports.settings.showNextGoalBetSecondPeriod',
          value: this.settings.showNextGoalBetSecondPeriod
        }),
        new CheckboxQuestion({
          key: 'showHomeAwayGoals',
          label: 'livesports.settings.showHomeAwayGoals',
          value: this.settings.showHomeAwayGoals
        }),
        new CheckboxQuestion({
          key: 'showGoalNogoal',
          label: 'livesports.settings.showGoalNogoal',
          value: this.settings.showGoalNogoal
        }),
        new CheckboxQuestion({
          key: 'showFirstHalfBetsNotStarted',
          label: 'livesports.settings.showFirstHalfBetsNotStarted',
          value: this.settings.showFirstHalfBetsNotStarted
        }),
        new CheckboxQuestion({
          key: 'showHandicapBet',
          label: 'livesports.settings.showHandicapBet',
          value: this.settings.showHandicapBet
        }),
        new CheckboxQuestion({
          key: 'showKickoff',
          label: 'livesports.settings.showKickoff',
          value: this.settings.showKickoff
        }),
        new CheckboxQuestion({
          key: 'showDoubleChance',
          label: 'livesports.settings.showDoubleChance',
          value: this.settings.showDoubleChance
        }),
        new CheckboxQuestion({
          key: 'showDNB',
          label: 'livesports.settings.showDNB',
          value: this.settings.showDNB
        }),
        new CheckboxQuestion({
          key: 'showCorrectScore',
          label: 'livesports.settings.showCorrectScore',
          value: this.settings.showCorrectScore
        }),
        new CheckboxQuestion({
          key: 'showHalftimeFulltime',
          label: 'livesports.settings.showHalftimeFulltime',
          value: this.settings.showHalftimeFulltime
        }),
        new CheckboxQuestion({
          key: 'showTennis',
          label: 'livesports.settings.showTennis',
          value: this.settings.showTennis
        }),
        new CheckboxQuestion({
          key: 'showHockey',
          label: 'livesports.settings.showHockey',
          value: this.settings.showHockey
        }),
        new CheckboxQuestion({
          key: 'showBasketball',
          label: 'livesports.settings.showBasketball',
          value: this.settings.showBasketball
        }),
        new CheckboxQuestion({
          key: 'showHandball',
          label: 'livesports.settings.showHandball',
          value: this.settings.showHandball
        }),
        new CheckboxQuestion({
          key: 'showVolleyball',
          label: 'livesports.settings.showVolleyball',
          value: this.settings.showVolleyball
        }),
        new CheckboxQuestion({
          key: 'showFootball',
          label: 'livesports.settings.showFootball',
          value: this.settings.showFootball
        }),
        new CheckboxQuestion({
          key: 'showRugby',
          label: 'livesports.settings.showRugby',
          value: this.settings.showRugby
        }),
        new CheckboxQuestion({
          key: 'showBaseball',
          label: 'livesports.settings.showBaseball',
          value: this.settings.showBaseball
        }),
        new CheckboxQuestion({
          key: 'showWintersport',
          label: 'livesports.settings.showWintersport',
          value: this.settings.showWintersport
        }),
        new CheckboxQuestion({
          key: 'showMotorsport',
          label: 'livesports.settings.showMotorsport',
          value: this.settings.showMotorsport
        }),
        new CheckboxQuestion({
          key: 'showFutsal',
          label: 'livesports.settings.showFutsal',
          value: this.settings.showFutsal
        }),
        new CheckboxQuestion({
          key: 'showEsport',
          label: 'livesports.settings.showEsport',
          value: this.settings.showEsport
        }),
        new CheckboxQuestion({
          key: 'showSimulatedReality',
          label: 'livesports.settings.showSimulatedReality',
          value: this.settings.showSimulatedReality
        })
      ], null, this.isWebShop() ? Permission.notAllowed : null),
      new QuestionContainer('livesports.settings.header.storno', [
        new CheckboxQuestion({
          key: 'stornoEnabled',
          label: 'livesports.settings.stornoEnabled',
          value: this.settings.stornoEnabled
        })
      ], Permission.stornoTickets, this.isWebShop() ? Permission.notAllowed : null),
      new QuestionContainer('livesports.settings.header.cashout', [
        new CheckboxQuestion({
          key: 'enableCashOut',
          label: 'livesports.settings.enableCashOut',
          value: this.settings.enableCashOut
        }),
        new TextboxQuestion({
          key: 'cashoutMargin',
          label: 'livesports.settings.cashoutMargin',
          value: this.settings.cashoutMargin,
          type: 'number'
        })
      ]),
      new QuestionContainer('livesports.settings.header.oddShift', [
        new SliderQuestion({
          key: 'oddShiftA',
          label: 'livesports.settings.oddShiftA',
          value: this.settings.oddShiftA,
          min: -0.15,
          max: 0.1,
          tickInterval: 1,
          stepSize: 0.05
        }),
        new SliderQuestion({
          key: 'oddShiftBC',
          label: 'livesports.settings.oddShiftBC',
          value: this.settings.oddShiftBC,
          type: 'number',
          min: -0.15,
          max: 0.1,
          tickInterval: 1,
          stepSize: 0.05
        }),
        new SliderQuestion({
          key: 'oddShiftD',
          label: 'livesports.settings.oddShiftD',
          value: this.settings.oddShiftD,
          type: 'number',
          min: -0.15,
          max: 0.1,
          tickInterval: 1,
          stepSize: 0.05
        })
      ], Permission.editLB2RiskManagement, this.isWebShop() ? Permission.notAllowed : null),
      new QuestionContainer('livesports.settings.header.shop', [
        new CheckboxQuestion({
          key: 'enableCashbook',
          label: 'livesports.settings.enableCashbook',
          value: this.settings.enableCashbook
        }),
        new CheckboxQuestion({
          key: 'enableKeyboardUI',
          label: 'livesports.settings.enableKeyboardUI',
          value: this.settings.enableKeyboardUI
        })
      ], null, this.isShop() ? null : Permission.notAllowed),
      new QuestionContainer('livesports.settings.header.designSelection', [
        new DropdownQuestion({
          key: 'lB2DesignID',
          label: 'livesports.settings.lB2DesignID',
          value: this.settings.lB2DesignID.toString() || this.lstLB2Design.keys().next().value,
          options: Array.from(this.lstLB2Design.entries()).map(([key, value]) => ({key, value}))
        })
      ], Permission.editLB2Design, this.isWebShop() ? Permission.notAllowed : null),
      new QuestionContainer('livesports.settings.header.ticket', [
        new CheckboxQuestion({
          key: 'printShortTicket',
          label: 'livesports.settings.printShortTicket',
          value: this.settings.printShortTicket
        }),
        new CheckboxQuestion({
          key: 'printTicketTitle',
          label: 'livesports.settings.printTicketTitle',
          value: this.settings.printTicketTitle
        }),
        new TextareaQuestion({
          key: 'ticketText',
          label: 'livesports.settings.ticketText',
          value: this.settings.ticketText,
          minRows: 5
        })
      ]),
      new QuestionContainer('livesports.settings.header.combiBonus', [
        new CheckboxQuestion({
          key: 'combiBonusEnabled',
          label: 'livesports.settings.combiBonusEnabled',
          value: this.settings.combiBonusEnabled
        }),
        ...combiBonusQuestions,
        new InfoLabelQuestion({
          label: 'livesports.settings.combiBonusInfo'
        })
      ], Permission.editLB2CombiBonus),
      new QuestionContainer('livesports.settings.header.extendedOptions', [
        new TextareaQuestion({
          key: 'logoURL',
          label: 'livesports.settings.logoURL',
          value: this.settings.logoURL,
          minRows: 2
        }),
        new TextareaQuestion({
          key: 'logo2URL',
          label: 'livesports.settings.logo2URL',
          value: this.settings.logo2URL,
          minRows: 2
        }),
        new TextareaQuestion({
          key: 'oddSheetURL',
          label: 'livesports.settings.oddSheetURL',
          value: this.settings.oddSheetURL,
          minRows: 2
        })
      ], null, this.isWebShop() ? Permission.notAllowed : Permission.editLogoURLs),
      new QuestionContainer('livesports.settings.header.serverSettings', [
        new TextboxQuestion({
          key: 'liveServerPort',
          label: 'livesports.settings.liveServerPort',
          value: this.settings.liveServerPort,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'liveServerHost',
          label: 'livesports.settings.liveServerHost',
          value: this.settings.liveServerHost
        }),
        new TextareaQuestion({
          key: 'helpURL',
          label: 'livesports.settings.helpURL',
          value: this.settings.helpURL,
          minRows: 2
        }),
        new CheckboxQuestion({
          key: 'useProtoBuf',
          label: 'livesports.settings.useProtoBuf',
          value: this.settings.useProtoBuf
        })
      ], null, this.isWebShop() ? Permission.notAllowed : Permission.editAdvancedAdminSettings),
      new QuestionContainer('livesports.settings.header.terminal', [
        new CheckboxQuestion({
          key: 'guiBettingEnabled',
          label: 'livesports.settings.guiBettingEnabled',
          value: this.settings.enableCashbook
        }),
        new TextboxQuestion({
          key: 'inactivityTimeout',
          label: 'livesports.settings.inactivityTimeout',
          value: this.settings.inactivityTimeout,
          type: 'number',
          viewPermission: Permission.editAdvancedAdminSettings
        }),
        new CheckboxQuestion({
          key: 'isReservationStation',
          label: 'livesports.settings.isReservationStation',
          value: this.settings.isReservationStation
        }),
      ], null, this.isShop() || this.isWebShop() ? Permission.notAllowed : null)
    ];

    const combiBonusContainer = this.questions.find(qc => qc.label === 'livesports.settings.header.combiBonus');
    combiBonusContainer.addSubmit('livesports.settings.combiBonus.save', this.saveCombiBonus.bind(this),
      Permission.editLB2CombiBonus);
    combiBonusContainer.addSubmit('livesports.settings.combiBonus.delete', this.deleteCombiBonus.bind(this),
      Permission.editLB2CombiBonus);
    combiBonusContainer.addSubmit('livesports.settings.combiBonus.transfer', this.transferCombiBonus.bind(this),
      Permission.editLB2CombiBonus);
  }

  private saveCombiBonus(combiBonusFormValues: any) {
    const combiBonus: CombiBonus[] = JSON.parse(JSON.stringify(this.lstLB2CombiBonus));
    combiBonus.forEach(cb => cb.bonus = +combiBonusFormValues['combiBonus' + cb.combi]);
    this.gameService.saveCombiBonus(combiBonus).subscribe(() => {
      this.lstLB2CombiBonus = combiBonus;
      this.resetSettingsQuestions();
      this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
    }, () => {
      this.resetSettingsQuestions();
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  private deleteCombiBonus() {
    this.gameService.deleteCombiBonus(this.settings.stationID).subscribe(() => {
      this.resetSettingsQuestions();
      this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
    }, () => {
      this.resetSettingsQuestions();
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  private transferCombiBonus() {
    this.dialog.open(CombiBonusCopyComponent, {
      width: '40%',
      minWidth: '300px',
      data: {
        'stationID': this.stationID
      }
    });
  }

  private isShop() {
    return this.stationType === StationType.SHOP;
  }

  private isWebShop() {
    return this.stationType === StationType.WEBSHOP;
  }

  private generateTooltip(key: string) {
    switch (key) {
      case 'limitWay3':
        return this.translate('Bet_Result') + '\n' + this.translate('Bet_Way3H1') + '\n' +
          this.translate('Bet_Way3H2') + '\n' + this.translate('Bet_Result_OT') + '\n' +
          this.translate('Bet_Result') + ' + ' + this.translate('Bet_UO_O') + '/' + this.translate('Bet_UO_U') + this.translate('Bet_UO_Goals') + '\n' +
          this.translate('Bet_Doublechance') + '\n' + this.translate('Bet_DC_H1') + '\n' + this.translate('Bet_DC_H2') + '\n' + this.translate('Bet_DrawNoBet');
      case 'limitRestOfMatch':
        return this.translate('Bet_ROM') + '\n' + this.translate('Bet_ROM_H1') + '\n' + this.translate('Bet_ROM_OT') + '\n' +
          this.translate('Bet_Hockey_PeriodWinner');
      case 'limitNextGoal':
        return this.translate('Bet_NG') + '\n' + this.translate('Bet_NG_H1') + '\n' + this.translate('Bet_NG_OT');
      case 'limitUnderOver':
        return this.translate('Bet_UO_O') + '/' + this.translate('Bet_UO_U') + ' ' + this.translate('Bet_UO_Goals') + '\n' +
          this.translate('Bet_UO_O') + '/' + this.translate('Bet_UO_U') + ' ' + this.translate('Bet_UO_H1_Goals') + '\n' +
          this.translate('Bet_UO_O') + '/' + this.translate('Bet_UO_U') + ' ' + this.translate('Bet_UO_OT_Goals') + '\n' +
          this.translate('Bet_OverUnderH2') + '\n' +
          this.translate('Bet_UO_Home') + '\n' + this.translate('Bet_UO_Away') + '\n' + this.translate('Bet_UO_Corner');
      case 'limitHandicap':
        return this.translate('Bet_Handicap') + '\n' + this.translate('Bet_HC_H1') + '\n' + this.translate('Bet_HC_H2') + '\n' + this.translate('Bet_AdvNextRound');
      case 'limitHalftimeFulltime':
        return this.translate('Bet_HTFT');
      case 'limitGoalSpecial':
        return this.translate('Bet_GAT') + '\n' + this.translate('Bet_GHT') + '\n' + this.translate('Bet_GoalNoGoal') + '\n' +
          this.translate('Bet_GoalNoGoalH1') + '\n' + this.translate('Bet_GoalOddEven') + '\n' + this.translate('Bet_LastGoal') + '\n' +
          this.translate('Bet_TotalGoals') + '\n' + this.translate('Bet_FirstGoalInt15') + '\n' + this.translate('Bet_HighestScoreHalf') + '\n' +
          this.translate('Bet_TotGoalsExact') + '\n' + this.translate('Bet_TotGoalsExact_H1') + '\n' + this.translate('Bet_TotGoalsExact_H2');
      case 'limitCorrectScore':
        return this.translate('Bet_CorrectScore') + '\n' + this.translate('Bet_CS_H1');
      case 'limitSoccerSpecial':
        return this.translate('Bet_Kickoff') + '\n' + this.translate('Bet_Penalty') + '\n' +
          this.translate('Bet_PlayerSentOff') + '\n' + this.translate('Bet_CornerWay3') + '\n' + this.translate('Bet_CornerTotal');
      case 'limitTennisWinner':
        return this.translate('Bet_Result') + '\n' + this.translate('Bet_Tennis_WhoWinsSet') + ' X';
      case 'limitTennisCorrectScore':
        return this.translate('Bet_Tennis_Result_Best3') + '\n' + this.translate('Bet_Tennis_Result_Best5') + '\n' +
          this.translate('Bet_CorrectScore') + ' ' + this.translate('Bet_Tennis_InSet') + ' X';
      case 'limitTennisUnderOver':
        return this.translate('Bet_Tennis_OU_Games') + ' ' + this.translate('Bet_Tennis_InSet') + '\n' +
          this.translate('Bet_Tennis_NumberOfSets_BestOf3') + '\n' + this.translate('Bet_Tennis_NumberOfSets_BestOf5') + '\n' +
          this.translate('Bet_Tennis_OU_Games') + '\n' +
          this.translate('Bet_Tennis_WhoWinsGame') + ' X ' + this.translate('Bet_Tennis_InSet') + ' Y\n' +
          this.translate('Bet_Tennis_WhoWinsGames') + ' X ' + this.translate('Bet_And') + ' Y ' + this.translate('Bet_Tennis_InSet') + ' Z ';
      case 'limitOutright':
        return this.translate('lblOutright');
      case 'limitDefault':
      default:
        return '...';
    }
  }

  private translate(key: string) {
    return this.translationService.translate('ticketDetail.livesports.betTypeKey.' + key);
  }
}
