<mat-toolbar color="accent" class="toolbar">
  {{'stationCopy.title' | translate: [station?.stationID, station?.name]}}
</mat-toolbar>
<br>
<vit-dynamic-form #questions [questionContainer]="copyQuestions"></vit-dynamic-form>
<mat-card>
  <p><b>{{'stationCopy.stations' | translate }}</b></p>
  <div *ngFor="let station of newStations; let idx = index" class="new-station">

    <mat-form-field>
      <mat-label>{{'stationCopy.newStationID' | translate: ['' + (idx + 1)]}}</mat-label>
      <input matInput [(ngModel)]="station.stationID" [readonly]="station.status"
             type="text">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'stationCopy.newStationName' | translate: ['' + (idx + 1)]}}</mat-label>
      <input matInput [(ngModel)]="station.name" [readonly]="station.status"
             type="text">
    </mat-form-field>
    <div class="status" *ngIf="station.status">
      <mat-spinner matTooltip="{{'stationCopy.toolProcessing' | translate}}" *ngIf="station.status === 'processing'" [diameter]="20"></mat-spinner>
      <mat-icon matTooltip="{{'stationCopy.toolSuccessful' | translate}}" *ngIf="station.status === 'successful'">check_circle</mat-icon>
      <mat-icon matTooltip="{{'stationCopy.toolFailed' | translate}}" *ngIf="station.status === 'failed'">warning</mat-icon>
    </div>
    <mat-icon matTooltip="{{'stationCopy.toolOpen' | translate}}" class="open" *ngIf="station.status === 'successful'" (click)="open(station)">open_in_new</mat-icon>
  </div>
</mat-card>

<div class="footer">
  <button type="reset"
          (click)="close()"
          mat-flat-button
          color="secondary">
    {{'stationCopy.close' | translate}}
  </button>
  <button type="submit"
          (click)="triggerSave()"
          [disabled]="!questions.form.valid || loading || stationsInvalid() || stationsProcessed()"
          mat-flat-button
          color="accent">
    {{'stationCopy.submit' | translate}}
  </button>
</div>
