import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {SystemConfig} from '../config/system-config';
import {Observable} from 'rxjs';
import {QueryFilter} from '../domain/query-filter';

@Injectable({
  providedIn: 'root'
})
export class CycleService {

  constructor(public httpService: HttpService) {
  }

  fetchRF2Cycles(): Observable<any[]> {
    return this.httpService.get(SystemConfig.Endpoints.CYCLE_RF2);
  }

  fetchBB1Cycles(): Observable<any[]> {
    return this.httpService.get(SystemConfig.Endpoints.CYCLE_BB1);
  }

  fetchPB1Cycles(): Observable<any[]> {
    return this.httpService.get(SystemConfig.Endpoints.CYCLE_PB1);
  }

  updateRF2Cycle(cycle): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.CYCLE_RF2_UPDATE, cycle);
  }

  updateBB1Cycle(cycle): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.CYCLE_BB1_UPDATE, cycle);
  }

  updatePB1Cycle(cycle): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.CYCLE_PB1_UPDATE, cycle);
  }
}
