<vit-search-form
  (submitEmiter)="fetchCashbookStations($event)"
  [hideGames]="true"
  [hideMinProfit]="true"
  [hideMinStake]="true"
  [hideMinTickets]="true"
  [hideIntoEuro]="true"
  [showNow]="true"
  [rowCount]="cashbookService?.lastCashbookStationsRowCount">
</vit-search-form>
<ag-grid-angular
  *ngIf="rowData"
  (columnVisible)="storeColumnVisibilityFn('cashbook', $event)"
  class="ag-theme-balham"
  style="width: 100%; flex: 1 1 1px;"
  [gridOptions]="gridOptions"
  [detailRowHeight]="160"
  [rowData]="rowData | async"
  [columnDefs]="columnDefs"
  [detailCellRendererParams]="detailParams"
  (gridReady)="onGridReady($event)">
</ag-grid-angular>
<div class="info-bar vit-red"
     *ngIf="rowData">
  <span>{{'cashbook.infoText' | translate}}</span>
  {{rowData?.length}}
</div>

<!-- 1223px -->
