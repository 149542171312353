import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GameNumber} from '../../domain/game-number';
import {TicketDetailService} from '../ticket-detail.service';
import {SessionService} from '../../services/session.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';
import {TranslationService} from '../../services/translation.service';
import {Permission} from '../../domain/permission';

@Component({
  selector: 'vit-storno',
  templateUrl: './storno.component.html',
  styleUrls: ['./storno.component.scss']
})
export class StornoComponent implements OnInit {

  @Input()
  ticket: any;

  @Input()
  gameNumer: GameNumber;

  @Output()
  update: EventEmitter<void> = new EventEmitter();

  constructor(private ticketDetailService: TicketDetailService,
              public sessionService: SessionService,
              public translationService: TranslationService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  click() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        'message': this.translationService.translate('ticketDetail.stornoWidget.confirmMessage' + (this.ticket.storno ? 'Undo' : ''))
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.ticket.storno = !this.ticket.storno;
        this.ticketDetailService.update(this.ticket, this.gameNumer).subscribe(() => {
          this.update.emit();
        });
      }
    });
  }

  isAllowed() {
    if (!this.ticket.storno) {
      return (this.sessionService.
        hasPermission(this.gameNumer === GameNumber.LIVESPORTS ? Permission.stornoLB2Tickets : Permission.stornoTickets)
        ) || this.sessionService.hasPermission(Permission.editAdvancedAdminSettings);
    } else {
      return this.gameNumer === GameNumber.LIVESPORTS && this.sessionService.hasPermission(Permission.editAdvancedAdminSettings);
    }
  }
}
