
import {Component, HostBinding, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {SessionService} from './services/session.service';
import { MatSidenav } from '@angular/material/sidenav';
import {UserService} from './services/user.service';
import {HomeMenuService} from './services/home-menu.service';
import {MobileService} from './services/mobile.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {isNullOrUndefined} from '../utils';

@Component({
  selector: 'vit-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('sideMenu', { static: true }) sideNav: MatSidenav;
  @ViewChild('tree', { static: true }) treeNav: MatSidenav;

  @HostBinding('class.is-mobile')
  get isMobileClass() {
    return this.mobileService.isMobile;
  }
  @HostBinding('attr.class')
  get theme() {
    return this.sessionService.theme;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.mobileService.setScreenWidth(window.innerWidth);
  }

  constructor(@Inject('permission') private permission,
              public sessionService: SessionService,
              public userService: UserService,
              private titleService: Title,
              public homeMenuService: HomeMenuService,
              public mobileService: MobileService) {
    this.mobileService.setScreenWidth(window.innerWidth);
    this.titleService.setTitle(environment.title);

    this.sessionService.treeObs.subscribe(e => {
      this.toggleTree(e);
    });
    this.sessionService.menuObs.subscribe(e => {
      this.toggleMenu(e);
    });
  }

  ngOnInit() {
    this.hideSidenavIfMobile();
    setInterval(() => this.sessionService.refreshSessionTimer(), 2500);
  }

  toggleMenu(state: 'wide' | 'narrow') {
    if (state && this.sideNav) {
      if (state === 'wide' && this.sideNav.opened) {
        this.sideNav.toggle();
      } else if (state === 'narrow' && !this.sideNav.opened) {
        this.sideNav.toggle();
      }
    }
  }

  toggleTree(state: 'wide' | 'narrow') {
    if (state && this.treeNav) {
      this.treeNav.opened = state !== 'wide';
    }
  }

  hideSidenavIfMobile(alsoHideTree?: boolean) {
    if ((this.sideNav && this.mobileService.isMobile && !isNullOrUndefined(this.sessionService.selectedMenuElement))) {
      this.sideNav.close();
    }
    if (alsoHideTree) {
      this.treeNav.close();
    }
  }
}
