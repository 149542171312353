import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, SimpleChanges} from '@angular/core';
import {BetTypeKeyMapper} from './livesports-bettype-mapper.service';
import {TranslationService} from '../services/translation.service';
import {DatePipe} from '@angular/common';
import {TicketDetailLivesportsService} from './ticket-detail-livesports.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {SecurecodeDialogComponent} from './securecode-dialog.component';
import {SessionService} from '../services/session.service';
import {Permission} from '../domain/permission';
import {copyToClipboard, ERROR_SNACK_CONFIG, SUCCESS_SNACK_CONFIG} from '../../utils';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {LBScoreType, MatchStatus, MeScore, SimpleMatchStatus} from '../live-admin/match-editor/match-detail/MEObject';
import {TicketListComponent} from '../ticket-list/ticket-list.component';
import {Sport} from './sport';
import {generate} from 'rxjs';

@Component({
  selector: 'vit-ticket-detail-livesports',
  templateUrl: './ticket-detail-livesports.component.html',
  styleUrls: ['./ticket-detail-livesports.component.scss']
})
export class TicketDetailLivesportsComponent implements OnInit {

  @Input()
  ticketData: any;
  ticket: any;
  bets: any[] = [];
  canEditLB2TicketBets = false;
  clipboard;
  showPrepaidAdminLink = false;
  showCustomerAnalysisLink = false;

  @Output()
  betsChanged: EventEmitter<void> = new EventEmitter();

  SimpleMatchStatus = SimpleMatchStatus;

  constructor(public betTypeKeyMapper: BetTypeKeyMapper,
              public sessionService: SessionService,
              public router: Router,
              public translationService: TranslationService,
              @Inject(LOCALE_ID) private language: string,
              public ticketDetailLivesportsService: TicketDetailLivesportsService,
              public datePipe: DatePipe,
              public snackBar: MatSnackBar,
              public dialog: MatDialog) {
    this.clipboard = copyToClipboard.bind(this);
  }

  ngOnInit() {
    this.canEditLB2TicketBets = this.sessionService.hasPermission(Permission.editLB2TicketBets);
    this.ticket = this.ticketData.ticket;
    this.showPrepaidAdminLink = !!environment.prepaidAdminUrl && this.sessionService.user.userAccountAdminEnabled;
    this.showCustomerAnalysisLink = this.sessionService.hasPermission(Permission.seeCustomerAnalysis);

    this.bets = this.ticketData.bets.map(bet => {
      const hasIcon = [Sport.SOCCER, Sport.BASKETBALL, Sport.BASEBALL, Sport.ICE_HOCKEY, Sport.TENNIS, Sport.HANDBALL,
        Sport.MOTORSPORT, Sport.RUGBY, Sport.WINTERSPORT, Sport.FOOTBALL, Sport.TABLE_TENNIS, Sport.VOLLEYBALL, Sport.ESOCCER, Sport.DARTS
      ].indexOf(bet.brSportID) > -1;
      return {
        'id': bet.lB2BetID,
        'tooltip': this.generateTooltip(bet),
        'startTs': this.datePipe.transform(bet.matchStartDate, 'medium'),
        'sportName': bet.brNameSport,
        'brMatchID': bet.brMatchID,
        'lB_MatchID': bet.lB_MatchID,
        'match': bet.competitors,
        'live': bet.isLive,
        'type': this.betTypeKeyMapper.getText(bet.betTypeKey, bet.brSportID, bet.specialOddValue),
        'odd': bet.odd,
        'tip': bet.betTypeKey === 49 ? bet.brOddFieldType : this.mapTip(bet.oddKey),
        'bank': bet.bank,
        'cancelled': bet.cancelled,
        'calculated': bet.calculated,
        'won': bet.won,
        'storno': bet.storno,
        'simpleMatchStatus': bet.simpleMatchStatus,
        'matchStatus': bet.matchStatus,
        'sportIcon': hasIcon ? 'sport_' + bet.brSportID : undefined,
        bet
      };
    });
  }

  openPrepaidAdmin(userAccountId: string) {
    const uri = environment.prepaidAdminUrl + userAccountId;
    console.log(uri);
    window.open(encodeURI(uri), '_blank');
  }

  openCustomerAnalysis(customerId: string) {
    const url = environment.host + '/home/customer-info?customerId=' + customerId;
    window.open(encodeURI(url), '_blank');
  }

  confirmWithSecureCode(bet) {
    const dialogRef = this.dialog.open(SecurecodeDialogComponent, {
      data: {secureCode: this.sessionService.secureCode},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(secureCode => {
      if (secureCode && secureCode.length > 0) {
        this.submitUpdate(bet, secureCode);
      }
    });
  }

  private submitUpdate(bet, secureCode) {
    this.ticketDetailLivesportsService.update(bet, this.ticket.number, false, secureCode).subscribe(() => {
      this.sessionService.secureCode = secureCode;
      const idx = this.ticketData.bets.findIndex(b => b.lB2BetID === bet.lB2BetID);
      this.ticketData.bets[idx] = bet;
      this.betsChanged.emit();
      this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
    }, () => {
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  setWon(lB2BetID: number) {
    const bet = JSON.parse(JSON.stringify(this.getBet(lB2BetID)));
    bet.won = true;
    bet.calculated = true;
    this.confirmWithSecureCode(bet);
  }

  setLost(lB2BetID: number) {
    const bet = JSON.parse(JSON.stringify(this.getBet(lB2BetID)));
    bet.won = false;
    bet.calculated = true;
    this.confirmWithSecureCode(bet);
  }

  cancel(lB2BetID: number) {
    const bet = JSON.parse(JSON.stringify(this.getBet(lB2BetID)));
    bet.cancelled = true;
    this.confirmWithSecureCode(bet);
  }

  undoCancel(lB2BetID: number) {
    const bet = JSON.parse(JSON.stringify(this.getBet(lB2BetID)));
    bet.cancelled = false;
    this.confirmWithSecureCode(bet);
  }

  getBet(lB2BetID: number): any {
    return this.ticketData.bets.find(b => b.lB2BetID === lB2BetID);
  }

  generateTooltip(bet: any) {
    const startDateLabel = this.translationService.translate('ticketDetail.livesports.startdate') + ' ';
    const matchIdLabel = this.translationService.translate('ticketDetail.livesports.matchId') + ' ';
    const brMatchIdLabel = this.translationService.translate('ticketDetail.livesports.brMatchId') + ' ';
    const ticketIdLabel = this.translationService.translate('ticketDetail.livesports.ticketId') + ' ';
    const betTypeIdLabel = this.translationService.translate('ticketDetail.livesports.betTypeId') + ' ';
    const scoreLabel = this.translationService.translate('ticketDetail.livesports.score') + ' ';
    const sportLabel = this.translationService.translate('ticketDetail.livesports.sport') + ' ';
    const matchStatus = MatchStatus[bet.matchStatus] + ' ';
    const scoreVerlauf = 'Score-Verlauf ';
    const lineBreak = '\n';

    // scoreTimeline: MeScore[];
    
    return bet.competitors + lineBreak
      + scoreLabel + (bet.scoreDetail ? bet.scoreDetail : bet.score) + lineBreak + ' ' + lineBreak 

      + startDateLabel + this.datePipe.transform(bet.matchStartDate, 'medium') + lineBreak
      + 'Turnier: ' + bet.tName + lineBreak
      + sportLabel + bet.brNameSport + lineBreak 
      + 'Status: ' + matchStatus + lineBreak + ' ' + lineBreak

      + brMatchIdLabel + bet.brMatchID + lineBreak
      + matchIdLabel + bet.lB_MatchID + lineBreak
      + ticketIdLabel + bet.lB2TicketID + lineBreak
      + betTypeIdLabel + bet.lB_BetTypeID + lineBreak + ' ' + lineBreak

      + (bet.scoreTimeline?.length > 0 ? (scoreVerlauf + lineBreak + this.generateScores(bet, bet.scoreTimeline).join(lineBreak)) : '');
  }

  generateScores(bet, scores) {
      const entries = [];
      entries.push(...scores.map(score => ({
        ts: score.timeStamp,
        minute: score.time,
        score: score.home + ' : ' + score.away,
        team: score.scoringTeam === 1 ? bet.homeTeam + ' (H)'
          : (score.scoringTeam === 2 ? bet.awayTeam + ' (G)' : score.scoringTeam),
        player: score.player,
        cancelled: score.cancelled,
        type: LBScoreType[score.type],
        scoreType: score.type
      })));
      // primary sort by timestamp, secondary by minute desc
      entries.sort((a, b) => {
        if (a.ts < b.ts) {
          return -1;
        } else if (a.ts > b.ts) {
          return 1;
        } else {
          return b.minute - a.minute;
        }
      });
      let prevScore = '0 : 0';
      entries.forEach(entry => {
        entry.sameScore = !entry.score;
        entry.score = entry.score || prevScore;
        prevScore = entry.score;
      });

    return entries.map(entry => {
      let row = '';
      row += `${this.datePipe.transform(entry.ts, 'shortTime')} `;
      row += `${entry.scoreType !== 'fullTime' && entry.scoreType !== 'halfTime' ? entry.minute + '"' : ''} `;
      row += `${entry.cancelled ? 'cancel' : ''} `;
      row += `${entry.scoreType === 'halfTime' ? entry.minute + '. ' : ''} `;
      row += `${!entry.cardType ? entry.type : ''} `;
      row += `${entry.score || ''} `;
      row += `${entry.team || ''} `;
      row += `${entry.player || ''}`;
      return row;
    });
  }

  ticketList(bet: any) {
    console.log('bet', bet, bet.competitors);
    this.dialog.open(TicketListComponent, {
      height: '90%',
      width: '90%',
      data: {
        query: {
          eventID: bet.lB_MatchID,
        },
        enhancedTicketList: true,
        cid: this.ticket.customerId,
        uaid: this.ticket.userAccountID,
        competitors: bet.match,
        btKey: bet.bet?.betTypeKey,
        btSov: bet.bet?.specialOddValue,
        matchStartTs: bet.bet?.matchStartDate,
        brSportID: bet.bet?.brSportID,
      }
    });
  }

  googleDefault(bet) {
    console.log('bet', bet);
    const url = 'https://google.com/search?hl=de' +
      '&q=' + bet.bet.competitors
      + ' ' + this.datePipe.transform(bet.bet.matchStartDate, 'YYYY-MM-dd HH:mm') + ' sofascore';
    this.openLink(encodeURI(url));
  }

  googleHome(bet) {
    const url = 'https://google.com/search?hl=de' +
      '&q=' + bet.bet.homeTeam + '+ "facebook OR wikipedia"';
    this.openLink(encodeURI(url));
  }

  googleGuest(bet) {
    const url = 'https://google.com/search?hl=de' +
      '&q=' + bet.bet.awayTeam + '+ "facebook OR wikipedia"';
    this.openLink(encodeURI(url));
  }

  canSeeMatchEditor() {
    return this.sessionService.hasPermission(Permission.editAdvancedAdminSettings);
  }

  canSeeLB2MatchTickets() {
    return this.sessionService.hasPermission(Permission.seeTicketlist) && this.sessionService.hasPermission(Permission.editAdvancedAdminSettings);
  }

  openMatchEditor(bet) {
    const baseUrl = window.location.origin;
    const matchUrl = baseUrl + '/#/live-admin/match-editor?brMatchID=' + bet.bet.brMatchID;
    window.open(matchUrl, '_blank');
  }

  brSupport(title: string, bet: any, ticket: any) {

    const baseUrl = window.location.origin;
    const ticketUrl = baseUrl + '/#/home/ticket-search/' + ticket.number;
    const matchUrl = baseUrl + '/#/live-admin/match-editor?brMatchID=' + bet.bet.brMatchID;


    this.clipboard(`Hallo!
${title}
Ticket ${ticket.number}
Match: ${bet.bet.competitors}
Sport: ${bet.sportName}
BrMID: ${bet.bet.brMatchID}
Wette: ${bet.type}
Tip: ${bet.tip}
Score: ${bet.bet.scoreDetail}
Ticket-Abgabe: ${this.datePipe.transform(ticket.inDate, 'medium')}
Ticket-Abrechnung: ${this.datePipe.transform(ticket.calcDate, 'medium')}
Match-Start: ${this.datePipe.transform(bet.bet.matchStartDate, 'medium')}
Ticket Suche: ${ticketUrl}
Match Editor: ${matchUrl}`, this.snackBar);
  }

  googleTournament(bet) {
    const url = 'https://google.com/search?hl=de' +
      '&q=' + bet.bet.tName;
    this.openLink(encodeURI(url));
  }

  openLink(link) {
    const halfScreenWidth = screen.width * 0.7;
    const halfScreenHeight = screen.height * 0.7;
    const leftPosition = (screen.width - halfScreenWidth) / 2;
    const topPosition = (screen.height - halfScreenHeight) / 2;

    window.open(link, 'google-tab',
      `width=${halfScreenWidth},height=${halfScreenHeight},left=${leftPosition},top=${topPosition}`);
  }

  private mapTip(oddKey: any) {
    return this.translationService.translate('ticketDetail.livesports.oddKey.' + oddKey);
  }
}
