<vit-search-form (submitEmiter)="fetch($event)"
                 (anomalyTrigger)="nextAnomaly()"
                 [hideGames]="true"
                 [hideMinProfit]="true"
                 [hideMinStake]="true"
                 [hideMinTickets]="true"
                 [hideDetails]="true"
                 [hideFranchiser]="true"
                 [hideAllStations]="true"
                 [hideIntoEuro]="true"
                 [anomaly]="hasAnomalies"
                 [hideStationRestrictions]="true">
</vit-search-form>
<ag-grid-angular
  *ngIf="rowData"
  (columnVisible)="storeColumnVisibilityFn('cashTimeline', $event)"
  class="ag-theme-balham"
  style="width: 100%; flex: 1;"
  [gridOptions]="gridOptions"
  [rowData]="rowData | async"
  [columnDefs]="columnDefs"
  (gridReady)="onGridReady($event)">
</ag-grid-angular>

