import {Injectable} from '@angular/core';
import {User} from '../domain/user';
import {LinkedElement} from '../domain/linked-element';
import {Permission} from '../domain/permission';
import {HierarchyElement} from '../domain/hierarchy-element';
import {FranchiserData} from '../domain/franchiser-data';
import {StationData} from '../domain/station-data';
import {Theme} from '../domain/theme';
import {BehaviorSubject, Subject} from 'rxjs';
import {SystemConfig} from '../config/system-config';
import {DropdownValue} from '../dynamic-form/dropdown-value';
import {MEObject} from '../live-admin/match-editor/match-detail/MEObject';
import {MatchEditorFilter} from '../live-admin/match-editor/match-filter/match-editor-filter';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  treeObs = new BehaviorSubject<'wide' | 'narrow'>(window.innerWidth < 600 ? 'wide' : 'narrow');
  menuObs = new BehaviorSubject<'wide' | 'narrow'>(window.innerWidth < 600 ? 'wide' : 'narrow');
  timeRemaining = '';
  sessionExpiryProgress = 100;
  largeTree = false;
  enableTreeNodeSelection = false;
  treeNodeSelected = new Subject();

  theme = Theme.DEFAULT;
  version = '002020005';
  user: User;

  franchisers: HierarchyElement[] = [];
  stations: HierarchyElement[] = [];
  groups: DropdownValue[] = [];

  selectedFranchiser: FranchiserData;
  selectedStation: StationData;

  selectedMenuElement: LinkedElement;

  secureCode = '';
  searchedTickets: any[] = [];

  private loadingCount: number;


  // match editor
  showMatchEditorFilter = true;
  showMatchEditorList = true;
  selectedMatchEditorEvent: MEObject | undefined;
  activeMatchEditorFilter: MatchEditorFilter | undefined;

  constructor() {
    this.resetLoading();
  }

  get loading() {
    return this.loadingCount > 0;
  }

  set loading(begin: boolean) {
    if (begin) {
      this.loadingCount++;
    } else {
      this.loadingCount--;
    }
  }

  updateSessionTs() {
    if (this.user && localStorage.getItem(SystemConfig.LocalStorageConstants.AUTH_TOKEN)) {
      localStorage.setItem(SystemConfig.LocalStorageConstants.SESSION_REFRESH, '' + new Date().getTime());
      this.refreshSessionTimer();
    }
  }

  refreshSessionTimer() {
    if (this.user) {
      const now = new Date().getTime();
      const storedValue = localStorage.getItem(SystemConfig.LocalStorageConstants.SESSION_REFRESH);
      const lastSessionRefresh = storedValue ? +storedValue : now;
      const timeoutInMs = this.user.inactivityTimeoutMinutes ? this.user.inactivityTimeoutMinutes * 60 * 1000 : 30 * 60 * 1000;
      const remainingMs = now - lastSessionRefresh;
      this.sessionExpiryProgress = (1 - remainingMs / timeoutInMs) * 100;
      this.timeRemaining = this.getTimeLeft(lastSessionRefresh, timeoutInMs);
      if (this.sessionExpiryProgress < 0) {
        this.logout();
        localStorage.removeItem(SystemConfig.LocalStorageConstants.AUTH_TOKEN);
        location.reload();
      }
    }
  }

  getTimeLeft(lastRefresh: number, timeout: number) {
    const expiryTs = lastRefresh + timeout;
    const now = new Date().getTime();
    const diff = expiryTs - now;
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff - hours * 1000 * 60 * 60) / (1000 * 60));
    const seconds = Math.floor((diff - hours * 1000 * 60 * 60 - minutes * 1000 * 60) / 1000);
    return (hours > 0 ? (hours + 'h ') : '') + minutes + 'm ' + seconds + 's';

  }

  showLoadingTimer() {
    return location.href.indexOf('/customer-info') > -1;
  }

  logout() {
    this.user = null;
    localStorage.setItem(SystemConfig.LocalStorageConstants.SESSION_REFRESH, '' + new Date(0).getTime());
  }

  resetLoading() {
    this.loadingCount = 0;
  }

  isLoggedIn() {
    return !!this.user;
  }

  hasPermission(...permissions: Permission[]) {
    return this.user && this.user.permissions
      && permissions.every(p => this.user.permissions.indexOf(p) > -1 || p === null || p === undefined);
  }

  hasSomePermissions(...permissions: Permission[]) {
    return this.user && this.user.permissions
      && permissions.some(p => this.user.permissions.indexOf(p) > -1);
  }
}
