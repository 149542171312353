import {Injectable} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {SessionService} from '../../services/session.service';
import {Observable} from 'rxjs';
import {QueryFilter} from '../../domain/query-filter';
import {SystemConfig} from '../../config/system-config';
import {map} from 'rxjs/operators';
import {mapMap} from '../../../utils';

@Injectable({
  providedIn: 'root'
})
export class RoleManagementService {

  constructor(public httpService: HttpService,
              public sessionService: SessionService) {
  }

  fetchRoles(): Observable<any> {
    return this.httpService.get(SystemConfig.Endpoints.ROLES);
  }

  fetchRole(roleId: number): Observable<any[]> {
    return this.httpService.get(SystemConfig.Endpoints.ROLE + roleId);
  }

  updateRole(role): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.ROLE_UPDATE, role);
  }

  insertRole(role): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.ROLE_INSERT, role);
  }
}
