import { QuestionBase } from './question-base';

export class CheckboxQuestion extends QuestionBase<string> {
    controlType = 'checkbox';
    icon: string;

    constructor(options: any = {}) {
        super(options);
        this.icon = options.icon;
        this.desc = options.desc;
    }
}
