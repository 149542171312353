import {Component, OnInit} from '@angular/core';
import {HoldOverviewService} from '../../services/hold-overview.service';
import {TranslationService} from '../../services/translation.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {QueryFilter} from '../../domain/query-filter';
import {SessionService} from '../../services/session.service';
import {map, tap} from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {TicketListComponent} from '../../ticket-list/ticket-list.component';
import {StationUtils} from '../../utils/station-utils';
import {TreeNodeType} from '../../domain/tree-node-type';
import {getContextMenuItems, isNumber} from 'utils';
import {NewTabComponent} from '../../new-tab/new-tab.component';
import {environment} from '../../../environments/environment';
import {SystemConfig} from '../../config/system-config';
import {enrichByStationId, loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {Router} from '@angular/router';
import {GridReadyEvent} from 'ag-grid-community';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-turnover',
  templateUrl: './turnover.component.html',
  styleUrls: ['./turnover.component.scss']
})
export class TurnoverComponent implements OnInit {

  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;
  showTestDataWarning = false;
  showDifferentCurrencyWarning = false;
  columnDefs;
  gridOptions;
  rowData;
  sumRowData;
  detailParams;

  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public holdOverviewService: HoldOverviewService,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public datePipe: DatePipe,
              public httpService: HttpService,
              public router: Router,
              public decimalPipe: DecimalPipe,
              public dialog: MatDialog) {
    this.columnDefs = this.generateColumnDefs();
    this.detailParams = this.generateDetailParams();
    this.gridOptions = this.generateGridOptions();
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('turnover', event);
  }

  ngOnInit() {
  }

  updateIntoEuro() {
    this.query.intoEuro = true;
    this.fetchHoldOverview(this.query);
  }

  updateIgnoreTest() {
    this.query.ignoreTest = true;
    this.fetchHoldOverview(this.query);
  }

  fetchHoldOverview(query: QueryFilter) {
    this.showTestDataWarning = false;
    this.showDifferentCurrencyWarning = false;
    if (query) {
      this.query = query;
      this.sumRowData = [];
      this.rowData = this.holdOverviewService.fetchHoldOverview(query).pipe(map((rowData) => {
        this.sumRowData = [rowData.shift()];
        if (!query.ignoreTest && this.sumRowData?.[0].includesTestData) {
          this.showTestDataWarning = true;
        }
        if (!query.intoEuro && this.sumRowData?.[0]?.hasDifferentCurrencies) {
          this.showDifferentCurrencyWarning = true;
          this.gridOptions.columnApi.setColumnVisible('currencyCode', true);
        }
        return rowData;
      }));
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('holdOverview.type'),
        field: 'type',
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'type',
        width: 23,
        cellRenderer: function (params) {
          const icon = StationUtils.getIcon(TreeNodeType.STATION, params.data.type, false, params.data.softLocked, params.data.hardLocked);
          const img = '<img style="cursor: pointer" src=\'/assets/img/icons/' + icon + '\'>'
            + (params.data.online ? '<div class=\'online\'></div>' : '');
          return params.data.stationID === 0 ? '' : img;
        }
      },
      {
        headerName: this.translationService.translate('holdOverview.stationID'),
        field: 'stationID',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40,
        headerTooltip: this.translationService.translate('holdOverview.stationID')
      },
      {
        headerName: this.translationService.translate('holdOverview.stationName'),
        field: 'stationName',
        sortable: true,
        filter: true,
        resizable: true,
        width: TurnoverComponent.COLUMN_WIDTH_LARGE
      },
      {
        headerName: this.translationService.translate('holdOverview.franchiser'),
        field: 'franchiserName',
        sortable: true,
        filter: true,
        resizable: true,
        hide: true,
        width: TurnoverComponent.COLUMN_WIDTH_LARGE,
        headerTooltip: this.translationService.translate('holdOverview.franchiser')
      },
      {
        headerName: this.translationService.translate('holdOverview.countryCode'),
        field: 'countryCode',
        sortable: true,
        filter: true,
        resizable: true,
        width: TurnoverComponent.COLUMN_WIDTH,
        hide: true,
        headerTooltip: this.translationService.translate('holdOverview.countryCode')
      },
      {
        headerName: this.translationService.translate('holdOverview.online'),
        field: 'online',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: (params) => params.data.stationID > 0 ? (params.value === true ?
          this.translationService.translate('holdOverview.isOnline') : this.translationService.translate('holdOverview.isOffline'))
          : '',
        width: TurnoverComponent.COLUMN_WIDTH,
        hide: true,
        headerTooltip: this.translationService.translate('holdOverview.online')
      },
      {
        headerName: this.translationService.translate('holdOverview.currencyCode'),
        field: 'currencyCode',
        sortable: true,
        filter: true,
        resizable: true,
        cellClassRules: {
          'currency-warning': () => this.showDifferentCurrencyWarning
        },
        width: TurnoverComponent.COLUMN_WIDTH,
        hide: true,
        headerTooltip: this.translationService.translate('holdOverview.currencyCode')
      },
      {
        headerName: this.translationService.translate('holdOverview.quantity'),
        field: 'quantity',
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: TurnoverComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.quantity')
      },
      {
        headerName: this.translationService.translate('holdOverview.stakeNet'),
        field: 'stakeNet',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: TurnoverComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.stakeNet')
      },
      {
        headerName: this.translationService.translate('holdOverview.averageStakeNet'),
        field: 'averageStakeNet',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        hide: true,
        width: TurnoverComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.averageStakeNet')
      },
      {
        headerName: this.translationService.translate('holdOverview.stakeTax'),
        field: 'stakeTax',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        hide: true,
        width: TurnoverComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.stakeTax')
      },
      {
        headerName: this.translationService.translate('holdOverview.bonusStake'),
        field: 'bonusStake',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        hide: true,
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: TurnoverComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.bonusStake')
      },
      {
        headerName: this.translationService.translate('holdOverview.profitTotalGross'),
        field: 'profitTotalGross',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: TurnoverComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.profitTotalGross')
      },
      {
        headerName: this.translationService.translate('holdOverview.winTax'),
        field: 'winTax',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: TurnoverComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.winTax')
      },
      {
        headerName: this.translationService.translate('holdOverview.openPayoffGross'),
        field: 'openPayoffGross',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: TurnoverComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.openPayoffGross')
      },
      {
        headerName: this.translationService.translate('holdOverview.jackpotProfitNet'),
        field: 'jackpotProfitNet',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: TurnoverComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.jackpotProfitNet')
      },
      {
        headerName: this.translationService.translate('holdOverview.hold'),
        field: 'hold',
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: TurnoverComponent.COLUMN_WIDTH,
        valueFormatter: this.decimalFormatter.bind(this)
      },
      {
        headerName: this.translationService.translate('holdOverview.holdPercent'),
        field: 'holdPercent',
        sortable: true,
        filter: true,
        resizable: true,
        valueFormatter: this.percentFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: TurnoverComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.holdPercent')
      },
      {
        headerName: this.translationService.translate('holdOverview.identifier'),
        field: 'identifier',
        sortable: true,
        filter: true,
        resizable: true,
        hide: true,
        cellClass: 'ui-grid-cell-contents-auto',
        headerTooltip: this.translationService.translate('holdOverview.identifier')
      }
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: true,
      detailRowHeight: 160,
      isRowMaster: (data) => data.stationID !== 0,
      rowClassRules: {
        'bold': (params) => params.data.stationID === 0,
        'text-align-right': (params) => isNumber(params.data.value)
      },
      onCellClicked: (params) => {
        if (params.colDef.field === 'type') {
          const url = environment.host + SystemConfig.Routes.STATION + '/' + params.data.franchiserID
            + '/' + params.data.stationID;
          window.open(encodeURI(url), '_blank');
        } else {
          params.node.setExpanded(!params.node.expanded);
        }
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      },
    };
  }

  generateDetailParams() {
    return {
      detailGridOptions: {
        toolPanelSuppressSideButtons: false,
        columnDefs: [
          {
            headerName: this.translationService.translate('holdOverview.newTab'),
            field: 'newTab',
            width: 23,
            cellClass: 'new-tab',
            cellRenderer: NewTabComponent
          },
          {
            headerName: this.translationService.translate('holdOverview.gameName'),
            field: 'gameName',
            width: 190,
            cellRenderer: 'agGroupCellRenderer'
          },
          {
            headerName: this.translationService.translate('holdOverview.quantity'),
            field: 'quantity',
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: TurnoverComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('holdOverview.quantity')
          },
          {
            headerName: this.translationService.translate('holdOverview.stakeNet'),
            field: 'stakeNet',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: TurnoverComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('holdOverview.stakeNet')
          },
          {
            headerName: this.translationService.translate('holdOverview.averageStakeNet'),
            field: 'averageStakeNet',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            hide: true,
            width: TurnoverComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('holdOverview.averageStakeNet')
          },
          {
            headerName: this.translationService.translate('holdOverview.stakeTax'),
            field: 'stakeTax',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            hide: true,
            width: TurnoverComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('holdOverview.stakeTax')
          },
          {
            headerName: this.translationService.translate('holdOverview.profitTotalGross'), field: 'profitTotalGross',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: TurnoverComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('holdOverview.profitTotalGross')
          },
          {
            headerName: this.translationService.translate('holdOverview.winTax'), field: 'winTax',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: TurnoverComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('holdOverview.winTax')
          },
          {
            headerName: this.translationService.translate('holdOverview.openPayoffGross'), field: 'openPayoffGross',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: TurnoverComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('holdOverview.openPayoffGross')
          },
          {
            headerName: this.translationService.translate('holdOverview.jackpotProfitNet'), field: 'jackpotProfitNet',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: TurnoverComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('holdOverview.jackpotProfitNet')
          },
          {
            headerName: this.translationService.translate('holdOverview.hold'), field: 'hold',
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: TurnoverComponent.COLUMN_WIDTH,
            valueFormatter: this.decimalFormatter.bind(this)
          },
          {
            headerName: this.translationService.translate('holdOverview.holdPercent'), field: 'holdPercent',
            valueFormatter: this.percentFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: TurnoverComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('holdOverview.holdPercent')
          },
          {
            headerName: this.translationService.translate('holdOverview.identifier'),
            field: 'identifier',
            hide: true,
            cellClass: 'ui-grid-cell-contents-auto',
            headerTooltip: this.translationService.translate('holdOverview.identifier')
          }
        ],
        onGridReady: (params) => {
          // params.api.sizeColumnsToFit();
        },
        onCellClicked: (params) => {
          this.query.stationID = params.data.stationID;
          if (params.colDef.cellRenderer === NewTabComponent) {
            // TODO: use service-worker instead to pass info
            const url = environment.host + SystemConfig.Routes.TICKET_LIST + '/' + params.data.gameNumber
              + '/' + btoa(JSON.stringify(this.query));
            window.open(encodeURI(url), '_blank');
          } else {
            this.dialog.open(TicketListComponent, {
              height: '90%',
              width: '80%',
              data: {query: this.query, gameNumber: params.data.gameNumber},
              disableClose: true
            });
          }
        }
      },
      getDetailRowData: (params) => {
        this.query.stationID = params.data.stationID;
        this.holdOverviewService.fetchHoldOverviewPerGame(this.query).subscribe(data => {
          params.successCallback(enrichByStationId(data, params.data.stationID));
        });
      }
    };
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }
}
