<vit-search-form (submitEmiter)="fetch($event)"
                 [hideIntoEuro]="true"
                 [showCustomerId]="true"
                 [customerIdPanelTitle]="'customerIdPanelTitle'"
                 [hideMinTickets]="true"
                 [hideDetails]="true"
                 [hideStationRestrictions]="true"
                 [showCustomerIdInHeader]="true"
                 [suggestCustomerIds]="true"
                 [hideFranchiser]="true"
                 [hideStation]="true"
                 [hideGames]="true">
</vit-search-form>

<div class="canvas-container" *ngIf="data">
  <div *ngIf="data && !bigChart" class="highlight-container">
    <div class="highlight-block">
      <h2>{{'customerInfo.data.overviewTotal' | translate}}</h2>
      <div class="highlight-prop">
        <span class="value" [class.red]="data.statHoldSum < 0">{{data.statHoldSum | number: '0.2-2'}}</span>
        <span class="label">{{'customerInfo.data.properties.statHoldSum' | translate}}</span>
      </div>
      <div class="highlight-prop">
        <span class="value"><span>{{data.totalTickets}}</span>/<span
          class="green">{{data.totalWonTickets}}</span></span>
        <span class="label">{{'customerInfo.data.properties.totalTickets' | translate}}</span>
      </div>
      <div class="highlight-prop">
        <span class="value">{{data.statStakeSum | number: '0.2-2'}}</span>
        <span class="label">{{'customerInfo.data.properties.statStakeSum' | translate}}</span>
      </div>
    </div>
    <div class="highlight-block">
      <h2>{{'customerInfo.data.overviewTime' | translate}}</h2>
      <div class="highlight-prop"
           [class.green]="data.statHoldTimeFrameSum > 0"
           [class.red]="data.statHoldTimeFrameSum < 0">
        <span class="value">{{data.statHoldTimeFrameSum | number: '0.2-2'}}</span>
        <span class="label">{{'customerInfo.data.properties.statHoldTimeFrameSum' | translate}}</span>
      </div>
      <div class="highlight-prop">
        <span class="value">{{data.timeFrameTickets}}/<span
          class="green">{{data.wonTicketsTimeFrame}}</span></span>
        <span class="label">{{'customerInfo.data.properties.timeFrameTickets' | translate}}</span>
      </div>
      <div class="highlight-prop">
        <span class="value">{{data.statStakeTimeFrameSum | number: '0.2-2'}}</span>
        <span class="label">{{'customerInfo.data.properties.statStakeTimeFrameSum' | translate}}</span>
      </div>
    </div>
    <div class="highlight-block large">
      <h2>{{'customerInfo.data.timeline' | translate}}</h2>
      <canvas baseChart
              type="line"
              class="overview-chart"
              [data]="overviewChart.data"
              [options]="overviewChart.options"></canvas>
      <button class="expand" mat-icon-button (click)="bigChart = overviewChart">
        <mat-icon>open_in_full</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="data && !bigChart" class="highlight-table">
    <div class="highlight-icon-block" *ngIf="data.lockedFranchiser">
      <mat-icon class="red">cancel</mat-icon>
      <p>{{'customerInfo.data.properties.iconBlock.lockedFranchiser' | translate}}</p>
    </div>
    <div class="highlight-icon-block" *ngIf="data.lockedFranchiserPartly">
      <mat-icon class="orange">cancel</mat-icon>
      <p>{{'customerInfo.data.properties.iconBlock.lockedFranchiserPartly' | translate}}</p>
    </div>
    <div class="highlight-icon-block" *ngIf="data.lockedCPCTotal">
      <mat-icon class="red">cancel</mat-icon>
      <p>{{'customerInfo.data.properties.iconBlock.lockedCPCTotal' | translate}}</p>
    </div>
    <div class="highlight-icon-block" *ngIf="data.lockedCPCPartly">
      <mat-icon class="orange">cancel</mat-icon>
      <p>{{'customerInfo.data.properties.iconBlock.lockedCPCPartly' | translate}}</p>
    </div>
    <div class="highlight-icon-block" *ngIf="data.uaLocked">
      <mat-icon class="red">cancel</mat-icon>
      <p>{{'customerInfo.data.properties.iconBlock.uaLocked' | translate}}</p>
    </div>
    <div class="highlight-icon-block" *ngIf="data.numTicketsOriginLandbased > 0">
      <mat-icon class="green">store</mat-icon>
      <p>{{data.numTicketsOriginLandbased}}<br>Landbased<br>Tickets</p>
    </div>
    <div class="highlight-icon-block" *ngIf="data.numTicketsOriginOnline > 0">
      <mat-icon class="green">cloud</mat-icon>
      <p>{{data.numTicketsOriginOnline}}<br>Online<br>Tickets</p>
    </div>
  </div>
  <mat-accordion class="example-headers-align" multi *ngIf="data && !bigChart">
    <mat-expansion-panel *ngFor="let panel of expansionPanels" [disabled]="panel.disabled">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon *ngIf="panel.icon">{{panel.icon}}</mat-icon>
          {{'customerInfo.data.' + panel.title | translate}}
        </mat-panel-title>
        <mat-panel-description>
          {{panel.preview}}
        </mat-panel-description>
      </mat-expansion-panel-header>

      <img class="profile-img" height="122px" [src]="'data:image/png;base64,'+data[panel.image]"
           *ngIf="panel.image"/>

      <ng-container *ngFor="let data of panel.textData">
        <br *ngIf="data.value == 'break_line'">
        <mat-form-field appearance="fill" *ngIf="data.value != 'break_line'">
          <mat-label>{{data.key}}</mat-label>
          <input matInput [value]="data.value">
        </mat-form-field>
      </ng-container>

      <ng-container *ngFor="let data of panel.textBlockData">
        <br *ngIf="data.value == 'break_line'">
        <mat-form-field style="width: 100%" appearance="fill" *ngIf="data.value != 'break_line'">
          <mat-label>{{data.key}}</mat-label>
          <textarea matInput [value]="data.value" rows="{{calcRows(data.value)}}"></textarea>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="panel.tableData"></ng-container>
      <table *ngFor="let table of panel.tableData; let tableIdx = index">
        <tr *ngFor="let row of table; let rowIndex = index">
          <td *ngFor="let field of row; let columnIdx = index"
              [attr.colspan]="rowIndex === 0 ? table[1].length : ''"
              [class.no-border]="rowIndex === 0"
              [class.numeric]="rowIndex !== 0 && panel.tableNumbers && panel.tableNumbers[tableIdx] && panel.tableNumbers[tableIdx][columnIdx]"
              [innerHTML]="field">
          </td>
        </tr>
      </table>
      <div class="charts-container">
        <div class="chart-box" *ngFor="let chart of panel.charts">
          <canvas baseChart
                  [type]="chart.type"
                  [data]="chart.data"
                  [options]="chart.options"></canvas>
          <button class="expand" mat-icon-button (click)="bigChart = chart">
            <mat-icon>open_in_full</mat-icon>
          </button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="big-chart-container" *ngIf="bigChart">
    <canvas baseChart
            [type]="bigChart.type"
            [data]="bigChart.data"
            [options]="bigChart.options"></canvas>
    <button class="expand" mat-icon-button (click)="bigChart = undefined">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
