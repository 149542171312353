import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {StationRoutingModule} from './station-routing.module';
import {StationComponent} from './station.component';
import {MaterialImportsModule} from '../../material-imports.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DynamicFormModule} from '../dynamic-form/dynamic-form.module';
import {SharedModule} from '../../shared.module';
import {KenobetComponent} from './kenobet/kenobet.component';
import {LivesportsComponent} from './livesports/livesports.component';
import {PokerbetComponent} from './pokerbet/pokerbet.component';
import {SmartraceComponent} from './smartrace/smartrace.component';
import {ButtonsModule} from '../buttons/buttons.module';
import {ColsModule} from '../cols/cols.module';

@NgModule({
  imports: [
    CommonModule,
    StationRoutingModule,
    MaterialImportsModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicFormModule,
    SharedModule,
    ButtonsModule,
    ColsModule
  ],
  declarations: [StationComponent, KenobetComponent, LivesportsComponent, PokerbetComponent, SmartraceComponent]
})
export class StationModule {
}
