<vit-search-form (submitEmiter)="fetch($event)"
                 [hideMinProfit]="true"
                 [hideMinStake]="true"
                 [hideStation]="true"
                 [showDateOfLastAccountingButton]="true"
                 [hideMinTickets]="true"
                 [showExtendedOptions]="true"
                 [hideIntoEuro]="true">
</vit-search-form>
<ag-grid-angular
  *ngIf="query"
  (columnVisible)="storeColumnVisibilityFn('balance', $event)"
  class="ag-theme-balham"
  style="width: 100%; flex: 1;"
  [gridOptions]="gridOptions"
  [tooltipShowDelay]="0"
  [pinnedTopRowData]="titleRow"
  [columnDefs]="columnDefs"
  [detailCellRendererParams]="detailParams"
  (gridReady)="onGridReady($event)">
</ag-grid-angular>

