import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {SessionService} from './session.service';
import {SystemConfig} from '../config/system-config';
import {map} from 'rxjs/operators';
import {StationData} from '../domain/station-data';
import {CashStorageInfo} from '../domain/cash-storage-info';
import {HoldLimiter} from '../domain/hold-limiter';
import {DisburseTypeInfo} from '../domain/disburse-type-info';
import {Station} from '../domain/station';
import {Observable} from 'rxjs/index';
import {StationCopyData} from '../domain/station-copy-data';
import {mapMap} from '../../utils';

@Injectable()
export class StationService {
  constructor(public httpService: HttpService,
              public sessionService: SessionService) {
  }

  copy(copyData: StationCopyData): any {
    return this.httpService.post(SystemConfig.Endpoints.STATION_COPY, copyData);
  }

  fetchOpenIDs(franchiserId, amount = 1): any {
    return this.httpService.get(SystemConfig.Endpoints.STATION_COPY_FREE_IDS + franchiserId + '/' + amount);
  }

  update(station: Station): any {
    return this.httpService.post(SystemConfig.Endpoints.STATION_UPDATE, station);
  }

  insert(station: Station): any {
    return this.httpService.post(SystemConfig.Endpoints.STATION_INSERT, station);
  }


  fetchStationData(stationId: number): Observable<StationData> {
    if (stationId === -1) {
      return this.httpService.get(SystemConfig.Endpoints.STATION_DUMMY).pipe(map((json) => this.map(json)));

    } else {
      return this.httpService.get(SystemConfig.Endpoints.STATION + stationId).pipe(map((json) => this.map(json)));
    }
  }

  submitCashStorage(cashStorageInfo: any): Observable<CashStorageInfo> {
    const csi = cashStorageInfo.cashStorageInfo;
    const cashStorageDTO: any = {
      'coinCashStorageID': (csi && csi.coinDevice) ? csi.coinDevice.cashStorageID : 0,
      'noteCashStorageID': (csi && csi.noteDevice) ? csi.noteDevice.cashStorageID : 0,
      'coinFloatingValue': cashStorageInfo.floatingValueCoins ? +cashStorageInfo.floatingValueCoins : 0,
      'noteFloatingValue': cashStorageInfo.floatingValueNotes ? +cashStorageInfo.floatingValueNotes : 0,
      'noteInitialStorage': cashStorageInfo.initialStorageNotes ? +cashStorageInfo.initialStorageNotes : 0,
      'coinInitialStorage': 0
    };
    return this.httpService.post(SystemConfig.Endpoints.CASH_STORAGE_INFO_UPDATE, cashStorageDTO);
  }

  updateDisburseTypeInfos(disburseTypeInfos: DisburseTypeInfo[]): Observable<DisburseTypeInfo[]> {
    return this.httpService.post(SystemConfig.Endpoints.DISBURSE_TYPE_INFOS_UPDATE, disburseTypeInfos);
  }

  createHoldLimiter(stationID: number, defaultValue: number, addValue = 0): Observable<HoldLimiter> {
    return this.httpService.post(SystemConfig.Endpoints.HOLD_LIMITER_CREATE, {
      stationID,
      defaultValue,
      addValue
    });
  }

  resetHoldLimiter(holdLimiter: HoldLimiter): Observable<HoldLimiter> {
    return this.httpService.post(SystemConfig.Endpoints.HOLD_LIMITER_RESET + holdLimiter.stationID, null);
  }

  resetMacAddress(stationID: number): Observable<Station> {
    return this.httpService.post(SystemConfig.Endpoints.MAC_ADDRESS_RESET + stationID, null);
  }

  toggleActivateHoldLimiter(activated: boolean, holdLimiter: HoldLimiter): Observable<HoldLimiter> {
    if (activated) {
      return this.httpService.post(SystemConfig.Endpoints.HOLD_LIMITER_ACTIVATE + holdLimiter.stationID, null);
    } else {
      return this.httpService.post(SystemConfig.Endpoints.HOLD_LIMITER_DEACTIVATE + holdLimiter.stationID, null);
    }
  }

  map(json: any): StationData {
    const stationData: StationData = new StationData();
    stationData.station = json.station;
    stationData.lstFranchisers = mapMap(json.lstFranchisers);
    stationData.lstLanguage = json.lstLanguage;
    stationData.lstCountry = json.lstCountry;
    stationData.lstCurrency = json.lstCurrency;
    stationData.credit = json.credit;
    stationData.holdLimiter = json.holdLimiter;
    stationData.mappableMKCompany = json.mappableMKCompany;
    stationData.lstDisburseTypeInfo = json.lstDisburseTypeInfo;
    stationData.cashStorageInfo = json.cashStorageInfo;
    stationData.lstWebshops = mapMap(json.lstWebshops);
    stationData.lstStationsConnectedToWebshop = json.lstStationsConnectedToWebshop;
    stationData.franchiserHierarchy = json.franchiserHierarchy;
    stationData.terminalDefaultApps = this.generateTerminalDefaultApps(json.station);
    return stationData;
  }

  private generateTerminalDefaultApps(station: Station) {
    const defaultApps = new Map<string, string>();
    defaultApps.set('0', 'menu');
    if (station.pB1Enabled) {
      defaultApps.set('1', 'pokerbet');
    }
    if (station.rF2Enabled) {
      defaultApps.set('2', 'smartrace');
    }
    if (station.bB1Enabled) {
      defaultApps.set('3', 'kenobet');
    }
    if (station.lB2Enabled) {
      defaultApps.set('5', 'livesports');
    }

    return defaultApps;
  }
}
