export enum SearchTicketStatus {
  ALL = 0,
  OPEN = 1,
  WON = 2,
  LOST = 3,
  STORNO = 4,
  DISBURSED = 5,
  NOTDISBURSED = 6,
  JACKPOT = 7,
  WON_LOCKED = 8,
  OUTDATE_AFTER_TIMESPAN = 9,
  CASHOUT = 10,
  ONLY_POSSIBLE_WIN = 11
}
