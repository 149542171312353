import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ColsComponent } from '../../cols/cols.component';
import { QuestionContainer } from '../../domain/question-container';
import { DropdownQuestion } from '../../domain/question-dropdown';
import { SessionService } from '../../services/session.service';
import { TranslationService } from '../../services/translation.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Permission } from '../../domain/permission';
import { ERROR_SNACK_CONFIG, mapMap, SUCCESS_SNACK_CONFIG } from '../../../utils';
import { TextboxQuestion } from '../../domain/question-textbox';
import { CheckboxQuestion } from '../../domain/question-checkbox';
import { InfoLabelQuestion } from '../../domain/question-info-label';
import { FormUtils } from '../../utils/form-utils';

import { CustomerTreeService } from '../../services/customer-tree.service';
import { SubSink } from 'subsink';
import { GroupManagementService } from './group-management.service';
import { CustomerTreeNode } from '../../domain/customer-tree-node';
import {StationUtils} from '../../utils/station-utils';
import {TreeNodeType} from '../../domain/tree-node-type';

@Component({
  selector: 'vit-group-management',
  templateUrl: './group-management.component.html',
  styleUrls: ['./group-management.component.scss']
})
export class GroupManagementComponent implements OnInit, OnDestroy {

  @ViewChild(ColsComponent, { static: true })
  colsComponent: ColsComponent;

  selectionQuestions = [];  // contains ui elements for selecting one group from a list (i.e. a dropdown selector)
  groups = [];              // holds the list of groups visible to the user
  questions = [];           // holds the ui element container which is referred to in the html part as <vit-cols [elements]='questions'></vit-cols>
  groupDto = null;
  isNew = false;
  loading = false;

  private subs = new SubSink();

  Permission = Permission;

  constructor(public sessionService: SessionService,
    public translationService: TranslationService,
    public groupManagementService: GroupManagementService,
    public customerTreeService: CustomerTreeService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.reset();

    this.sessionService.enableTreeNodeSelection = true;

    // this observer subscribes to clicks (RMB) on tree elements and adds/removes them to/from the members list
    this.subs.sink = this.sessionService.treeNodeSelected.subscribe((node: CustomerTreeNode) => {

      if (this.isNew) {
        // store name and description in object
        const groupForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'groupManagement.header.group');
        Object.assign(this.groupDto, groupForm);
        console.log(this.groupDto);
      }

      if (node.type === 0) {

        // franchiser
        if (this.groupDto && this.groupDto.members) {
          // check if element already exists, if yes => remove it from list, otherwise add it to list
          if (this.groupDto.members.filter(x => x.franchiserID === node.id).length > 0) {
            // remove element
            this.groupDto.members = this.groupDto.members.filter(x => x.franchiserID !== node.id);
          } else {
            // add element
            this.groupDto.members.push({ 'type': -1, 'stationID': 0, 'franchiserID': node.id, 'name': node.name, 'isSelected': true })
          }
        } else {
          this.groupDto.members = [{ 'type': -1, 'stationID': 0, 'franchiserID': node.id, 'name': node.name, 'isSelected': true }];
        }

      } else if (node.type === 1) {

        // station
        if (this.groupDto && this.groupDto.members) {
          // check if element already exists, if yes => remove it from list, otherwise add it to list
          if (this.groupDto.members.filter(x => x.stationID === node.id).length > 0) {
            // remove element
            this.groupDto.members = this.groupDto.members.filter(x => x.stationID !== node.id);
          } else {
            // add element
            this.groupDto.members.push({ 'type': node.stationType, 'stationID': node.id, 'franchiserID': 0, 'name': node.name, 'isSelected': true })
          }
        } else {
          this.groupDto.members = [{ 'type': node.stationType, 'stationID': node.id, 'franchiserID': 0, 'name': node.name, 'isSelected': true }];
        }
      }

      this.setGroupQuestions();

    });
  }

  ngOnDestroy() {
    this.sessionService.enableTreeNodeSelection = false;
    this.subs.unsubscribe();
  }

  reset() {
    this.groupDto = null;
    this.isNew = false;
    this.groupManagementService.fetchGroups().subscribe((groups) => {
      this.groups = groups;
      this.setSelectionQuestions();
      this.questions = this.selectionQuestions;
    });
  }

  private setSelectionQuestions() {
    this.selectionQuestions = [new QuestionContainer('groupManagement.header.groups', [
      new DropdownQuestion({
        key: 'franchiserStationGroupID',
        label: 'groupManagement.groupID',
        value: null,
        clear: true,
        options: this.groups.map(group => ({ key: group.franchiserStationGroupID.toString(), value: group.name })),
        onChange: this.onGroupSelected.bind(this)
      })
    ])];
  }

  private setGroupQuestions() {

    const group = new QuestionContainer('groupManagement.header.group', [
      new TextboxQuestion({
        key: 'name',
        label: 'groupManagement.name',
        value: this.groupDto.name,
        required: this.isNew,
        // editPermission: this.isNew ? null : Permission.notAllowed  // it is always allowed to change a group-name
      }),
      new TextboxQuestion({
        key: 'description',
        label: 'groupManagement.description',
        required: this.isNew,
        value: this.groupDto.description,
      }),
      new CheckboxQuestion({
        key: 'isPublic',
        label: 'groupManagement.isPublic',
        required: this.isNew,
        value: this.groupDto.isPublic
      }),
    ]);

    const infoContainer = new QuestionContainer('Hinweis', []);
    infoContainer.questions = [new InfoLabelQuestion({
      label: 'Mit Rechtsklick können Sie Elemente aus dem Baum zur Gruppe hinzufügen. Es können sowohl Franchiser als auch Stationen ausgewählt werden. Befindet sich ein Element bereits in der Gruppe, wird es durch erneuten Rechtsklick wieder aus der Gruppe entfernt. Sie können bestehende Elemente auch über die Checkbox neben jedem Element aus der Liste entfernen.'
    })];

    const groupFranchisers = new QuestionContainer('groupManagement.header.franchiser', []);
    if (this.groupDto.members && this.groupDto.members.length > 0) {
      groupFranchisers.questions = this.groupDto.members.filter(m => m.type < 0).map(member => new CheckboxQuestion({
        key: member.franchiserID,
        label: member.name,
        icon: StationUtils.getIcon(TreeNodeType.FRANCHISER, member.type),
        value: member.isSelected
      }));
    }

    const groupStations = new QuestionContainer('groupManagement.header.stations', []);
    if (this.groupDto.members && this.groupDto.members.length > 0) {
      groupStations.questions = this.groupDto.members.filter(m => m.type >= 0).map(member => new CheckboxQuestion({
        key: member.stationID,
        label: member.name,
        icon: StationUtils.getIcon(TreeNodeType.STATION, member.type),
        value: member.isSelected
      }));
    }

    this.questions = [group, groupFranchisers, infoContainer, groupStations];
  }

  new() {
    this.groupDto = {
      'franchiserStationGroupID': 0,
      'name': 'Neue Gruppe',
      'description': 'Beschreibung der neuen Gruppe',
      'isPublic': false,
      'userID': 0,
      'frIDs': null,
      'stIDs': null,
      'members': [],
    };
    this.isNew = true;
    this.setGroupQuestions();
  }

  save() {
    const groupForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'groupManagement.header.group');
    const groupFormFranchiser: any = FormUtils.getFormValues(this.colsComponent.forms, 'groupManagement.header.franchiser');
    const groupFormStations: any = FormUtils.getFormValues(this.colsComponent.forms, 'groupManagement.header.stations');

    if (!groupForm['name']) {
      this.snackBar.open(this.translationService.translate('groupManagement.nameMissing'), null, ERROR_SNACK_CONFIG);
      return;
    }
    Object.assign(this.groupDto, groupForm);

    this.groupDto.members = [
      ...Object.entries(groupFormFranchiser)
        .map(([franchiserId, isSelected]) => ({ type: -1, franchiserId, isSelected }))
        .filter(el => el.isSelected),
      ...Object.entries(groupFormStations)
        .map(([stationId, isSelected]) => ({ type: this.groupDto.members.find(m => m.stationID.toString() === stationId)?.type, stationId, isSelected }))
        .filter(el => el.isSelected),
    ];

    const result = this.isNew ? this.groupManagementService.insertGroup(this.groupDto)
      : this.groupManagementService.updateGroup(this.groupDto);
    result.subscribe((response: any) => {
      this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
      // this.onGroupSelected(this.isNew ? response.franchiserStationGroupID : this.groupDto.franchiserStationGroupID);
      this.onGroupSelected(this.isNew ? response.franchiserStationGroupID : this.groupDto.franchiserStationGroupID);
    }, () => {
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  delete() {

    const groupForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'groupManagement.header.group');

    if (!groupForm['name']) {
      this.snackBar.open(this.translationService.translate('groupManagement.nameMissing'), null, ERROR_SNACK_CONFIG);
      return;
    }
    Object.assign(this.groupDto, groupForm);

    const result = this.isNew ? this.groupManagementService.deleteGroup(this.groupDto) : this.groupManagementService.deleteGroup(this.groupDto);
    result.subscribe((response: any) => {
      this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
      this.reset();
    }, () => {
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  private onGroupSelected(id) {
    if (id) {
      this.groupManagementService.fetchGroup(id).subscribe((franchiserStationGroupDto) => {

        this.groupDto = franchiserStationGroupDto;
        this.groupDto.members.forEach(member => {
          member.isSelected = true;
          member.name = member.type >= 0 ? member.stationID + ' - (' + member.name + ')' : member.name;
        });
        this.isNew = false;
        this.setGroupQuestions();
      });
    }
  }
}
