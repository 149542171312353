import {Injectable} from '@angular/core';
import {TranslationService} from '../services/translation.service';
import {BetTypeKey} from './bet-type-key';
import {Sport} from './sport';

@Injectable({
  providedIn: 'root'
})
export class BetTypeKeyMapper {

  constructor(public translationService: TranslationService) {
  }

  getText(betTypeKey: number, sport: number, specialOV: string = '') {
    switch (betTypeKey) {
      case BetTypeKey.REST_OF_MATCH:
        return this.formatString('{0}? {1}: {2}', this.translate('Bet_ROM'), this.translate('Bet_ActScore'), specialOV);
      case BetTypeKey.REST_OF_MATCH_H1:
        return this.formatString('{0}? {1}: {2}', this.translate('Bet_ROM_H1'), this.translate('Bet_ActScore'), specialOV);
      case BetTypeKey.UNDER_OVER:
        if (sport === Sport.BASKETBALL || sport === Sport.FOOTBALL || sport === Sport.BASEBALL) {
          return this.formatString('{0}/{1} {2} {3} {4}', this.translate('Bet_UO_O'), this.translate('Bet_UO_U'), specialOV,
            this.getPointsOrGoals(sport), this.translate('Bet_BBall_WithOT'));
        } else if (sport === Sport.VOLLEYBALL || sport === Sport.BEACHVOLLEYBALL) {
          return this.formatString('{0}/{1} {2} {3} {4}', this.translate('Bet_UO_O'), this.translate('Bet_UO_U'), specialOV,
            this.getPointsOrGoals(sport), '');
        } else {
          return this.formatString('{0}/{1} {2} {3}', this.translate('Bet_UO_O'), this.translate('Bet_UO_U'), specialOV,
            this.translate('Bet_UO_Goals'));
        }
      case BetTypeKey.UNDER_OVER_H1:
        return this.formatString('{0}/{1} {2} {3}', this.translate('Bet_UO_O'), this.translate('Bet_UO_U'), specialOV,
          this.translate('Bet_UO_H1_Goals'));
      case BetTypeKey.NEXT_GOAL:
        return this.formatString('{0}? {1}: {2}', this.translate('Bet_NG'), this.translate('Bet_ActScore'), specialOV);
      case BetTypeKey.WAY_2:
        if (sport === Sport.BASKETBALL) {
          return this.formatString('{0} {1}', this.translate('Bet_Result'), this.translate('Bet_BBall_WithOT'));
        } else if (sport === Sport.MOTORSPORT) {
          return this.formatString('{0}', 'Head 2 Head');
        } else {
          return this.formatString('{0}', this.translate('Bet_Result'));
        }
      case BetTypeKey.WAY_3:
        return this.formatString('{0}', this.translate('Bet_Result'));
      case BetTypeKey.WAY_3_OT:
        return this.formatString('{0}', this.translate('Bet_Result_OT'));
      case BetTypeKey.WAY_3_H1:
        return this.formatString('{0}', this.translate('Bet_Result_H1'));
      case BetTypeKey.REST_OF_MATCH_OT:
        return this.formatString('{0}? {1}: {2}', this.translate('Bet_ROM_OT'), this.translate('Bet_ActScore'), specialOV);
      case BetTypeKey.UNDER_OVER_OT:
        return this.formatString('{0}/{1} {2} {3}', this.translate('Bet_UO_O'), this.translate('Bet_UO_U'), specialOV,
          this.translate('Bet_UO_OT_Goals'));
      case BetTypeKey.NEXT_GOAL_OT:
        return this.formatString('{0}? {1}: {2}', this.translate('Bet_NG'), this.translate('Bet_ActScore'), specialOV);
      case BetTypeKey.PENALTY_SHOOTOUT:
        return this.formatString('{0}?', this.translate('Bet_Penalty'));
      case BetTypeKey.HANDICAP:
        if (sport === Sport.BASKETBALL) {
          return this.formatString('{0} {1} {2}', this.translate('Bet_Handicap'), specialOV, this.translate('Bet_BBall_WithOT'));
        } else {
          return this.formatString('{0} {1}', this.translate('Bet_Handicap'), specialOV);
        }
      case BetTypeKey.DOUBLE_CHANCE:
        return this.formatString('{0}', this.translate('Bet_Doublechance'));
      case BetTypeKey.DNB:
        return this.formatString('{0}', this.translate('Bet_DrawNoBet'));
      case BetTypeKey.KICKOFF:
        return this.formatString('{0}', this.translate('Bet_Kickoff'));
      case BetTypeKey.NEXT_GOAL_H1:
        return this.formatString('{0}? {1}: {2}', this.translate('Bet_NG_H1'), this.translate('Bet_ActScore'), specialOV);

      case BetTypeKey.GOAL_NOGOAL:
        return this.formatString('{0}', this.translate('Bet_GoalNoGoal'));
      case BetTypeKey.HT_FT:
        return this.formatString('{0}', this.translate('Bet_HTFT'));
      case BetTypeKey.CORRECT_SCORE:
      case BetTypeKey.CORRECT_SCORE_LIVE:
        return this.formatString('{0}', this.translate('Bet_CorrectScore'));
      case BetTypeKey.GOAL_HOMETEAM:
        return this.formatString('{0}', this.translate('Bet_GHT'));
      case BetTypeKey.GOAL_AWAYTEAM:
        return this.formatString('{0}', this.translate('Bet_GAT'));
      case BetTypeKey.TOTAL_GOALS:
        return this.formatString('{0}', this.translate('Bet_TotalGoals'));

      case BetTypeKey.TENNIS_MATCH_WINNER:
        return this.formatString('{0}', this.translate('Bet_Result'));
      case BetTypeKey.OUTRIGHT_WINNER:
        return this.formatString('{0}', this.translate('Bet_OutrightWinner'));
      case BetTypeKey.TENNIS_SET_WINNER:
        return this.formatString('{0} {1} {2}?', this.translate('Bet_Tennis_WhoWins'), this.translate('Bet_Tennis_Set'), specialOV);
      case BetTypeKey.TENNIS_GAMES_WINNER:
        return this.getTennisGameWinnerDesc(specialOV);
      case BetTypeKey.TENNIS_SETS_COUNT_3:
      case BetTypeKey.TENNIS_SETS_COUNT_5:
        return this.formatString('{0}', this.translate('Bet_Tennis_NumberOfSets'));
      case BetTypeKey.TENNIS_UNDER_OVER:
        if (sport === Sport.VOLLEYBALL || sport === Sport.BEACHVOLLEYBALL) {
          return this.formatString('{0}/{1} {2} {3} {4}', this.translate('Bet_UO_O'), this.translate('Bet_UO_U'), specialOV,
            this.translate('Bet_BBall_Points'), '');
        }
        return this.formatString('{0}/{1} {2} {3}', this.translate('Bet_UO_O'), this.translate('Bet_UO_U'), specialOV,
          this.translate('Bet_Tennis_UO_Games'));
      case BetTypeKey.TENNIS_RESULTS_BEST3:
        return this.formatString('{0}', this.translate('Bet_Tennis_Result3'));
      case BetTypeKey.TENNIS_RESULTS_BEST5:
        return this.formatString('{0}', this.translate('Bet_Tennis_Result5'));
      case BetTypeKey.TENNIS_GAME_X_WINNER:
        return this.formatString(this.translate('Bet_Tennis_Game_X') + ' {0} ' + this.translate('Bet_Tennis_ofSet') + ' {1}?',
          specialOV.substr(specialOV.indexOf('-') + 1), specialOV.substr(0, specialOV.indexOf('-')));
      case BetTypeKey.TENNIS_SET_UNDER_OVER:
        return this.formatString('{0}/{1} {2} {3} {4}', this.translate('Bet_UO_O'), this.translate('Bet_UO_U'),
          specialOV.substr(specialOV.indexOf('-') + 1), this.translate('Bet_Tennis_UO_GamesSet'), specialOV[0]);
      case BetTypeKey.PERIOD_WINNER:
        return this.formatString('{0} {1}', this.translate('Bet_Hockey_PeriodWinner'), specialOV);
      case BetTypeKey.RACE_X_POINTS:
        return this.formatString('{0} {1} {2}', this.translate('Bet_BBall_RaceXPoints'), specialOV, this.getPointsOrGoals(sport));
      case BetTypeKey.SCORE_X_POINT:
        return this.formatString('{0} {1} {2}', this.translate('Bet_BBall_ScoreXPoints'), this.getPointOrGoal(sport), specialOV);
      case BetTypeKey.GOAL_ODDEVEN:
        if (sport === Sport.BASKETBALL) {
          return this.formatString('{0}', this.translate('Bet_BB_Goal_OddEven'));
        }
        return this.formatString('{0}', this.translate('Bet_Goal_OddEven'));
      case BetTypeKey.GOAL_NOGOAL_H1:
        return this.formatString('{0}', this.translate('Bet_Goal_Nogoal_H1'));
      case BetTypeKey.FIRST_GOAL_INT_15:
        if (specialOV != null && +specialOV > 1) {
          return this.formatString('{0}',
            this.translationService.translateArgs('ticketDetail.livesports.betTypeKey.Bet_FirstGoalX', specialOV));
        }
        return this.formatString('{0}', this.translate('Bet_FirstGoal15'));
      case BetTypeKey.LAST_GOAL:
        return this.formatString('{0}', this.translate('Bet_LastGoal'));
      case BetTypeKey.HIGHEST_SCORE_HALF:
        return this.formatString('{0}', this.translate('Bet_HighestScoringHalf'));
      case BetTypeKey.WAY_3_H2:
        return this.formatString('{0}', this.translate('Bet_Result_H2'));
      case BetTypeKey.UNDER_OVER_H2:
        return this.formatString('{0}/{1} {2} {3}', this.translate('Bet_UO_O'), this.translate('Bet_UO_U'), specialOV,
          this.translate('Bet_OU_H2'));
      case BetTypeKey.PLAYER_SENT_OFF:
        return this.formatString('{0}', this.translate('Bet_PlayerSentOff'));
      case BetTypeKey.CORNER_WAY3:
        return this.formatString('{0}', this.translate('Bet_CornerResult'));
      case BetTypeKey.CORNER_TOTAL:
        return this.formatString('{0}', this.translate('Bet_CornerTotal'));
      case BetTypeKey.TENNIS_SET_CORRECT_SCORE:
        return this.formatString('{0} {1} {2}', this.translate('Bet_CorrectScore'), this.translate('Bet_Tennis_Set'), specialOV);
      case BetTypeKey.WAY_3_UNDER_OVER:
        return this.formatString('{0}{1}{2} + {3}/{4} {5} {6}', this.translate('Bet_Result'), '', '', this.translate('Bet_UO_O'),
          this.translate('Bet_UO_U'), specialOV, this.getPointsOrGoals(sport));
      case BetTypeKey.ADVANCE_NEXT_ROUND:
        return this.formatString('{0}', this.translate('Bet_AdvNextRound'));
      case BetTypeKey.CORRECT_SCORE_H1:
        return this.formatString('{0}', this.translate('Bet_CS_H1'));
      case BetTypeKey.CORRECT_SCORE_LIVE_H1:
        return this.formatString('{0} {1}: {2}', this.translate('Bet_CS_H1'), this.translate('Bet_ActScore'), specialOV);
      case BetTypeKey.HANDICAP_H1:
        return this.formatString('{0} {1}', this.translate('Bet_HC_H1'), specialOV);
      case BetTypeKey.HANDICAP_H2:
        return this.formatString('{0} {1}', this.translate('Bet_HC_H2'), specialOV);
      case BetTypeKey.DOUBLE_CHANCE_H1:
        return this.formatString('{0}', this.translate('Bet_DC_H1'));
      case BetTypeKey.DOUBLE_CHANCE_H2:
        return this.formatString('{0}', this.translate('Bet_DC_H2'));
      case BetTypeKey.TOTAL_GOALS_EXACT:
        return this.formatString('{0}', this.translate('Bet_TotGoalsExact'));
      case BetTypeKey.TOTAL_GOALS_EXACT_H1:
        return this.formatString('{0}', this.translate('Bet_TotGoalsExact_H1'));
      case BetTypeKey.TOTAL_GOALS_EXACT_H2:
        return this.formatString('{0}', this.translate('Bet_TotGoalsExact_H2'));
      case BetTypeKey.UNDER_OVER_HOMETEAM:
        return this.formatString('{0}/{1} {2} {3}', this.translate('Bet_UO_O'), this.translate('Bet_UO_U'), specialOV,
          this.translate('Bet_UO_Home'));
      case BetTypeKey.UNDER_OVER_AWAYTEAM:
        return this.formatString('{0}/{1} {2} {3}', this.translate('Bet_UO_O'), this.translate('Bet_UO_U'), specialOV,
          this.translate('Bet_UO_Away'));
      case BetTypeKey.UNDER_OVER_CORNER:
        return this.formatString('{0}/{1} {2} {3}', this.translate('Bet_UO_O'), this.translate('Bet_UO_U'), specialOV,
          this.translate('Bet_UO_Corner'));
      case BetTypeKey.GOAL_NOGOAL_H2:
        return this.formatString('{0} {1}', this.translate('Bet_Goal_Nogoal_H2'), specialOV);
      case BetTypeKey.WAY_3_GOAL_NOGOAL:
        return this.formatString('{0} {1}', this.translate('Bet_Way3_Goal_Nogoal'), specialOV);
      case BetTypeKey.UNDER_OVER_GOAL_NOGOAL:
        return this.formatString('{3} {0}/{1} {2}', this.translate('Bet_UO_O'), this.translate('Bet_UO_U'), specialOV,
          this.translate('Bet_UO_Goal_Nogoal'));
      case BetTypeKey.WAY_3_INT_10:
        return this.formatString('{0} {1}', this.translate('Bet_Way3_Int10'), !specialOV ? '1-10' : specialOV);
      case BetTypeKey.UNDER_OVER_HOMETEAM_H1:
        return this.formatString('{0}/{1} {2} {3}', this.translate('Bet_UO_O'), this.translate('Bet_UO_U'), specialOV, this.translate('Bet_UO_Home_H1'));
      case BetTypeKey.UNDER_OVER_AWAYTEAM_H1:
        return this.formatString('{0}/{1} {2} {3}', this.translate('Bet_UO_O'), this.translate('Bet_UO_U'), specialOV, this.translate('Bet_UO_Away_H1'));
      case BetTypeKey.WAY_3_UNDER_OVER_H1:
        return this.formatString('{0} + {1}/{2} {3} {4}', this.translate('Bet_Result'), this.translate('Bet_UO_O'), this.translate('Bet_UO_U'), specialOV, this.translate('Bet_UO_H1_Goals'));
      case BetTypeKey.WAY_3_GOAL_NOGOAL_H1:
        return this.formatString('{0}', this.translate('Bet_Way3_GoalNogoal_H1'));
      case BetTypeKey.DOUBLE_CHANCE_UNDER_OVER:
        return this.formatString('{0} + {1}/{2} {3} {4}', this.translate('Bet_Doublechance'), this.translate('Bet_UO_O'), this.translate('Bet_UO_U'), specialOV, this.getPointsOrGoals(sport));
      case BetTypeKey.DOUBLE_CHANCE_GOAL_NOGOAL:
        return this.formatString('{0} + {1}', this.translate('Bet_Doublechance'), this.translate('Bet_GoalNoGoal'));
      case BetTypeKey.UNDER_OVER_PERIOD:
        if (specialOV.length > 2) {
          return this.formatString('{0}/{1} {2} {3} {4} {5}', this.translate('Bet_UO_O'), this.translate('Bet_UO_U'), specialOV.substring(2), this.getPointsOrGoals(sport), this.getInPeriodOrInQuarter(sport), specialOV.substring(0, 1));
        }
        return '';
      case BetTypeKey.XTH_GOAL_PERIOD:
        if (specialOV.length > 2) {
          return this.formatString('{0} {1} {2} {3} {4}', this.translate('Bet_WhoScores'), this.getPointOrGoal(sport), specialOV.substring(2), this.getInPeriodOrInQuarter(sport), specialOV.substring(0, 1));
        }
        return ''; // TODO: fix for brMatchId 41773275
      case BetTypeKey.REST_OF_MATCH_PERIOD:
        if (specialOV.length > 2) {
          return this.formatString('{0} {1}? {2}: {3}', this.translate('Bet_ROM_Period'), specialOV.substring(0, 1), this.translate('Bet_ActScore'), specialOV.substring(2));
        }
        return '';

      case BetTypeKey.HIGHEST_SCORE_PERIOD:
        return this.formatString('{0}', this.translate('Bet_HighestScoringPeriod'));
    }
    return '***';
  }

  getInPeriodOrInQuarter(sport: number) {
    if (sport === Sport.BASKETBALL || sport === Sport.FOOTBALL) {
      return this.translate('Bet_InQuarter');
    }
    return this.translate('Bet_InPeriod');
  }

  translate(label: string) {
    return this.translationService.translate('ticketDetail.livesports.betTypeKey.' + label);
  }

  getPointsOrGoals(sport: number) {
    if (sport === Sport.BASKETBALL || sport === Sport.FOOTBALL) {
      return this.translate('Bet_BBall_Points');
    } else if (sport === Sport.BASEBALL) {
      return this.translate('Bet_BaseBall_Runs');
    }
    return this.translate('Bet_UO_Goals');
  }

  getPointOrGoal(sport: number) {
    if (sport === Sport.BASKETBALL || sport === Sport.FOOTBALL) {
      return this.translate('Bet_BBall_point');
    } else if (sport === Sport.BASEBALL) {
      return this.translate('Bet_BaseBall_Runs');
    }
    return this.translate('Bet_TextGoal');
  }

  splitTennisGameBet(specialValue: string, setFirst: boolean) {
    try {
      const result: any = ['', '', ''];
      const vals = /^(\d+)-(\d+)and(\d+)$/.exec(specialValue);
      result[1] = vals[3];
      if (setFirst) {
        result[0] = vals[1];
        result[2] = vals[2];
      } else {
        result[0] = vals[2];
        result[2] = vals[1];
      }
      return result;
    } catch (e) {
      console.log('error', specialValue, setFirst, e);
      return  ['', '', ''];
    }
  }

  getTennisGameWinnerDesc(specialOV: string) {
    const args = this.splitTennisGameBet(specialOV, false);
    return this.formatString(this.translate('Bet_Tennis_WhoWins') + ' '
      + this.translate('Bet_Tennis_Game') + ' {0} '
      + this.translate('Bet_Tennis_and') + ' {1} '
      + this.translate('Bet_Tennis_ofSet') + ' {2}?'
      , args[0], args[1], args[2]);
  }

  formatString(...args: any[]) {
    let theString = args[0];
    // start with the second args (i = 1)
    for (let i = 1; i < args.length; i++) {
      // 'gm' = RegEx options for Global search (more than one instance)
      // and for Multiline search
      const regEx = new RegExp('\\{' + (i - 1) + '\\}', 'gm');
      theString = theString.replace(regEx, args[i]);
    }

    return theString;
  }
}

