import {Component, OnInit} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {isNumber, isNullOrUndefined, loadColumnVisibility, storeColumnVisibility, getContextMenuItems} from '../../../utils';
import {CashTimelineService} from './cash-timeline.service';
import {GridReadyEvent} from 'ag-grid-community';
import {Router} from '@angular/router';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {TicketSearchComponent} from '../ticket-search/ticket-search.component';
import {ActionButtonCellRenderer} from './action-button-cell-renderer/action-button-cell-renderer.component';
import {tap} from 'rxjs';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-cash-timeline',
  templateUrl: './cash-timeline.component.html',
  styleUrls: ['./cash-timeline.component.scss']
})
export class CashTimelineComponent implements OnInit {

  columnDefs;
  gridOptions;
  rowData;
  api;

  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;

  hasAnomalies = false;
  lastAnomalyNode = null;

  constructor(public cashTimelineService: CashTimelineService,
              public translationService: TranslationService,
              public router: Router,
              public dialog: MatDialog,
              public sessionService: SessionService,
              public httpService: HttpService,
              public datePipe: DatePipe,
              public decimalPipe: DecimalPipe) {
    this.columnDefs = this.generateColumnDefs();
    this.gridOptions = this.generateGridOptions();
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('cashTimeline', event);
    this.api = event.api;
  }

  ngOnInit() {
  }

  fetch(query: QueryFilter) {
    if (query) {
      this.query = query;
      this.rowData = this.cashTimelineService.fetchTimeline(query)
        .pipe(tap(tickets => this.hasAnomalies = tickets.some(ticket => !!ticket.note)));
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  nextAnomaly(resetable = true) {
    let done = false;
    let inBlock = true;
    this.gridOptions.api.forEachNode(node => {
      if (!!node.data.note) {
        if (inBlock && (!this.lastAnomalyNode || node.rowIndex > this.lastAnomalyNode?.rowIndex)) {
          this.lastAnomalyNode = node;
          this.gridOptions.api.ensureIndexVisible(node.rowIndex, 'middle');
          if (!done) {
            done = true;
          }
        }
      } else if (done) {
        inBlock = false;
      }
    });
    if (resetable && !done) {
      this.lastAnomalyNode = null;
      this.nextAnomaly(false);
    }
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('cashTimeline.stationID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationID',
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40,
        headerTooltip: this.translationService.translate('cashTimeline.stationID')
      },
      {
        headerName: this.translationService.translate('cashTimeline.date'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'date',
        width: 160,
        valueFormatter: this.dateFormatter.bind(this),
        headerTooltip: this.translationService.translate('cashTimeline.date')
      },
      {
        headerName: this.translationService.translate('cashTimeline.action'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'action',
        width: 300,
        headerTooltip: this.translationService.translate('cashTimeline.action')
      },
      {
        headerName: this.translationService.translate('cashTimeline.deltaCredit'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'deltaCredit',
        width: 95,
        valueFormatter: params => params.value === 0 ? ' ' : this.decimalFormatter(params.value),
        cellClass: 'text-align-right',
        headerTooltip: this.translationService.translate('cashTimeline.deltaCredit')
      },
      {
        headerName: this.translationService.translate('cashTimeline.note'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'note',
        width: 95,
        headerTooltip: this.translationService.translate('cashTimeline.note')
      },
      {
        headerName: this.translationService.translate('cashTimeline.link'),
        resizable: true,
        field: 'link',
        width: 100,
        cellClass: () => 'link-cell',
        cellRenderer: ActionButtonCellRenderer,
        cellRendererParams: {
          clicked: (params: any) => {
            this.openLink(params);
          }
        },
      }
    ];
  }

  openLink(params: any) {
    const split = params.data.link.split(':');
    const prefix = split[0];
    const value = split[1];
    if (prefix === 'TN' || prefix === 'CTN') {
      const normalizedTicketNumber: string = value.replace(/[^0-9]/g, '');
      this.dialog.open(TicketSearchComponent, {
        height: '90%',
        width: '80%',
        data: {
          ticketNumber: normalizedTicketNumber,
        }
      }).afterClosed().subscribe(result => {
      });
    } else if (prefix === 'CID') {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/home/customer-info'],
        {queryParams: {customerId: value}}));
      window.open('#' + url, '_blank');
    } else if (prefix === 'UAID') {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/home/user-account-timeline'],
        {queryParams: {userAccountId: value}}));
      window.open('#' + url, '_blank');
    }
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: false,
      detailRowHeight: 160,
      rowClassRules: {
        'bold': (params) => params.data.deltaCredit === 0,
        'text-align-right': (params) => isNumber(params.data.value),
        'vit-red': (params) => !isNullOrUndefined(params.data.note) && params.data.note !== '',
        'vit-green': (params) => !isNullOrUndefined(params.data.type) && params.data.type === -2,
        'vit-blue': (params) => !isNullOrUndefined(params.data.type) && params.data.type >= 20,
      },
      onCellClicked: (params) => {
        params.node.setExpanded(!params.node.expanded);
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }

}
