import {Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {MarkdownInfoComponent} from '../markdown-info/markdown-info.component';
import {TranslationService} from './translation.service';
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {SessionService} from './session.service';

interface BubbleConfig {
  width?: string;
  height?: string;
  maxHeight?: string;
  maxWidth?: string;
}

@Injectable({
  providedIn: 'root'
})
export class BubbleEngineService {

  dialogRef: MatDialogRef<any>;
  folderPath = '/assets/md';

  constructor(private readonly dialog: MatDialog,
              private readonly translationService: TranslationService,
              private readonly sessionService: SessionService,
              private readonly http: HttpClient) {
  }

  show(key: string, config: BubbleConfig = {}) {
    this.dialogRef?.close();
    const {width, height, maxWidth, maxHeight} = config;

    const currentLanguage = this.translationService.getLanguage();
    const pathToMarkdown = `${this.folderPath}/${key}-${currentLanguage}.md`;
    const pathToEnMarkdown = `${this.folderPath}/${key}-en.md`;
    const queryParam = `?${this.sessionService.version}-${environment.buildTime}`;
    console.log(key, currentLanguage);
    this.http.get(`${pathToMarkdown}${queryParam}`, {responseType: 'text'})
      .pipe(catchError(error => this.http.get(`${pathToEnMarkdown}${queryParam}`, {responseType: 'text'})))
      .subscribe({
        next: markdown => {
          this.dialogRef = this.dialog.open(MarkdownInfoComponent, {
            width,
            height,
            maxHeight: maxHeight || '90vh',
            maxWidth: maxWidth || '90vw',
            data: {markdown}
          });
        }
      });

  }

}
