import {QuestionBase} from './question-base';

export class TimeFrameQuestion extends QuestionBase<string> {
  controlType = 'time-frame';
  from: Date;
  to: Date;
  onlyMonths: boolean;
  onlyLastMonths: boolean;
  onlyLastMonths2: boolean;
  hideInput: boolean;

  constructor(options: {} = {}) {
    super(options);
    this.from = options['from'] || new Date();
    this.to = options['to'] || new Date();
    this.onlyMonths = !!options['onlyMonths'];
    this.onlyLastMonths = !!options['onlyLastMonths'];
    this.onlyLastMonths2 = !!options['onlyLastMonths2'];
    this.hideInput = !!options['hideInput'];
  }
}

