<table>
  <tr>
    <td>{{'ticketDetail.ticketNumber' | translate}}</td>
    <td>{{ticket?.number}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.code' | translate}}</td>
    <td>{{ticket?.code ? ticket?.code : '---'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.applicationSubType' | translate}}</td>
    <td>{{'ticketDetail.applicationSubTypes.' + ticket?.applicationSubType | translate}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.eventID' | translate}}</td>
    <td>0 ({{ticket?.bingoGameID}})</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.stationID' | translate}}</td>
    <td>{{ticket?.stationID}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.stakeTotalGross' | translate}}</td>
    <td>{{(ticket?.stake + ticket?.tax) | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td class="indented">{{'ticketDetail.stake' | translate}}</td>
    <td>{{(ticket?.stake - ticket?.stakeExtraNums) | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td class="indented">{{'ticketDetail.stakeExtraNums' | translate}}</td>
    <td>{{ticket?.stakeExtraNums | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td class="indented">{{'ticketDetail.tax' | translate}}</td>
    <td>{{ticket?.tax | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.stakeTotalNet' | translate}}</td>
    <td>{{ticket?.stake | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.profitTotalGross' | translate}}</td>
    <td>{{(ticket?.profit + ticket?.winTax + ticket?.jackpotProfit) | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td class="indented">{{'ticketDetail.profitNet' | translate}}</td>
    <td>{{ticket?.profit | number: '0.2-2'}}</td>
  <tr>
  <tr>
    <td class="indented">{{'ticketDetail.jackpotProfit' | translate}}</td>
    <td>{{ticket?.jackpotProfit | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td class="indented">{{'ticketDetail.winTax' | translate}}</td>
    <td>{{ticket?.winTax | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.wonProfit' | translate}}</td>
    <td>{{(ticket?.profit + ticket?.jackpotProfit) | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.inDate' | translate}}</td>
    <td>{{ticket?.inDate | date: 'medium'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.outDate' | translate}}</td>
    <td>{{ticket?.outDate | date: 'medium'}}</td>
  </tr>
</table>
<table>
  <tr>
    <td>{{'ticketDetail.jackpotTicket' | translate}}</td>
    <td><input type="checkbox" disabled [checked]="ticket?.jackpotTicket"></td>
  </tr>
  <tr *ngIf="ticket?.applicationSubType !== applicationSubtype.BB1_LUCKYSIX">
    <td>{{'ticketDetail.keno.selectedCount' | translate}}</td>
    <td>{{ticket?.selectedCount}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.keno.allNumbers' | translate}}</td>
    <td>{{ticketData?.allNumbers}}</td>
  </tr>
  <tr *ngIf="ticket?.applicationSubType !== applicationSubtype.BB1_LUCKYSIX">
    <td>{{'ticketDetail.keno.extraNumbers' | translate}}</td>
    <td>{{ticketData?.extraNumbers ? ticketData?.extraNumbers : '-'}}</td>
  </tr>
  <tr *ngIf="ticket?.applicationSubType !== applicationSubtype.BB1_LUCKYSIX">
    <td>{{'ticketDetail.keno.hitCount' | translate}}</td>
    <td>{{ticket?.hitCount}}</td>
  </tr>
  <tr *ngIf="ticket?.applicationSubType !== applicationSubtype.BB1_LUCKYSIX">
    <td>{{'ticketDetail.keno.selectedNums' | translate}}</td>
    <td>{{ticket?.selectedNums}}</td>
  </tr>


  <tr *ngIf="ticket?.applicationSubType === applicationSubtype.BB1_LUCKYSIX">
    <td>{{'ticketDetail.keno.starsPosition' | translate}}</td>
    <td>{{ticketData?.starsPosition}}</td>
  </tr>
  <tr *ngIf="ticket?.applicationSubType === applicationSubtype.BB1_LUCKYSIX">
    <td>{{'ticketDetail.keno.starsDrawn' | translate}}</td>
    <td>{{ticketData?.starsDrawn}}</td>
  </tr>
  <tr *ngIf="ticket?.applicationSubType === applicationSubtype.BB1_LUCKYSIX">
    <td>{{'ticketDetail.keno.firstBallColor' | translate}}</td>
    <td>{{bb1LsColors[ticketData?.firstBallColor]}}</td>
  </tr>
  <tr *ngIf="ticket?.applicationSubType === applicationSubtype.BB1_LUCKYSIX">
    <td>{{'ticketDetail.keno.lastBallColor' | translate}}</td>
    <td>{{bb1LsColors[ticketData?.lastBallColor]}}</td>
  </tr>
  <tr *ngIf="ticket?.applicationSubType === applicationSubtype.BB1_LUCKYSIX">
    <td>{{'ticketDetail.keno.mostColors' | translate}}</td>
    <td>{{mapMostColors(ticketData?.mostColors)}}</td>
  </tr>
  <tr *ngIf="ticket?.applicationSubType === applicationSubtype.BB1_LUCKYSIX">
    <td>{{'ticketDetail.keno.firstBallUO' | translate}}</td>
    <td>{{bb1LsUo[ticketData?.firstBallUO]}}</td>
  </tr>
  <tr *ngIf="ticket?.applicationSubType === applicationSubtype.BB1_LUCKYSIX">
    <td>{{'ticketDetail.keno.preBallsUO' | translate}}</td>
    <td>{{bb1LsUo[ticketData?.preBallsUO]}}</td>
  </tr>
  <tr *ngIf="ticket?.applicationSubType === applicationSubtype.BB1_LUCKYSIX">
    <td>{{'ticketDetail.keno.firstBallEO' | translate}}</td>
    <td>{{bb1LsEo[ticketData?.firstBallEO]}}</td>
  </tr>
  <tr *ngIf="ticket?.applicationSubType === applicationSubtype.BB1_LUCKYSIX">
    <td>{{'ticketDetail.keno.preBallsEO' | translate}}</td>
    <td>{{bb1LsEo[ticketData?.preBallsEO]}}</td>
  </tr>
  <tr *ngIf="ticket?.applicationSubType === applicationSubtype.BB1_LUCKYSIX">
    <td>{{'ticketDetail.keno.drawnBallsEO' | translate}}</td>
    <td>{{bb1LsEo[ticketData?.drawnBallsEO]}}</td>
  </tr>





  <tr *ngIf="ticket?.applicationSubType !== applicationSubtype.BB1_LUCKYSIX">
    <td>{{'ticketDetail.keno.drawings' | translate}}</td>
    <td [class.red]="ticket?.drawings > 1">{{ticket?.drawings}}</td>
  </tr>
  <tr *ngIf="ticket?.applicationSubType !== applicationSubtype.BB1_LUCKYSIX">
    <td>{{'ticketDetail.keno.openDrawings' | translate}}</td>
    <td [class.red]="ticket?.openDrawings > 0">{{ticket?.openDrawings}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.userAccountID' | translate}}</td>
    <td *ngIf="ticket?.userAccountID">{{ticket?.userAccountID}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.status' | translate}}</td>
    <td><vit-ticket-status [ticket]="ticket"></vit-ticket-status></td>
  </tr>
</table>
<table class="bets" *ngIf="ticket?.applicationSubType === applicationSubtype.BB1_LUCKYSIX">
  <tr>
    <td>{{'ticketDetail.keno.betTypes' | translate}}</td>
    <td>{{'ticketDetail.keno.tipValues' | translate}}</td>
    <td>{{'ticketDetail.keno.odds' | translate}}</td>
    <td>{{'ticketDetail.keno.stake' | translate}}</td>
    <td>{{'ticketDetail.keno.profit' | translate}}</td>
    <td>{{'ticketDetail.keno.status' | translate}}</td>
  </tr>
  <tr *ngFor="let bet of ticketData?.bB1Bets">
    <td>{{('ticketDetail.keno.betType.'+ bet.betType) | translate}}</td>
    <td>{{translateTipValues(bet.betType, bet.tipValues)}}</td>
    <td>{{bet.odd | number: '0.2-2'}}</td>
    <td>{{bet.stake | number: '0.2-2'}}</td>
    <td>{{bet.profit | number: '0.2-2'}}</td>
    <td>
      <vit-bet-status [betCalculated]="bet.calculated"
                      [betWon]="bet.won"></vit-bet-status>
    </td>
  </tr>
</table>
