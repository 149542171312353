import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'vit-ticket-detail-smartrace',
  templateUrl: './ticket-detail-smartrace.component.html',
  styleUrls: ['./ticket-detail-smartrace.component.scss']
})
export class TicketDetailSmartraceComponent implements OnInit {

  @Input()
  ticketData: any;
  ticket: any;

  constructor() { }

  ngOnInit() {
    this.ticket = this.ticketData.ticket;
  }

}
