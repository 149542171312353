<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<ng-container *ngIf="!mobileService.isMobile">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

<ng-container *ngIf="mobileService.isMobile">
  <mat-menu #appMenu="matMenu" yPosition="above">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </mat-menu>
  <button mat-icon-button [matMenuTriggerFor]="appMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
</ng-container>
