import {Component, EventEmitter, Output} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {HomeMenuService} from '../services/home-menu.service';
import {UserService} from '../services/user.service';
import {SessionService} from '../services/session.service';
import {MobileService} from '../services/mobile.service';
import {BubbleEngineService} from '../services/bubble-engine.service';

@Component({
  selector: 'vit-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  @Output()
  onNavigation: EventEmitter<boolean> = new EventEmitter();

  constructor(public router: Router,
              public homeMenuService: HomeMenuService,
              public bubbleEngineService: BubbleEngineService,
              public userService: UserService,
              public sessionService: SessionService,
              public mobileService: MobileService,
              public route: ActivatedRoute) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const parts = event.url.split('/');
        if (parts.length > 2 && (parts[1] === 'home' || parts[1] === 'live-admin')) {
          const menu = parts[2];
          const submenu = parts[3];
          this.sessionService.selectedMenuElement = [].concat(...this.homeMenuService.menu.map(a => a.content))
            .find(hm => {
              return hm.link[1] === menu + (submenu ? ('/' + submenu) : '')
                || (hm.link[0] === 'ref' && this.hasUrlInRef(hm.queryParams, menu, submenu));
            });
        } else {
          this.sessionService.selectedMenuElement = null;
        }
      }
    });
  }

  openInTab(el, event, force = false) {
    if (force || event.ctrlKey || event.metaKey) {
      event.preventDefault();
      const urlTree = this.router.createUrlTree(el.link, { queryParams: el.queryParams });
      const url = this.router.serializeUrl(urlTree);
      const baseUrl = window.location.origin;
      window.open(baseUrl + '/#' + url, '_blank');
    }
  }

  logout() {
    this.userService.logout();
    this.onNavigation.emit(true);
  }

  private hasUrlInRef(queryParams: any, menu: string, submenu: string) {
    return queryParams && queryParams.ref && queryParams.ref.indexOf(menu + (submenu ? ('/' + submenu) : '')) > -1;
  }
}
