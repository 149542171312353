import {Injectable} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {SessionService} from '../../services/session.service';
import {Observable} from 'rxjs';
import {SystemConfig} from '../../config/system-config';
import {Currency} from '../../domain/currency';

@Injectable({
  providedIn: 'root'
})
export class CurrenciesService {

  constructor(public httpService: HttpService,
              public sessionService: SessionService) {
  }

  fetch() {
    return this.httpService.get(SystemConfig.Endpoints.CURRENCIES);
  }

  update(currency: Currency): Observable<any> {
    return this.httpService.post(SystemConfig.Endpoints.UPDATE_CURRENCY, currency);
  }

}
