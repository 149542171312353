<mat-card [@fadeInOut]>
  <form [formGroup]="loginForm"
        novalidate
        (submit)="login()">
    <mat-card-title>
      {{'login.title' | translate}}
    </mat-card-title>
    <mat-card-content>
      <mat-form-field>
        <input matInput
               [placeholder]="'login.username' | translate"
               vitAutoFocus delay="1000"
               formControlName="username">
        <mat-error i18n="@@form.required"
                   *ngIf="loginForm.controls['username'].hasError('required')">
          {{'login.required' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput
               [placeholder]="'login.password' | translate"
               formControlName="password"
               [type]="hide ? 'password' : 'text'">
        <mat-icon matSuffix
                  (click)="hide = !hide">
          {{hide ? 'visibility' : 'visibility_off'}}
        </mat-icon>
        <mat-error i18n="@@form.required"
                   *ngIf="loginForm.controls['password'].hasError('required')">
          {{'login.required' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-error i18n="@@login.error">
        <ng-container *ngIf="error">
          {{'login.failed' | translate}}
        </ng-container>&nbsp;
      </mat-error>

      <div class="buttons">
        <span class="fill"></span>
        <button [disabled]="!loginForm.valid || submitted"
                type="submit"
                mat-flat-button
                color="accent"
                i18n="@@login.login">
          {{'login.submit' | translate}}
        </button>
      </div>
    </mat-card-content>
  </form>
</mat-card>
