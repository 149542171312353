import {Component, Input, OnChanges, ViewChild} from '@angular/core';
import {GameService} from '../../services/game.service';
import {Game} from '../../domain/game';
import {QuestionContainer} from '../../domain/question-container';
import {StationType} from '../../domain/station-type';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {Permission} from '../../domain/permission';
import {TextboxQuestion} from '../../domain/question-textbox';
import {TextareaQuestion} from '../../domain/question-textarea';
import {CheckboxQuestion} from '../../domain/question-checkbox';
import {SmartraceSettingsData} from '../../domain/smartrace-settings-data';
import {SmartraceSettings} from '../../domain/smartrace-settings';
import {FormUtils} from '../../utils/form-utils';
import {DropdownQuestion} from '../../domain/question-dropdown';
import {Jackpot} from '../../domain/jackpot';
import {JackpotService} from '../../services/jackpot.service';
import {ColsComponent} from '../../cols/cols.component';
import {RaceCycleQuestion} from '../../domain/question-race-cycle';
import {ERROR_SNACK_CONFIG, SUCCESS_SNACK_CONFIG} from '../../../utils';

@Component({
  selector: 'vit-smartrace',
  templateUrl: './smartrace.component.html',
  styleUrls: ['./smartrace.component.scss']
})
export class
SmartraceComponent implements OnChanges {
  @Input()
  stationID: number;
  settings: SmartraceSettings;
  jackpots: Jackpot[];
  selectedJackpot: Jackpot;
  gameCycles: SmartraceSettings.CycleInfo[];
  winningPlanDescriptions: string[];
  gameQuestions: QuestionContainer[] = [];
  jackpotQuestionContainer: QuestionContainer;
  hasChangePermission = false;
  @Input()
  stationType: StationType;

  questions: QuestionContainer[] = [];

  loading: boolean;
  @ViewChild(ColsComponent, { static: true })
  colsComponent: ColsComponent;

  constructor(public gameService: GameService,
              public snackBar: MatSnackBar,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public jackpotService: JackpotService) {
    this.hasChangePermission = this.sessionService.hasPermission(Permission.changeRF2Settings);
  }

  ngOnChanges() {
    this.loading = true;
    this.gameService.get(Game.SMART_RACE, this.stationID).subscribe((sd: SmartraceSettingsData) => {
      this.settings = sd.settings;
      this.jackpots = sd.jackpots;
      this.gameCycles = sd.gameCycles;
      this.loading = false;
      this.reset();
    }, () => {
      this.loading = false;
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  async save() {
    this.loading = true;
    const settings = this.generateSettingsFromForm();
    if (this.selectedJackpot) {
      const jackpot = this.generateJackpotFromForm();
      if (this.selectedJackpot.jackpotID === -1) {
        const createdJackpot: Jackpot = await this.jackpotService.insert(jackpot);
        this.jackpots.push(createdJackpot);
        settings.jackpotID = createdJackpot.jackpotID;
      } else if (this.jackpotService.isStationJackpot(this.selectedJackpot, this.settings.stationID)) {
        await this.jackpotService.update(jackpot);
        const index = this.jackpots.map(jp => jp.jackpotID).indexOf(jackpot.jackpotID);
        this.jackpots[index] = jackpot;
      }
    }
    this.gameService.saveSmartraceSettings(settings).subscribe(() => {
this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
      this.settings = settings;
      this.loading = false;
      this.reset();
    }, () => {
      this.loading = false;
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  private generateSettingsFromForm(): SmartraceSettings {
    const stakeForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'smartrace.settings.header.stake');
    const profitForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'smartrace.settings.header.profit');
    const shopForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'smartrace.settings.header.shop');
    const extendedOptionsForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'smartrace.settings.header.extendedOptions');
    let jackpotConfigForm: any = null;
    if (this.selectedJackpot) {
      jackpotConfigForm = FormUtils.getFormValues(this.colsComponent.forms, 'smartrace.settings.header.jackpotConfig');
    }

    const settings: SmartraceSettings = {
      'rF2SettingID': this.settings.rF2SettingID,
      'stationID': this.settings.stationID,
      'raceCycleID': this.settings.raceCycleID,
      'jackpotID': this.selectedJackpot ? this.selectedJackpot.jackpotID : null,
      'starters': this.settings.starters, // TODO !!
      'tripleFactor': extendedOptionsForm['tripleFactor'] != null ? +extendedOptionsForm['tripleFactor'] : null,
      'hasSpecialBet': shopForm['hasSpecialBet'],
      'hasJackpot': !!this.selectedJackpot,
      'minStakePerTicket': stakeForm['minStakePerTicket'] != null ? +stakeForm['minStakePerTicket'] : null,
      'minStakePerTip': stakeForm['minStakePerTip'] != null ? +stakeForm['minStakePerTip'] : null,
      'maxStakePerTip': stakeForm['maxStakePerTip'] != null ? +stakeForm['maxStakePerTip'] : null,
      'jackpotMinStake': (this.selectedJackpot && jackpotConfigForm['jackpotMinStake'])
        ? +jackpotConfigForm['jackpotMinStake'] : this.settings.jackpotMinStake,
      'jackpotMinHold': (this.selectedJackpot && jackpotConfigForm['jackpotMinHold'])
        ? +jackpotConfigForm['jackpotMinHold'] : this.settings.jackpotMinHold,
      'jackpotMinPercentage': (this.selectedJackpot && jackpotConfigForm['jackpotMinPercentage'])
        ? +jackpotConfigForm['jackpotMinPercentage'] : this.settings.jackpotMinPercentage,
      'jackpotMaxPercentage': (this.selectedJackpot && jackpotConfigForm['jackpotMaxPercentage'])
        ? +jackpotConfigForm['jackpotMaxPercentage'] : this.settings.jackpotMaxPercentage,
      'stakeButtonFactor': shopForm['stakeButtonFactor'] != null ? +shopForm['stakeButtonFactor'] : null,
      'enableCashbook': shopForm['enableCashbook'],
      'winnerGraduation': extendedOptionsForm['winnerGraduation'] != null ? +extendedOptionsForm['winnerGraduation'] : null,
      'podiumGraduation': extendedOptionsForm['podiumGraduation'] != null ? +extendedOptionsForm['podiumGraduation'] : null,
      'doubleGraduation': extendedOptionsForm['doubleGraduation'] != null ? +extendedOptionsForm['doubleGraduation'] : null,
      'weakGraphicCard': extendedOptionsForm['weakGraphicCard'],
      'singleTipPerTicket': shopForm['singleTipPerTicket'],
      'taxRate': shopForm['taxRate'] != null ? +shopForm['taxRate'] : null,
      'handlingFee': shopForm['handlingFee'] != null ? +shopForm['handlingFee'] : null,
      'validDays': profitForm['validDays'] != null ? +profitForm['validDays'] : null,
      'disburseLimit': profitForm['disburseLimit'] != null ? +profitForm['disburseLimit'] : null,
      'maxProfitPerTicket': profitForm['maxProfitPerTicket'] != null ? +profitForm['maxProfitPerTicket'] : null,
      'logoURL': extendedOptionsForm['logoURL'],
      'tssServerHost': this.settings.tssServerHost, // not used
      'tssServerPort': this.settings.tssServerPort, // not used
      'printTicketTitle': shopForm['printTicketTitle'],
    };
    return settings;
  }

  private generateJackpotFromForm(): Jackpot {
    const jackpotConfigForm: any = FormUtils.getFormValues(this.colsComponent.forms, 'smartrace.settings.header.jackpotConfig');

    const jackpot: Jackpot = {
      'jackpotID': this.selectedJackpot.jackpotID,
      'franchiserID': this.selectedJackpot.franchiserID,
      'applicationID': this.selectedJackpot.applicationID,
      'value': this.selectedJackpot.value,
      'minInitialValue': jackpotConfigForm['minInitialValue'] ? +jackpotConfigForm['minInitialValue'] : 0,
      'maxInitialValue': jackpotConfigForm['maxInitialValue'] ? +jackpotConfigForm['maxInitialValue'] : 0,
      'minValue': jackpotConfigForm['minValue'] ? +jackpotConfigForm['minValue'] : 0,
      'maxValue': jackpotConfigForm['maxValue'] ? +jackpotConfigForm['maxValue'] : 0,
      'hitValue': this.selectedJackpot.hitValue,
      'lastHitTime': this.selectedJackpot.lastHitTime,
      'lastHitStation': this.selectedJackpot.lastHitStation,
      'lastHitValue': this.selectedJackpot.lastHitValue,
      'name': this.selectedJackpot.name,
      'autoRaise': this.selectedJackpot.autoRaise
    };
    return jackpot;
  }

  resetJackpotForm(selectedJackpotId?: string) {
    if (selectedJackpotId != null && +selectedJackpotId !== JackpotService.NO_JACKPOT_ID) {
      this.selectedJackpot = this.jackpots.filter(jp => jp.jackpotID === +selectedJackpotId)[0];
    } else if (this.settings.jackpotID != null && +selectedJackpotId !== JackpotService.NO_JACKPOT_ID) {
      this.selectedJackpot = this.jackpots.filter(jp => jp.jackpotID === this.settings.jackpotID)[0];
    } else {
      this.selectedJackpot = null;
    }
    if (this.selectedJackpot != null) {
      this.jackpotQuestionContainer = new QuestionContainer('smartrace.settings.header.jackpotConfig', [
        new TextboxQuestion({
          key: 'value',
          label: 'smartrace.settings.value',
          value: this.selectedJackpot.value,
          type: 'number',
          disabled: true,
        }),
        new TextboxQuestion({
          key: 'jackpotMinStake',
          label: 'smartrace.settings.jackpotMinStake',
          value: this.settings.jackpotMinStake,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'jackpotMinPercentage',
          label: 'smartrace.settings.jackpotMinPercentage',
          value: this.settings.jackpotMinPercentage,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'jackpotMaxPercentage',
          label: 'smartrace.settings.jackpotMaxPercentage',
          value: this.settings.jackpotMaxPercentage,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'minInitialValue',
          label: 'smartrace.settings.minInitialValue',
          value: this.selectedJackpot.minInitialValue,
          type: 'number',
          readonly: !this.jackpotService.isStationJackpot(this.selectedJackpot, this.settings.stationID)
        }),
        new TextboxQuestion({
          key: 'maxInitialValue',
          label: 'smartrace.settings.maxInitialValue',
          value: this.selectedJackpot.maxInitialValue,
          type: 'number',
          readonly: !this.jackpotService.isStationJackpot(this.selectedJackpot, this.settings.stationID)
        }),
        new TextboxQuestion({
          key: 'minValue',
          label: 'smartrace.settings.minValue',
          value: this.selectedJackpot.minValue,
          type: 'number',
          readonly: !this.jackpotService.isStationJackpot(this.selectedJackpot, this.settings.stationID)
        }),
        new TextboxQuestion({
          key: 'maxValue',
          label: 'smartrace.settings.maxValue',
          value: this.selectedJackpot.maxValue,
          type: 'number',
          readonly: !this.jackpotService.isStationJackpot(this.selectedJackpot, this.settings.stationID)
        })]);
      if (this.sessionService.hasPermission(Permission.initJackpot)
        && this.jackpotService.isStationJackpot(this.selectedJackpot, this.settings.stationID)
        && this.selectedJackpot.jackpotID !== -1) {
        this.jackpotQuestionContainer.addSubmit('smartrace.settings.initJackpot', this.resetJackpotValues.bind(this));
      }
    } else {
      this.jackpotQuestionContainer = null;
    }
    this.questions = this.jackpotQuestionContainer ? [...this.gameQuestions, this.jackpotQuestionContainer] : this.gameQuestions;
  }

  resetJackpotValues() {
    this.jackpotService.init(this.selectedJackpot).subscribe((jackpot) => {
this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
      const index = this.jackpots.map(jp => jp.jackpotID).indexOf(jackpot.jackpotID);
      this.jackpots[index] = jackpot;
      this.loading = false;
      this.resetJackpotForm(jackpot.jackpotID);
    }, () => {
      this.loading = false;
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
    });
  }

  reset() {
    this.resetSettingsQuestions();
    this.resetJackpotForm();
    this.questions = this.jackpotQuestionContainer ? [...this.gameQuestions, this.jackpotQuestionContainer] : this.gameQuestions;
  }

  mapJackpotName(id: number, name: string) {
    if (id === JackpotService.NO_JACKPOT_ID) {
      return this.translationService.translate('smartrace.settings.noJackpot');
    } else if (name === this.settings.stationID.toString()) {
      return this.translationService.translate('smartrace.settings.ownJackpot');
    }
    return name;
  }

  saveRaceCycle(formValue: any) {
    this.loading = true;
    this.gameService.saveRaceCycle(formValue.raceCycle).subscribe(() => {
this.snackBar.open(this.translationService.translate('snackBar.saved'), null, SUCCESS_SNACK_CONFIG);
      this.loading = false;
    }, () => {
      this.loading = false;
      this.snackBar.open(this.translationService.translate('snackBar.error'), null, ERROR_SNACK_CONFIG);
      this.reset();
    });
  }

  resetSettingsQuestions() {

    const raceCycleQuestion: QuestionContainer = new QuestionContainer('smartrace.settings.header.raceCycle', [
      new RaceCycleQuestion({
        key: 'raceCycle',
        value: this.gameCycles,
        editPermission: Permission.changeRF2Settings
      })]);

    if (this.sessionService.hasPermission(Permission.changeRF2Settings)) {
      raceCycleQuestion.addSubmit('smartrace.settings.raceCycle.save', this.saveRaceCycle.bind(this));
    }

    this.gameQuestions = [
      new QuestionContainer('smartrace.settings.header.stake', [
        new TextboxQuestion({
          key: 'minStakePerTicket',
          label: 'smartrace.settings.minStakePerTicket',
          value: this.settings.minStakePerTicket,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'minStakePerTip',
          label: 'smartrace.settings.minStakePerTip',
          value: this.settings.minStakePerTip,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'maxStakePerTip',
          label: 'smartrace.settings.maxStakePerTip',
          value: this.settings.maxStakePerTip,
          type: 'number'
        }),
      ]),
      raceCycleQuestion,
      new QuestionContainer('smartrace.settings.header.profit', [
        new TextboxQuestion({
          key: 'maxProfitPerTicket',
          label: 'smartrace.settings.maxProfitPerTicket',
          value: this.settings.maxProfitPerTicket,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'disburseLimit',
          label: 'smartrace.settings.disburseLimit',
          value: this.settings.disburseLimit,
          type: 'number'
        }),
        new TextboxQuestion({
          key: 'validDays',
          label: 'smartrace.settings.validDays',
          value: this.settings.validDays,
          type: 'number'
        }),
      ]),
      new QuestionContainer('smartrace.settings.header.shop', [
        new CheckboxQuestion({
          key: 'printTicketTitle',
          label: 'smartrace.settings.printTicketTitle',
          value: this.settings.printTicketTitle,
          viewPermission: Permission.viewRF2Gradation,
          editPermission: Permission.editRF2Gradation
        }),
        new TextboxQuestion({
          key: 'stakeButtonFactor',
          label: 'smartrace.settings.stakeButtonFactor',
          value: this.settings.stakeButtonFactor,
          type: 'number'
        }),
        new CheckboxQuestion({
          key: 'enableCashbook',
          label: 'smartrace.settings.enableCashbook',
          value: this.settings.enableCashbook
        }),
        new CheckboxQuestion({
          key: 'hasSpecialBet',
          label: 'smartrace.settings.hasSpecialBet',
          value: this.settings.hasSpecialBet
        }),
        new CheckboxQuestion({
          key: 'singleTipPerTicket',
          label: 'smartrace.settings.singleTipPerTicket',
          value: this.settings.singleTipPerTicket
        }),
        new TextboxQuestion({
          key: 'taxRate',
          label: 'smartrace.settings.taxRate',
          value: this.settings.taxRate,
          type: 'number', // TODO: min, max
          editPermission: Permission.editGamesTaxRates,
        }), new TextboxQuestion({
          key: 'handlingFee',
          label: 'smartrace.settings.handlingFee',
          value: this.settings.handlingFee,
          type: 'number', // TODO: min, max
          editPermission: Permission.editGamesTaxRates,
        })
      ], null, this.isShop() ? null : Permission.notAllowed),
      new QuestionContainer('smartrace.settings.header.extendedOptions', [
        new CheckboxQuestion({
          key: 'weakGraphicCard',
          label: 'smartrace.settings.weakGraphicCard',
          value: this.settings.weakGraphicCard,
          viewPermission: Permission.viewRF2Gradation,
          editPermission: Permission.editRF2Gradation
        }), new TextboxQuestion({
          key: 'winnerGraduation',
          label: 'smartrace.settings.winnerGraduation',
          value: this.settings.winnerGraduation,
          type: 'number', // TODO: min, max
          viewPermission: Permission.viewRF2Gradation
        }), new TextboxQuestion({
          key: 'podiumGraduation',
          label: 'smartrace.settings.podiumGraduation',
          value: this.settings.podiumGraduation,
          type: 'number', // TODO: min, max
          viewPermission: Permission.viewRF2Gradation
        }), new TextboxQuestion({
          key: 'doubleGraduation',
          label: 'smartrace.settings.doubleGraduation',
          value: this.settings.doubleGraduation,
          type: 'number', // TODO: min, max
          viewPermission: Permission.viewRF2Gradation
        }), new TextboxQuestion({
          key: 'tripleFactor',
          label: 'smartrace.settings.tripleFactor',
          value: this.settings.tripleFactor,
          type: 'number', // TODO: min, max
          viewPermission: Permission.viewRF2Gradation
        }), new TextareaQuestion({
          key: 'logoURL',
          label: 'smartrace.settings.logoURL',
          value: this.settings.logoURL,
          minRows: 3,
          viewPermission: Permission.editLogoURLs
        })
      ]),
      new QuestionContainer('smartrace.settings.header.jackpot', [
        new DropdownQuestion({
          key: 'jackpotID',
          label: 'smartrace.settings.jackpotID',
          value: (this.settings.jackpotID || JackpotService.NO_JACKPOT_ID).toString(),
          options: this.jackpots.map(jp => ({
            'key': jp.jackpotID.toString(),
            'value': this.mapJackpotName(jp.jackpotID, jp.name)
          })),
          onChange: this.resetJackpotForm.bind(this)
        })])];
  }

  private isShop() {
    return this.stationType === StationType.SHOP;
  }
}
