<div class="action"
     [matTooltip]="'cashTimeline.copyNumber' | translate"
     (click)="clipboard(params.data.link.split(':')[1])" *ngIf="params.data.link">
  <mat-icon>file_copy</mat-icon>
</div>

<div class="action" mat-flat-button (click)="btnClickedHandler($event)" *ngIf="params.data.link">
  <mat-icon [matTooltip]="'cashTimeline.showCustomer' | translate" *ngIf="prefix === 'CID'">person</mat-icon>

  <mat-icon [matTooltip]="'cashTimeline.showCashTicket' | translate" *ngIf="prefix === 'CTN'">request_quote</mat-icon>

  <mat-icon [matTooltip]="'cashTimeline.showTicket' | translate" *ngIf="prefix === 'TN'">fact_check</mat-icon>

  <mat-icon [matTooltip]="'cashTimeline.showTimeline' | translate" *ngIf="prefix === 'UAID'">cloud_circle</mat-icon>

  <mat-icon [matTooltip]="'cashTimeline.showCustomer' | translate" *ngIf="prefix === 'CID' || prefix === 'UAID'" class="exit">exit_to_app</mat-icon>
</div>
