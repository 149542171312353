<mat-toolbar color="accent" class="toolbar">
  <button mat-icon-button
    (click)="sessionService.menuObs.next(sessionService.menuObs.value === 'narrow' ? 'wide' : 'narrow')">
    <mat-icon>menu</mat-icon>
  </button>
  <h1 (click)="enableSearch()" [class.search]="search">{{search ? '' : breadcrumb}}</h1>
  <mat-form-field class="search fill" *ngIf="search" [floatLabel]="never" (click)="enableSearch()">
    <input [matAutocomplete]="auto" #searchInput="matInput" type="text" focused="true" value="" matInput
      (keyup.enter)="enter()" [formControl]="searchCtrl">
    <span matPrefix>
      <mat-icon matSuffix>search</mat-icon>
    </span>
    <mat-autocomplete class="search-autocomplete" (optionSelected)="select($event)" #auto="matAutocomplete">
      <mat-option *ngFor="let option of searchService.result" [value]="option">
        <a mat-list-item [routerLink]="option.link">
          <img *ngIf="option.icon" [src]="'/assets/img/icons/'+option.icon"><span>{{option.label | translate}}</span>
        </a>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <button *ngIf="showBackToHome" (click)="backToHome()" mat-raised-button>Exit Live-Admin</button>&nbsp;&nbsp;
  <button *ngIf="!showBackToHome" mat-icon-button (click)="toggleSearch()">
    <mat-icon *ngIf="!search">search</mat-icon>
    <mat-icon *ngIf="search">close</mat-icon>
  </button>
  <button mat-icon-button (click)="toggleWidth()" *ngIf="!mobileService.isMobile">
    <mat-icon *ngIf="sessionService.treeObs.value === 'wide'">width_normal</mat-icon>
    <mat-icon *ngIf="sessionService.treeObs.value === 'narrow'">width_full</mat-icon>
  </button>
  <button mat-icon-button (click)="duplicate()" *ngIf="!mobileService.isMobile">
    <mat-icon>dynamic_feed</mat-icon>
  </button>
  <mat-menu #appMenu="matMenu" yPosition="above">
    <button mat-button (click)="router.navigate([routes.HOME, routes.HomeMenu.CHANGE_PASSWORD])">
      {{'home.menu.changePassword' | translate}}
    </button>
    <p style="margin-bottom: 0; line-height: 12px; padding-left: 6px">
      <span style="font-size: 10px;">Version {{sessionService.version}}</span><br>
      <span style="font-size: 10px;">{{buildTime.replace('T','  ')}}</span><br>
      <span style="font-size: 10px; cursor:pointer; display: flex"
            [matTooltip]="httpService.getToken()"
            (click)="clipboard(httpService.getToken(), snackBar)"
            *ngIf="sessionService.hasPermission(permissions.editAdvancedAdminSettings, permissions.seeBetaFeatures)">
        Auth Token&nbsp;<mat-icon style="font-size: 10px">file_copy</mat-icon>
      </span>
    </p>
  </mat-menu>
  <button class="user-button" mat-button [matMenuTriggerFor]="appMenu" *ngIf="!mobileService.isMobile"
    [matTooltipClass]="'multiline-tooltip'"
    [matTooltip]="'Last Login: ' + (sessionService.user?.lastLogin | date: 'medium') + '\nTime Remaining: '+sessionService.timeRemaining">
    <div class="icon-container">
      <mat-spinner diameter="36" [mode]="'determinate'" [value]="sessionService.sessionExpiryProgress"></mat-spinner>
      <mat-icon class="person-icon">person</mat-icon>
    </div>
    <span class="user-name">{{sessionService.user?.displayName}}</span>
  </button>
  <button class="mobile-user-button" mat-icon-button [matMenuTriggerFor]="appMenu" *ngIf="mobileService.isMobile"
    [matTooltipClass]="'multiline-tooltip'"
    [matTooltip]="'Last Login: ' + (sessionService.user?.lastLogin | date: 'medium') + '\nTime Remaining: '+sessionService.timeRemaining">
    <div class="icon-container">
      <mat-spinner diameter="36" [mode]="'determinate'" [value]="sessionService.sessionExpiryProgress"></mat-spinner>
      <mat-icon class="person-icon">person</mat-icon>
    </div>
  </button>
  <button mat-icon-button (click)="logoutUser()">
    <mat-icon class="logout-icon">power_settings_new</mat-icon>
  </button>
  <button mat-icon-button
    (click)="sessionService.treeObs.next(sessionService.treeObs.value === 'narrow' ? 'wide' : 'narrow')">
    <mat-icon>account_tree</mat-icon>
  </button>
</mat-toolbar>
