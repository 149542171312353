import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {SessionService} from './session.service';
import {SystemConfig} from '../config/system-config';
import {Observable} from 'rxjs';
import {QueryFilter} from '../domain/query-filter';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CashTicketOverviewService {

  private lastCashTicketStationsQueryFilter: QueryFilter;
  public lastCashTicketStationsRowCount: number;

  constructor(public httpService: HttpService,
              public sessionService: SessionService) {
  }

  fetchCashTicketStations(query: QueryFilter): Observable<any[]> {
    this.lastCashTicketStationsRowCount = null;
    return this.httpService.post(SystemConfig.Endpoints.CASH_TICKET_STATIONS, query).pipe(tap((rows: any[]) => {
      this.lastCashTicketStationsQueryFilter = query;
      this.lastCashTicketStationsRowCount = rows.length;
      console.log(rows);
    }));
  }

  fetchCashTickets(query: QueryFilter): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.CASH_TICKETS, query);
  }

  redeemManually(query: QueryFilter): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.CASH_TICKET_INVALIDATE, query);
  }

  redeemAndPayinManually(query: QueryFilter): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.CASH_TICKET_INVALIDATE_AND_PAYIN, query);
  }

  lockPayoutManually(query: QueryFilter): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.CASH_TICKET_CHANGE_STATUS, query);
  }
}
