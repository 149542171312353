import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {ColsComponent} from '../../cols/cols.component';
import {DropdownValue} from '../../dynamic-form/dropdown-value';
import {UserManagementService} from '../user-management/user-management.service';
import {DatePipe} from '@angular/common';
import {CustomerTreeService} from '../../services/customer-tree.service';
import {ERROR_SNACK_CONFIG, sortAlphabetically} from '../../../utils';
import {QuestionContainer} from '../../domain/question-container';
import {DropdownQuestion} from '../../domain/question-dropdown';
import {FormUtils} from '../../utils/form-utils';
import {Permission} from '../../domain/permission';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {SystemConfig} from '../../config/system-config';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'vit-virtual-mode',
  templateUrl: './virtual-mode.component.html',
  styleUrls: ['./virtual-mode.component.scss']
})
export class VirtualModeComponent implements OnInit {

  @ViewChild(ColsComponent, { static: true })
  colsComponent: ColsComponent;

  selectionQuestions = [];
  userQuestions = [];
  franchisersAll: DropdownValue[] = [];
  franchisers: DropdownValue[] = [];
  selectedFranchiserID: string;
  questions = [];
  loading = false;

  Permission = Permission;

  constructor(public sessionService: SessionService,
              public userService: UserService,
              public translationService: TranslationService,
              public userManagementService: UserManagementService,
              public datePipe: DatePipe,
              public customerTreeService: CustomerTreeService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.customerTreeService.tree.subscribe(node => {
      if (node) {
        this.franchisersAll.push(...this.sessionService.franchisers.map(f => {
          return {
            'key': f.node.id.toString(), 'value': f.node.label
          };
        }));
        this.franchisers = JSON.parse(JSON.stringify(this.franchisersAll));
        sortAlphabetically(this.franchisersAll, 'value');
        this.franchisersAll.unshift({value: this.translationService.translate('searchForm.all'), key: '0'});
        this.reset();
      }
    });
  }

  reset() {
    this.userManagementService.fetchUsers(+this.franchisersAll[0].key).subscribe((options) => {
      this.setSelectionQuestions(options);
      this.questions = this.selectionQuestions;
    });
  }

  private setSelectionQuestions(options) {
    this.selectionQuestions = [new QuestionContainer('userManagement.header.users', [
      new DropdownQuestion({
        key: 'franchiserID',
        label: 'userManagement.franchiserID',
        value: '0',
        clear: true,
        clearTo: '0',
        options: this.franchisersAll,
        onChange: this.franchiserChanged.bind(this)
      }),
      new DropdownQuestion({
        key: 'user',
        label: 'userManagement.user',
        value: null,
        clear: true,
        options: options
      })])];

      this.selectionQuestions[0].addSubmit('virtualMode.start',
        this.enterVirtualMode.bind(this), localStorage.getItem(SystemConfig.LocalStorageConstants.VIRTUAL_USER)
          ? Permission.notAllowed : Permission.editAdvancedAdminSettings);
  }

  private async enterVirtualMode() {
    console.log('virtual mode');
    const user = FormUtils.getForm(this.colsComponent.forms, 'userManagement.header.users').controls['user'].value;
    if (user) {
      localStorage.setItem(SystemConfig.LocalStorageConstants.VIRTUAL_USER, user);
      await this.userService.tokenLogin().then(success => location.replace(location.origin));
    } else {
      this.snackBar.open(this.translationService.translate('virtualMode.pleaseSelectUser'), null, ERROR_SNACK_CONFIG);
    }
  }

  private franchiserChanged(franchiserID: number) {
    this.selectedFranchiserID = franchiserID.toString();
    // TODO: find stationID dropdown dynamically!!
    this.userManagementService.fetchUsers(franchiserID).subscribe((options) => {
      (this.selectionQuestions[0].questions[1] as DropdownQuestion).options = options;
      this.selectionQuestions[0].questions[1] = Object.assign({}, this.selectionQuestions[0].questions[1]);
      FormUtils.getForm(this.colsComponent.forms, 'userManagement.header.users').controls['user'].patchValue(null);
    });
  }

}
