import {Component, Inject, OnInit} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {TranslationService} from '../services/translation.service';

@Component({
  selector: 'vit-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  title;
  message;
  yes;
  no;
  hideNo = false;
  secureCode = false;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any = { secureCode: false },
              public translationService: TranslationService) {
    this.secureCode = data?.secureCode;
  }

  ngOnInit() {
    this.data = this.data || {};
    this.title = this.data.title || this.translationService.translate('confirmDialog.defaultTitle');
    this.message = this.data.message || this.translationService.translate('confirmDialog.defaultMessage');
    this.yes = this.data.yes || this.translationService.translate('confirmDialog.defaultYes');
    this.no = this.data.no || this.translationService.translate('confirmDialog.defaultNo');
    this.hideNo = !!this.data.hideNo;
  }
}
