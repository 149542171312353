import {Component, OnInit} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {StationUtils} from '../../utils/station-utils';
import {TreeNodeType} from '../../domain/tree-node-type';
import {getContextMenuItems, isNumber} from 'utils';
import {BalanceMonthService} from './balance-month.service';
import {enrichByStationId, loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {GridReadyEvent} from 'ag-grid-community';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-balance-month',
  templateUrl: './balance-month.component.html',
  styleUrls: ['./balance-month.component.scss']
})
export class BalanceMonthComponent implements OnInit {

  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;
  columnDefs;
  gridOptions;
  rowData;
  detailParams;

  query: QueryFilter;
  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public balanceMonthService: BalanceMonthService,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public datePipe: DatePipe,
              public httpService: HttpService,
              public decimalPipe: DecimalPipe) {
    this.columnDefs = this.generateColumnDefs();
    this.detailParams = this.generateDetailParams();
    this.gridOptions = this.generateGridOptions();
  }

  onGridReady(event: GridReadyEvent) {
    loadColumnVisibility('balanceMonth', event);
  }

  ngOnInit() {
  }

  fetch(query: QueryFilter) {
    if (query) {
      this.query = query;
      this.rowData = this.balanceMonthService.fetchMonthBalanceStations(query);
    } else {
      this.query = null;
      this.rowData = null;
    }
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('balanceMonth.type'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'type',
        cellClass: 'type',
        width: 23,
        cellRenderer: function (params) {
          const icon = StationUtils.getIcon(TreeNodeType.STATION, params.data.type, false, params.data.softLocked, params.data.hardLocked);
          const img = '<img src=\'/assets/img/icons/' + icon + '\'>';
          return params.data.stationID === 0 ? '' : img;
        }
      },
      {
        headerName: this.translationService.translate('balanceMonth.stationID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationID',
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40,
        headerTooltip: this.translationService.translate('balanceMonth.stationID')
      },
      {
        headerName: this.translationService.translate('balanceMonth.name'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'name',
        width: BalanceMonthComponent.COLUMN_WIDTH_LARGE,
      }
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: true,
      detailRowHeight: 160,
      rowClassRules: {
        'bold': (params) => params.data.stationID === 0,
        'text-align-right': (params) => isNumber(params.data.value)
      },
      onCellClicked: (params) => {
        params.node.setExpanded(!params.node.expanded);
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }


  generateDetailParams() {
    return {
      detailGridOptions: {
        columnDefs: [
          {
            headerName: this.translationService.translate('balanceMonth.monthCode'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'monthCode',
            width: BalanceMonthComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balanceMonth.monthCode')
          },
          {
            headerName: this.translationService.translate('balanceMonth.numTickets'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'numTickets',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: BalanceMonthComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balanceMonth.numTickets')
          },
          {
            headerName: this.translationService.translate('balanceMonth.cashIn'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'cashIn',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: BalanceMonthComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balanceMonth.cashIn')
          },
          {
            headerName: this.translationService.translate('balanceMonth.cashOut'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'cashOut',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: BalanceMonthComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balanceMonth.cashOut')
          },
          {
            headerName: this.translationService.translate('balanceMonth.credit'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'credit',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: BalanceMonthComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balanceMonth.credit')
          },
          {
            headerName: this.translationService.translate('balanceMonth.bonus'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'bonus',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: BalanceMonthComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balanceMonth.bonus')
          },
          {
            headerName: this.translationService.translate('balanceMonth.cashInOnline'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'cashInOnline',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: BalanceMonthComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balanceMonth.cashInOnline_Tooltip')
          },
          {
            headerName: this.translationService.translate('balanceMonth.stake'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'stake',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: BalanceMonthComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balanceMonth.stake')
          },
          {
            headerName: this.translationService.translate('balanceMonth.stakeTax'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'stakeTax',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: BalanceMonthComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balanceMonth.stakeTax')
          },
          {
            headerName: this.translationService.translate('balanceMonth.winnings'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'winnings',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: BalanceMonthComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balanceMonth.winnings')
          },
          {
            headerName: this.translationService.translate('balanceMonth.winTax'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'winTax',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: BalanceMonthComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balanceMonth.winTax')
          },
          {
            headerName: this.translationService.translate('balanceMonth.calculatedBalance'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'calculatedBalance',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: BalanceMonthComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balanceMonth.calculatedBalance')
          }
        ],
        onCellClicked: (params) => {
          params.node.setExpanded(!params.node.expanded);
        }
      },
      getDetailRowData: (params) => {
        this.query.stationID = params.data.stationID;
        this.balanceMonthService.fetchMonthBalances(this.query).subscribe(data => {
          params.successCallback(enrichByStationId(data, params.data.stationID));
        });
      }
    };
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }

}
