<div class="container" *ngIf="!rowData">
  <mat-card *ngFor="let questionContainer of questions">
    <mat-card-header>{{questionContainer.label | translate}}</mat-card-header>
    <mat-card-content>
      <vit-dynamic-form [questionContainer]="questionContainer"></vit-dynamic-form>
    </mat-card-content>
  </mat-card>
</div>
<ag-grid-angular
  *ngIf="rowData"
  (columnVisible)="storeColumnVisibilityFn('jackpotOverview', $event)"
  class="ag-theme-balham"
  style="width: 100%; flex: 1;"
  [gridOptions]="gridOptions"
  [rowData]="rowData | async"
  [columnDefs]="columnDefs"
  [detailCellRendererParams]="detailParams"
  (gridReady)="onGridReady($event)">
</ag-grid-angular>
<mat-toolbar class="footer">
  <vit-buttons>
    <button type="reset"
            (click)="reset()"
            mat-flat-button
            color="secondary">
      {{'jackpotOverview.footer.reset' | translate}}
    </button>
    <button type="submit"
            [disabled]="!forms?.first?.form.valid"
            (click)="show()"
            mat-flat-button
            color="accent">
      {{'jackpotOverview.footer.show' | translate}}
    </button>
  </vit-buttons>
</mat-toolbar>
