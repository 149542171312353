import {Injectable} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {SessionService} from '../../services/session.service';
import {Observable, tap} from 'rxjs';
import {QueryFilter} from '../../domain/query-filter';
import {SystemConfig} from '../../config/system-config';

@Injectable({
  providedIn: 'root'
})
export class HoldOverviewFranchiserService {

  constructor(public httpService: HttpService,
              public sessionService: SessionService) {
  }

  fetchBalances(query: QueryFilter): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.HOLD_OVERVIEW_FRANCHISER_V2, query).pipe(tap(arr => arr.forEach(el => {
      el.name = el.stationName ? el.stationName : el.franchiserName;
    })));
  }

}
