import { QueryList } from '@angular/core';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import {UntypedFormGroup} from '@angular/forms';

export class FormUtils {
  static getFormValues(formList: QueryList<DynamicFormComponent>, label: string): any {
    const f = formList.find(sf => sf.questionContainer.label === label);
    return f ? f.form.getRawValue() : null;
  }
  static getForm(formList: QueryList<DynamicFormComponent>, label: string): UntypedFormGroup {
    const f = formList.find(sf => sf.questionContainer.label === label);
    return f ? f.form : null;
  }
}
