<vit-header *ngIf="sessionService.isLoggedIn()"
            [selectedMenu]="sessionService.selectedMenuElement"
            (logout)="hideSidenavIfMobile(true)"
            (menuToggle)="toggleMenu($event)"
            (toggleTree)="toggleTree($event)"></vit-header>
<mat-sidenav-container [class.login]="!sessionService.isLoggedIn()">
  <mat-sidenav class="menu"
               #sideMenu
               [opened]="sessionService.isLoggedIn() && (!mobileService.isMobile || !sessionService.selectedMenuElement)"
               [mode]="mobileService.isMobile ? 'over' : 'side'"
               [fixedInViewport]="true"
               fixedTopGap="56">
    <vit-menu (onNavigation)="hideSidenavIfMobile($event)"></vit-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
  <mat-sidenav #tree
               [class.large-tree]="sessionService.largeTree"
               [class.tree]="!sessionService.largeTree"
               [opened]="sessionService.isLoggedIn() && (!mobileService.isMobile)"
               [mode]="mobileService.isMobile ? 'over' : 'side'"
               [fixedInViewport]="true"
               fixedTopGap="56"
               position="end">
    <vit-tree (hide)="tree.opened = false"></vit-tree>
  </mat-sidenav>
</mat-sidenav-container>
<div class="vit-loading" *ngIf="sessionService.loading">
  <svg class="spinner" viewBox="25 25 50 50">
    <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
  </svg>
  <vit-timer *ngIf="sessionService.showLoadingTimer()"></vit-timer>
</div>
