import {Component, Input, OnInit} from '@angular/core';
import {TicketState} from '../domain/ticket-state';

@Component({
  selector: 'vit-ticket-status',
  templateUrl: './ticket-status.component.html',
  styleUrls: ['./ticket-status.component.scss']
})
export class TicketStatusComponent implements OnInit {

  @Input()
  ticket: any;

  status: TicketState;

  constructor() {
  }

  ngOnInit() {
    if (!this.ticket.storno && this.ticket.cashOut) {
      this.status = TicketState.CASHOUT;
    } else if (!this.ticket.calculated && !this.ticket.storno) {
      if (this.ticket.preCalcStatus === 1) {
        this.status = TicketState.PENDING_1;
      } else if (this.ticket.preCalcStatus === 2) {
        this.status = TicketState.PENDING_2;
      } else {
        this.status = TicketState.PENDING;
      }
    } else if (this.ticket.calculated && !this.ticket.storno && !this.ticket.won) {
      this.status = TicketState.LOST;
    } else if (this.ticket.calculated && !this.ticket.storno && this.ticket.won && this.ticket.disbursed) {
      this.status = TicketState.DISBURSED;
    } else if (this.ticket.calculated && !this.ticket.storno && this.ticket.won) {
      this.status = TicketState.WON;
    } else if (this.ticket.storno) {
      this.status = TicketState.STORNO;
    }
  }

}
