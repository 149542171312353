<table class="basics">
  <tr>
    <td>{{'ticketDetail.ticketNumber' | translate}}</td>
    <td>{{ticket?.number}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.code' | translate}}</td>
    <td>{{ticket?.code ? ticket?.code : '---'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.stationID' | translate}}</td>
    <td>{{ticket?.stationID}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.stakeTotalGross' | translate}}</td>
    <td>{{(ticket?.stake + ticket?.tax) | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td class="indented">{{'ticketDetail.tax' | translate}}</td>
    <td>{{ticket?.tax | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td class="indented">{{'ticketDetail.stake' | translate}}</td>
    <td>{{ticket?.stake | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.profitTotalGross' | translate}}</td>
    <td>{{(ticket?.profit + ticket?.winTax + ticket?.jackpotProfit) | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td class="indented">{{'ticketDetail.profitNet' | translate}}</td>
    <td>{{ticket?.profit | number: '0.2-2'}}</td>
  <tr>
  <tr>
    <td class="indented">{{'ticketDetail.jackpotProfit' | translate}}</td>
    <td>{{ticket?.jackpotProfit | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td class="indented">{{'ticketDetail.winTax' | translate}}</td>
    <td>{{ticket?.winTax | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.wonProfit' | translate}}</td>
    <td class="bold">{{(ticket?.profit + ticket?.jackpotProfit) | number: '0.2-2'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.inDate' | translate}}</td>
    <td>{{ticket?.inDate | date: 'medium'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.calcDate' | translate}}</td>
    <td>{{ticket?.calcDate | date: 'medium'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.outDate' | translate}}</td>
    <td>{{ticket?.outDate | date: 'medium'}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.pokerbet.playMode' | translate}}</td>
    <td>{{('ticketDetail.pokerbet.appSubType.' + ticket?.applicationSubType) | translate}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.pokerbet.betType' | translate}}</td>
    <td>{{(ticket?.combi ? 'ticketDetail.pokerbet.combinationBet' : 'ticketDetail.pokerbet.singleBet') | translate}}
    </td>
  </tr>
  <tr>
    <td>{{'ticketDetail.pokerbet.phase' | translate}}</td>
    <td>{{ticket?.gameStateText}}</td>
  </tr>
  <tr>
    <td>{{'ticketDetail.status' | translate}}</td>
    <td>
      <vit-ticket-status [ticket]="ticket"></vit-ticket-status>
    </td>
  </tr>
</table>
<table *ngIf="ticket?.applicationSubType === 1 || ticket?.applicationSubType === 2" class="game-info">
  <ng-container *ngFor="let game of ticketData?.games; let i = index">
    <tr>
      <td><b>{{'ticketDetail.pokerbet.playersCount' | translate}}</b></td>
      <td><b>{{game.playersCount}}</b></td>
    </tr>
    <tr>
      <td>{{'ticketDetail.eventID' | translate}}</td>
      <td>{{game.gameNumber+' ('+game.gameID+')'}}</td>
    </tr>
    <tr>
      <td>{{'ticketDetail.pokerbet.winner' | translate}}</td>
      <td>{{'ticketDetail.pokerbet.player' | translate: [game.winner]}}</td>
    </tr>
    <tr>
      <td>{{'ticketDetail.pokerbet.result' | translate}}</td>
      <td>{{game.result}}</td>
    </tr>
    <tr>
      <td>{{'ticketDetail.pokerbet.riverSuit' | translate}}</td>
      <td>{{('ticketDetail.pokerbet.suits.' + game.riverSuit) |translate}}</td>
    </tr>
    <tr>
      <td>{{'ticketDetail.pokerbet.communityCards' | translate}}</td>
      <td>{{communityCards[i]}}</td>
    </tr>
    <tr>
      <td>{{'ticketDetail.pokerbet.playerCards' | translate}}</td>
      <td>
          <span *ngFor="let playerCard of playerCards[i]; let p = index">
            <span *ngIf="ticket.applicationSubType === 1">{{(p +1) +': '+playerCard+(p !== playerCards[i].length ? ', ' : '')}}</span>
            <span *ngIf="ticket.applicationSubType === 2">{{(i+1)+''+(p)+': '+playerCard+(p !== playerCards[i].length ? ', ' : '')}}</span>
          </span>
      </td>
    </tr>
    <tr>
      <td>{{'ticketDetail.pokerbet.legend' | translate}}</td>
      <td>{{'ticketDetail.pokerbet.legendText' | translate}}</td>
    </tr>
  </ng-container>
</table>
<table *ngIf="ticket?.applicationSubType === 3">
  <ng-container *ngFor="let game of ticketData?.games; let i = index">
    <tr>
      <td>{{'ticketDetail.eventID' | translate}}</td>
      <td>{{game.gameNumber+' ('+game.gameID+')'}}</td>
    </tr>
    <tr>
      <td>{{'ticketDetail.pokerbet.playersCount' | translate}}</td>
      <td>{{game.playersCount}}</td>
    </tr>
    <tr>
      <td>{{'ticketDetail.pokerbet.result' | translate}}</td>
      <td>{{game.result}}</td>
    </tr>
    <tr>
      <td>{{'ticketDetail.pokerbet.cards1x7' | translate}}</td>
      <td>{{blackJackCards[i][0]}}</td>
    </tr>
    <tr>
      <td>{{'ticketDetail.pokerbet.cards8x13' | translate}}</td>
      <td>{{blackJackCards[i][1]}}</td>
    </tr>
    <tr>
      <td>{{'ticketDetail.pokerbet.cards14xx' | translate}}</td>
      <td>{{blackJackCards[i][2]}}</td>
    </tr>
    <tr>
      <td>{{'ticketDetail.pokerbet.legend' | translate}}</td>
      <td>{{'ticketDetail.pokerbet.legendText' | translate}}</td>
    </tr>
  </ng-container>
</table>
<table class="bets spaced">
  <tr>
    <td>{{'ticketDetail.pokerbet.type' | translate}}</td>
    <td>{{'ticketDetail.pokerbet.text' | translate}}</td>
    <td>{{'ticketDetail.pokerbet.odds' | translate}}</td>
    <td>{{'ticketDetail.pokerbet.stake' | translate}}</td>
    <td>{{'ticketDetail.pokerbet.raised' | translate}}</td>
    <td>{{'ticketDetail.pokerbet.status' | translate}}</td>
  </tr>
  <tr *ngFor="let bet of ticketData?.bets">
    <td>{{bet.betTypeText}}</td>
    <td>{{bet.text}}</td>
    <td>{{bet.odd | number: '0.2-2'}}</td>
    <td>{{bet.stake | number: '0.2-2'}}</td>
    <td><input type="checkbox" disabled [checked]="bet.raised"></td>
    <td>
      <vit-bet-status [betCalculated]="bet.calculated"
                      [betWon]="bet.won"
                      [betStorno]="bet.storno"></vit-bet-status>
    </td>
  </tr>
</table>
