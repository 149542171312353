import {Component, OnDestroy, OnInit} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {StationUtils} from '../../utils/station-utils';
import {TreeNodeType} from '../../domain/tree-node-type';
import {getContextMenuItems, isNumber, unique} from 'utils';
import {HoldOverviewFranchiserService} from './hold-overview-franchiser.service';
import {enrichByStationId, loadColumnVisibility, storeColumnVisibility} from '../../../utils';
import {GridApi, GridReadyEvent} from 'ag-grid-community';
import {BalanceService} from '../balance/balance.service';
import {CustomerTreeService} from '../../services/customer-tree.service';
import {NotificationService} from '../../notification.service';
import {BalanceFranchiserComponent} from '../balance-franchiser/balance-franchiser.component';
import {HttpService} from '../../services/http.service';

@Component({
  selector: 'vit-hold-overview-franchiser',
  templateUrl: './hold-overview-franchiser.component.html',
  styleUrls: ['./hold-overview-franchiser.component.scss']
})
export class HoldOverviewFranchiserComponent implements OnInit, OnDestroy {

  static COLUMN_WIDTH = 90;
  static COLUMN_WIDTH_LARGE = 150;
  showTestDataWarning = false;
  showDifferentCurrencyWarning = false;
  columnDefs;
  gridOptions;
  gridApi: GridApi;
  titleRow;
  detailParams;
  query: QueryFilter;
  data = [];

  storeColumnVisibilityFn = storeColumnVisibility;

  constructor(public holdOverviewFranchiserService: HoldOverviewFranchiserService,
              public translationService: TranslationService,
              public balanceService: BalanceService,
              public sessionService: SessionService,
              public notificationService: NotificationService,
              public datePipe: DatePipe,
              public httpService: HttpService,
              public customerTreeService: CustomerTreeService,
              public decimalPipe: DecimalPipe) {
    this.columnDefs = this.generateColumnDefs();
    this.detailParams = this.generateDetailParams();
    this.gridOptions = this.generateGridOptions();
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    loadColumnVisibility('hold-overview-franchiser', event);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  updateIntoEuro() {
    this.query.intoEuro = true;
    this.data = [];
    this.fetch(this.query);
  }

  updateIgnoreTest() {
    this.query.ignoreTest = true;
    this.data = [];
    this.fetch(this.query);
  }

  fetch(query: QueryFilter) {
    this.showTestDataWarning = false;
    this.showDifferentCurrencyWarning = false;
    if (!query) {
      this.data = [];
      this.query = null;
      if (this.gridApi) {
        this.gridApi.setRowData([]);
      }
      return;
    }

    const timeFrameChanged = query && this.query && query?.dateFrom !== this.query?.dateFrom;
    this.query = query;
    let tempRowData = [];

    const franchiserID = query.franchiserID !== 0 ? query.franchiserID : this.sessionService.user.franchiserID;
    if (query && !timeFrameChanged && this.data.length > 0 && query.franchiserID != null) {
      tempRowData = this.data.filter(n => n.mFrID === franchiserID || n.franchiserID === franchiserID);
      tempRowData.sort((n1, n2) => !n2.franchiserName || (n1.franchiserName && n1.franchiserName.toLowerCase() < n2.franchiserName.toLowerCase()) ? 1 : -1);
      tempRowData.sort((n1, n2) => n1.stationId > n2.stationId ? -1 : 1);
      this.customerTreeService.showTreeNode.next({id: query.franchiserID, type: TreeNodeType.FRANCHISER});
    }

    if (tempRowData.length > 0 && !!this.data.find(n => n.franchiserID === franchiserID)) {
      tempRowData.sort((n1, n2) => !n2.franchiserName || (n1.franchiserName && n1.franchiserName.toLowerCase() < n2.franchiserName.toLowerCase()) ? 1 : -1);
      tempRowData.sort((n1, n2) => n1.stationId > n2.stationId ? -1 : 1);
      this.gridApi.setRowData(tempRowData.filter(rd => rd.type !== -2 && !(rd.franchiserID === franchiserID && rd.type === -1)));
      this.gridApi.setPinnedTopRowData(this.getSumRow(tempRowData, franchiserID, query));
      return;
    }

    this.holdOverviewFranchiserService.fetchBalances(this.query).subscribe(rowData => {
      this.data.push(...rowData);
      this.data = unique(this.data, ['franchiserID', 'mFrID', 'stationID', 'type']);

      rowData = this.data.filter(n => n.mFrID === franchiserID || n.franchiserID === franchiserID);
      rowData.sort((n1, n2) => !n2.franchiserName || (n1.franchiserName && n1.franchiserName.toLowerCase() < n2.franchiserName.toLowerCase()) ? 1 : -1);
      rowData.sort((n1, n2) => n1.stationId > n2.stationId ? -1 : 1);
      this.gridApi.setRowData(rowData.filter(rd => rd.type !== -2 && !(rd.franchiserID === franchiserID && rd.type === -1)));
      this.gridApi.setPinnedTopRowData(this.getSumRow(rowData, franchiserID, query));
    });
  }

  private getSumRow(tempRowData: any[], sumRowFrId: number, query: QueryFilter) {
    let sumRow = tempRowData.find(n => n.franchiserID === sumRowFrId && n.type === -1);
    if (!sumRow) {
      return [];
    }
    sumRow = JSON.parse(JSON.stringify(sumRow));
    sumRow.name = 'SUM (' + (tempRowData.length - 1) + ')';
    if (!query.ignoreTest && sumRow?.includesTestData) {
      this.showTestDataWarning = true;
    }
    if (!query.intoEuro && sumRow?.hasDifferentCurrencies) {
      this.gridOptions.columnApi.setColumnVisible('currencyCode', true);
      this.showDifferentCurrencyWarning = true;
    }
    return [sumRow];
  }

  generateColumnDefs() {
    return [
      {
        headerName: this.translationService.translate('holdOverview.type'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'type',
        cellClass: 'type',
        width: 23,
        cellRenderer: function (params) {
          if (!params.data) {
            return '';
          }
          const icon = StationUtils.getIcon(params.data.stationID ? TreeNodeType.STATION : TreeNodeType.FRANCHISER, params.data.type,
            false, params.data.softLocked, params.data.hardLocked);
          const img = '<img style="cursor: pointer" src=\'/assets/img/icons/' + icon + '\'>'
            + (params.data.online ? '<div class=\'online\'></div>' : '');
          return img;
        }
      },
      {
        headerName: this.translationService.translate('balance.stationID'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stationID',
        valueFormatter: (params) => params.value === 0 ? ' ' : params.value,
        cellClass: 'station-id text-align-right',
        width: 40,
        headerTooltip: this.translationService.translate('balance.stationID'),
        cellRenderer: (params) => {
          if (!params.data) {
            return '';
          }
          const eDiv = document.createElement('div');
          if (params.data.name.startsWith('SUM (')) {
            if (params.data.franchiserID !== this.sessionService.user.franchiserID) {
              eDiv.innerHTML = '<span class="my-css-class"><button mat-stroked-button class="btn-back">'
                + '▲' + '</button></span>';
              const eButton = eDiv.querySelectorAll('.btn-back')[0];
              eButton.addEventListener('click', (event) => {
                this.sessionService.treeNodeSelected.next({id: params.data.mFrID, type: TreeNodeType.FRANCHISER});
                event.stopPropagation();
                event.preventDefault();
              });
            }
          } else if (params.data.stationID === 0) {
            if (params.data.franchiserID !== this.sessionService.user.franchiserID) {
              eDiv.innerHTML = '<span class="my-css-class"><button mat-stroked-button class="btn-back">'
                + '➕' + '</button></span>';
            }
          } else {
            eDiv.innerHTML = params.data.stationID;
          }

          return eDiv;
        }
      },
      {
        headerName: this.translationService.translate('balance.name'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'name',
        width: BalanceFranchiserComponent.COLUMN_WIDTH_LARGE,
      },
      {
        headerName: this.translationService.translate('holdOverview.online'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'online',
        valueFormatter: (params) => params.data?.stationID > 0 ? (params.value === true ?
            this.translationService.translate('holdOverview.isOnline') : this.translationService.translate('holdOverview.isOffline'))
          : '',
        width: HoldOverviewFranchiserComponent.COLUMN_WIDTH,
        hide: true,
        headerTooltip: this.translationService.translate('holdOverview.online')
      },
      {
        headerName: this.translationService.translate('holdOverview.currencyCode'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'currencyCode',
        cellClassRules: {
          'currency-warning': () => this.showDifferentCurrencyWarning
        },
        width: HoldOverviewFranchiserComponent.COLUMN_WIDTH,
        hide: true,
        headerTooltip: this.translationService.translate('holdOverview.currencyCode')
      },
      {
        headerName: this.translationService.translate('holdOverview.quantity'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'quantity',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: HoldOverviewFranchiserComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.quantity')
      },
      {
        headerName: this.translationService.translate('holdOverview.stakeNet'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stakeNet',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: HoldOverviewFranchiserComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.stakeNet')
      },
      {
        headerName: this.translationService.translate('holdOverview.averageStakeNet'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'averageStakeNet',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        hide: true,
        width: HoldOverviewFranchiserComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.averageStakeNet')
      },
      {
        headerName: this.translationService.translate('holdOverview.stakeTax'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'stakeTax',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        hide: true,
        width: HoldOverviewFranchiserComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.stakeTax')
      },
      {
        headerName: this.translationService.translate('holdOverview.bonusStake'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'bonusStake',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        hide: true,
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: HoldOverviewFranchiserComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.bonusStake')
      },
      {
        headerName: this.translationService.translate('holdOverview.profitTotalGross'), sortable: true,
        filter: true,
        resizable: true,
        field: 'profitTotalGross',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: HoldOverviewFranchiserComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.profitTotalGross')
      },
      {
        headerName: this.translationService.translate('holdOverview.winTax'), sortable: true,
        filter: true,
        resizable: true,
        field: 'winTax',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: HoldOverviewFranchiserComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.winTax')
      },
      {
        headerName: this.translationService.translate('holdOverview.openPayoffGross'), sortable: true,
        filter: true,
        resizable: true,
        field: 'openPayoffGross',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: HoldOverviewFranchiserComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.openPayoffGross')
      },
      {
        headerName: this.translationService.translate('holdOverview.jackpotProfitNet'), sortable: true,
        filter: true,
        resizable: true,
        field: 'jackpotProfitNet',
        valueFormatter: this.decimalFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: HoldOverviewFranchiserComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.jackpotProfitNet')
      },
      {
        headerName: this.translationService.translate('holdOverview.hold'), sortable: true,
        filter: true,
        resizable: true,
        field: 'hold',
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: HoldOverviewFranchiserComponent.COLUMN_WIDTH,
        valueFormatter: this.decimalFormatter.bind(this)
      },
      {
        headerName: this.translationService.translate('holdOverview.holdPercent'), sortable: true,
        filter: true,
        resizable: true,
        field: 'holdPercent',
        valueFormatter: this.percentFormatter.bind(this),
        cellClass: 'text-align-right',
        cellClassRules: {
          'smaller-font': this.isLargeNumber.bind(this)
        },
        width: HoldOverviewFranchiserComponent.COLUMN_WIDTH,
        headerTooltip: this.translationService.translate('holdOverview.holdPercent')
      },
      {
        headerName: this.translationService.translate('holdOverview.identifier'),
        sortable: true,
        filter: true,
        resizable: true,
        field: 'identifier',
        hide: true,
        cellClass: 'ui-grid-cell-contents-auto',
        headerTooltip: this.translationService.translate('holdOverview.identifier')
      }
    ];
  }

  generateGridOptions() {
    return {
      getContextMenuItems: (params) => getContextMenuItems(this.httpService, this.query, params),
      masterDetail: true,
      detailRowHeight: 160,
      isRowMaster: (data) => data?.stationID !== 0,
      rowClassRules: {
        'bold': (params) => params.data?.stationID === 0,
        'text-align-right': (params) => isNumber(params.data?.value)
      },
      onCellClicked: (params) => {
        if (params.data.stationID === 0 && params.data.franchiserID !== this.sessionService.user.franchiserID) {
          this.sessionService.treeNodeSelected.next({
            type: 0,
            id: params.data.franchiserID
          });
        } else {
          params.node.setExpanded(!params.node.expanded);
        }
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        defaultToolPanel: '',
      }
    };
  }


  generateDetailParams() {
    return {
      detailGridOptions: {
        toolPanelSuppressSideButtons: false,
        enableSorting: true,
        toolPanelSuppressValues: true,
        toolPanelSuppressRowGroups: true,
        toolPanelSuppressPivotMode: true,
        enableFilter: true,
        enableColResize: true,
        columnDefs: [
          {
            headerName: this.translationService.translate('balance.game'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'stationName',
            width: HoldOverviewFranchiserComponent.COLUMN_WIDTH
          },
          {
            headerName: this.translationService.translate('balance.cashIn'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'cashIn',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: HoldOverviewFranchiserComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balance.cashInTooltip')
          },
          {
            headerName: this.translationService.translate('balance.tax'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'tax',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: HoldOverviewFranchiserComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balance.taxTooltip')
          },
          {
            headerName: this.translationService.translate('balance.cashOut'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'cashOut',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: HoldOverviewFranchiserComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balance.cashOutTooltip')
          },
          {
            headerName: this.translationService.translate('balance.winTax'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'winTax',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: HoldOverviewFranchiserComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balance.winTax')
          },
          {
            headerName: this.translationService.translate('balance.balance'),
            sortable: true,
            filter: true,
            resizable: true,
            field: 'balance',
            valueFormatter: this.decimalFormatter.bind(this),
            cellClass: 'text-align-right',
            cellClassRules: {
              'smaller-font': this.isLargeNumber.bind(this)
            },
            width: HoldOverviewFranchiserComponent.COLUMN_WIDTH,
            headerTooltip: this.translationService.translate('balance.balance')
          }
        ],
        onCellClicked: (params) => {
          params.node.setExpanded(!params.node.expanded);
        }
      },
      getDetailRowData: (params) => {
        this.query.stationID = params.data.stationID;
        this.balanceService.fetchBalancesPerGame(this.query).subscribe(data => {
          params.successCallback(enrichByStationId(data, params.data.stationID));
        });
      }
    };
  }

  isLargeNumber(params) {
    return isNumber(params.value) && Math.round(params.value).toString().length >= 8;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }

}
