import {Permission} from './permission';

export class QuestionBase<T> {
  value: string | T;
  key: string;
  label: string;
  required: boolean;
  disabled: boolean;
  readonly: boolean; // disabled but not transparent
  controlType: string;
  editPermission: Permission;
  editPermissionsSome: Permission[];
  viewPermission: Permission;
  onChange: Function;
  tooltip: string;
  copy: boolean;
  visible: boolean;
  desc?: string;

  constructor(options: {
    value?: T,
    key?: string,
    label?: string,
    required?: boolean,
    disabled?: boolean,
    readonly?: boolean,
    controlType?: string,
    editPermission?: Permission,
    editPermissionsSome?: Permission[],
    viewPermission?: Permission,
    tooltip?: string,
    copy?: boolean,
    visible?: boolean
  } = {}) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.copy = !!options.copy;
    this.disabled = !!options.disabled || !!options.readonly;
    this.readonly = !!options.readonly;
    this.controlType = options.controlType || '';
    this.editPermission = options.editPermission;
    this.editPermissionsSome = options.editPermissionsSome || [];
    this.tooltip = options.tooltip,
    this.viewPermission = options.viewPermission;
    this.visible = options.visible ?? true;
    this.onChange = options['onChange'] || function () {
    };
    if (options.editPermissionsSome?.length > 0 && options.editPermission) {
      console.error('warning, ' + options.editPermission + ' is not exclusive anymore.', options);
    }
  }
}
