import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {QueryFilter} from '../../domain/query-filter';
import {TranslationService} from '../../services/translation.service';
import {SessionService} from '../../services/session.service';
import {DatePipe, DecimalPipe} from '@angular/common';
import {CustomerInfoService} from './customer-info.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatAccordion, MatExpansionPanel} from '@angular/material/expansion';
import {filterNulls, isNotEmpty} from '../../../utils';
import {clone} from '../../utils/basic-utils';
import {Permission} from '../../domain/permission';
import {SystemConfig} from '../../config/system-config';
import {StationUtils} from '../../utils/station-utils';
import {TreeNodeType} from '../../domain/tree-node-type';
import {BaseChartDirective} from 'ng2-charts';

@Component({
  selector: 'vit-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss']
})
export class CustomerInfoComponent implements OnInit {

  @ViewChild(MatAccordion, {static: false}) accordion: MatAccordion;
  @ViewChildren(BaseChartDirective) chartList: QueryList<BaseChartDirective>;
  @ViewChildren(MatExpansionPanel) panels: QueryList<MatExpansionPanel>;
  query: QueryFilter;
  data: any;
  bigChart: any;
  overviewChart: any;

  expansionPanels: any[] = [];

  constructor(public customerInfoService: CustomerInfoService,
              public translationService: TranslationService,
              public sessionService: SessionService,
              public dialog: MatDialog,
              public datePipe: DatePipe,
              public decimalPipe: DecimalPipe) {
  }

  ngOnInit() {
  }

  toTextData(props: string[], data: any) {
    return props.map(key => {
      return {
        'key': this.translationService.translate('customerInfo.data.properties.' + key),
        value: this.getProperty(data, key.split('.'))
      };
    });
  }

  toTableData(name: string, props: string[], data: any) {
    return (data && data.length > 0) ?
      [[name], props.map(prop => this.translationService.translate('customerInfo.data.properties.' + prop.split('|')[0])),
        ...data.map(row => props.map(key => this.getProperty(row, key.split('.'))))]
      : null;
  }

  getProperty(data: any, keys: string[]) {
    return [data, ...keys].reduce((result: string, key: string) => {
      let format: string;
      const keyFormat = key.split('|');
      if (keyFormat.length > 1) {
        format = keyFormat[1];
        key = key.substring(0, key.length - 1 - format.length);
      }
      return result[key] !== undefined ? this.mapProperty(key, result, format) : keys;
    });
  }

  private mapProperty(key: string, result: string, format: string) {
    const value = result[key];
    if (key === 'sportID') {
      return this.mapSportID(value);
    }
    try {
      if (('' + value).match('[0-9]{1,4}-[0-9]{1,2}-[0-9]{1,2}T[0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}.*')) {
        return this.dateFormatter({value});
      }
    } catch (e) {
      console.log('failed to transform ' + key, result);
    }

    if (key === 'sType') {
      const icon = StationUtils.getIcon(TreeNodeType.STATION, value, false, false, false);
      return '<img style="margin-left: 4px; margin-top: 5px;" src=\'/assets/img/icons/' + icon + '\'>';
    }

    if (format === 'd') {
      return this.decimalFormatter(value);
    }

    if (format === 'ua-timeline') {
      return '<a target="_blank" href="/#/home/user-account-timeline?userAccountId=' + value + '">' + value + '</a>';
    }

    if (value === true) {
      return this.translationService.translate('customerInfo.data.properties.yes');
    } else if (value === false) {
      return this.translationService.translate('customerInfo.data.properties.no');
    }
    return value;
  }

  mapSportID(sportId) {
    return this.translationService.translate('customerInfo.sport.' + sportId);
  }

  updateCustomerInsights(res) {
    let storedIds: { key, value }[] = JSON.parse(localStorage.getItem(SystemConfig.LocalStorageConstants.RECENT_CUSTOMER_IDS) || '[]');
    storedIds = storedIds.filter(el => el.key !== res.customer.customerID);
    storedIds.unshift({
      key: res.customer.customerID,
      value: '<b>ID: ' + res.customer.customerID + ', ' + res.customer.name
        + '</b> <small class="right-info">' + this.datePipe.transform(new Date(), 'short') + '</small>'
    });
    storedIds.length = Math.min(10, storedIds.length);
    localStorage.setItem(SystemConfig.LocalStorageConstants.RECENT_CUSTOMER_IDS, JSON.stringify(storedIds));
  }

  fetch(query: QueryFilter) {
    if (query) {
      this.overviewChart = [];
      this.expansionPanels = [];
      this.bigChart = undefined;
      this.query = query;
      this.data = null;
      const now = new Date().getTime();
      this.customerInfoService.fetch(query).subscribe((res: any) => {
        const requestDuration = new Date().getTime() - now;
        this.data = res;
        this.updateCustomerInsights(res);
        this.expansionPanels = [
          {
            title: 'basic',
            icon: 'account_circle',
            image: 'image',
            textData: this.toTextData(['customer.firstName', 'customer.name', 'customer.gender', 'age', 'break_line', 'customer.createDate',
              'customer.thirdPartyUsername'], res),
            preview: ['customer.firstName', 'customer.name'].map(k => this.getProperty(res, k.split('.'))).join(', '),
            permission: res.customer ? null : Permission.notAllowed,
          },
          {
            title: 'anomalies',
            icon: 'assignment',
            textData: this.toTextData(filterNulls(['cc', 'das', 'dal']), res),
            textBlockData: this.toTextData(['cr', 'dad'], res),
            preview: ['cc'].map(k => this.getProperty(res, k.split('.'))).join(', '),
            permission: (res.das >= 0 && res.dal >= 0) ? null : Permission.notAllowed
          },
          {
            title: 'personal',
            icon: 'person_outline',
            textData: this.toTextData(['province', 'phoneCountry', 'firstActivity', 'lockedFranchiser', 'lockedFranchiserPartly',
              'lockedCPCTotal', 'lockedCPCPartly', 'imageExists', 'imageSizeKB'], res),
            preview: 'Bundesland: ' + res.province + ', Handy: ' + res.phoneCountry + ', registriert seit: ' + this.dateFormatter({value: res.customer.createDate})
          },
          {
            title: 'fingerprint',
            disabled: !res.fpExists,
            icon: 'fingerprint',
            tableNumbers: [[1, 0, 1, 0]],
            textData: this.toTextData(['fpExists', 'fpNum', 'fpPersonsNum', 'fpConnectedToUser', 'fpUserList'], res),
            tableData: filterNulls([this.toTableData('Fingerabdrücke', ['personID', 'finger', 'qty', 'regDate'], res.fpInfoList)]),
            preview: res.fpExists ? ['fpExists'].map(k => this.getProperty(res, k.split('.'))).join(', ') : this.translationService.translate('customerInfo.data.missing'),
          },
          {
            title: 'login',
            icon: 'touch_app',
            tableNumbers: [[1, 0, 1]],
            textData: this.toTextData(['terminalLoginsNum', 'terminalLoginsNumStations'], res),
            preview: res.terminalLoginsNum + ' Anmeldungen auf ' + res.terminalLoginsNumStations + ' Terminals',
            tableData: filterNulls([this.toTableData('Anmeldungen', ['sid', 'tName', 'tn'], res.terminalUse)]),
            charts: [{
              type: 'bar',
              data: {
                labels: res.terminalActivityStats.map(ts => this.datePipe.transform(ts.date, this.calculateDateFormat(res.terminalActivityStatsInterval))),
                datasets: [
                  {
                    label: this.translationService.translateArgs('chart.terminalActivityStats',
                      this.translationService.translate('chart.interval.' + res.terminalActivityStatsInterval)),
                    data: res.terminalActivityStats.map(ts => ts.q),
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  }
                ],
              },
              options: {
                responsive: true,
                maintainAspectRatio: true,
                aspectRatio: 1.5
              }
            }, {
              type: 'bar',
              data: {
                labels: res.terminalActivityStatsTimeFrame.map(ts => this.datePipe.transform(ts.date, this.calculateDateFormat(res.terminalActivityStatsTimeFrameInterval))),
                datasets: [
                  {
                    label: this.translationService.translateArgs('chart.terminalActivityStatsTimeFrame',
                      this.translationService.translate('chart.interval.' + res.terminalActivityStatsTimeFrameInterval)),
                    data: res.terminalActivityStatsTimeFrame.map(ts => ts.q),
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  }
                ],
              },
              options: {
                responsive: true,
                maintainAspectRatio: true,
                aspectRatio: 1.5
              }
            }]
          },
          {
            title: 'ticketData',
            icon: 'functions',
            preview: '#:' + res.totalTickets + '/' + res.totalWonTickets
              + ', IN:' + this.decimalFormatter(res.statStakeSum)
              + ', OUT:' + this.decimalFormatter(res.statProfitSum)
              + ', HOLD:' + this.decimalFormatter(res.statHoldSum),
            tableNumbers: [[0, 1, 1]],
            tableData: [
              [[''], ['tdValue', 'tdTotal', 'tdTimeframe'].map(prop => this.translationService.translate('customerInfo.data.properties.' + prop)),
                ...[
                  ['Anzahl Tickets', 'totalTickets', 'timeFrameTickets'],
                  ['Anzahl gewonnene Tickets', 'totalWonTickets', 'wonTicketsTimeFrame'],
                  ['Einsatz', 'statStakeSum|d', 'statStakeTimeFrameSum|d'],
                  ['Gewinn', 'statProfitSum|d', 'statProfitTimeFrameSum|d'],
                  ['Hold', 'statHoldSum|d', 'statHoldTimeFrameSum|d'],
                  ['Einsatz (Durchschnitt)', 'statStakeAvg|d', 'statStakeTimeFrameAvg|d'],
                  ['Einsatz (Median)', 'statStakeMedian|d', 'statStakeTimeFrameMedian|d']
                ].map(row => row.map(key => this.getProperty(res, key.split('.'))))]],
            charts: [
              {
                type: 'bar',
                data: {
                  labels: res.lB2TicketStats.map(ts => this.datePipe.transform(ts.date, this.calculateDateFormat(res.lB2TicketStatsInterval))),
                  datasets: [
                    {
                      label: this.translationService.translateArgs('chart.lB2TicketStats',
                        this.translationService.translate('chart.interval.' + res.lB2TicketStatsInterval)),
                      data: res.lB2TicketStats.map(ts => ts.q),
                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    }
                  ],
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: true,
                  aspectRatio: 1.5
                }
              },
              {
                type: 'bar',
                data: {
                  labels: res.lB2TicketStatsTimeFrame.map(ts => this.datePipe.transform(ts.date, this.calculateDateFormat(res.lB2TicketStatsTimeFrameInterval))),
                  datasets: [
                    {
                      label: this.translationService.translateArgs('chart.lB2TicketStatsTimeFrame',
                        this.translationService.translate('chart.interval.' + res.lB2TicketStatsTimeFrameInterval)),
                      data: res.lB2TicketStatsTimeFrame.map(ts => ts.q),
                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    }
                  ],
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: true,
                  aspectRatio: 1.5
                }
              },
              {
                type: 'bar',
                data: {
                  labels: res.lB2TicketStatsByWeekDay.map(ts => this.translationService.translate('chart.weekday.' + ts.d)),
                  datasets: [
                    {
                      label: this.translationService.translate('chart.lB2TicketStatsByWeekDay'),
                      data: res.lB2TicketStatsByWeekDay.map(ts => ts.q),
                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    }
                  ],
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: true,
                  aspectRatio: 1.5
                }
              },
              {
                type: 'bubble',
                data: {
                  labels: res.lB2TicketStatsByWeekDay.map(ts => this.translationService.translate('chart.weekday.' + ts.d)),
                  datasets: [
                    {
                      label: this.translationService.translate('chart.lB2TicketStatsByWeekDayByHour'),
                      data: res.lB2TicketStatsByWeekDayByHour.map(ts => {
                        return {
                          x: ts.d,
                          y: ts.h,
                          r: ts.r,
                          q: ts.q,
                        };
                      }).filter(ts => ts.r),
                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    }
                  ],
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: true,
                  aspectRatio: 1.5,
                  scales: {
                    y: {
                      min: 0,
                      max: 24
                    },
                    x: {
                      ticks: {
                        callback: (value) => {
                          return this.translationService.translate('chart.weekday.' + value);
                        }
                      }
                    }
                  },
                  tooltips: {
                    callbacks: {
                      label: function (a, x) {
                        const data = x.datasets[0].data[a.index];

                        return data.y + '-' + (data.y + 1) + ' Uhr, ' + data.q + ' Tickets';
                      }
                    }
                  },
                }
              }
            ]
          },
          {
            title: 'ticketAnalysis',
            icon: 'rule',
            preview: 'Einzel: ' + res.totalSingleTickets + ', Kombi: ' + res.totalAccuTickets,
            tableNumbers: [[0, 1, 1, 1, 1, 1, 1]],
            tableData: [
              [[''], ['', 'alle', 'gewonnen', 'Einzel', 'Kombi', 'System', 'Mehrweg'], //.map(prop => this.translationService.translate('customerInfo.data.properties.' + prop)),
                ...[
                  ['Anzahl', 'totalTickets', 'totalWonTickets', 'totalSingleTickets', 'totalAccuTickets', 'totalSystemTickets', 'totalMultiWayTickets'],
                  ['Einsatz', 'statStakeSum|d', 'totalStakeWonTickets|d', 'totalStakeSingleTickets|d', 'totalStakeAccuTickets|d', 'totalStakeSystemTickets|d', 'totalStakeMultiWayTickets|d'],
                  ['Einsatz (Durchschnitt)', 'statStakeAvg|d', 'avgStakeWonTickets|d', 'avgStakeSingleTickets|d', 'avgStakeAccuTickets|d', 'avgStakeSystemTickets|d', 'avgStakeMultiWayTickets|d'],
                  ['Einsatz (Median)', 'statStakeMedian|d', 'medianStakeWonTickets|d', 'medianStakeSingleTickets|d', 'medianStakeAccuTickets|d', 'medianStakeSystemTickets|d', 'medianStakeMultiWayTickets|d'],
                  ['Gewinn', 'statProfitSum|d', 'totalProfitWonTickets|d', 'totalProfitSingleTickets|d', 'totalProfitAccuTickets|d', 'totalProfitSystemTickets|d', 'totalProfitMultiWayTickets|d'],
                  ['Hold', 'statHoldSum|d', 'totalHoldWonTickets|d', 'totalHoldSingleTickets|d', 'totalHoldAccuTickets|d', 'totalHoldSystemTickets|d', 'totalHoldMultiWayTickets|d'],
                ].map(row => row.map(key => this.getProperty(res, key.split('.'))))]],
            charts: [
              {
                type: 'bar',
                data: {
                  labels: res.stakeDistribution.map(ts => this.decimalPipe.transform(ts.s, '0.0-2')),
                  datasets: [
                    {
                      backgroundColor: 'rgba(204, 0, 0, 0.21)',
                      label: this.translationService.translate('chart.stakeDistributionChartWon'),
                      data: res.stakeDistribution.map(ts => ts.tp),
                    },
                    {
                      label: this.translationService.translate('chart.stakeDistribution'),
                      data: res.stakeDistribution.map(ts => ts.ts),
                    }
                  ],
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: true,
                  aspectRatio: 1.5
                }
              },
              {
                type: 'bar',
                data: {
                  labels: res.accuStats.map(stat => stat.accu),
                  datasets: [
                    {
                      label: this.translationService.translate('chart.accuStats.n'),
                      data: res.accuStats.map(stat => stat.n),
                    },
                    {
                      label: this.translationService.translate('chart.accuStats.s'),
                      backgroundColor: 'rgba(0, 200, 0, 0.21)',
                      data: res.accuStats.map(stat => stat.s),
                    },
                    {
                      label: this.translationService.translate('chart.accuStats.p'),
                      backgroundColor: 'rgba(204, 0, 0, 0.21)',
                      data: res.accuStats.map(stat => stat.p),
                    },
                    {
                      label: this.translationService.translate('chart.accuStats.h'),
                      backgroundColor: 'rgba(0, 0, 200, 0.21)',
                      data: res.accuStats.map(stat => stat.h),
                    }
                  ],
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: true,
                  aspectRatio: 1.5
                }
              },
              {
                type: 'bar',
                data: {
                  labels: res.oddsStats.map(stat => stat.o),
                  datasets: [
                    {
                      label: this.translationService.translate('chart.oddsStats.n'),
                      data: res.oddsStats.map(stat => stat.n),
                    },
                    {
                      label: this.translationService.translate('chart.oddsStats.w'),
                      backgroundColor: 'rgba(204, 0, 0, 0.21)',
                      data: res.oddsStats.map(stat => stat.w),
                    }
                  ],
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: true,
                  aspectRatio: 1.5
                }
              }
            ]
          },
          {
            title: 'ticketsLivePrematch',
            icon: 'emoji_flags',
            preview: 'Live: ' + res.totalLiveOnlyTickets + ', Prematch: ' + res.totalPreMatchOnlyTickets + ', Gemischt: ' + res.totalLivePreMatchTickets,
            tableNumbers: [[0, 1, 1, 1, 1, 1]],
            tableData: [
              [[''], ['Bezeichnung', 'Anz. Tickets', 'Anz. gewonnene<br/>Tickets', 'Anz. Tickets<br/>(Zeitraum)', 'Anz. Gewonnene<br/>Tickets (Zeitraum)'], //.map(prop => this.translationService.translate('customerInfo.data.properties.' + prop)),
                ...[
                  ['Anzahl 100% Live', 'totalLiveOnlyTickets', 'totalWonLiveOnlyTickets', 'timeFrameLiveOnlyTickets', 'wonTimeFrameLiveOnlyTickets'],
                  ['Anzahl 100% Prematch', 'totalPreMatchOnlyTickets', 'totalWonPreMatchOnlyTickets', 'timeFramePreMatchOnlyTickets', 'wonTimeFramePreMatchOnlyTickets'],
                  ['Anzahl gemischt Live/Pre', 'totalLivePreMatchTickets', 'totalWonLivePreMatchTickets', 'timeFrameLivePreMatchTickets', 'wonTimeFrameLivePreMatchTickets'],
                  ['Hold 100% Live', 'totalHoldLiveOnlyTickets|d', '', 'timeFrameHoldLiveOnlyTickets|d', ''],
                  ['Hold 100% Prematch', 'totalHoldPreMatchOnlyTickets|d', '', 'timeFrameHoldPreMatchOnlyTickets|d', ''],
                  ['Hold gemischt Live/Pre', 'totalHoldLivePreMatchTickets|d', '', 'timeFrameHoldLivePreMatchTickets|d', ''],
                ].map(row => row.map(key => this.getProperty(res, key.split('.'))))]]
          },
          {
            title: 'platform',
            icon: 'devices',
            preview: 'Landbased: ' + res.numTicketsOriginLandbased + ', Desktop: ' + res.numTicketsOriginDesktop + ', Mobile: ' + res.numTicketsOriginMobile + ', Terminal: ' + res.numTicketsOriginTerminal + ', Shop: ' + res.numTicketsOriginShop,
            tableNumbers: [[0, 1, 1, 1, 1]],
            tableData: [
              [[''], ['Bezeichnung', 'Anzahl', 'Einsatz', 'Gewinn', 'Hold'], //.map(prop => this.translationService.translate('customerInfo.data.properties.' + prop)),
                ...[
                  ['Tickets landbased', 'numTicketsOriginLandbased', 'stakeTicketsOriginLandbased|d', 'profitTicketsOriginLandbased|d', 'holdTicketsOriginLandbased|d'],
                  ['Tickets online', 'numTicketsOriginOnline', 'stakeTicketsOriginOnline|d', 'profitTicketsOriginOnline|d', 'holdTicketsOriginOnline|d'],
                  ['Tickets online (Desktop)', 'numTicketsOriginDesktop', 'stakeTicketsOriginDesktop|d', 'profitTicketsOriginDesktop|d', 'holdTicketsOriginDesktop|d'],
                  ['Tickets online (Mobile)', 'numTicketsOriginMobile', 'stakeTicketsOriginMobile|d', 'profitTicketsOriginMobile|d', 'holdTicketsOriginMobile|d'],
                  ['Tickets Terminal', 'numTicketsOriginTerminal', 'stakeTicketsOriginTerminal|d', 'profitTicketsOriginTerminal|d', 'holdTicketsOriginTerminal|d'],
                  ['Tickets Shop', 'numTicketsOriginShop', 'stakeTicketsOriginShop|d', 'profitTicketsOriginShop|d', 'holdTicketsOriginShop|d'],
                ].map(row => row.map(key => this.getProperty(res, key.split('.'))))]]
          },
          {
            title: 'franchiserStats',
            icon: 'home',
            disabled: !isNotEmpty(res.franchiserStats),
            tableNumbers: [[1, 0, 1, 1, 1, 1]],
            tableData: filterNulls([this.toTableData('', ['fid', 'fName', 'n', 's|d', 'p|d', 'h|d'], res.franchiserStats)]),
            preview: isNotEmpty(res.franchiserStats) ? res.franchiserStats.length + ' Franchiser' : this.translationService.translate('customerInfo.data.missing')
          },
          {
            title: 'franchiserTimeFrameStats',
            icon: 'timelapse',
            disabled: !isNotEmpty(res.franchiserTimeFrameStats),
            tableNumbers: [[1, 0, 1, 1, 1, 1]],
            tableData: filterNulls([this.toTableData('', ['fid', 'fName', 'n', 's', 'p', 'h'], res.franchiserTimeFrameStats)]),
            preview: isNotEmpty(res.franchiserTimeFrameStats) ? res.franchiserTimeFrameStats.length + ' Franchiser' : this.translationService.translate('customerInfo.data.missing')
          },
          {
            title: 'stationStats',
            icon: 'home',
            disabled: !isNotEmpty(res.stationStats),
            tableNumbers: [[1, 0, 1, 1, 1, 1]],
            tableData: filterNulls([this.toTableData('', ['sid', 'sType', 'sName', 'fName', 'n', 's', 'p', 'h'], res.stationStats)]),
            preview: isNotEmpty(res.stationStats) ? res.stationStats.length + ' Stationen' : this.translationService.translate('customerInfo.data.missing')
          },
          {
            title: 'stationTimeFrameStats',
            icon: 'timelapse',
            disabled: !isNotEmpty(res.stationTimeFrameStats),
            tableNumbers: [[1, 0, 0, 0, 1, 1, 1, 1]],
            tableData: filterNulls([this.toTableData('', ['sid', 'sType', 'sName', 'fName', 'n', 's', 'p', 'h'], res.stationTimeFrameStats)]),
            preview: isNotEmpty(res.stationTimeFrameStats) ? res.stationTimeFrameStats.length + ' Stationen' : this.translationService.translate('customerInfo.data.missing')
          },
          {
            title: 'topTickets',
            icon: 'military_tech',
            disabled: !isNotEmpty(res.topTickets),
            tableNumbers: [[1, 0, 0, 0, 1, 1, 1, 1]],
            tableData: filterNulls([this.toTableData('', ['stationID', 'number', 'stake|d', 'profit|d', 'inDate', 'systemX', 'systemY', 'wayCount',
              'isLive', 'ticketOrigin', 'maxOdd|d'], res.topTickets)]),
            preview: isNotEmpty(res.topTickets) ? this.firstN(res.topTickets.map(tt => tt.profit), 5, true).join(', ') : this.translationService.translate('customerInfo.data.missing')
          },
          {
            title: 'topTicketsTimeFrame',
            icon: 'military_tech',
            disabled: !isNotEmpty(res.topTicketsTimeFrame),
            tableNumbers: [[1, 1, 1, 1, 0, 1, 1, 1]],
            tableData: filterNulls([this.toTableData('', ['stationID', 'number', 'stake|d', 'profit|d', 'inDate', 'systemX', 'systemY', 'wayCount',
              'isLive', 'ticketOrigin', 'maxOdd|d'], res.topTicketsTimeFrame)]),
            preview: isNotEmpty(res.topTicketsTimeFrame) ? this.firstN(res.topTicketsTimeFrame.map(tt => tt.profit), 5, true).join(', ') : this.translationService.translate('customerInfo.data.missing')
          },
          {
            title: 'bonusStatistics',
            icon: 'redeem',
            textData: this.toTextData(['numBonusVouchers', 'numUsedBonusVouchers', 'numOpenBonusVouchers', 'numExpiredBonusVouchers',
              'totalSumBonusVouchers|d', 'totalSumUsedBonusVouchers|d', 'totalSumOpenBonusVouchers|d', 'totalSumExpiredBonusVouchers|d',
              'firstBonusDate', 'lastBonusDate', 'avgBonusRedeemTime'], res),
            tableNumbers: [[0, 0, 0, 0, 0, 0, 0, 0, 0]],
            tableData: filterNulls([this.toTableData('Kategorien', ['bc', 'bn', 'bnu', 'bno', 'bne', 'bs', 'bsu', 'bso', 'bse'], res.bonusCategoryStats)]),
            charts: [{
              type: 'bar',
              data: {
                labels: res.bonusEvents.map(ts => this.datePipe.transform(ts.bd, this.calculateDateFormat(res.bonusEventsInterval))),
                datasets: [
                  {
                    label: this.translationService.translateArgs('chart.bonusEvents',
                      this.translationService.translate('chart.interval.' + res.bonusEventsInterval)),
                    data: res.bonusEvents.map(ts => ts.bs),
                  }
                ],
              },
              options: {
                responsive: true,
                maintainAspectRatio: true
              }
            }]
          },
          {
            title: 'userAccount',
            icon: 'people',
            tableNumbers: [
              [0, 1],
              [0, 1, 0, 0, 1, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
              [0, 0, 0, 1, 1]],
            tableData: filterNulls([
              [['Statistische Daten'], ['Bezeichnung', 'Wert'], //.map(prop => this.translationService.translate('customerInfo.data.properties.' + prop)),
                ...[
                  ['Anzahl Konten', 'uaNumAccounts'],
                  ['Credit gesamt', 'uaTotalCredit'],
                  ['Erstellt', 'uaCreateDate'],
                  ['Letzte Aktivität', 'uaLastActivity'],
                  ['Anzahl Tickets', 'uaTotalNumTickets'],
                  ['Summe Einzahlungen', 'uaTotalPayIn|d'],
                  ['Summe Auszahlungen', 'uaTotalPayOut|d'],
                  ['Einzahlungen Paypal', 'uaTotalPayInPaypal|d'],
                  ['Einzahlungen Handyrechnung', 'uaTotalPayInDimoco|d'],
                  ['Einzahlungen Prepaid', 'uaTotalPayInPrepaid|d'],
                  ['Einzahlungen Terminal', 'uaTotalPayInStation|d'],
                  ['Einzahlungen Ladebon', 'uaTotalPayInVoucher|d'],
                  ['Einzahlungen Bonus', 'uaTotalPayInBonus|d'],
                  ['Auch Terminal-Tickets online anzeigen?', 'customer.loadAllTicketsInUA']
                ].map(row => row.map(key => this.getProperty(res, key.split('.'))))],
              this.toTableData('Benutzerkonten', ['uaid|ua-timeline', 'username', 'credit|d', 'createDate', 'createStation', 'webshopID', 'locked', 'lastActivity',
                'numTickets', 'stake|d', 'profit|d', 'hold|d', 'payin|d', 'payout|d', 'payinPaypal|d', 'payinPrepaid|d', 'payinStation|d', 'payinVoucher|d',
                'payinBonus|d'], res.userAccounts),
              this.toTableData('Einzahlungen je Prepaid Admin Operator', ['userID', 'username', 'displayName', 'payin|d', 'numPayin|d'], res.uaPrepaidOperators)]),
            preview: isNotEmpty(res.userAccounts) ?
              'Anzahl:' + res.uaNumAccounts + ', Credit:' + this.decimalFormatter(res.uaTotalCredit) + ', Aktiv:' + this.getProperty(res, ['uaLastActivity'])
              : this.translationService.translate('customerInfo.data.missing'),
            charts: [
              {
                type: 'pie',
                data: {
                  labels: [
                    this.translationService.translate('chart.payin.uaTotalPayInBonus'),
                    this.translationService.translate('chart.payin.uaTotalPayInPaypal'),
                    this.translationService.translate('chart.payin.uaTotalPayInPrepaid'),
                    this.translationService.translate('chart.payin.uaTotalPayInStation'),
                    this.translationService.translate('chart.payin.uaTotalPayInVoucher'),
                    this.translationService.translate('chart.payin.uaTotalPayInDimoco'),
                  ],
                  datasets: [
                    {
                      data: [
                        res.uaTotalPayInBonus,
                        res.uaTotalPayInPaypal,
                        res.uaTotalPayInPrepaid,
                        res.uaTotalPayInStation,
                        res.uaTotalPayInVoucher,
                        res.uaTotalPayInDimoco
                      ],
                      backgroundColor: [
                        'rgba(255, 50, 100, 0.3)',
                        'rgba(54, 162, 235, 0.3)',
                        'rgba(255, 205, 86, 0.3)',
                        'rgba(10, 205, 86, 0.3)',
                        'rgba(100, 40, 86, 0.3)',
                        'rgba(40,83,255,0.3)'
                      ],
                    }
                  ],
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: true,
                  title: {
                    display: true,
                    text: this.translationService.translate('chart.uaTotalPayIn')
                  }
                }
              }
            ]
          },
          {
            title: 'sports',
            icon: 'sports_soccer',
            tableNumbers: [[0, 1, 1, 1], [0, 1, 1, 1]],
            tableData: filterNulls([
              this.toTableData('Tickets mit Wetten aus nur einer Sportart', ['sportID', 'n', 's|d', 'p|d', 'h|d'], res.sportStatsOneSport),
              this.toTableData('Tickets mit Wetten aus mehreren Sportarten', ['sportID', 'n', 's|d', 'p|d', 'h|d'], res.sportStatsMixedSports)]),
          },
          {
            title: 'betType',
            icon: 'donut_large',
            tableNumbers: [[0, 1, 1]],
            tableData: filterNulls([
              this.toTableData('', ['d', 'n', 'w'], res.betTypeStats)]),
          },
          {
            title: 'technician',
            icon: 'shutter_speed',
            preview: requestDuration + 'ms',
            tableNumbers: [[0, 1]],
            tableData: [
              [[''], ['Parameter', 'Wert'], //.map(prop => this.translationService.translate('customerInfo.data.properties.' + prop)),
                ...[
                  ['Schwellwert für Intervall-Wechsel (Tag, Woche, Monat)', 'systemStatsIntervalSplitThreshold'],
                  ['Gesamtdauer Request auf Server (ms)', 'systemTimeElapsedMS'],
                  ['Gesamtdauer Request auf Client (ms)', '' + requestDuration],
                  ['Laden aller Tickets aus DB (ms)', 'systemTimeLoadDBTicketsElapsedMS'],
                ].map(row => row.map(key => this.getProperty(res, key.split('.'))))]],
            permission: Permission.editAdvancedAdminSettings
          },
        ].filter(el => !el.permission || this.sessionService.hasPermission(el.permission));

        setTimeout(() => this.panels.forEach((panel, index) => {
          if (index < 1) {
            panel.open();
          }
        }), 200);

        this.overviewChart = {
          type: 'line',
          data: {
            labels: res.holdOverTime.map(ts => this.datePipe.transform(ts.d, this.calculateDateFormat(res.holdOverTimeInterval))),
            datasets: [
              {
                backgroundColor: 'rgba(204, 0, 0, 0.21)',
                label: this.translationService.translate('chart.holdOverTimeNeg'),
                data: res.holdOverTime.map(ts => ts.b).map(x => x < 0 ? x : 0),
                fill: true,
                pointRadius: 0,
                borderWidth: 0,
              },
              {
                label: this.translationService.translate('chart.holdOverTimePos'),
                data: res.holdOverTime.map(ts => ts.b).map(x => x < 0 ? 0 : x),
                fill: true,
                pointRadius: 0,
                borderWidth: 0,
              }
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: 2
          }
        };
        console.log('OC', this.overviewChart);
      });
    } else {
      this.query = null;
      this.data = null;
    }
  }

  firstN(list: any[], amount, addDots = false) {
    if (list && list.length > amount) {
      list = clone(list);
      list.length = amount;
      if (addDots) {
        list.push('...');
      }
      return list;
    }
    return list;
  }

  calculateDateFormat(interval: string) {
    switch (interval) {
      case 'w':
        return 'EEEE, M/d/yy';
      case 'm':
        return 'MMM y';
      case 'd':
      default:
        return 'shortDate';
    }
  }

  calcRows(value: string) {
    return (value ? value.match(new RegExp('\\n', 'g')) : []).length + 1;
  }

  dateFormatter(x: any) {
    return this.datePipe.transform(x.value, 'medium');
  }

  decimalFormatter(x: any) {
    return this.decimalPipe.transform(x.value || x, '0.2-2');
  }

  percentFormatter(x: any) {
    return this.decimalPipe.transform(x.value, '0.2-2') + '%';
  }

}
