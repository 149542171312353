<vit-cols [elements]="questions"></vit-cols>

<mat-toolbar class="footer" *ngIf="settings && !loading">
    <button type="reset"
            (click)="reset()"
            mat-flat-button
            color="secondary">
        {{'pokerbet.settings.footer.reset' | translate}}
    </button>
    <button type="submit"
            (click)="save()"
            [disabled]="!hasChangePermission"
            mat-flat-button
            color="accent">
        {{'pokerbet.settings.footer.save' | translate}}
    </button>
</mat-toolbar>
