import { KenobetSettings } from './kenobet-settings';

export class KenobetSettingsData {
    settings: KenobetSettings;
    jackpots: any[];
    gameCycles: Map<string, string>;
    winningPlanDescriptions: string[];

    constructor() {
    }
}
