import { QuestionBase } from './question-base';
import { CashStorageInfo } from './cash-storage-info';

export class CashStorageQuestion extends QuestionBase<CashStorageInfo> {
    controlType = 'cash-storage';

    constructor(options: {} = {}) {
        super(options);
    }
}
