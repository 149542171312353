<mat-toolbar color="accent" class="toolbar">
  {{'combiBonusCopy.title' | translate}}
</mat-toolbar>
<br>
<mat-card>
  <mat-card-content>
    <vit-dynamic-form #questions [questionContainer]="copyQuestions"></vit-dynamic-form>
  </mat-card-content>
  <mat-card-footer>
    <button type="reset"
            (click)="close()"
            mat-flat-button
            color="secondary">
      {{'combiBonusCopy.cancel' | translate}}
    </button>
    <button type="submit"
            (click)="save()"
            [disabled]="!questions.form.valid || loading"
            mat-flat-button
            color="accent">
      {{'combiBonusCopy.submit' | translate}}
    </button>
  </mat-card-footer>
</mat-card>
