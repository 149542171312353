<h2 *ngIf="secureCode" mat-dialog-title>{{'ticketDetail.livesports.secureCodeDialog.title' | translate}}</h2>
<div  *ngIf="secureCode" mat-dialog-content>
  <p>{{'ticketDetail.livesports.secureCodeDialog.message' | translate}}</p>
  <mat-form-field>
    <input type="password" matInput [(ngModel)]="data.secureCode"
           [placeholder]="'ticketDetail.livesports.secureCodeDialog.placeholder' | translate">
  </mat-form-field>
</div>

<h2 *ngIf="!secureCode" mat-dialog-title>{{title}}</h2>
<mat-dialog-content  *ngIf="!secureCode" [innerHTML]="message"></mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" [class.hidden]="hideNo">{{no}}</button>
  <button mat-button [mat-dialog-close]="true">{{yes}}</button>
</mat-dialog-actions>
