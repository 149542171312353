import {Injectable} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {SessionService} from '../../services/session.service';
import {Observable} from 'rxjs';
import {QueryFilter} from '../../domain/query-filter';
import {SystemConfig} from '../../config/system-config';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(public httpService: HttpService,
              public sessionService: SessionService) {
  }

  fetchUsers(franchiserId: number): Observable<any> {
    return this.httpService.get(SystemConfig.Endpoints.USERS_PER_FRANCHISER + franchiserId).pipe(map(res => {
      return res.map(el => ({
        key: el.uid.toString(),
        value: el.n,
        icon: (el.lck ? '<img class="options-icon" src=\'/assets/img/icons/lock.png\'>' : '') +
          (!el.acs ? '<img class="options-icon" src=\'/assets/img/icons/hidden.png\'>' : ''),
      data: el}));
    }));
  }

  fetchUser(userId: number): Observable<any[]> {
    return this.httpService.get(SystemConfig.Endpoints.USER + userId);
  }

  fetchDummyUser(): Observable<any> {
    return this.httpService.get(SystemConfig.Endpoints.USER_DUMMY);
  }

  deleteUser(user): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.USER_DELETE, user);
  }

  updateUser(user): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.USER_UPDATE, user);
  }

  insertUser(user): Observable<any[]> {
    return this.httpService.post(SystemConfig.Endpoints.USER_INSERT, user);
  }
}
