import {Injectable} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {SessionService} from '../../services/session.service';
import {SystemConfig} from '../../config/system-config';
import {tap} from 'rxjs';
import {MatchEditorFilter} from './match-filter/match-editor-filter';
import {MeBetType, MEObject} from './match-detail/MEObject';
import {BetTypeKeyMapper} from '../../ticket-detail-livesports/livesports-bettype-mapper.service';
import {TranslationService} from '../../services/translation.service';
import {BetTypeKey} from '../../ticket-detail-livesports/bet-type-key';

@Injectable({
  providedIn: 'root'
})
export class MatchEditorService {

  selectedBetType?: MeBetType;
  openFilterEnabled = false;
  btWithTicketsFilterEnabled = false;
  compactFilterEnabled = false;
  cancelledFilterEnabled = false;
  filterStr = '';

  constructor(public httpService: HttpService,
              public betTypeKeyMapper: BetTypeKeyMapper,
              public translationService: TranslationService,
              public sessionService: SessionService) {
  }

  public static readonly DONT_MAP_SOV_FOR_BT = [BetTypeKey.REST_OF_MATCH_H1, BetTypeKey.HANDICAP, BetTypeKey.UNDER_OVER_GOAL_NOGOAL,
    BetTypeKey.DOUBLE_CHANCE_UNDER_OVER, BetTypeKey.HANDICAP_H1, BetTypeKey.HANDICAP_H2, BetTypeKey.WAY_3_UNDER_OVER,
    BetTypeKey.WAY_3_UNDER_OVER_H1, BetTypeKey.TENNIS_UNDER_OVER, BetTypeKey.NEXT_GOAL_H1, BetTypeKey.NEXT_GOAL, BetTypeKey.NEXT_GOAL_OT,
    BetTypeKey.UNDER_OVER, BetTypeKey.UNDER_OVER_HOMETEAM_H1, BetTypeKey.UNDER_OVER_HOMETEAM, BetTypeKey.UNDER_OVER_AWAYTEAM,
    BetTypeKey.UNDER_OVER_AWAYTEAM_H1, BetTypeKey.UNDER_OVER_H1, BetTypeKey.UNDER_OVER_OT, BetTypeKey.UNDER_OVER_PERIOD,
    BetTypeKey.UNDER_OVER_H2, BetTypeKey.UNDER_OVER_CORNER, BetTypeKey.XTH_GOAL_PERIOD];

  public getMatches(filter: MatchEditorFilter) {
    console.log('fetching matches', filter);
    return this.httpService.post(SystemConfig.Endpoints.MATCH_EDITOR_MATCHES, filter);
  }

  public getMatch(brMatchID: any) {
    console.log('fetching match brmatchid', brMatchID);
    return this.httpService.post(SystemConfig.Endpoints.MATCH_EDITOR_MATCH, {brMatchID})
      .pipe(tap(res => this.fillBetTypes(res, res.sport.brSportID, undefined)));
  }

  public cancelAllOpen(event: MEObject, secureCode: string) {
    return this.httpService.post(SystemConfig.Endpoints.MATCH_EDITOR_CANCEL_ALL_OPEN, {
      lBMatchID: event.match.lB_MatchID,
      brMatchId: event.match.brMatchID,
      securityPIN: secureCode,
    }).pipe(tap(res => this.fillBetTypes(res, event.match.brSportID, event)));
  }

  public deleteAllSettlements(event: MEObject) {
    return this.httpService.post(SystemConfig.Endpoints.MATCH_EDITOR_DELETE_ALL_SETTLEMENTS, {
      lBMatchID: event.match.lB_MatchID,
      brMatchId: event.match.brMatchID
    }).pipe(tap(res => this.fillBetTypes(res, event.match.brSportID, event)));
  }

  public recalculateAllTickets(event: MEObject) {
    return this.httpService.post(SystemConfig.Endpoints.MATCH_EDITOR_RECALCULATE_TICKETS, {
      lBMatchID: event.match.lB_MatchID,
      brMatchID: event.match.brMatchID
    }).pipe(tap(res => this.fillBetTypes(res, event.match.brSportID, event)));
  }

  public lockAllTickets(event: MEObject) {
    return this.httpService.post(SystemConfig.Endpoints.MATCH_EDITOR_LOCK_ALL_TICKETS, {
      lBMatchID: event.match.lB_MatchID,
      brMatchID: event.match.brMatchID
    }).pipe(tap(res => this.fillBetTypes(res.betTypes, event.match.brSportID, event)));
  }

  public updateBetTypes(betTypes: MeBetType[], securityPIN: string, brSportID: number, lBMatchID: number, brMatchID: number) {
    console.log('SENDING', betTypes);
    return this.httpService.post(SystemConfig.Endpoints.MATCH_EDITOR_BET_TYPES_UPDATE, {
      betTypes,
      securityPIN,
      lBMatchID,
      brMatchID
    }).pipe(tap(res => {
      console.log('RECEIVED', res);
      this.fillBetTypes(res, brSportID, null);
    }));
  }

  public updateMatch(req: {
    lBMatchID: number, brMatchID: number, active?: boolean, betStatus?: boolean,
    offered?: boolean, offeredLive?: boolean, vip?: boolean, broadcast?: boolean
  }[]) {
    return this.httpService.post(SystemConfig.Endpoints.MATCH_EDITOR_MATCH_STATUS_UPDATE, req);
  }

  public getGroups(filter: MatchEditorFilter) {
    return this.httpService.post(SystemConfig.Endpoints.MATCH_EDITOR_GROUPS, filter).pipe(tap(res => {
      res.matchCategories.sort((a, b) => a.ctgOrder > b.ctgOrder ? 1 : -1);
      res.categoryTournaments.sort((a, b) => a.tmtOrder > b.tmtOrder ? 1 : -1);
    }));
  }

  private fillBetTypes(res: MEObject, brSportID, oldEvent: MEObject) {
    let betTypes = res.betTypes;
    const betTypeOrder = this.getBetTypeOrder();

    betTypes = betTypes.sort((a, b) => {
      const aValue = betTypeOrder[a.betTypeKey] > 0 ? betTypeOrder[a.betTypeKey] : 9999999;
      const bValue = betTypeOrder[b.betTypeKey] > 0 ? betTypeOrder[b.betTypeKey] : 9999999;
      if (aValue !== bValue) {
        return aValue - bValue;
      }
      return (+a.brSpecialOddsValue) - (+b.brSpecialOddsValue);
    });

    betTypes.forEach(betType => {
      betType.betTypeLabel = this.betTypeKeyMapper.getText(betType.betTypeKey, brSportID,
        MatchEditorService.DONT_MAP_SOV_FOR_BT.includes(betType.betTypeKey) ? undefined : betType.brSpecialOddsValue);
      betType.odds.forEach(odd => {
        odd.oddLabel = betType.betTypeKey === BetTypeKey.OUTRIGHT_WINNER ? (odd.team.brNameDE || odd.team.brName)
          : this.translationService.translate('ticketDetail.livesports.oddKey.' + odd.oddKey);
      });
    });

    res.betTypeGroups = Object.entries(betTypes.reduce((acc, person) => {
      const key = person.betTypeKey;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(person);

      return acc;
    }, {})).map(group => {
      const groupedBetTypes = group[1] as MeBetType[];

      groupedBetTypes.forEach(betType => {
        const oneXTwo = ['1', 'X', '2'];
        if (betType.odds.length === 3 && betType.odds.every(odd => oneXTwo.indexOf(odd.oddLabel) > -1)) {
          betType.odds.sort((a, b) => {
            const order = oneXTwo;
            return order.indexOf(a.oddLabel) - order.indexOf(b.oddLabel);
          });
        }
      });

      const betTypeKey = +group[0];
      return {
        betTypeKey: betTypeKey,
        betTypeLabel: groupedBetTypes[0].betTypeLabel,
        expanded: oldEvent ? !!oldEvent.betTypeGroups.find(btg => btg.betTypeKey === betTypeKey)?.expanded : true,
        hide: oldEvent ? !!oldEvent.betTypeGroups.find(btg => btg.betTypeKey === betTypeKey)?.hide : false,
        betTypes: groupedBetTypes,
        sumNBets: groupedBetTypes.reduce((acc, betType) => acc + betType.nBets, 0),
        sumNTickets: groupedBetTypes.reduce((acc, betType) => acc + betType.nTickets, 0),
        sumMaxProfit: groupedBetTypes.reduce((acc, betType) =>
          acc + betType.odds.reduce((accOdds, odds) => accOdds + odds.maxProfit, 0), 0),
        anyClearbet: groupedBetTypes.some(betType => betType.clearbet),
        anyCancelled: groupedBetTypes.some(betType => betType.cancelled),
        anyPending: groupedBetTypes.some(betType => !betType.cancelled && (!betType.clearbet || (!betType.cleared && !betType.calculated))),
      };
    });
  }

  getBetTypeOrder(): number[] {
    const rank: number[] = [];

    rank[BetTypeKey.WAY_3] = 0.1;
    rank[BetTypeKey.WAY_3_OT] = 1;
    rank[BetTypeKey.WAY_2] = 2;
    rank[BetTypeKey.PENALTY_SHOOTOUT] = 3;
    rank[BetTypeKey.TENNIS_MATCH_WINNER] = 3.1;
    rank[BetTypeKey.TENNIS_MATCH_WINNER] = 4;
    rank[BetTypeKey.ADVANCE_NEXT_ROUND] = 5;

    rank[BetTypeKey.REST_OF_MATCH] = 6;
    rank[BetTypeKey.REST_OF_MATCH_OT] = 7;
    rank[BetTypeKey.UNDER_OVER] = 8;
    rank[BetTypeKey.UNDER_OVER_OT] = 9;
    rank[BetTypeKey.NEXT_GOAL] = 10;
    rank[BetTypeKey.NEXT_GOAL_OT] = 11;

    rank[BetTypeKey.HANDICAP] = 12;
    rank[BetTypeKey.GOAL_NOGOAL] = 12.5;
    rank[BetTypeKey.DOUBLE_CHANCE] = 13;
    rank[BetTypeKey.DNB] = 15;

    rank[BetTypeKey.WAY_3_H1] = 16;
    rank[BetTypeKey.REST_OF_MATCH_H1] = 17;
    rank[BetTypeKey.UNDER_OVER_H1] = 18;
    rank[BetTypeKey.NEXT_GOAL_H1] = 19;
    rank[BetTypeKey.HANDICAP_H1] = 19.1;
    rank[BetTypeKey.PERIOD_WINNER] = 19.5;
    rank[BetTypeKey.GOAL_NOGOAL_H1] = 20;
    rank[BetTypeKey.DOUBLE_CHANCE_H1] = 21;

    rank[BetTypeKey.WAY_3_UNDER_OVER] = 21.1;
    rank[BetTypeKey.WAY_3_GOAL_NOGOAL] = 21.2;
    rank[BetTypeKey.UNDER_OVER_GOAL_NOGOAL] = 21.3;
    rank[BetTypeKey.WAY_3_INT_10] = 21.4;

    rank[BetTypeKey.TENNIS_SET_WINNER] = 22;
    rank[BetTypeKey.TENNIS_RESULTS_BEST3] = 23;
    rank[BetTypeKey.TENNIS_RESULTS_BEST5] = 24;
    rank[BetTypeKey.TENNIS_SETS_COUNT_3] = 25;
    rank[BetTypeKey.TENNIS_SETS_COUNT_5] = 26;
    rank[BetTypeKey.TENNIS_GAMES_WINNER] = 27;
    rank[BetTypeKey.TENNIS_GAME_X_WINNER] = 28;
    rank[BetTypeKey.TENNIS_UNDER_OVER] = 29;
    rank[BetTypeKey.TENNIS_SET_UNDER_OVER] = 30;
    rank[BetTypeKey.TENNIS_SET_CORRECT_SCORE] = 31;

    rank[BetTypeKey.RACE_X_POINTS] = 32;
    rank[BetTypeKey.SCORE_X_POINT] = 33;

    rank[BetTypeKey.HT_FT] = 34;
    rank[BetTypeKey.HIGHEST_SCORE_HALF] = 35;
    rank[BetTypeKey.TOTAL_GOALS] = 36;
    rank[BetTypeKey.TOTAL_GOALS_EXACT] = 36.1;
    rank[BetTypeKey.TOTAL_GOALS_EXACT_H1] = 36.2;
    rank[BetTypeKey.UNDER_OVER_HOMETEAM] = 36.3;
    rank[BetTypeKey.UNDER_OVER_AWAYTEAM] = 36.4;
    rank[BetTypeKey.GOAL_HOMETEAM] = 37;
    rank[BetTypeKey.GOAL_AWAYTEAM] = 38;
    rank[BetTypeKey.GOAL_ODDEVEN] = 39;
    rank[BetTypeKey.FIRST_GOAL_INT_15] = 40;
    rank[BetTypeKey.LAST_GOAL] = 41;

    rank[BetTypeKey.KICKOFF] = 42;
    rank[BetTypeKey.DNB] = 43;

    rank[BetTypeKey.WAY_3_H2] = 44;
    rank[BetTypeKey.UNDER_OVER_H2] = 45;
    rank[BetTypeKey.HANDICAP_H2] = 45.1;
    rank[BetTypeKey.GOAL_NOGOAL_H2] = 45.2;
    rank[BetTypeKey.DOUBLE_CHANCE_H2] = 45.3;
    rank[BetTypeKey.TOTAL_GOALS_EXACT_H2] = 45.4;

    rank[BetTypeKey.PLAYER_SENT_OFF] = 46;
    rank[BetTypeKey.CORNER_WAY3] = 47;
    rank[BetTypeKey.CORNER_TOTAL] = 48;
    rank[BetTypeKey.UNDER_OVER_CORNER] = 48.1;

    rank[BetTypeKey.OUTRIGHT_WINNER] = 49;

    rank[BetTypeKey.CORRECT_SCORE] = 50;
    rank[BetTypeKey.CORRECT_SCORE_LIVE] = 51;
    rank[BetTypeKey.CORRECT_SCORE_H1] = 51.1;
    rank[BetTypeKey.CORRECT_SCORE_LIVE_H1] = 51.2;

    rank[BetTypeKey.NONE] = 52;
    rank[BetTypeKey.OTHER] = 53;

    return rank;
  }

}
