<mat-toolbar class="toolbar-warning" *ngIf="showTestDataWarning || showDifferentCurrencyWarning">
  <mat-toolbar-row *ngIf="showTestDataWarning">
    <mat-icon>warning</mat-icon>
    <span>{{'balance.testDataWarning' | translate}}</span>
    <button mat-raised-button (click)="updateIgnoreTest()">{{'balance.testFranchiserButton' | translate}}</button>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="showDifferentCurrencyWarning">
    <mat-icon>warning</mat-icon>
    <span>{{'balance.differentCurrencyWarning' | translate}}</span>
    <button mat-raised-button (click)="updateIntoEuro()">{{'balance.toEuroButton' | translate}}</button>
  </mat-toolbar-row>
</mat-toolbar>
<vit-search-form
  [showNow]="true"
  (submitEmiter)="fetchHoldOverview($event)">
</vit-search-form>
<ag-grid-angular
  *ngIf="rowData"
  (columnVisible)="storeColumnVisibilityFn('turnover', $event)"
  class="ag-theme-balham"
  style="width: 100%; flex: 1;"
  [gridOptions]="gridOptions"
  [tooltipShowDelay]="0"
  [suppressLoadingOverlay]="rowData === null"
  [rowData]="rowData | async"
  [pinnedTopRowData]="sumRowData"
  [columnDefs]="columnDefs"
  [detailCellRendererParams]="detailParams"
  (gridReady)="onGridReady($event)">
</ag-grid-angular>
<!-- 1223px -->
